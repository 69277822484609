import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import systemServices from './systemService'

const initialState = {
    verifyRCToken: {
        token: null, 
        verifyRCTokenLoading : false,
        verifyRCTokenError : false,
        verifyRCTokenSuccess : false,
        verifyRCTokenMessage : '',
    },
    contactus: {
        contactusLoading : false,
        contactusError : false,
        contactusSuccess : false,
        contactusMessage : '',
    },
    messages: {
        messages: [],
        messagesLoading : false,
        messagesError : false,
        messagesSuccess : false,
        deleteLoading: false,
        deleteMessage: '',
        messagesMessage : '',
    }
}


//Verify Recapcha Token
export const verifyRCToken = createAsyncThunk('System_Verify_RCToken', async (data, thunkAPI) => {
    try {           
        return await systemServices.verifyRCToken(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//send contact us message
export const sendContactUsMessage = createAsyncThunk('System_ContactUs_Message', async (data, thunkAPI) => {
    try {           
        return await systemServices.sendContactUsMessage(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Get contactus messages list
export const getMessages = createAsyncThunk('Staff_Get_Messages', async (search='', thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await systemServices.getMessages(search, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Get delete contactus message
export const deleteMessage = createAsyncThunk('Staff_Delete_Message', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await systemServices.deleteMessage(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


export const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {
        verifyRCTokenReset: (state) => {
            state.verifyRCToken.verifyRCTokenLoading = false
            state.verifyRCToken.verifyRCTokenError = false
            state.verifyRCToken.verifyRCTokenSuccess = false
            state.verifyRCToken.verifyRCTokenMessage = ''
            state.verifyRCToken.token = null
        },
        sendMessageReset : (state) => {
            state.contactus.contactusLoading = false
            state.contactus.contactusSuccess = false
            state.contactus.contactusError = false
            state.contactus.contactusMessage = ''
        }
    },
    extraReducers: (builder) => {
        builder
        //verify google recapcha token
        .addCase(verifyRCToken.pending, (state)=>{
            state.verifyRCToken.verifyRCTokenLoading = true
            state.verifyRCToken.verifyRCTokenSuccess = false
            state.verifyRCToken.verifyRCTokenError = false
        }) 
        .addCase(verifyRCToken.fulfilled, (state, action)=>{
            state.verifyRCToken.verifyRCTokenLoading = false
            state.verifyRCToken.verifyRCTokenSuccess = true
            state.verifyRCToken.verifyRCTokenError = false
            state.verifyRCToken.verifyRCTokenMessage = ''
            state.verifyRCToken.token = action.payload
        })  
        .addCase(verifyRCToken.rejected, (state, action)=>{
            state.verifyRCToken.verifyRCTokenLoading = false
            state.verifyRCToken.verifyRCTokenSuccess = false
            state.verifyRCToken.verifyRCTokenError = true
            state.verifyRCToken.verifyRCTokenMessage = action.payload
            state.verifyRCToken.token = null
            
        })
        //send contact us message
        .addCase(sendContactUsMessage.pending, (state)=>{
            state.contactus.contactusLoading = true
            state.contactus.contactusSuccess = false
            state.contactus.contactusError = false
        }) 
        .addCase(sendContactUsMessage.fulfilled, (state, action)=>{
            state.verifyRCToken.verifyRCTokenSuccess = true
            state.verifyRCToken.token = null
            state.contactus.contactusLoading = false
            state.contactus.contactusSuccess = true
            state.contactus.contactusError = false
            state.contactus.contactusMessage = ''            
        })  
        .addCase(sendContactUsMessage.rejected, (state, action)=>{
            state.contactus.contactusLoading = false
            state.contactus.contactusSuccess = false
            state.contactus.contactusError = true
            state.contactus.contactusMessage = action.payload
            
        })
        //get contact us messages
        .addCase(getMessages.pending, (state)=>{
            state.messages.messagesLoading = true
            state.messages.messagesSuccess = false
            state.messages.messagesError = false
        }) 
        .addCase(getMessages.fulfilled, (state, action)=>{
            state.messages.messagesLoading = false
            state.messages.messagesSuccess = true
            state.messages.messagesError = false
            state.messages.messagesMessage = ''     
            state.messages.messages = action.payload       
        })  
        .addCase(getMessages.rejected, (state, action)=>{
            state.messages.messagesLoading = false
            state.messages.messagesSuccess = false
            state.messages.messagesError = true
            state.messages.messagesMessage = action.payload
            state.messages.messages = []            
        })
        //delete contact us message
        .addCase(deleteMessage.pending, (state)=>{
            state.messages.deleteLoading = true
        }) 
        .addCase(deleteMessage.fulfilled, (state, action)=>{
            state.messages.messages = state.messages.messages.filter(x=>x._id !== action.payload)  
            state.messages.deleteLoading = false                 
        })  
        .addCase(deleteMessage.rejected, (state, action)=>{
            state.messages.deleteLoading = false    
            state.messages.deleteMessage = action.payload          
        })

    },
})


export const { verifyRCTokenReset, sendMessageReset } = systemSlice.actions
export default systemSlice.reducer