import {useState, useEffect} from 'react'
//import styles from './customershipmentscreen.module.css'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MyPagination, MySpinner, AlertMessage, useWindowSize } from '../../Components'
import { getShipments, listReset } from '../../features/shipment/shipmentSlice'
import { MdCircle, MdExitToApp } from 'react-icons/md'

const StaffShipmentListScreen = () => {
  const size = useWindowSize()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {searchKeyword} = useParams()
  const {pageNumber} = useParams()
  const [keyword, setKeyword] = useState('')  

  useEffect(()=>{
    dispatch(getShipments({searchKeyword, pageNumber, limit: 20}))
    return()=>{
      dispatch(listReset())
    }
  },[dispatch, searchKeyword, pageNumber])

  const { shipments, listLoading, listError, listSuccess, listMessage, page, pages } = useSelector(state=>state.shipment.list)

  return (
    <>   
      <div className='mb-3'>
        <Row>
          <Col md={6} >
              <Form.Control
                type='text'
                size='sm'
                value={keyword}
                placeholder={searchKeyword ? searchKeyword : 'search...'}
                onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
                onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                    if(keyword === ''){
                      navigate(`/staff/shipments`)
                    } else {
                      navigate(`/staff/shipments/search/${keyword}`)
                    }                    
                  }
                }}
              />
              <div style={{fontSize: '13px'}} className='text-danger'>Search: Shipment ID, Customer ID, Invoice ID, Sender Name, Receiver Name, Sender Email</div>
              {/* <Link to={`/staff/items/search/${keyword}`}>
                <Button variant="outline-secondary" >
                  Search
                </Button>
              </Link> */}
          </Col>
          <Col md={3} xs={6}>
            <div>
              <Button size='sm' onClick={()=>navigate('/staff/shipments/create')}>Create New Shipment</Button>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <MyPagination
              path={keyword !== "" ? `staff/shipments/search/${keyword}` : 'staff/shipments'} 
              pages={pages}
              page={page}
           />
          </Col>
        </Row>
        
      </div>

      {listError && <AlertMessage msg={listMessage} />}
      {listLoading && <MySpinner /> }
      
      {listSuccess && 
      <div className='my-3'>
        {size.width > 750 ? 
        <>
          <div className='text-danger text-end' style={{fontSize:'13px'}}>C=Cutoff, S=Shipped, R=Received, P=Paid, D=Delivered</div> 
          <Table striped hover bordered size='sm'>
            <thead>
              <tr className='text-center'>
                <th>#</th>
                <th>ID</th>
                <th>Type</th>
                <th>Box Qty</th>
                <th>From/To</th>
                <th>Total Weight</th>
                <th>Created</th>
                <th>C</th>
                <th>S</th>
                <th>R</th>
                <th>P</th>
                <th>D</th>
                <th>Detail</th>
              </tr>
            </thead>
            <tbody>
              {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
                <tr key={s._id} className='text-center'>
                  <td>{i+1}</td>
                  <td>{s.shipmentId}</td>
                  <td>{s.cargoType.toUpperCase()}</td>
                  <td>{s.boxes.length}</td>
                  <td>
                    <div style={{fontSize:'13px'}}>
                      <div>{s.sender.name +', '+ s.sender.address.city +', '+  s.sender.address.country}</div>
                      <div>{s.receiver.name +', '+ s.receiver.address.city +', '+  s.receiver.address.country}</div>
                    </div>
                  </td>
                  <td>{s.totalWeight.toFixed(2)}</td>
                  <td>{new Date(s.createdAt).toLocaleDateString('en-TH',{day: '2-digit', month: 'short'})}</td>
                  <td>{s.isCutOffed ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                  <td>{s.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                  <td>{s.isReceived ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                  <td>{s.isPaid ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                  <td>{s.isDelivered ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                  <td className='text-center'>
                    <Button 
                      size='sm' 
                      variant='outline-primary' 
                      style={{height: '30px', width: '50px'}} 
                      className='m-0 p-0'
                      onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                    ><MdExitToApp size={30}  /></Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
        :
        <Table striped hover bordered size='sm'>
            <thead>
              <tr>
                <th>#</th>
                <th>Detail</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
                <tr key={s._id}>
                  <td>{i+1}</td>
                  <td style={{fontSize: '15px'}}>
                    <div>ID - {s.shipmentId}</div>
                    <div>Cargo Type - {s.cargoType.toUpperCase()}</div>
                    <div>Box Qty - {s.boxes.length}</div>
                    <div>Receiver - {s.receiver.name}</div>
                    <div>{s.receiver.address.city}, {s.receiver.address.state}, {s.receiver.address.contry}</div>                  
                    <div>Total Weight - {s.totalWeight.toFixed(2)}</div>
                    <div>Paid - {s.isPaid ? 'Yes' : 'No'}</div>
                    {/* <div>Status - {s.tracking.length === 0 ? 'Shipment Created' : s.tracking[s.tracking.length-1].status}</div> */}
                  </td>
                  <td className='text-center'>
                    <Button 
                      size='sm' 
                      variant='outline-primary' 
                      style={{height: '40px', width: '50px'}} 
                      className='m-0 p-0'
                      onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                    ><MdExitToApp size={30}  /></Button></td>
                </tr>
              ))}
            </tbody>
          </Table>
        }
      </div>
      }
    </>
  )
}

export default StaffShipmentListScreen