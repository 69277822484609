import axios from 'axios'

const API_URL = '/api/system/boxsize'

//Add New boxsize
const addNewBoxsize = async (newItem, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, newItem, config)
    return response.data
}

//Get Boxsize list
const getBoxsizes = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

//Update boxsize
const updateBoxsize = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+'/' + data.editId, data.newBox, config)
    return response.data
}

//Delete boxsize
const deleteBoxsize = async (boxId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+'/'+ boxId, config)
    return response.data
}


const boxsizeServices = {
    addNewBoxsize,
    getBoxsizes,
    updateBoxsize,
    deleteBoxsize,
}

export default boxsizeServices