import { Badge } from "react-bootstrap"

import React from 'react'

const NoBadge = () => {
  return (
    <Badge pill className='bg-danger'>No</Badge>
  )
}

export default NoBadge