import { Row, Col, Card } from 'react-bootstrap'
import styles from './customercreateshipmentscreen.module.css'

const CargoType = ({cargoType, setCargoType}) => {
  return (
    <div>
        <h3>Please choose the service type you want to use.</h3>
        <Row>
            <Col md={4} onClick={()=>setCargoType('air')}>
                <Card 
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='air' && '2px solid var(--color-gold)', background: cargoType==='air' && 'var(--color-hover)'}}
                    >                            
                    <Card.Body>
                        <Card.Title>AIR</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">1 - 14 days</Card.Subtitle>
                        {/* <Card.Text>
                        Air Cargo
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4} onClick={()=>setCargoType('ocean')} >
                <Card 
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='ocean' && '2px solid var(--color-gold)', background: cargoType==='ocean' && 'var(--color-hover)'}}
                    >
                    <Card.Body>
                        <Card.Title>OCEAN</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">75 - 90 days</Card.Subtitle>
                        {/* <Card.Text>
                        Ocean Freight
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4} onClick={()=>setCargoType('express')}>
                <Card  
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='express' && '2px solid var(--color-gold)', background: cargoType==='express' && 'var(--color-hover)'}}
                    >
                    <Card.Body>
                        <Card.Title>EXPRESS OCEAN</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">30 days</Card.Subtitle>
                        {/* <Card.Text>
                        Express Ship
                        </Card.Text> */}
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
  )
}

export default CargoType