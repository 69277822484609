import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AlertMessage, MySpinner } from '../../Components'
import styles from './scanning.module.css'
import Html5QrcodePlugin from './Html5QrcodePlugin.jsx'
import ResultContainerPlugin from './ResultContainerPlugin.jsx'
import { Button, Form, InputGroup, Table } from 'react-bootstrap'
import {toast} from 'react-toastify'
import { boxesReset, getBoxesByShipmentId, markAsReceived } from '../../features/shipment/shipmentSlice'
import { MdCircle } from 'react-icons/md'

const ReceiveScan = (props) => {
    const dispatch = useDispatch()
    const [ scannerType, setScannerType ] = useState('phone')
    const [ decodedResults, setDecodedResults ] = useState([]);
    const [ shipmentCode, setShipmentCode ] = useState('')
    const [ handScanCode, setHandScanCode ] = useState('')

    const { boxes, boxesLoading, boxesError, boxesSuccess, boxesMessage } = useSelector(state=>state.shipment.boxes)
    const { receivedLoading, receivedError, receivedSuccess, receivedMessage, receivedList } = useSelector(state=>state.shipment.received)

    useEffect(()=>{
        if(receivedSuccess){
            setShipmentCode('')
            setDecodedResults([])
            dispatch(boxesReset())
        }
        return()=>{
            dispatch(boxesReset())
        }
    },[receivedSuccess, dispatch])

    const onNewScanResult = (decodedText, decodedResult) => {
        console.log("App [result]", decodedResult);
        setDecodedResults(prev => [...prev, decodedResult]);
    };

    const handleGetBoxes = () =>{
        if(shipmentCode === ''){
            toast.warning('Enter Shipment ID')
            return
        }
        dispatch(getBoxesByShipmentId({shipmentCode, requestFor: 'receive'}))
    }

    const handleMarkPicked = () => {
        let data = {
            type: 'shipment',
            shipmentId: shipmentCode
        }
        dispatch(markAsReceived(data))
    }

    const handleMarkPickedByPhoneScan = () => {
        if(decodedResults.length === 0){
            toast.warning('There is no scanned box')
            return
        }
        const decodedList = decodedResults.map(d=> d.decodedText)
        const decodedArray = [...new Set(decodedList)]
        let data = {
            type: 'phone',
            boxes: []
        }
        decodedArray.forEach(a=>{
            const sId = a.substring(0,10)
            const bId = a.substring(10,19)
            const index = data.boxes.findIndex(x=>x.shipmentId === sId)
            if(index === -1){
                data.boxes.push({shipmentId: a.substring(0,10),boxesIds: [a.substring(10,19)]})
            } else {
                data.boxes[index].boxesIds.push(bId)               
            }
        })
        dispatch(markAsReceived(data))
    }

    const handleMarkPickedByHandScan = (box) => {
        const boxId = box.substring(10,19)
        const shipmentId = box.substring(0, 10)
        //const shipmentId = boxData[0].substring(0,10)
        const data = {
            shipmentId,
            boxId,
            type: 'hand'
        }
        dispatch(markAsReceived(data))
    }

    return (
        <div className={styles.App}>  

            <div className={styles.App_section_title}><span className='bg-success px-2 rounded text-white'>Receiving</span> Scanning</div>      

            <div className='mb-3'>
                <Form.Select value={scannerType} onChange={({target})=>setScannerType(target.value)}>
                    <option value='phone'>Phone Browser Scan</option>
                    <option value='shipment'>Enter Shipment ID</option>
                    <option value='hand'>Hand Scanner</option>
                </Form.Select>
            </div>
            
            {receivedError && <AlertMessage msg={receivedMessage} type='danger' />}
            {boxesLoading && <MySpinner />}
            {receivedLoading && <MySpinner />}

            {scannerType === 'phone' && 
                <>
                    <ResultContainerPlugin results={decodedResults} />
                    <Button
                        variant='success'
                        className='mb-3'
                        onClick={()=>handleMarkPickedByPhoneScan()}
                    >Mark as Received</Button> 
                    <Html5QrcodePlugin
                        fps={10}
                        qrbox={250}
                        disableFlip={false}
                        qrCodeSuccessCallback={onNewScanResult}
                    />    
                    
                </>    
            } 
            
            {scannerType === 'shipment' &&
                <>
                    {boxesError && <AlertMessage msg={boxesMessage} type='danger' />}
                    <InputGroup className="mb-3">
                        <Form.Control
                            size='lg'
                            type='text'
                            value={shipmentCode}
                            maxLength={10}
                            onChange={({target})=>setShipmentCode(target.value.toUpperCase())}

                        />
                        <Button     
                            variant="secondary" 
                            size='lg'
                            onClick={()=>handleGetBoxes()}
                        >
                            Go
                        </Button>
                    </InputGroup>

                    {boxesSuccess && 
                    <>
                        <div className='text-start bg-warning-subtle p-2 mb-2' style={{fontSize: '14px'}}>
                            <div>From: {boxes.sender}</div>
                            <div>To: {boxes.receiver}</div>
                            <div>Cargo Type: {boxes.cargoType && boxes.cargoType.toUpperCase()}</div>
                            
                        </div>
                        <Table size='sm' bordered style={{fontSize: '14px'}}>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Box Detail</th>
                                </tr>
                            </thead>
                            <tbody>
                                {boxes.boxes.map((b,i)=>(
                                    <tr key={i}>
                                        <td className='text-center'>{i+1}</td>
                                        <td className='text-start'>
                                            <div>{b.boxId}</div>
                                            <div>{b.length}x{b.width}x{b.height} : {b.actualWeight} lbs</div>
                                            <div></div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </>
                    }
                    <Button
                        variant='success'
                        onClick={()=>handleMarkPicked()}
                        disabled={boxes===null}
                    >Mark as Received</Button>
                </>
            }
            {scannerType === 'hand' &&
            <>    
                

                <Form.Control
                    size='lg'
                    type='text'
                    className='mb-3'
                    value={handScanCode}
                    autoFocus
                    onChange={({target})=>setHandScanCode(target.value.toUpperCase())}
                    onKeyDown={e=>{
                        if(e.key === 'Enter'){
                            const box = e.target.value.toUpperCase()
                            handleMarkPickedByHandScan(box)
                            setHandScanCode('')
                        }
                    }}
                />
                <div >    

                    <div className='text-danger text-end' style={{fontSize:'13px'}}>S=Shipped, R=Received, D=Delivered</div>  
                    <Table bordered size='sm'>
                        <thead>
                            <tr className='bg-secondary-subtle'>
                                <td>#</td>
                                <td>Shipment ID</td>
                                <td>Box ID</td>
                                <td>S</td>
                                <td>R</td>
                                <td>D</td>
                            </tr>
                        </thead>
                        <tbody>
                            {receivedList.map((b, i) => {
                            return (
                                <tr key={i}>
                                    <td>{receivedList.length - i}</td>
                                    <td>{b.shipmentId}</td>
                                    <td>{b.boxId}</td>
                                    <td>{b.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                    <td>{b.isReceived ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                    <td>{b.isDelivered ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                </tr>
                            );
                            })}
                        </tbody>
                    </Table>
                </div>
            </>
            }
                 
        </div>
    );
};

export default ReceiveScan;