
import Barcode from 'react-barcode'

const CreateBarcode = ({width, height, margin, value}) => {
  return (
    <Barcode
        width= {width}
        height= {height}
        format= "CODE128"
        displayValue= 'true'
        fontOptions= ""
        font= "monospace"
        fontSize= '20'
        margin= {margin}
        value={value}
    />
  )
}

CreateBarcode.defaultProps = {
    width: '3',
    height: '50',
    fontSize: '20',
    margin: '20',
    value:"123456789",
}

export default CreateBarcode