import { useState, useEffect } from 'react'
import Alert from 'react-bootstrap/Alert'
    
const AlertMessage = ({ type, msg }) => {
  const [show, setShow] = useState(true)

  // On componentDidMount set the timer
  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setShow(false)
    }, 30000)

    return () => {
      clearTimeout(timeId)
    }
  }, []);

  // If show is false the component will return null and stop here
  if (!show) {
    return null;
  }

  // If show is true this will be returned
  return (
    <Alert variant={type} className='my-3' onClose={() => setShow(false)} dismissible>
      {msg}
    </Alert>
  )
}

AlertMessage.defaultProps = {
  type: 'info',
}

export default AlertMessage;