import { useState, useEffect } from 'react'
import axios from 'axios'
import { Form, Button, Row, Col, Alert, Image, Modal, Card } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { addUrlToGallery, getUrlsGallery, deleteUrlGallery } from '../../features/gallery/gallerySlice'
import { AlertMessage, MySpinner } from '../../Components'
import { MdClose } from 'react-icons/md'

const StaffGalleryScreen = () => {
    const dispatch = useDispatch()
    const [uploadFile, setUploadFile] = useState("")
    const [cdError, setCdError] = useState('')
    const [cdLoading, setCdLoading] = useState(false)
    const { urlList, listLoading, listError, listSuccess, listMessage } = useSelector(state=>state.gallery.list)
    const { addLoading, addError, addMessage } = useSelector(state=>state.gallery.add)
    const { deleteLoading, deleteError, deleteMessage } = useSelector(state=>state.gallery.delete)

    const [show, setShow] = useState(false)
    const [viewImg, setViewImg] = useState('')
    const handleClose = ()=> setShow(false)

    useEffect(()=>{
        dispatch(getUrlsGallery())
    },[dispatch])

    const handleUpload = async (e) => {
        e.preventDefault()
        setCdLoading(true)
        const formData = new FormData()
        formData.append("file", uploadFile)
        formData.append("upload_preset", process.env.REACT_APP_CLOUDINAY_PRESET)
     
        try {
            const response = await axios.post(
                "https://api.cloudinary.com/v1_1/dwsuz7osd/image/upload",
                formData
            )
            if(response.statusText === "OK"){
                setUploadFile("")
                setCdError("")
                //save image url to nyss server
                const data = {
                    imageUrl: response.data.secure_url,
                    cloudinaryId: response.data.public_id
                }
                setCdLoading(false)
                dispatch(addUrlToGallery(data))
            } 
        } catch (error) {
            cdLoading(false)
            setCdError(error.response.data.error.message ? error.response.data.error.message : 'cloudinary error')
        }
    }


    return (
        <div>
            <div className='border rounded p-3'>
                {cdError && <Alert variant='danger'>{cdError}</Alert>}
                <h3> Upload New Images</h3>
                <Row>
                    <Col xs={9}>
                        <Form.Control 
                            type="file" 
                            accept='Image/*'
                            onChange ={({target}) => {setUploadFile(target.files[0]);}} 
                        />
                    </Col>
                    <Col xs={3} className='d-flex'>
                        <Button 
                            onClick = {handleUpload} 
                            disabled={uploadFile === ""}
                            className='ms-auto' 
                            style={{width: '100px'}}
                            variant='success'
                        >Save</Button>
                    </Col>
                </Row>
            </div>

            <div>
                {(listLoading || addLoading || deleteLoading || cdLoading) && <MySpinner />}
                {listError && <AlertMessage type='danger' msg={listMessage} />}
                {addError && <AlertMessage type='danger' msg={addMessage} />}
                {deleteError && <AlertMessage type='danger' msg={deleteMessage} />}

                {/* {listSuccess && urlList.map(u=>(
                    <div key={u._id} style={{width: '200px'}}>
                        <Button onClick={()=>dispatch(deleteUrlGallery(u._id))}>Delete</Button>
                        <Image src={u.imageUrl} className='w-100' />
                    </div>
                ))} */}

                <Row>
                    {listSuccess && urlList.map((im,i)=>(
                        <Col xs={6} sm={6} md={4} lg={3} xl={2} key={i} className='p-2'>  
                            <Card className='m-0 p-0'>  
                                <Card.Header className='m-0 py-0 px-1'>
                                    <div className='d-flex justify-content-between'>
                                        <div style={{fontSize: '13px'}} className='pt-1'>
                                            {new Date(im.createdAt).toLocaleDateString('en-US',{month: 'short', year: '2-digit'})}
                                        </div>
                                        <div><Button 
                                            size='sm' 
                                            variant='flush'
                                            onClick={()=>dispatch(deleteUrlGallery(im._id))} 
                                            className='m-0 py-0 px-2'><MdClose color='red' /></Button></div>
                                    </div>
                                </Card.Header> 
                                <Card.Body className='m-0 p-0'>                
                                    <Image 
                                        className='w-100 rounded shadow-sm' 
                                        src={im.imageUrl} 
                                        thumbnail 
                                        style={{cursor: 'pointer', height: '150px', objectFit: 'cover'}}
                                        onClick={()=>{
                                            setViewImg(im.imageUrl)
                                            setShow(true)
                                        }}
                                    />
                                </Card.Body>  
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <Modal show={show} onHide={handleClose} size='lg'>              
                <Image
                    src={viewImg}                        
                    className='h-100'
                    onClick={()=>{
                        setShow(false)
                    }}
                />                
            </Modal>
        </div>
    )
}

export default StaffGalleryScreen