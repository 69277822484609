import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import chargesnameServices from './chargesnameService'
import { toast } from 'react-toastify';

const initialState = {
    add: {
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        chargesnames: [],
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    delete: {
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    }
}


//Add new chargesname
export const addNewChargesName = createAsyncThunk('ChargesName_Add', async (newCharge, thunkAPI) => {
    try {    
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await chargesnameServices.addNewChargesName(newCharge, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get chargesnames
export const getChargesNameList = createAsyncThunk('ChargesName_List', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token
        return await chargesnameServices.getChargesNameList(token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//delete charges name
export const deleteChargesName = createAsyncThunk('ChargesName_Delete', async (chargesId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await chargesnameServices.deleteChargesName(chargesId, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const chargesNameSlice = createSlice({
    name: 'Chargesname',
    initialState,
    reducers: {
        reset: (state) => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addNewChargesName.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
            state.add.addMessage = ''
        }) 
        .addCase(addNewChargesName.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            toast.success('new charge name added successfully.')
            state.list.chargesnames = [action.payload, ...state.list.chargesnames]
        })  
        .addCase(addNewChargesName.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = true
            state.add.addMessage = action.payload
        })
        //get Boxsize list
        .addCase(getChargesNameList.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getChargesNameList.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.chargesnames = action.payload
        })  
        .addCase(getChargesNameList.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.chargesnames = []
        })
        //delete adn charges name 
        .addCase(deleteChargesName.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        }) 
        .addCase(deleteChargesName.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            toast.success('Charge name deleted successfully.')
            const chargeIndex = state.list.chargesnames.findIndex(e => e._id === action.payload)
            state.list.chargesnames.splice(chargeIndex,1)
        })  
        .addCase(deleteChargesName.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })


    },
})


export const { reset, addReset } = chargesNameSlice.actions
export default chargesNameSlice.reducer