
import styles from './header.module.css'
import { LinkContainer } from 'react-router-bootstrap'
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { usflag, mmflag, thflag} from '../../assets'
import { useDispatch, useSelector } from 'react-redux';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { logout } from '../../features/staff/staffSlice'
import { customerLogout } from '../../features/customer/customerSlice';

const lngs = {
  en: {nativeName: 'English', flag: usflag},
  mm: {nativeName: 'မြန်မာ', flag: mmflag},
  th: {nativeName: 'ไทย', flag: thflag},
}

const Header = () => {
  const dispatch = useDispatch()
  const { i18n,t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const { customer } = useSelector(state=>state.customer.auth)
  const { staff } = useSelector(state=>state.staff.auth)
  useEffect(()=>{
    const navbar = document.querySelector(`.${styles.nav_fixed}`);
    // const navbar = document.querySelector('.nav_fixed');
    window.onscroll = () => {
        if (window.scrollY > 1) {
            navbar.classList.add(`${styles.nav_active}`);
        } else {
            navbar.classList.remove(`${styles.nav_active}`);
        }
    }
  })
  const handleStaffLogout = () => {
    navigate('/staff')
    dispatch(logout())    
  }

   const handleCustomerLogout = () => {
    navigate('/login')
    dispatch(customerLogout())    
  }
  return (
    <>
      {location.pathname.startsWith('/staff') ? 
        <>
          {staff && 
            <div className={styles.nav_staff}>
              <span className={styles.nav_fixed}></span>
              <Navbar variant="flush" bg="transparent" expand="lg" className='fs-normal'>
                <Container fluid>
                    <Nav>
                      <NavDropdown title={staff.name} className='fs-5'>
                        <LinkContainer to={'/staff/myaccount'}>
                          <NavDropdown.Item>My Account</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Divider />
                          <NavDropdown.Item
                            onClick={handleStaffLogout}
                          >Logout</NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                </Container>
              </Navbar>
            </div>
          }
        </>
      :
        <header className={styles.nav_fixed}>
          <div className={styles.header_widescreen}>
            <div className={styles.header_logo}>
              <LinkContainer to="/">        
                <div className={styles.header_name}>
                  <h2>NYSS</h2>
                  <h2>NYSS</h2>
                </div>
              </LinkContainer>
            </div>        
            {location.pathname === '/' &&
              <div className={styles.header_languages}>
                {Object.keys(lngs).map((lng)=>(
                  <div key={lng} onClick={()=> i18n.changeLanguage(lng)} style={{cursor: 'pointer'}} >
                    <img src={lngs[lng].flag} alt={lngs[lng].nativeName} className={i18n.resolvedLanguage===lng ? `${styles.header_languages_selected}` : ''} />
                  {/* <button type='submit' >{lngs[lng].nativeName}</button> */}
                  </div>
                ))}
              </div>
            }    
            {customer ? 
              <div className={styles.header_user}>
                 <NavDropdown title={customer.name} id="nav-dropdown">
                  <NavDropdown.Item onClick={()=>navigate('/shipments')}>Shipments</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>navigate('/ositems')}>Os Items</NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>navigate('/account')}>Account</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={handleCustomerLogout}>Logout</NavDropdown.Item>
                </NavDropdown>
              </div>
              :  
              <div className={styles.header_login}>
                <button onClick={(e)=>{
                  switch (e.detail) {
                    case 1: {
                      navigate('/login')
                      break;
                    }
                    case 2: {
                      navigate('/staff')
                      break;
                    }
                    default: {
                      break;
                    }
                  }
                }}>
                  {t('LOGIN')}
                </button>
              </div>
            }    
            
          </div>
        </header>
      }    
    </>
  )
}

export default Header