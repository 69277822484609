import { Form,Button, Container, Col, Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { updateAccount, updateReset } from '../../features/staff/staffSlice'
import { AlertMessage } from '../../Components'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { toast } from 'react-toastify'
import { countries } from '../../data/countries'


const StaffAccountUpdateScreen = () => {
  const location = useLocation()  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState(location.state ? location.state.email : '')
  const [name, setName] = useState(location.state ? location.state.name : '')
  const [position, setPosition] = useState(location.state && location.state.position ? location.state.position : '')
  const [staffLocation, setStaffLocation] = useState(location.state && location.state.location ? location.state.location : '')
  const [admin, isAdmin] = useState(location.state && location.state.isAdmin ? 'true' : 'false')
  const [password, setPassword] = useState('')
  const [passView, setPassView] = useState(false)
  const [suspend, setSuspend] = useState(location.state.isSuspended)

  const { updateError, updateLoading, updateSuccess, updateMessage} = useSelector(state => state.staff.update)
  const { _id } = useSelector(state=>state.staff.auth.staff)

  useEffect(()=>{
    if(updateSuccess){
      navigate('/staff/accounts')
    }
    return()=>{
      dispatch(updateReset())
    }
  },[updateSuccess, navigate, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    const staffId = location.state._id
    if(password !== '' && password.length < 8){
      toast.error('Password minimum length is 8 characters')
      return
    }
    const updatedStaff = {
        name,
        email,
        password,
        location: staffLocation,
        position,
        admin: admin==='true' ? true : false,
        suspend
    }
    //console.log(updatedStaff)
    dispatch(updateAccount({staffId, updatedStaff}))
  }
  return (
    <>
    {location.state ?      
    <Container>
      <Col lg={8} className='mx-auto mt-5'>      
      <Form className='border p-4 shadow rounded' onSubmit={handleSubmit}>
        <h3 className='text-center'>{updateLoading ? <Spinner /> : 'Update staff account'}</h3>
        {updateError && < AlertMessage msg={updateMessage} />}
        <Form.Group className="mb-3" >
          <Form.Label>Full Name *</Form.Label>
          <Form.Control 
            type="text" 
            value={name}
            required
            onChange={e=>setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label>Email address *</Form.Label>
          <Form.Control 
            type="email" 
            value={email}
            required
            onChange={e=>setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>New Password * <span className='text-danger'>(min 8 characters)</span></Form.Label>
          <Form.Control 
            type={passView ? 'text' : "password" }
            value={password}
            onChange={e=>setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="passwordView">
          <Form.Check type="checkbox" checked={passView} label="View password" onChange={()=>{setPassView((prev)=>!prev)}} />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>Position <span className='text-muted'>(Optional)</span></Form.Label>
          <Form.Control 
            type="text" 
            value={position}
            onChange={e=>setPosition(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
            <Form.Label>Location <span className='text-muted'>(Optional)</span></Form.Label>
            <Form.Select value={staffLocation} aria-label="User Location" placeholder='Location' className="mb-3" onChange={(e)=>setStaffLocation(e.target.value)}>
                {countries.map(c=>(
                  <option key={c.name} value={c.value}>{c.name}</option>
                ))}
            </Form.Select>
        </Form.Group>

        <Form.Group>
            <Form.Label>Admin Access</Form.Label>
            <Form.Select aria-label="Admin Access" className="mb-3" onChange={(e)=>isAdmin(e.target.value)}>
                <option value="">{location.state.isAdmin ? 'Yes' : 'No'}</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </Form.Select>
        </Form.Group>

        <Form.Group className="my-3 bg-danger text-white p-2 rounded" controlId="suspendAccount">
          <Form.Check type="checkbox" disabled={_id === location.state._id} defaultChecked={location.state.isSuspended} label="Suspend acount" onChange={()=>{setSuspend((prev)=>!prev)}} />
        </Form.Group>



        <div className='d-grid mt-5' >
        <Button variant="secondary" type="submit" className='mb-3'>
          Submit
        </Button>
        <LinkContainer to='/staff/accounts'>
          <Button variant='outline-secondary'>Cancel</Button>
        </LinkContainer>
        </div>
      </Form>
      </Col>
    </Container>
    :
    <Navigate to='/staff/accounts' />
    }
    </>
  )
}

export default StaffAccountUpdateScreen