const nyssData = {
    name: 'NY SEA SHIPPING',
    company: 'NYSS EXPRESS US, INC',
    street: '37-39 58th St, Woodside',
    city: 'New York',
    state: 'NY',
    postalcode: '11377',
    country: 'USA',
    phonenumber1: '1-718-899-8389 (US)',
    phonenumber2: '02-1076066 (TH)',
    email: 'nyssexpress@gmail.com',
    bankName: 'JP Morgan Chase',
    bankAccountNo: '673995590',
    abaNo: '02 1000021',
    zelle: 'nyssexpress.inc@gmail.com',
    vemo: 'nyssexpress@gmail.com'
}

export default nyssData