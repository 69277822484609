import React, {useState} from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux';

const SimpleConfirmModal = ({text, confirmFunction, passingData, variant, fontSize, fontColor, disabled}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <Button 
            variant={variant}
            size='sm' 
            className='m-0 p-0' 
            style={{fontSize: `${fontSize}`, color:`${fontColor}`}}
            onClick={handleShow}
            disabled={disabled}
            >
            
            {text}
        </Button>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title> Confirm ? </Modal.Title>
            </Modal.Header>
            <Modal.Body className='d-flex justify-content-between'>
               <Button variant="secondary" onClick={handleClose} className='w-100'>
                    No
                </Button>
                <div style={{width: '50px'}} />
                <Button variant='danger' className='ms-auto w-100'
                    onClick={()=>{
                        dispatch(confirmFunction(passingData))
                        setShow(false)
                    }}
                >
                    Yes
                </Button>
            </Modal.Body>
        </Modal>
        </>
    )
}

SimpleConfirmModal.defaultProps = {
  disabled : false
}

export default SimpleConfirmModal