import React, {useState} from 'react'
import { Modal, Button, Form, ModalHeader, Row, Col, Table, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { payShipment, deletePaymentShipment } from '../features/shipment/shipmentSlice'
import { AlertMessage, MySpinner, useWindowSize } from '../Components'

const PaymentModal = ({grandTotal, paymentDetail, isPaid}) => {
  const size = useWindowSize()
  const dispatch = useDispatch()
  const [amount, setAmount] = useState('')
  const [currency, setCurrency] = useState('USD')
  const [amountInUSD, setAmountInUSD] = useState('')
  const [method, setMethod] = useState('')
  const [methodOther, setMethodOther] = useState('')
  const [note, setNote] = useState('')
  const [show, setShow] = useState(false)
  const params = useParams()

  const { paymentLoading, paymentError, paymentMessage } = useSelector(state=>state.shipment.payment)
  const { paymentdeleteLoading, paymentdeleteError, paymentdeleteMessage } = useSelector(state=>state.shipment.paymentdelete)

  
  const handleShow = () => setShow(true)
  const handleClose = () => {
    setAmount('')
    setCurrency('USD')
    setAmountInUSD('')
    setMethod('')
    setMethodOther('')
    setNote('')
    setShow(false)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if(amount === '' || Number(amount) <= 0){
      toast.warning('Amount can not be empty or 0')
      return
    }
    if(method === ''){
      toast.warning('Payment Method is required.')
      return
    }
    if(currency !== 'USD' && amountInUSD === ''){
      toast.warning('Amount in USD can not be empty or 0')
      return
    }
    if(method === 'Other' && methodOther === ''){
      toast.warning('Method other can not be empty')
      return
    } 

    const totalPaidAmount = paymentDetail.reduce((acc, p) => acc + p.amountInUSD, 0)
    let payAmount = 0
    if(currency === 'USD'){
      payAmount = Number(amount)
    } else {
      payAmount = Number(amountInUSD)
    }

    if(grandTotal < (totalPaidAmount+payAmount)){
      toast.error('Error. Total paying amount is higher than amount to pay')
      return
    }

    const newPayment = {
      shipmentId: params.id,
      amount,
      currency,
      amountInUSD,
      method,
      methodOther,
      note,
    }   
    //console.log(newPayment)
    dispatch(payShipment(newPayment))
  }

  return (
    <>
      <Button variant="success" className='m-2' onClick={handleShow}>
        Payment
      </Button>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size='xl'
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment</Modal.Title>
        </Modal.Header>
        <ModalHeader>
          <Row className='w-100 justify-content-between m-auto fs-6'>
            <Col style={{width: '30%'}} className='text-center bg-info-subtle m-2 p-2 rounded'>Amount to Pay - $ {grandTotal.toFixed(2)}</Col>
            <Col style={{width: '30%'}} className='text-center bg-info-subtle m-2 p-2 rounded'>Amount Paid - $ {(paymentDetail.reduce((acc, p) => acc + p.amountInUSD, 0)).toFixed(2)}</Col>
            <Col style={{width: '30%'}} className='text-center bg-info-subtle m-2 p-2 rounded'>Balance - $ {(grandTotal - (paymentDetail.reduce((acc, p) => acc + p.amountInUSD, 0))).toFixed(2)} </Col>
          </Row>
        </ModalHeader>
        <div className='px-3 border-bottom'>
          <h3 className='text-center my-2'>Paid Payments List</h3>
          {paymentdeleteLoading && <MySpinner />}
          {paymentdeleteError && <AlertMessage msg={paymentdeleteMessage} />}
          {paymentDetail.length === 0 ?
          <Alert>No payment found.</Alert>:
          <>
            {size.width > 760 ? <Table striped bordered hover size="sm">
              <thead>
                <tr>
                  <th style={{width: '30px'}}>#</th>
                  <th style={{width: '100px'}}>Amount</th>
                  <th style={{width: '80px'}}>Currency</th>
                  <th style={{width: '200px'}}>Method</th>
                  <th style={{width: '110px'}}>Amount USD</th>
                  <th style={{width: '100px'}}>Date</th>
                  <th >User & Note</th>
                  <th style={{width: '80px'}}>Remove</th>
                </tr>
              </thead>
              <tbody>
                {paymentDetail.map((p,i)=>(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>{p.amount}</td>
                    <td>{p.currency}</td>
                    <td>{p.method}</td>
                    <td>{p.amountInUSD}</td>
                    <td>{new Date(p.paidAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: '2-digit'})}</td>
                    <td>
                      <div>{p.createdBy}</div>
                      {p.note !== '' && <div style={{fontSize: '13px'}}>Note: {p.note}</div>}
                    </td>
                    <td>
                      <Button
                        size='sm'
                        className='m-0 py-0 px-2'
                        variant='danger'
                        onClick={()=>dispatch(deletePaymentShipment({shipmentId: params.id, paymentId: p._id}))}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            :
            <Table striped bordered hover size="sm" style={{fontSize: '14px'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Symmary</th>                
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {paymentDetail.map((p,i)=>(
                  <tr key={i}>
                    <td>{i+1}</td>
                    <td>
                      <div>Amount - {p.amount}</div>
                      <div>Currency - {p.currency}</div>
                      <div>Method - {p.method}</div>
                      <div>Amount USD - {p.amountInUSD}</div>
                      <div>Date - {new Date(p.paidAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: '2-digit'})}</div>
                      <div>User - {p.createdBy}</div>
                      <div>Note - {p.note}</div>
                    </td>
                    <td>
                      <Button
                        size='sm'
                        className='m-0 py-0 px-2'
                        variant='danger'
                        onClick={()=>dispatch(deletePaymentShipment({shipmentId: params.id, paymentId: p._id}))}
                      >
                        x
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>}
          </>
          }
          
        </div>
        <Modal.Body>
          {paymentError && <AlertMessage msg={paymentMessage} />}
          {paymentLoading && <MySpinner />}
          <h3 className='mb-3'>New Payment</h3>
          <Row>
            <Col md={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control 
                  type='text'
                  value={amount}
                  onChange={({target})=>setAmount(target.value.replace(/[^0-9.]/g,""))}
                />
              </Form.Group>            
            </Col>
            <Col md={6} className='mb-3'>
              <Form.Group>
                <Form.Label>Currency</Form.Label>
                <Form.Select value={currency} onChange={({target})=>setCurrency(target.value)}>
                  <option value='USD'>USD</option>
                  <option value='MMK'>MMK</option>
                  <option value='THB'>THB</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {currency !== 'USD' &&
            <Form.Group className='mb-3'>
              <Form.Label>Amount in USD</Form.Label>
              <Form.Control 
                type='text'
                value={amountInUSD}
                onChange={({target})=>setAmountInUSD(target.value.replace(/[^0-9.]/g,""))}
              />
            </Form.Group> 
          }

          <Form.Group className='mb-3'>
            <Form.Label>Method</Form.Label>
            <Form.Select value={method} onChange={({target})=>setMethod(target.value)}>
              <option value=''>Select</option>
              <option value='Cash'>Cash</option>
              <option value='Zelle'>Zelle</option>
              <option value='Vemo'>Vemo</option>
              <option value='K pay'>K pay</option>
              <option value='Wave pay'>Wave pay</option>
              <option value='Bank Transfer'>Bank Transfer</option>
              <option value='Check'>Check</option>
              <option value='Other'>Other</option>
            </Form.Select>
          </Form.Group>

          {method === 'Other' &&
            <Form.Group className='mb-3'>
              <Form.Label>Payment Method Other</Form.Label>
              <Form.Control 
                type='text'
                value={methodOther}
                onChange={({target})=>setMethodOther(target.value)}
              />
            </Form.Group>  
          }
          
          <Form.Group className='mb-3'>
            <Form.Label>Note</Form.Label>
            <Form.Control 
              as='textarea'
              value={note}
              onChange={({target})=>setNote(target.value)}
            />
          </Form.Group>  
          
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleSubmit} disabled={isPaid}>Add Payment</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PaymentModal