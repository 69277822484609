import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { MdOutlineEditNote } from 'react-icons/md'
import { AlertMessage, MySpinner } from '../Components'
import { emptyCheck } from '../helpers';
import { toast } from 'react-toastify';
import { addressUpdateCustomer } from '../features/customerAccount/customerAccountSlice';

const AddressEditModal = ({address, customerId}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [empty, setEmpty] = useState([])
    const [receiverAddress, setReceiverAddress] = useState({
        name: address.name,
        email: address.email ? address.email : '',
        company: address.company ? address.company : '',
        street: address.street,
        city: address.city,
        state: address.state,
        postalcode: address.postalcode,
        country: address.country,
        phonenumber1: address.phonenumber1,
        phonenumber2: address.phonenumber2 ? address.phonenumber2 : '',
        remark: address.remark ? address.remark : '',
    })
    const { addressUpdateLoading, addressUpdateSuccess, addressUpdateError, addressUpdateMessage } = useSelector(state=>state.customerAccount.addressUpdate)
    useEffect(()=>{
        if(addressUpdateSuccess){
            setShow(false)
        }
    },[addressUpdateSuccess])
    const handleShow = () => setShow(true);

    const dataToCheck = [
      {'rname': receiverAddress.name},
      {'rstreet': receiverAddress.street},
      {'rcity': receiverAddress.city},
      {'rstate': receiverAddress.state},
      {'rpostalcode': receiverAddress.postalcode},
      {'rcountry': receiverAddress.country},
      {'rphonenumber1': receiverAddress.phonenumber1}
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        const emptyList = await emptyCheck(dataToCheck)
        if(emptyList.length !== 0){
            setEmpty(emptyList)
            toast.error('Please check required fields')
            return
        } else {
            //console.log(customerId, address._id, receiverAddress)
            const data = {customerId, addressId: address._id, receiverAddress}
            dispatch(addressUpdateCustomer(data))
        }
    }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <>
            <Button variant="secondary" onClick={handleShow} size='sm' className="me-2"><MdOutlineEditNote className="pb-1 pe-1" size={23} /></Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton><Modal.Title>Update address</Modal.Title> </Modal.Header>
                <Modal.Body>
                    {addressUpdateLoading && <MySpinner />}
                    {addressUpdateError && <AlertMessage msg={addressUpdateMessage} />}
                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Name <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.name}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, name: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rname')])
                            }}
                            isInvalid={empty.includes('rname')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Company</Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.company}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, company: e.target.value})
                            }}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Address <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.street}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, street: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rstreet')])
                            }}
                            isInvalid={empty.includes('rstreet')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>City <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.city}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, city: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rcity')])
                            }}
                            isInvalid={empty.includes('rcity')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>State/Province <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.state}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, state: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rstate')])
                            }}
                            isInvalid={empty.includes('rstate')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Postal Code <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.postalcode}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, postalcode: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rpostalcode')])
                            }}
                            isInvalid={empty.includes('rpostalcode')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Select Country <small className='text-danger'>* required</small></Form.Label>
                        <Form.Select value={receiverAddress.country}
                            onChange={(e)=>{
                            setReceiverAddress({...receiverAddress, country: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rcountry')])
                            }}
                            isInvalid={empty.includes('rcountry')}
                        >
                            <option value=''></option>
                            <option value='Thailand'>Thailand</option>
                            <option value='Myanmar'>Myanmar</option>
                            <option value='Lao'>Lao</option>
                            <option value='United States'>United States</option>
                        </Form.Select>
                    </Form.Group>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Group className="mb-2" controlId="receiver-phonenumber1">
                            <Form.Label className='text-muted'>Phone Number 1 <small className='text-danger'>* required</small></Form.Label>
                            <Form.Control 
                                type="tel" 
                                value={receiverAddress.phonenumber1}
                                onChange={({target})=>{
                                setReceiverAddress({...receiverAddress, phonenumber1: target.value})
                                setEmpty([...empty.filter(x=>x!=='rphonenumber1')])
                                }} 
                                isInvalid={empty.includes('rphonenumber1')}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2" controlId="receiver-phonenumber2">
                            <Form.Label className='text-muted'>Phone Number 2</Form.Label>
                            <Form.Control 
                                type="tel" 
                                value={receiverAddress.phonenumber2}
                                onChange={({target})=>setReceiverAddress({...receiverAddress, phonenumber2: target.value})} 
                            />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Email</Form.Label>
                        <Form.Control 
                            type='email'
                            value={receiverAddress.email}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, email: e.target.value})
                            }}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label className='text-muted'>Remark</Form.Label>
                        <Form.Control 
                            as='textarea'
                            value={receiverAddress.remark}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, remark: e.target.value})
                            }}
                        /> 
                    </Form.Group>
                  
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddressEditModal