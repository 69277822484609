import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './modals.module.css'
import { Modal, Card } from 'react-bootstrap'
import { MdMenuBook } from 'react-icons/md'

const AddressBook = ({receiver, setReceiver , sender, setSender}) => {
    const [show, setShow] = useState(false)

    const { addresses } = useSelector(state=>state.customer.auth.customer)
    const handleShow = () => setShow(true)
    const handleClose = () => {setShow(false)}
    return (
        <div className={styles.add_item_main}>
            <button onClick={handleShow}>
                <MdMenuBook size={20} /> Address Book
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                
                size='lg'
            >
                <Modal.Header closeButton>               
                    <Modal.Title>Choose Receiver</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                    
                    {addresses.map((ad, i)=>(
                        <Card
                            key={i}
                            onClick={()=>{
                                if(sender){
                                    setSender(ad)
                                } else if(receiver) {
                                    setReceiver(ad)
                                } else {
                                    return
                                }                                
                                setShow(false)
                            }}
                            className={styles.address_book_card}
                            
                        >
                            <Card.Body className='py-1 px-2'>
                                <Card.Title>{ad.name}</Card.Title>
                                {ad.company && <Card.Subtitle className="mb-2 text-muted d-inline">{ad.company}</Card.Subtitle>}
                                {ad.email && <Card.Subtitle className="mb-2 text-muted d-inline">{',  ' + ad.email}</Card.Subtitle>}
                                <Card.Text  className='mb-0'>
                                    {ad.street}, {ad.city}, {ad.state}, {ad.postalcode}, {ad.country}
                                </Card.Text>
                                <Card.Text  className='mb-0'>
                                    {ad.phonenumber1} {ad.phonenumber2 && ', ' + ad.phonenumber2}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        // <div className={styles.address_list_item} 
                        //     key={i}
                        //     onClick={()=>{
                        //         setReceiverAddress(ad)
                        //         setShow(false)
                        //     }}
                        // >
                        //     <div className={styles.address_list_header}>
                        //         <h5>{ad.name} </h5>
                        //         {ad.company && <h5> &nbsp; &nbsp;({ad.company})</h5>}
                        //     </div>
                        //     <div className={styles.address_list_email_ph}>
                        //         {ad.email && <p>{ad.email} &nbsp;</p>}
                        //         {ad.phone && <p> - {ad.phone}</p>}
                        //     </div>
                        //     <div className={styles.address_list_address}>
                        //         <p>{ad.street}, {ad.city}, {ad.state}, {ad.postalcode}, {ad.country}</p>
                        //     </div>
                        // </div>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddressBook