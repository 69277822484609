import { useRef } from 'react';
import styles from './customershipmentscreen.module.css'
import { Button, Col, Row, Table, Card } from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { MdPrint } from 'react-icons/md'
import nyssData from '../../../data/nyssData'
import { useSelector } from 'react-redux';

const PackingListScreen = () => {
    const componentRef = useRef()
    const { shipment } = useSelector(state=>state.shipment.detail)
    return (
        <div>           
            <div> Packing List </div>
                <div className='me-5 ms-auto mt-3'>
                    <ReactToPrint
                        
                        trigger={() => <Button variant='info ' style={{width: '100px'}}><MdPrint /> Print</Button>}
                        content={() => componentRef.current}
                    />
                </div>
                <divy className='d-flex justify-content-center w-100'>
                    <div className={`${styles.packing_list_main} d-print-flex`} ref={componentRef}>
                    <div className={`${styles.packing_list}`}>                            
                        
                        <div className='text-center fs-3 fw-bold py-1'>Packing List</div>                                
                        <div className='text-center'>Shipper: {nyssData.company}</div>                                
                        <div className='text-center mb-3'>{nyssData.street + ', ' + nyssData.state + ', ' + nyssData.postalcode}</div>                                
                        
                        <Row className='mb-3'>
                            <Col sm={6}>
                                <Card >
                                    <Card.Header>CUSTOMER ID : {shipment.customerId}</Card.Header>
                                    <Card.Body style={{fontSize: '14px'}}>
                                        <Row className='d-flex'>
                                            <Col sm={3}>NAME</Col>
                                            <Col sm={9}>{shipment.sender.name.toUpperCase()}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>PHONE</Col>
                                            <Col sm={9}>{shipment.sender.phonenumber1} {shipment.sender.phonenumber2 && ', ' + shipment.sender.phonenumber2}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>EMAIL</Col>
                                            <Col sm={9}>{shipment.sender.email.toUpperCase()}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card>
                                    <Card.Header>RECEIVER</Card.Header>
                                    <Card.Body style={{fontSize: '14px'}}>
                                        <Row className='d-flex'>
                                            <Col sm={3}>NAME</Col>
                                            <Col sm={9}>{shipment.receiver.name.toUpperCase()}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>PHONE</Col>
                                            <Col sm={9}>{shipment.receiver.phonenumber1} {shipment.receiver.phonenumber2 && ', ' + shipment.receiver.phonenumber2}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>ADDRESS</Col>
                                            <Col sm={9}>{(shipment.receiver.address.street + ', ' + shipment.receiver.address.city + ', ' + shipment.receiver.address.state + ', ' + shipment.receiver.address.country + ', ' + shipment.receiver.address.postalcode).toUpperCase()}</Col>
                                        </Row>
                                        {shipment.receiver.email && <Row className='d-flex'>
                                            <Col sm={3}>EMAIL</Col>
                                            <Col sm={9}>{shipment.receiver.email.toUpperCase()}</Col>
                                        </Row>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Card className='mb-3'>
                            <Card.Body>
                                <div>SHIPMENT ID : {shipment.shipmentId}</div>
                                <div>CARGO TYPE : {shipment.cargoType.toUpperCase()}</div>
                                <div>TERM : FOB</div>
                                <div>TOTAL INVOICE VALUE : $ {(shipment.boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + itm.price*itm.unit, 0)), 0)).toFixed(2)}</div>
                            </Card.Body>
                        </Card>

                        <Table bordered size="sm">
                            <thead style={{fontWeight: '300'}}>
                                <tr className='text-center'>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Product Name</th>
                                    <th style={{width: '50px'}}>Unit</th>
                                    <th style={{width: '100px'}}>Unit Price</th>
                                    <th style={{width: '100px'}} className='text-end'>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {shipment.items.length > 0 && shipment.items.map((itm,i)=>(
                                    <tr key={i}>
                                        <td className='text-center'>{i+1}</td>
                                        <td>{itm.product}</td>
                                        <td className='text-center'>{itm.unit}</td>
                                        <td className='text-center'>$ {itm.price.toFixed(2)}</td>
                                        <td className='text-end'>$ {(itm.unit * itm.price).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <div className='text-end fs-5'>Total Invoice Value - $ {(shipment.boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (itm.price*itm.unit), 0)), 0)).toFixed(2)}</div>
                                                 
                    </div>                   
                    </div>
                </divy>
        </div>
    )
}

export default PackingListScreen