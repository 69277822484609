import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import styles from './modals.module.css'
import { Modal, FormControl, FormLabel, Spinner, ListGroup, Row, Col, Button, Tabs, Tab, Alert } from 'react-bootstrap'
import { MdAdd } from 'react-icons/md'
import { getItems } from '../features/item/itemSlice';
import { AlertMessage } from '../Components'
import { toast } from 'react-toastify';
import { addItem, addReset } from '../features/item/itemSlice'
import { getOSItemListByCusId } from '../features/osItem/osItemSlice'

const AddItemToShipmentModal = ({boxes, setBoxes, boxIdx, customerId, customer}) => {
    const dispatch = useDispatch()
    const [ show, setShow ] = useState(false);
    const [ newItem, setNewItem ] = useState('')
    const [ searchKeyword, setSearchKeyword ] = useState('')
    const [ selectedList, setSelectedList ] = useState([])
    const { itemList, listLoading, listError, listMessage } = useSelector(state=>state.item.list)
    const { addError, addLoading, addSuccess, addMessage } = useSelector(state=>state.item.add)
    const { ositemlist, listLoading: osListLoading, listError: osListError, listSuccess: osListSuccess, listMessage: osListMessage} = useSelector(state=>state.ositem.list)
    const { staff } = useSelector(state=>state.staff.auth)

    useEffect(()=>{
        if(addSuccess){
            handleItemSelect(newItem)
            setNewItem('')
        }          
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[addSuccess])
    
 
    const handleShow = () => setShow(true);

    const handleClose = () => {
        setSearchKeyword('')
        setShow(false)
    }

    const handleItemSelect = (selectedItem) => {
        setSelectedList([...selectedList, selectedItem])
        const newBoxes = boxes
        const foundIndex = newBoxes[boxIdx].items.findIndex(e=>e.product === selectedItem)
        if(foundIndex === -1){
            newBoxes[boxIdx].items.push({product: selectedItem, unit: '', price: ''})
            setBoxes([...newBoxes])
            return
        } else {
            toast.warning(`${selectedItem} is already added to box ${boxIdx+1}.`)
            return
        }        
    }

    const handleOsItemSelect = (idx) => {
        const osItem = ositemlist[idx]
        setSelectedList([...selectedList, osItem.name])
        const newBoxes = boxes
        const foundIndex = newBoxes[boxIdx].items.findIndex(e=>e.product === osItem.name)
        if(foundIndex === -1){
            newBoxes[boxIdx].items.push({product: osItem.name, unit: osItem.qty, price: '', isOsItem: true})
            setBoxes([...newBoxes])
            return
        } else {
            toast.warning(`${osItem.name} is already added to box ${boxIdx+1}.`)
            return
        }        
    }
    return (
        <div>
            <button size='sm' variant='success' className={styles.item_add_button} style={{height: '25px'}} onClick={handleShow}><MdAdd /> Add Item</button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size='lg'
                style={{height: '80vh'}}
                onEnter={()=>{
                    dispatch(getOSItemListByCusId({customerId, customer}))
                    let itemsArray = []
                    boxes.forEach(b => {
                        let itemsArray2 = []
                        b.items.forEach(itm => {
                            itemsArray2.push(itm.product)
                        })
                        itemsArray.push(itemsArray2)
                    })
                    setSelectedList([...itemsArray])
                }}
                onExit={()=>{
                    setNewItem('')
                    dispatch(addReset())
                }}
                scrollable
            >
                <Modal.Header closeButton>
                {listLoading ?
                <>
                    <Spinner animation="border" />
                    <Modal.Title> Please wait getting item...</Modal.Title>
                </>
                :
                    <Modal.Title>Item List {addLoading && <Spinner />}</Modal.Title>
                }
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        defaultActiveKey="items"
                        id="item-tab"
                        className="mb-3"
                    >
                        <Tab eventKey="items" title="NYSS Items">
                            {listError && <AlertMessage msg={listMessage} />}
                            {addError && <AlertMessage msg={addMessage} />}
                            <FormLabel>Search item</FormLabel>
                            <FormControl 
                                type='text'
                                value={searchKeyword}
                                className='border-info'
                                autoFocus
                                onChange={e=>setSearchKeyword(e.target.value)}
                                onKeyDown={e=>{
                                    if(e.key === 'Enter'){
                                        if(e.target.value ===''){
                                            dispatch(getItems({searchKeyword: '', pageNumber: 1, limit: 100}))
                                        } else {
                                            dispatch(getItems({searchKeyword, pageNumber: 1, limit: 100}))
                                        }                                
                                    }
                                }}
                            />
                            <hr />

                            {staff &&
                                <div className='mb-2'>
                                    <div>New Item</div>
                                    <Row>
                                        <Col xs={10}>
                                            <FormControl 
                                                type='text'
                                                value={newItem}
                                                onChange={({target})=>{setNewItem(target.value)}}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Button 
                                                className='w-100'
                                                disabled={newItem === ''}
                                                onClick={()=>dispatch(addItem({name: newItem}))}
                                            >Add</Button>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            }
                            <ListGroup className={styles.item_list_main}>
                                {itemList.map(itm=>(
                                    <ListGroup.Item 
                                        key={itm._id} 
                                        value={itm.name}
                                        style={{height: '40px', margin: '0', padding: '7px 0 0 10px'}}
                                        className={selectedList.length !== 0 && selectedList[boxIdx].includes(itm.name) && 'bg-warning-subtle'}
                                        onClick={({target})=>handleItemSelect(target.outerText)}
                                    >{itm.name}</ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Tab>
                        <Tab eventKey="ositem" title={osListLoading ? <Spinner size='sm' style={{fontSize: '13px'}} /> : <div>Online Shopping Items</div>}>
                            {osListError && <AlertMessage msg={osListMessage} />}
                            {osListSuccess && ositemlist.length === 0 ? <Alert variant='info'>Customer doesn't have any online shopping item.</Alert> :
                            <ListGroup className={styles.item_list_main}>
                                {ositemlist.map((itm,i)=>(
                                    <ListGroup.Item 
                                        key={itm._id} 
                                        style={{height: '40px', margin: '0', padding: '7px 10px 0 10px'}}
                                        className={selectedList.length !== 0 && selectedList[boxIdx].includes(itm.name) && 'bg-warning-subtle'}
                                        onClick={()=>handleOsItemSelect(i)}
                                    >
                                        <div className='d-flex justify-content-between'>
                                            <div>{itm.name}</div>
                                            <div>{itm.qty}</div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                            }
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddItemToShipmentModal