import { useState, useEffect } from 'react'
import { Table, Button, Form, Alert } from "react-bootstrap"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addReset, addNewBoxsize, getBoxsizes, deleteBoxsize, updateBoxsize } from '../../features/boxsizes/boxsizeSlice'
import { MySpinner, AlertMessage } from '../../Components'
import { MdDangerous, MdSettings } from 'react-icons/md'

const StaffBoxsizeListScreen = () => {
    const dispatch = useDispatch()
    const [isAdd, setIsAdd] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [editId, setEditId] = useState('')
    const [length, setLength] = useState(0)
    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)


    const { addLoading, addError, addSuccess, addMessage } = useSelector(state=>state.boxsize.add)
    const { deleteLoading, deleteError, deleteSuccess, deleteMessage } = useSelector(state=>state.boxsize.delete)
    const { updateLoading, updateError, updateSuccess, updateMessage } = useSelector(state=>state.boxsize.update)
    const { boxsizes, listLoading, listError, listSuccess, listMessage } = useSelector(state=>state.boxsize.list)

    useEffect(()=>{
        dispatch(getBoxsizes())
    },[dispatch])

    useEffect(()=>{
        if(addSuccess){
            setLength(0)
            setWidth(0)
            setHeight(0)
            setIsAdd(false)
        }
        if(updateSuccess){
            setEditId('')
            setIsEdit(false)
            setLength(0)
            setWidth(0)
            setHeight(0)
            setIsAdd(false)
        }
    },[addSuccess, deleteSuccess, updateSuccess])

    const handleAddNew = () => {
        if(length === 0 || height === 0 || width === 0 || length === "" || width === "" || height === "" || length === "0" || width === "0" || height === "0"){
            toast.warning('Box size can not be 0 inch')
            return
        }
        const newBox = {
            l: Number(length),
            w: Number(width),
            h: Number(height)
        }
        if(isEdit){
            dispatch(updateBoxsize({editId, newBox}))
        } else {
            dispatch(addNewBoxsize(newBox))
        }        
    }
    return (
        <div>
            {addLoading && <MySpinner />}
            {listLoading && <MySpinner />}
            {deleteLoading && <MySpinner />}
            {updateLoading && <MySpinner />}
            {listError && <AlertMessage type='danger' msg={listMessage} />}
            {deleteError && <AlertMessage type='danger' msg={deleteMessage} />}
            
            <div className='d-flex justify-content-center'>
                <div style={{width: '500px'}}>
                        <div className="d-flex justify-content-between mb-3">
                            <div className="fs-2 text-center">Box Sizes List</div>
                            <div className="pt-2">
                                <Button
                                    size='sm'
                                    variant={isAdd ? 'secondary' : 'primary'}
                                    onClick={()=>{
                                        setLength(0)
                                        setWidth(0)
                                        setHeight(0)
                                        dispatch(addReset())
                                        setIsAdd(prev=>!prev)
                                    }}
                                >{isAdd ? 'Cancel' : 'Add New'}</Button>
                            </div>
                        </div>

                        {isAdd && 
                            <div className='mb-4 border border-dark p-2 rounded shadow' >
                                {/* style={{position:'absolute', width: '500px', background: 'white'}} */}
                                <div className='d-flex justify-content-between'>
                                    <div className='mx-1'>
                                        <div className='mb-1'>Length</div>
                                        <div >
                                            <Form.Control 
                                                type='number'
                                                style={{height: '30px'}}
                                                className='border-dark'
                                                value={length}
                                                onChange={({target})=>setLength(target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='mx-1'>
                                        <div className='mb-1'>Width</div>
                                        <div >
                                            <Form.Control 
                                                type='number'
                                                style={{height: '30px'}}
                                                className='border-dark'
                                                value={width}
                                                onChange={({target})=>setWidth(target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className='mx-1'>
                                        <div className='mb-1'>Height</div>
                                        <div >
                                            <Form.Control 
                                                type='number'
                                                style={{height: '30px'}}
                                                className='border-dark'
                                                value={height}
                                                onChange={({target})=>setHeight(target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {addError && <Alert variant='danger' className='my-2'>{addMessage}</Alert>}
                                {updateError && <AlertMessage type='danger' msg={updateMessage} />}
                                <div className='d-flex mt-3 pe-1'>
                                    <Button
                                        className='ms-auto'
                                        variant='success'
                                        style={{width: '100px'}}
                                        size='sm'
                                        onClick={handleAddNew}
                                    >Save</Button>
                                </div>
                            </div>
                        }
                        
                        <Table striped bordered hover variant="info" size='sm' className='text-center'>
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Boxsize</th>
                                <th>Edit</th>
                                <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listSuccess && boxsizes.map((bs,i)=>(
                                    <tr key={bs._id}>
                                        <td>{i+1}</td>
                                        <td>{bs.l} x {bs.w} x {bs.h}</td>
                                        <td>
                                            <Button
                                                className='m-0 px-2 py-0'
                                                size='sm'
                                                variant='warning'
                                                onClick={()=>{
                                                    setEditId(bs._id)
                                                    setIsEdit(true)
                                                    setLength(bs.l)
                                                    setWidth(bs.w)
                                                    setHeight(bs.h)
                                                    setIsAdd(true)
                                                }}
                                            >
                                                <MdSettings size={24} />
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                className='m-0 px-2 py-0'
                                                size='sm'
                                                variant='danger'
                                                onClick={()=>dispatch(deleteBoxsize(bs._id))}
                                            >
                                                <MdDangerous size={24} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                </div>
            </div>
        </div>
    )
}

export default StaffBoxsizeListScreen