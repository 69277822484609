import { useState, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Button, Card } from 'react-bootstrap'
import ReCAPTCHA from "react-google-recaptcha";
import { sendContactUsMessage, verifyRCToken, sendMessageReset } from '../features/system/systemSlice'
import { MySpinner, AlertMessage} from '../Components'
import { useNavigate } from 'react-router-dom';

const ContactUsScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const captchaRef = useRef(null)

  const { token:verifiedToken, verifyRCTokenLoading, verifyRCTokenSuccess, verifyRCTokenError, verifyRCTokenMessage} = useSelector(state=>state.system.verifyRCToken)
  const { contactusLoading, contactusSuccess, contactusError, contactusMessage} = useSelector(state=>state.system.contactus)

  useEffect(()=>{
    if(verifyRCTokenSuccess && verifiedToken?.success){
      const data = {
        name,
        email,
        message
      }
      dispatch(sendContactUsMessage(data))
    }
    if(contactusSuccess){
      setName('')
      setEmail('')
      setMessage('')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contactusSuccess, verifyRCTokenSuccess, verifiedToken, dispatch, navigate])

  const onSubmit = async (e) =>{
    e.preventDefault()
    let token = captchaRef.current.getValue();
    dispatch(verifyRCToken({token}))
    
  }

  return (
    <div style={{padding: '100px 5%'}}>
      
      <div className='w-100 d-flex justify-content-center'>
        <div style={{width: '500px'}} className='border p-4 rounded shadow'>
        <h3 className='mb-4'>Contact Us</h3>
        
        {verifyRCTokenLoading && <MySpinner />}
        {contactusLoading && <MySpinner />}
        {verifyRCTokenError && <AlertMessage type='danger' msg={verifyRCTokenMessage} />}
        {contactusError && <AlertMessage type='danger' msg={contactusMessage} />}
          {contactusSuccess ?
            <>
              <Card>
                <Card.Body>
                  <Card.Title>Thank You</Card.Title>
                  <Card.Link 
                    style={{cursor: 'pointer'}}
                    onClick={()=>{
                      dispatch(sendMessageReset())
                      navigate('/')
                    }}
                  >Back to home</Card.Link>
                </Card.Body>
              </Card>
            </>
            :
            <>
            <Form onSubmit={onSubmit}>
                <Form.Group className='mb-4'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control 
                    type='text'
                    value={name}
                    maxLength={40}
                    required
                    onChange={({target})=>setName(target.value.replace(/[^\w\s]/gi, ""))}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label>Email</Form.Label>
                  <Form.Control 
                    type='email'
                    value={email}
                    maxLength={40}
                    required
                    onChange={({target})=>setEmail(target.value)}
                  />
                </Form.Group>

                <Form.Group className='mb-4'>
                  <Form.Label>Message <span style={{color: 'red', fontSize: '12px'}}>({200-message.length} characters left)</span></Form.Label>
                  <Form.Control 
                    as='textarea'
                    value={message}
                    maxLength={200}
                    style={{height: '200px'}}
                    required
                    onChange={({target})=>setMessage(target.value)}
                  />
                </Form.Group>
                <div className=' mb-3'>
                  <ReCAPTCHA 
                    sitekey={process.env.REACT_APP_RECAP_SITE_KEY} 
                    ref={captchaRef} 
                  />
                </div>
                <Button
                  type='submit'
                  className='w-100'
                  variant='secondary'
                >Submit</Button>
              </Form>
            </>  
          }
        </div>
      </div>
    </div>
  )
}

export default ContactUsScreen