import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customerServices from './customerService'
import { toast } from 'react-toastify'

// Get user from localStorage
const customer = JSON.parse(localStorage.getItem('customer'))
const requester = JSON.parse(localStorage.getItem('requester'))

const initialState = {
    auth: {
        customer: customer ? customer : null,
        authError: false,
        authSuccess: false,
        authLoading: false,
        authMessage: '', 
    },
    request: {
        email: requester ? requester : null,
        requestConfirmed: false,
        requestError: false,
        requestSuccess: false,
        requestLoading: false,
        requestMessage: '', 
    },
    receiverAdd: {
        receiverAddError: false,
        receiverAddSuccess: false,
        receiverAddLoading: false,
        receiverAddMessage: '', 
    },
    receiverDelete: {
        receiverDeleteError: false,
        receiverDeleteSuccess: false,
        receiverDeleteLoading: false,
        receiverDeleteMessage: '', 
    },
    update: {
        updateError: false,
        updateSuccess: false,
        updateLoading: false,
        updateMessage: '', 
    },
    forgot: {
        forgotEmail: null,
        forgotError: false,
        forgotSuccess: false,
        forgotLoading: false,
        forgotMessage: '', 
    },
    confirm: {
        confirmEmail: null,
        confirmError: false,
        confirmSuccess: false,
        confirmLoading: false,
        confirmMessage: '', 
    },
    password: {
        passwordError: false,
        passwordSuccess: false,
        passwordLoading: false,
        passwordMessage: '', 
    },
}


//Register customer
export const requestRegister = createAsyncThunk('Customer_Request_Register', async (email, thunkAPI) => {
    try {
        return await customerServices.requestRegister(email)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Register confirm code
export const registerConfirmCode = createAsyncThunk('Customer_Confirm_Register', async (data, thunkAPI) => {
    try {
        return await customerServices.registerConfirmCode(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//have code
export const haveCode = createAsyncThunk('Customer_Request_Have_Code', async (email, thunkAPI) => {
    try {
        return email
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Register customer
export const register = createAsyncThunk('Customer_Register', async (newCustomer, thunkAPI) => {
    try {
        return await customerServices.register(newCustomer)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Register login
export const login = createAsyncThunk('Customer_Login', async (credential, thunkAPI) => {
    try {
        return await customerServices.login(credential)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Forgot password customer
export const forgotPasswordCustomer = createAsyncThunk('Customer_Forgot_Password', async (email, thunkAPI) => {
    try {
        return await customerServices.forgotPasswordCustomer(email)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//confirm password reset code customer
export const confirmPasswordResetCodeCustomer = createAsyncThunk('Customer_Confirm_Code', async (data, thunkAPI) => {
    try {
        return await customerServices.confirmPasswordResetCodeCustomer(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//confirm password reset code customer
export const changePasswordCustomer = createAsyncThunk('Customer_Change_Password', async (data, thunkAPI) => {
    try {
        return await customerServices.changePasswordCustomer(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//receiver new address save
export const newReceiverSave = createAsyncThunk('Shipment_My_ReceiverSave', async (address, thunkAPI) => {
    try {           
        return address
    } catch (error) {
        const message = 'Can not add new receiver address'
        return thunkAPI.rejectWithValue(message)
    }
})

//Logout user
export const customerLogout = createAsyncThunk('Customer_Logout', async () => {
    await customerServices.logout()
})



//delete receiver 
export const deleteReceiver = createAsyncThunk('Customer_Receiver_Delete', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().customer.auth.customer.token         
        return await customerServices.deleteReceiver(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//add customer receiver address by customer
export const addReceiver = createAsyncThunk('Customer_Receiver_Add', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().customer.auth.customer.token         
        return await customerServices.addReceiver(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update account detail
export const updateAccountDetail = createAsyncThunk('Customer_Account_Update', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().customer.auth.customer.token         
        return await customerServices.updateAccountDetail(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const customerSlice = createSlice({
    name: 'Customer',
    initialState,
    reducers: {
        authReset: (state) => {
            state.auth.authLoading = false
            state.auth.authError = false
            state.auth.authSuccess = false
            state.auth.authMessage = ''
        },
        requestReset: (state) => {
            state.request.email = null
            state.request.requestConfirmed = false
            state.request.requestError = false
            state.request.requestSuccess = false
            state.request.requestLoading = false
            state.request.requestMessage = ''
        },
        forgotReset: (state) => {
            state.forgot.forgotEmail = null
            state.forgot.forgotLoading = false
            state.forgot.forgotError = false
            state.forgot.forgotSuccess = false
            state.forgot.forgotMessage = ''
        },
        confirmReset: (state) => {
            state.confirm.confirmEmail = null
            state.confirm.confirmLoading = false
            state.confirm.confirmError = false
            state.confirm.confirmSuccess = false
            state.confirm.confirmMessage = ''
        },
        passwordReset: (state) => {
            state.password.passwordLoading = false
            state.password.passwordError = false
            state.password.passwordSuccess = false
            state.password.passwordMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(register.pending, (state)=>{
            state.auth.authLoading = true
            state.auth.authSuccess = false
            state.auth.authError = false
        }) 
        .addCase(register.fulfilled, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = true
            state.request.requestConfirmed = false
            state.auth.authError = false            
            state.auth.customer = action.payload
            state.request.requestSuccess = false
        })  
        .addCase(register.rejected, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = false
            state.auth.authError = true
            state.auth.authMessage = action.payload
            state.auth.customer = null
        })
        //request register
        .addCase(requestRegister.pending, (state)=>{
            state.request.requestLoading = true
            state.request.requestSuccess = false
            state.request.requestError = false
        }) 
        .addCase(requestRegister.fulfilled, (state, action)=>{
            state.request.requestLoading = false
            state.request.requestSuccess = true
            state.request.requestError = false
            state.request.email = action.payload
        })  
        .addCase(requestRegister.rejected, (state, action)=>{
            state.request.requestLoading = false
            state.request.requestSuccess = false
            state.request.requestError = true
            state.request.requestMessage = action.payload
        })
        // have code
        .addCase(haveCode.fulfilled, (state, action)=>{
            state.request.requestLoading = false
            state.request.requestSuccess = true
            state.request.requestError = false
            state.request.email = action.payload
        })  
        //register confirm code
        .addCase(registerConfirmCode.pending, (state)=>{
            state.request.requestLoading = true
            state.request.requestError = false
            state.request.requestConfirmed = false
        }) 
        .addCase(registerConfirmCode.fulfilled, (state, action)=>{
            state.request.requestLoading = false
            state.request.requestConfirmed = true
            state.request.requestError = false
            state.request.email = action.payload
        })  
        .addCase(registerConfirmCode.rejected, (state, action)=>{
            state.request.requestLoading = false
            state.request.requestConfirmed = false
            state.request.requestError = true
            state.request.requestMessage = action.payload
        })
        //login
        .addCase(login.pending, (state)=>{
            state.auth.authLoading = true
            state.auth.authSuccess = false
            state.auth.authError = false
        }) 
        .addCase(login.fulfilled, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = true
            state.auth.authError = false
            state.auth.customer = action.payload
        })  
        .addCase(login.rejected, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = false
            state.auth.authError = true
            state.auth.authMessage = action.payload
            state.auth.customer = null
        })
        //logout customer account
        .addCase(customerLogout.fulfilled, (state)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = false
            state.auth.authError = false
            state.auth.authMessage = ""
            state.auth.customer = null
        })
        //New Receiver Save
        .addCase(newReceiverSave.fulfilled, (state, action)=>{
            state.auth.customer.addresses = [...state.auth.customer.addresses, action.payload]
        }) 
        //add recevier 
        .addCase(addReceiver.pending, (state)=>{
            state.receiverAdd.receiverAddLoading = true
            state.receiverAdd.receiverAddSuccess = false
            state.receiverAdd.receiverAddError = false
        }) 
        .addCase(addReceiver.fulfilled, (state, action)=>{
            state.receiverAdd.receiverAddLoading = false
            state.receiverAdd.receiverAddSuccess = true
            state.receiverAdd.receiverAddError = false
            state.auth.customer.addresses.unshift(action.payload)
            toast.success(`New receiver added.`)
        })  
        .addCase(addReceiver.rejected, (state, action)=>{
            state.receiverAdd.receiverAddLoading = false
            state.receiverAdd.receiverAddSuccess = false
            state.receiverAdd.receiverAddError = true
            state.receiverAdd.receiverAddMessage = action.payload
        })
        //delete customer recevier address by customer
        .addCase(deleteReceiver.pending, (state)=>{
            state.receiverDelete.receiverDeleteLoading = true
            state.receiverDelete.receiverDeleteSuccess = false
            state.receiverDelete.receiverDeleteError = false
        }) 
        .addCase(deleteReceiver.fulfilled, (state, action)=>{
            state.receiverDelete.receiverDeleteLoading = false
            state.receiverDelete.receiverDeleteSuccess = true
            state.receiverDelete.receiverDeleteError = false
            state.auth.customer.addresses = state.auth.customer.addresses.filter(x=>x._id !== action.payload)
            toast.success(`Receiver deleted.`)
        })  
        .addCase(deleteReceiver.rejected, (state, action)=>{
            state.receiverDelete.receiverDeleteLoading = false
            state.receiverDelete.receiverDeleteSuccess = false
            state.receiverDelete.receiverDeleteError = true
            state.receiverDelete.receiverDeleteMessage = action.payload
        })
        //update account detail
        .addCase(updateAccountDetail.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateSuccess = false
            state.update.updateError = false
        }) 
        .addCase(updateAccountDetail.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            state.update.updateError = false
            state.update.updateMessage = ''
            state.auth.customer.name = action.payload.name
            state.auth.customer.company = action.payload.company
            state.auth.customer.phonenumber1 = action.payload.phonenumber1
            state.auth.customer.phonenumber2 = action.payload.phonenumber2
            state.auth.customer.phonenumber3 = action.payload.phonenumber3
            state.auth.customer.defaultAddress.street = action.payload.street
            state.auth.customer.defaultAddress.city = action.payload.city
            state.auth.customer.defaultAddress.state = action.payload.state
            state.auth.customer.defaultAddress.postalcode = action.payload.postalcode
            state.auth.customer.defaultAddress.country = action.payload.country
            toast.success(`Account information updated.`)
        })  
        .addCase(updateAccountDetail.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = false
            state.update.updateError = true
            state.update.updateMessage = action.payload
        })
        //forgot password customer
        .addCase(forgotPasswordCustomer.pending, (state)=>{
            state.forgot.forgotLoading = true
            state.forgot.forgotSuccess = false
            state.forgot.forgotError = false
        }) 
        .addCase(forgotPasswordCustomer.fulfilled, (state, action)=>{
            state.forgot.forgotLoading = false
            state.forgot.forgotSuccess = true
            state.forgot.forgotError = false
            state.forgot.forgotMessage = ''
            state.forgot.forgotEmail = action.payload
        })  
        .addCase(forgotPasswordCustomer.rejected, (state, action)=>{
            state.forgot.forgotLoading = false
            state.forgot.forgotSuccess = false
            state.forgot.forgotError = true
            state.forgot.forgotMessage = action.payload
        })
        //confirm password reset code
        .addCase(confirmPasswordResetCodeCustomer.pending, (state)=>{
            state.confirm.confirmLoading = true
            state.confirm.confirmSuccess = false
            state.confirm.confirmError = false
        }) 
        .addCase(confirmPasswordResetCodeCustomer.fulfilled, (state, action)=>{
            state.confirm.confirmLoading = false
            state.confirm.confirmSuccess = true
            state.confirm.confirmError = false
            state.confirm.confirmMessage = ''
            state.confirm.confirmEmail = action.payload
            state.forgot.forgotSuccess = false
        })  
        .addCase(confirmPasswordResetCodeCustomer.rejected, (state, action)=>{
            state.confirm.confirmLoading = false
            state.confirm.confirmSuccess = false
            state.confirm.confirmError = true
            state.confirm.confirmMessage = action.payload
        })
        //confirm password reset code
        .addCase(changePasswordCustomer.pending, (state)=>{
            state.password.passwordLoading = true
            state.password.passwordSuccess = false
            state.password.passwordError = false
        }) 
        .addCase(changePasswordCustomer.fulfilled, (state, action)=>{
            toast.success('Please login with new password.')
            state.password.passwordLoading = false
            state.password.passwordSuccess = true
            state.password.passwordError = false
            state.password.passwordMessage = ''
            state.confirm.confirmSuccess = false
            
        })  
        .addCase(changePasswordCustomer.rejected, (state, action)=>{
            state.password.passwordLoading = false
            state.password.passwordSuccess = false
            state.password.passwordError = true
            state.password.passwordMessage = action.payload
        })
    },
})


export const { authReset, requestReset, forgotReset, confirmReset, passwordReset } = customerSlice.actions
export default customerSlice.reducer