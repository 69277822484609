import {useEffect, useState} from 'react'
import styles from './staffscreens.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { getItems, deleteItem } from '../../features/item/itemSlice'
import { Button, Table, Form, Col, Row, Spinner } from 'react-bootstrap'
import { MdClear } from 'react-icons/md'
import { AddNewItem, EditItem } from '../../Modals'
import { AlertMessage, MyPagination } from '../../Components'

const StaffItemListScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {searchKeyword} = useParams()
  const {pageNumber} = useParams()
  const [keyword, setKeyword] = useState('')
  const { itemList, listLoading, listError, listMessage, page, pages } = useSelector(state=>state.item.list)
  const { deleteLoading, deleteError, deleteMessage } = useSelector(state=>state.item.delete)
  
  useEffect(()=>{
    dispatch(getItems({searchKeyword, pageNumber, limit: 50}))
  },[dispatch, searchKeyword, pageNumber])

  return (
    <div >    
      <div className={styles.itemlist_options}>
        <Row>
          <Col md={6}>
              <Form.Control
                type='text'
                size='sm'
                value={keyword}
                placeholder={searchKeyword ? searchKeyword : 'search item name..'}
                onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
                onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                    if(keyword === ''){
                      navigate(`/staff/items`)
                    } else {
                      navigate(`/staff/items/search/${keyword}`)
                    }                    
                  }
                }}
              />
              {/* <Link to={`/staff/items/search/${keyword}`}>
                <Button variant="outline-secondary" >
                  Search
                </Button>
              </Link> */}
          </Col>
          <Col md={3}>
              <AddNewItem />
          </Col>
          <Col md={3}>
            <MyPagination
              path={keyword !== "" ? `staff/items/search/${keyword}` : 'staff/items'} 
              pages={pages}
              page={page}
           />
          </Col>
        </Row>
      </div>
      {listError && <AlertMessage msg={listMessage} />}
      {deleteError && <AlertMessage msg={deleteMessage} />}
      {deleteLoading && <Spinner />}
      {listLoading ? <Spinner /> : 
      <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>#</th>
          <th>Item Name</th>
          <th className='text-center'>Edit</th>
          <th className='text-center'>Delete</th>
        </tr>
      </thead>
      <tbody>
        {itemList.map((itm,i) => (
          <tr key={itm._id}>
            <td>{i+1}</td>
            <td >{itm.name}</td>
            <td style={{width: '100px'}} ><EditItem itemId={itm._id} itemName={itm.name} /></td>
            <td style={{width: '100px'}} >
              <Button 
                variant='danger'
                className='mx-auto' 
                style={{height: '25px', widht: '50px', display: 'flex'}}
                onClick={()=>dispatch(deleteItem(itm._id))}
                ><MdClear className='pb-1' /></Button>
            </td>

          </tr>
        ))}
      </tbody>
    </Table>}
    </div>
  )
}

export default StaffItemListScreen