import {useEffect, useState} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import styles from './staffscreens.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { updateReset, updateCustomerDetail } from '../../features/customerAccount/customerAccountSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertMessage } from '../../Components'
import { emptyCheck } from '../../helpers'
import { toast } from 'react-toastify'
import { MySpinner } from '../../Components'

const StaffCustomerAccountUpdateScreen = () => {
  const { id } = useParams()
  const { customer } = useSelector(state=>state.customerAccount.detail)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [empty, setEmpty] = useState([])
  const [name, setName] = useState(customer && customer.name)
  const [email, setEmail] = useState(customer && customer.email ? customer.email : '')
  const [company, setCompany] = useState(customer && customer.company ? customer.company : '')
  const [password, setPassword] = useState('')
  const [passView, setPassView] = useState(false)
  const [phonenumber1, setPhonenumber1] = useState(customer && customer.phonenumber1)
  const [phonenumber2, setPhonenumber2] = useState(customer && customer.phonenumber2 ? customer.phonenumber2 : '')
  const [phonenumber3, setPhonenumber3] = useState(customer && customer.phonenumber3 ? customer.phonenumber3 : '')
  const [accountSuspend, setAccountSuspend] = useState(customer && customer.isSuspended)
  const [defaultAddress, setDefaultAddress] = useState({
    street: customer && customer.defaultAddress.street,
    city: customer && customer.defaultAddress.city,
    state: customer && customer.defaultAddress.street,
    postalcode: customer && customer.defaultAddress.postalcode,
    country: customer && customer.defaultAddress.country
  })
  const { updateLoading, updateSuccess, updateError, updateMessage } = useSelector(state=>state.customerAccount.update)

  useEffect(()=>{
    if(customer === null){
      navigate(`/staff/customers/${id}`)
    }
  },[id, navigate, customer])
  
  useEffect(()=>{
    if(updateSuccess){
      navigate('/staff/customers')
    }
    return()=>{
      setEmpty([])
      dispatch(updateReset())
    }
  },[updateSuccess, navigate, dispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const dataToCheck = [
      {'name': name},
      {'email': email},
      {'phonenumber1': phonenumber1},
      {'dstreet': defaultAddress.street},
      {'dcity': defaultAddress.city},
      {'dstate': defaultAddress.state},
      {'dpostalcode': defaultAddress.postalcode},
      {'dcountry': defaultAddress.country}
    ]
    const emptyList = await emptyCheck(dataToCheck)
    if(emptyList.length !== 0){
      setEmpty(emptyList)
      toast.error('Please check required fields')
      return
    } else {
      const updatedCustomer = {
        name,
        email,
        password: password === '' ? undefined : password,
        company,
        defaultAddress,
        phonenumber1,
        phonenumber2,
        phonenumber3,
        isSuspended: accountSuspend
      }
      //console.log(updatedCustomer)
      dispatch(updateCustomerDetail({customerId: id, customer: updatedCustomer}))
    }
    
  }
  return (
    <div className={styles.customer_add_main} style={{opacity: updateLoading ? 0.5 : 10}}>

      <h2 className='text-center mb-4'>Update Customer Detail</h2>
      {updateError && <AlertMessage msg={updateMessage} />}
      {updateLoading && <MySpinner />}
      <Form>
        <Form.Group className="mb-2" controlId="name">
          <Form.Label>Full Name <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type="text" 
            value={name}
            onChange={({target})=>{
              setName(target.value)
              setEmpty([...empty.filter(x=>x!=='name')])
            }}
            isInvalid={empty.includes('name')}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="email">
          <Form.Label>Email Address <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type="email"  
            value={email}
            onChange={({target})=>{
              setEmail(target.value)
              setEmpty([...empty.filter(x=>x!=='email')])
            }}
            isInvalid={empty.includes('email')}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="company">
          <Form.Label>Company</Form.Label>
          <Form.Control 
            type="text" 
            value={company}
            onChange={({target})=>setCompany(target.value)} 
          />
        </Form.Group>

        <Form.Group className="mb-1" >
          <Form.Label>New Password </Form.Label>
          <Form.Control 
            type={passView ? 'text' : "password" }
            value={password}
            minLength="8"
            onChange={({target})=>{
              setPassword(target.value)
            }}
          />
          {password.length !== 0 && password.length < 8 && <small className='text-danger'>Password min-length 8 characters</small>}
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Check type="checkbox" label="View password" onChange={()=>{setPassView((prev)=>!prev)}} />
        </Form.Group>

        <Row>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber1">
              <Form.Label>Phone Number 1 <small className='text-danger'>* required</small></Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber1}
                onChange={({target})=>{
                  setPhonenumber1(target.value)
                  setEmpty([...empty.filter(x=>x!=='phonenumber1')])
                }} 
                isInvalid={empty.includes('phonenumber1')}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber2">
              <Form.Label>Phone Number 2</Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber2}
                onChange={({target})=>setPhonenumber2(target.value)} 
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber3">
              <Form.Label>Phone Number 3</Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber3}
                onChange={({target})=>setPhonenumber3(target.value)} 
              />
            </Form.Group>
          </Col>
        </Row>

        <hr />
        <h3 className='text-center'>Addresses</h3>
        <Form.Group className='mb-1'>
          <Form.Label>Street <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.street}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, street: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dstreet')])
            }}
            isInvalid={empty.includes('dstreet')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>City <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.city}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, city: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dcity')])
            }}
            isInvalid={empty.includes('dcity')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>State <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.state}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, state: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dstate')])
            }}
            isInvalid={empty.includes('dstate')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>Postal Code <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.postalcode}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, postalcode: e.target.value.replace(/[^\w\s]/gi, "")})
              setEmpty([...empty.filter(x=>x!=='dpostalcode')])
            }}
            isInvalid={empty.includes('dpostalcode')}
          /> 
        </Form.Group>

        <Form.Group>
          <Form.Label>Select Country <small className='text-danger'>* required</small></Form.Label>
          <Form.Select value={defaultAddress.country}
            onChange={(e)=>{
              setDefaultAddress({...defaultAddress, country: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dcountry')])
            }}
            isInvalid={empty.includes('dcountry')}
          >
            <option value=''></option>
            <option value='Thailand'>Thailand</option>
            <option value='Myanmar'>Myanmar</option>
            <option value='Lao'>Lao</option>
            <option value='United States'>United States</option>
          </Form.Select>
        </Form.Group>

        <Form.Group className="my-3">
          <Form.Check 
            type="checkbox" 
            className='ps-5 py-2 border border-danger rounded' 
            label="Suspend account" 
            defaultChecked={accountSuspend}
            onChange={()=>setAccountSuspend(prev=>!prev)}
            />
        </Form.Group>
          

        {updateError && <AlertMessage msg={updateMessage} type='danger' />}
        <Button
          type='button'
          className='my-5 w-100'
          onClick={handleSubmit}
        >Save</Button>

      </Form>
    </div>
  )
}

export default StaffCustomerAccountUpdateScreen