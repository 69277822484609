import styles from './customercreateshipmentscreen.module.css'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CargoType from './CargoType'
import Addresses from './Addresses'
import Boxes from './Boxes'
import PackingList from './PackingList'
import CostDetail from './CostDetail'
import { getItems } from '../../../features/item/itemSlice'
import { getPrice, reset } from '../../../features/rate/rateSlice'
import OtherServices from './OtherServices'
import MyProgressBar from './MyProgressBar'
import { MdArrowRightAlt, MdKeyboardBackspace, MdDone } from 'react-icons/md'
import { handleAddress, handleBoxes, handlePickup } from './handleFunctions'
import { Alert } from 'react-bootstrap'
import { addShipment, addReset } from '../../../features/shipment/shipmentSlice'
import { newReceiverSave } from '../../../features/customer/customerSlice'
import { getBoxsizes } from '../../../features/boxsizes/boxsizeSlice'
import { MySpinner } from '../../../Components'
import LabelAndTracking from './LabelAndTracking'
import { useNavigate } from 'react-router-dom'

const CustomerCreateShipmentScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {customer} = useSelector(state=>state.customer.auth)
    const {rate} = useSelector(state=>state.rate.rate)
    const [cargoType, setCargoType] = useState('')
    const [samebox, setSamebox] = useState(false)
    const [sender, setSender] = useState({
        name: customer.name,
        company: customer.company ? customer.company : null,
        email: customer.email,
        phonenumber1: customer.phonenumber1,
        phonenumber2: customer.phonenumber2 ? customer.phonenumber2 : null,
        street: customer.defaultAddress.street,
        city: customer.defaultAddress.city, 
        state: customer.defaultAddress.state,
        postalcode: customer.defaultAddress.postalcode,
        country: customer.defaultAddress.country
    })
    const [senderDifferent, setSenderDifferent] = useState(false)
    const [receiver, setReceiver] = useState({
        name: "",
        company: "",
        email: "",
        phonenumber1: "",
        phonenumber2: "",
        street: "",
        city: "", 
        state: "",
        postalcode: "",
        country: ""
    })
    const [senderSave, setSenderSave] = useState(false)
    const [receiverSave, setReceiverSave] = useState(false)
    const [boxes, setBoxes] = useState([
        {
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            items: []
        }
    ])
    const [noItem, setNoItem] = useState(false)
    const [insurance, setInsurance] = useState("no")
    const [pickup, setPickup] = useState("no")
    const [pickupData, setPickupData] = useState({
        date:'',
        time: '',
        street: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
    })
    const [otherCharges, setOtherCharges] = useState([])
    const [note, setNote] = useState('')
    const [progress , setProgress] = useState(20)
    const [warning, setWarning] = useState(false)
    const [dataCheck, setDataCheck] = useState('')

    const { addLoading, addSuccess, addError, addMessage } = useSelector(state=>state.shipment.add)
    const { boxsizes } = useSelector(state=>state.boxsize.list)

    useEffect(()=>{
        if(addSuccess){
            setProgress(100)
        }
        dispatch(getItems({searchKeyword: '', pageNumber: 1, limit: 100}))   
        dispatch(getBoxsizes())
    },[dispatch, addSuccess ])
    

    const handleSubmit = (e) => {
        e.preventDefault()
        if(receiverSave){
            dispatch(newReceiverSave(receiver))
        }
        var newShipment = {
            cargoType,
            customerId: customer._id,
            customerCode: customer.customerId,
            boxes: rate.boxes,
            sender: {
                name: sender.name,
                company: sender.company,
                email: sender.email,
                phonenumber1: sender.phonenumber1,
                phonenumber2: sender.phonenumber2,
                address: {
                    street: sender.street,
                    city: sender.city,
                    state: sender.state,
                    postalcode: sender.postalcode,
                    country: sender.country,
                }
            },
            receiver: {
                name: receiver.name,
                company: receiver.company,
                email: receiver.email,
                phonenumber1: receiver.phonenumber1,
                phonenumber2: receiver.phonenumber2,
                address: {
                    street: receiver.street,
                    city: receiver.city,
                    state: receiver.state,
                    postalcode: receiver.postalcode,
                    country: receiver.country,
                }
            },
            pickupRequested: pickup === 'yes' ? true : false,
            pickupData: pickup === 'yes' ? pickupData : null,
            totalRate: rate.totalRate,
            totalAmount: rate.totalAmount,
            totalWeight: rate.totalWeight,
            otherCharges,
            receiverSave,
            isInsured: insurance === 'yes' ? true : false,
            note,
            creator:'customer'
        }            
        dispatch(addShipment({newShipment}))
        //console.log(newShipment)
    }

    
    
    return (
        <div className={styles.create_shipment_main}>
            <div className={styles.create_shipment_contents}>
     
                <h1>Create Shipment</h1>
                {addLoading && <MySpinner />}
                <MyProgressBar progress={progress} setProgress={setProgress} />
                {addError && <Alert variant='danger'>{addMessage}</Alert>}
                {progress === 20 &&
                <>
                    <CargoType cargoType={cargoType} setCargoType={setCargoType} />
                    <hr />
                    <Addresses 
                        receiver={receiver} 
                        setReceiver={setReceiver} 
                        sender={sender} 
                        setSender={setSender} 
                        customer = {customer}
                        senderDifferent = {senderDifferent}
                        setSenderDifferent = {setSenderDifferent}
                        senderSave = {senderSave}
                        setSenderSave = {setSenderSave}
                        receiverSave = {receiverSave}
                        setReceiverSave = {setReceiverSave}
                    />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className={styles.next_back_buttons}>
                    <button className={styles.back_button} onClick={()=>navigate('/shipments')}><MdKeyboardBackspace /> Cancel</button>
                    <button className={styles.next_button}
                        onClick={async ()=> {
                            const result = await handleAddress(cargoType, receiver)
                            if(result.length !== 0){
                                setWarning(true)
                                setDataCheck("Please re-check " + result.join(', '))                               
                            } else {
                                setWarning(false)
                                setDataCheck('')
                                setProgress(40)
                            }
                        }}
                    >Next <MdArrowRightAlt /></button>
                    </div>
                </> }

                {progress === 40 &&
                <>
                    <Boxes samebox={samebox} setSamebox={setSamebox} boxes={boxes} setBoxes={setBoxes} boxSizes={boxsizes} />
                    <hr />
                    <PackingList boxes={boxes} setBoxes={setBoxes} noItem={noItem} setNoItem={setNoItem} customerId={customer._id} />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className={styles.next_back_buttons}>
                        <button className={styles.back_button} onClick={()=>setProgress(20)}><MdKeyboardBackspace /> Back</button>
                        <button className={styles.next_button}
                            onClick={async ()=> {
                                const result = await handleBoxes(boxes, noItem)
                                if(result.length !== 0){
                                    setWarning(true)
                                    setDataCheck("Please recheck " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                } else {
                                    setWarning(false)
                                    setDataCheck('')
                                    setProgress(60)
                                }
                            }}
                        >Next <MdArrowRightAlt /></button>
                    </div>
                </> }

                {progress === 60 &&
                <>
                    <OtherServices 
                        insurance={insurance} 
                        setInsurance={setInsurance} 
                        pickup={pickup}
                        pickupData={pickupData}
                        setPickupData={setPickupData}
                        setPickup={setPickup}
                        noItem={noItem}
                        boxes={boxes}
                        sender={sender}
                        otherCharges={otherCharges}
                        setOtherCharges={setOtherCharges}
                    />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className={styles.next_back_buttons}>
                        <button className={styles.back_button} onClick={()=>setProgress(40)}><MdKeyboardBackspace /> Back</button>
                        <button className={styles.next_button} 
                            onClick={async ()=> {
                                //dispatch(getPrice({type: cargoType, weight: boxes.reduce((acc, b) => acc + Number(b.weight), 0)}))
                                if(pickup === 'yes'){
                                   const result = await handlePickup(pickupData)
                                    if(result.length !== 0){
                                        setWarning(true)
                                        setDataCheck("Please recheck Pickup " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                    } else {
                                        setWarning(false)
                                        setDataCheck('')
                                        dispatch(getPrice({boxes, cargoType}))
                                        setProgress(80)
                                    }
                                } else {
                                    dispatch(getPrice({boxes, cargoType}))
                                    setProgress(80)
                                }
                                if(insurance === 'yes'){
                                    const chAmount = ((boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (Number(itm.price)*Number(itm.unit)), 0)), 0)) * 0.1).toFixed(2)
                                    setOtherCharges([{name: 'Insurance', amount: chAmount}])
                                } else {
                                    setOtherCharges([])
                                }
                                
                            }}>Next <MdArrowRightAlt />
                        </button>
                    </div>
                </> }

                {progress === 80 &&
                <>
                    <CostDetail 
                        cargoType={cargoType} 
                        boxes={boxes} 
                        insurance={insurance} 
                        pickup={pickup}
                        note={note}
                        setNote={setNote}
                        otherCharges={otherCharges}
                    />
                    {addError && <Alert variant='danger'>{addMessage}</Alert>}
                    <div className={styles.next_back_buttons}>
                        <button className={styles.back_button} 
                            onClick={()=>{
                                dispatch(reset())
                                setProgress(60)
                            }}
                        ><MdKeyboardBackspace /> Back</button>
                        <button className={styles.next_button} 
                            onClick={handleSubmit}>Next <MdArrowRightAlt />
                        </button>
                    </div>
                </> }
                
                {progress === 100 &&
                <>
                    <LabelAndTracking />
                    <hr />
                    <div className={styles.next_back_buttons}>
                        <div/>
                        <button className={styles.next_button} 
                            onClick={()=>{
                                dispatch(addReset())
                                navigate('/shipments')
                            }}>Done <MdDone />
                        </button>
                    </div>
                </>
                
                }
                
                
                
                {/* <button onClick={handleSubmit}>Create Shipment</button> */}
            </div>
            
        </div>
    )
}

export default CustomerCreateShipmentScreen