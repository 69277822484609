import styles from './customercreateshipmentscreen.module.css'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CargoType from './CargoType'
import Boxes from './Boxes'
import PackingList from './PackingList'
import CostDetail from './CostDetail'
import { getItems } from '../../../features/item/itemSlice'
import { getPrice, reset } from '../../../features/rate/rateSlice'
import OtherServices from './OtherServices'
import MyProgressBar from './MyProgressBar'
import { MdArrowRightAlt, MdKeyboardBackspace, MdDone } from 'react-icons/md'
import { handleAddress, handleBoxes, handlePickup } from './handleFunctions'
import { Alert, Button } from 'react-bootstrap'
import { addShipment, addReset } from '../../../features/shipment/shipmentSlice'
import { getBoxsizes } from '../../../features/boxsizes/boxsizeSlice'
import { getChargesNameList } from '../../../features/chargesname/chargesnameSlice'
import { MySpinner } from '../../../Components'
import LabelAndTracking from './LabelAndTracking'
import { useNavigate, useSearchParams } from 'react-router-dom'
import CustomerAndAddress from './CustomerAndAddress'
// import {Helmet} from 'react-helmet'

const StaffShipmentCreateScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const idToCreate = searchParams.get('customer')
    // const {customer} = useSelector(state=>state.customer.auth)
    const {rate} = useSelector(state=>state.rate.rate)
    const [cargoType, setCargoType] = useState('')
    const [samebox, setSamebox] = useState(false)
    const [customerId, setCustomerId] = useState("")
    const [customerCode, setCustomerCode] = useState("")
    const [addressList, setAddressList] = useState([])
    const [sender, setSender] = useState({
        name: "",
        company: "",
        email: "",
        phonenumber1: "",
        phonenumber2: "",
        street: "",
        city: "", 
        state: "",
        postalcode: "",
        country: ""
    })
    //const [senderDifferent, setSenderDifferent] = useState(false)
    const [receiver, setReceiver] = useState({
        name: "",
        company: "",
        email: "",
        phonenumber1: "",
        phonenumber2: "",
        street: "",
        city: "", 
        state: "",
        postalcode: "",
        country: ""
    })
    //const [senderSave, setSenderSave] = useState(false)
    const [receiverSave, setReceiverSave] = useState(false)
    const [boxes, setBoxes] = useState([
        {
            length: 0,
            width: 0,
            height: 0,
            weight: 0,
            items: []
        }
    ])
    const [noItem, setNoItem] = useState(false)
    const [pickup, setPickup] = useState("no")
    const [pickupData, setPickupData] = useState({
        date:'',
        time: '',
        street: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
    })
    const [note, setNote] = useState('')
    const [progress , setProgress] = useState(20)
    const [warning, setWarning] = useState(false)
    const [dataCheck, setDataCheck] = useState('')
    const [otherCharges, setOtherCharges] = useState([])
    const [ discount, setDiscount ] = useState(0)
    const { addLoading, addSuccess, addError, addMessage } = useSelector(state=>state.shipment.add)
    const { boxsizes } = useSelector(state=>state.boxsize.list)
    const { chargesnames } = useSelector(state=>state.chargesname.list)
    const { customerList } = useSelector(state=>state.customerAccount.list)


    useEffect(()=>{
        if(idToCreate){
            const customerToCreate = customerList.find(x=>x._id === idToCreate)
            if(customerToCreate){
                setSender({
                    name: customerToCreate.name,
                    company: customerToCreate.company ? customerToCreate.company : '',
                    email: customerToCreate.email,
                    phonenumber1: customerToCreate.phonenumber1,
                    phonenumber2: customerToCreate.phonenumber2? customerToCreate.phonenumber2 : '',
                    street: customerToCreate.defaultAddress.street,
                    city: customerToCreate.defaultAddress.city, 
                    state: customerToCreate.defaultAddress.state,
                    postalcode: customerToCreate.defaultAddress.postalcode,
                    country: customerToCreate.defaultAddress.country
                })
                setCustomerId(customerToCreate._id)
                setAddressList(customerToCreate.addresses)
            }            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    useEffect(()=>{
        if(addSuccess){
            setProgress(100)
        }
        dispatch(getItems({searchKeyword: '', pageNumber: 1, limit: 100}))  
        dispatch(getBoxsizes()) 
        dispatch(getChargesNameList())
    },[dispatch, addSuccess ])
    

    const handleSubmit = (e) => {
        e.preventDefault()
        var newShipment = {
            cargoType,
            customerId,
            customerCode,
            boxes: rate.boxes,
            sender: {
                name: sender.name,
                company: sender.company,
                email: sender.email,
                phonenumber1: sender.phonenumber1,
                phonenumber2: sender.phonenumber2,
                address: {
                    street: sender.street,
                    city: sender.city,
                    state: sender.state,
                    postalcode: sender.postalcode,
                    country: sender.country,
                }
            },
            receiver: {
                name: receiver.name,
                company: receiver.email,
                email: receiver.email,
                phonenumber1: receiver.phonenumber1,
                phonenumber2: receiver.phonenumber2,
                address: {
                    street: receiver.street,
                    city: receiver.city,
                    state: receiver.state,
                    postalcode: receiver.postalcode,
                    country: receiver.country,
                }
            },
            receiverSave,
            pickupRequested: pickup === 'yes' ? true : false,
            pickupData: pickup === 'yes' ? pickupData : null,
            totalRate: rate.totalRate,
            totalAmount: rate.totalAmount,
            totalWeight: rate.totalWeight,
            otherCharges,
            isInsured: otherCharges.some(x=>x.name === 'Insurance'),
            discount,
            note,
            creator: 'staff'
        }  
        //console.log(newShipment)          
        dispatch(addShipment({newShipment, staff: true}))
        
    }

    
    
    return (
    <>
        {/* <Helmet>
            <title>{addSuccess ? shipment.shipmentId : 'NYSS'}</title>
        </Helmet> */}
        <div className={styles.create_shipment_main}>
            
            <div className={styles.create_shipment_contents}>
     
                <div className='d-flex justify-content-between'>
                    <h1>Create Shipment</h1>
                    {/* <div >
                        <div 
                            className='border border-dark px-2 rounded'
                            >
                            <Button
                                variant='flush'
                                className='m-0 p-0'
                            >
                                <MdSave size={21} /> Save Draft
                            </Button>
                        </div>
                    </div> */}
                </div>
                {addLoading && <MySpinner />}
                <MyProgressBar progress={progress} setProgress={setProgress} />
                {addError && <Alert variant='danger'>{addMessage}</Alert>}
                {progress === 20 &&
                <>
                    <CargoType cargoType={cargoType} setCargoType={setCargoType} />
                    <hr />
                    <CustomerAndAddress 
                      sender={sender}
                      receiver={receiver}
                      setReceiver={setReceiver}
                      setSender={setSender} 
                      customerId={customerId}
                      setCustomerId={setCustomerId} 
                      customerCode={customerCode}
                      setCustomerCode={setCustomerCode}
                      setAddressList={setAddressList}
                      addressList={addressList}
                      receiverSave={receiverSave}
                      setReceiverSave={setReceiverSave}
                    />
                    {/* <Addresses 
                        receiver={receiver} 
                        setReceiver={setReceiver} 
                        sender={sender} 
                        setSender={setSender} 
                        customer = {customer}
                        senderDifferent = {senderDifferent}
                        setSenderDifferent = {setSenderDifferent}
                        senderSave = {senderSave}
                        setSenderSave = {setSenderSave}
                        receiverSave = {receiverSave}
                        setReceiverSave = {setReceiverSave}
                    /> */}
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                    <Button size='lg' onClick={()=>navigate('/staff/shipments')} variant='dark'><MdKeyboardBackspace /> Cancel</Button>
                    <Button 
                        variant='success'
                        onClick={async ()=> {
                            const result = await handleAddress(cargoType, receiver)
                            if(result.length !== 0){
                                setWarning(true)
                                setDataCheck("Please re-check " + result.join(', '))                               
                            } else {
                                setWarning(false)
                                setDataCheck('')
                                setProgress(40)
                            }
                        }}
                    >Next <MdArrowRightAlt /></Button>
                    </div>
                </> }

                {progress === 40 &&
                <>
                    <Boxes samebox={samebox} setSamebox={setSamebox} boxes={boxes} setBoxes={setBoxes} boxSizes={boxsizes} />
                    <hr />
                    <PackingList boxes={boxes} setBoxes={setBoxes} noItem={noItem} setNoItem={setNoItem} customerId={customerId} />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                        <Button size='lg' variant='dark' onClick={()=>setProgress(20)}><MdKeyboardBackspace /> Back</Button>
                        <Button
                            size='lg'
                            variant='success'
                            onClick={async ()=> {
                                const result = await handleBoxes(boxes, noItem)
                                if(result.length !== 0){
                                    setWarning(true)
                                    setDataCheck("Please recheck " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                } else {
                                    setWarning(false)
                                    setDataCheck('')
                                    setProgress(60)
                                }
                            }}
                        >Next <MdArrowRightAlt /></Button>
                    </div>
                </> }

                {progress === 60 &&
                <>
                    <OtherServices 
                        pickup={pickup}
                        pickupData={pickupData}
                        setPickupData={setPickupData}
                        setPickup={setPickup}
                        noItem={noItem}
                        boxes={boxes}
                        sender={sender}
                        otherCharges={otherCharges}
                        setOtherCharges={setOtherCharges}
                        chargesList={chargesnames}
                    />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                        <Button variant='dark' size='lg' onClick={()=>setProgress(40)}><MdKeyboardBackspace /> Back</Button>
                        <Button
                            variant='success'
                            size='lg'
                            onClick={async ()=> {
                                //dispatch(getPrice({type: cargoType, weight: boxes.reduce((acc, b) => acc + Number(b.weight), 0)}))
                                if(pickup === 'yes'){
                                   const result = await handlePickup(pickupData)
                                    if(result.length !== 0){
                                        setWarning(true)
                                        setDataCheck("Please recheck Pickup " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                    } else {
                                        setWarning(false)
                                        setDataCheck('')
                                        dispatch(getPrice({boxes, cargoType, staff: true}))
                                        setProgress(80)
                                    }
                                } else {
                                    dispatch(getPrice({boxes, cargoType, staff: true}))
                                    setProgress(80)
                                }
                                
                            }}>Next <MdArrowRightAlt />
                        </Button>
                    </div>
                </> }

                {progress === 80 &&
                <>
                    <CostDetail 
                        cargoType={cargoType} 
                        boxes={boxes} 
                        pickup={pickup}
                        note={note}
                        setNote={setNote}
                        otherCharges={otherCharges}
                        discount={discount}
                        setDiscount={setDiscount}
                    />
                    <div className='d-flex justify-content-between'>
                        <Button
                            variant='dark'
                            size='lg' 
                            onClick={()=>{
                                dispatch(reset())
                                setProgress(60)
                            }}
                        ><MdKeyboardBackspace /> Back</Button>
                        <Button 
                            variant='success'
                            size='lg'
                            onClick={handleSubmit}>Next <MdArrowRightAlt />
                        </Button>
                    </div>
                </> }
                
                {progress === 100 &&
                <>
                    <LabelAndTracking />
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <div/>
                        <Button 
                            variant='success'
                            size='lg'
                            onClick={()=>{
                                dispatch(addReset())
                                navigate('/staff/shipments')
                            }}>Done <MdDone />
                        </Button>
                    </div>
                </>
                
                }
                
                
                
                {/* <button onClick={handleSubmit}>Create Shipment</button> */}
            </div>
            
        </div>
    </>
    )
}

export default StaffShipmentCreateScreen