import React, {useState} from 'react'
import { Modal, Button } from 'react-bootstrap'

const ImageViewModal = ({imageFile, title}) => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
        <Button variant="link" size='sm' className='m-0 p-0' onClick={handleShow}>
            view
        </Button>

        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
            <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center border shadow'>
                    <img src={imageFile} alt='imageFile' />
                </div>
            </Modal.Body>
            <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}

export default ImageViewModal