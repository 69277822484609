import './welcome.css'
import { GiWoodenCrate,GiCargoShip, GiCardboardBox, GiTruck } from 'react-icons/gi'
import logoGold from '../../../assets/logo-gold.png'
import { useNavigate } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'

const Welcome = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  
  return (
    <div className='welcome' id="welcome">
      <div className='hero-section'>   
        <Trans i18nKey="welcomeheading">    
          <h4>Looking for quality shipping to           
            <span> Thailand</span>, 
            <span> Myanmar</span>, 
            <span> Laos</span> or anywhere in the 
            <span> United States</span>?
          </h4>  
        </Trans>
        <p>
          {t('welcomeheading1')}          
        </p>
        

        <div className='icon-list'>          
          <div className='icon-box'>
              <GiWoodenCrate />
              <h5>{t('Crating')}</h5>            
          </div>

          <div className='icon-box'>
            <div className='icon'>
              <GiCardboardBox />
            </div>
            <h5>{t('Packing')}</h5>
          </div>

          <div className='icon-box'>
            <div className='icon'>
              <GiTruck />
            </div>
            <h5>{t('Moving')}</h5>
          </div>

          <div className='icon-box'>
            <div className='icon'>
              <GiCargoShip />
            </div>
            <h5>{t('Shipping')}</h5>
            </div>
          <div>          
        </div>
      </div>

      <div className='hero-second'>
        <p>
          {t('welcomeheading2')} 
        </p>
        <button onClick={()=>navigate('/request')}>
          <span style={{"--i":"1"}}>G</span>
          <span style={{"--i":"2"}}>e</span>
          <span style={{"--i":"3"}}>t</span>
          <span style={{"--i":"4"}}>&nbsp;</span>
          <span style={{"--i":"5"}}>S</span>
          <span style={{"--i":"6"}}>t</span>
          <span style={{"--i":"7"}}>a</span>
          <span style={{"--i":"8"}}>r</span>
          <span style={{"--i":"9"}}>t</span>
          <span style={{"--i":"10"}}>e</span>
          <span style={{"--i":"11"}}>d</span>
        </button>
      </div> 


    </div>

      <div className='logo-section'>
        <img src={logoGold} alt='logoGold' />
      </div>
    </div>
  )
}

export default Welcome