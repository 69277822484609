import { useState } from 'react'
import { Row, Col, Form, Card, Button, Alert } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { MdLocationOn, MdLocalPhone } from 'react-icons/md'
import { deleteReceiver, updateAccountDetail } from '../../features/customer/customerSlice'
import { AlertMessage, MySpinner } from '../../Components'
import { AddressAddModal } from '../../Modals'
import { countries } from '../../data/countries'
import { emptyCheck } from '../../helpers'

const CustomerAccountScreen = () => {
    const dispatch = useDispatch()
    const { customer } = useSelector(state=>state.customer.auth)
    const [ name, setName ] = useState(customer && customer.name)
    const [ company, setCompany ] = useState(customer && customer.company)
    const [ phonenumber1, setPhonenumber1 ] = useState(customer && customer.phonenumber1)
    const [ phonenumber2, setPhonenumber2 ] = useState(customer && customer.phonenumber2)
    const [ phonenumber3, setPhonenumber3 ] = useState(customer && customer.phonenumber3)
    const [ street, setStreet ] = useState(customer && customer.defaultAddress.street)
    const [ city, setCity] = useState(customer && customer.defaultAddress.city)
    const [ state, setState ] = useState(customer && customer.defaultAddress.state)
    const [ postalcode, setPostalcode ] = useState(customer && customer.defaultAddress.postalcode)
    const [ country, setCountry ] = useState(customer && customer.defaultAddress.country)
    const [ empty, setEmpty ] = useState(false)
    const [ emptyList, setEmptyList ] = useState([])
    const { receiverDeleteLoading, receiverDeleteError, receiverDeleteMessage } = useSelector(state=>state.customer.receiverDelete)
    const { updateLoading, updateError, updateMessage } = useSelector(state=>state.customer.update)
    
    const handleSaveChanges = async (e) => {
        e.preventDefault()
        const dataToCheck = [
            {'Name': name},
            {'Phone Number 1': phonenumber1},
            {'Street': street},
            {'City': city},
            {'State/Province': state},
            {'Postal Code': postalcode},
            {'Country': country}
        ]
        const checkedList = await emptyCheck(dataToCheck)
        if(checkedList.length !== 0){
            setEmpty(true)
            setEmptyList(checkedList)
            return
        } else {
            setEmpty(false)
            setEmptyList([])
            const data = {
                id: customer._id,
                name,
                company,
                phonenumber1,
                phonenumber2,
                phonenumber3,
                street,
                city,
                state,
                postalcode,
                country
            }
            //console.log(data)
            dispatch(updateAccountDetail(data))
        }

    }
    return (
        <div>
            <h2 className='text-center mb-5'>Account Detail</h2>
            {updateLoading && <MySpinner />}
            {updateError && <AlertMessage msg={updateMessage} />}
            {customer && 
                <div>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Customer ID</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-danger'
                            defaultValue={customer.customerId}
                            readOnly
                        />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Name</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={name}
                            onChange={({target})=>setName(target.value)}
                        />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Email</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-danger'
                            defaultValue={customer.email}
                            readOnly
                        />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Company</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={company}
                            onChange={({target})=>setCompany(target.value)}
                        />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Phone Number 1</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            value={phonenumber1}
                            onChange={({target})=>setPhonenumber1(target.value.replace(/\D/,''))}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Phone Number 2</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={phonenumber2}
                            onChange={({target})=>setPhonenumber2(target.value.replace(/\D/,''))}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Phone Number 3</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={phonenumber3}
                            onChange={({target})=>setPhonenumber3(target.value.replace(/\D/,''))}
                        />
                        </Col>
                    </Row>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Created Date</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-danger'
                            defaultValue={new Date(customer.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})}
                            readOnly
                        />
                        </Col>
                    </Row>
                    <hr />
                    <h4 className='text-center mb-4'>Sender Address</h4>

                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Address</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={street}
                            onChange={({target})=>setStreet(target.value)}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>City</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={city}
                            onChange={({target})=>setCity(target.value)}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>State/Province</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={state}
                            onChange={({target})=>setState(target.value)}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Postal Code</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Control
                            type='text'
                            className='border-success'
                            defaultValue={postalcode}
                            onChange={({target})=>setPostalcode(target.value)}
                        />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col xs={4} className='text-center pt-2'>Country</Col>
                        <Col xs={8} className='pe-5'>
                        <Form.Select
                            className='border-success'
                            value={country}
                            onChange={({target})=>setCountry(target.value)}
                        >
                            {countries.map((c)=>(
                                <option key={c.name} value={c.value}>{c.name}</option>
                            ))}
                        </Form.Select>
                        </Col>
                    </Row>
                    {empty && <Alert>Please re-check {emptyList.join(', ')}</Alert>}
                    <div className='d-flex justify-content-center mb-5'><Button
                        variant='success'
                        onClick={handleSaveChanges}
                    >Save Changes</Button></div>

                    <hr />
                    <h4 className='text-center mb-4'>Receiver List</h4>
                    <div className='mb-3'><AddressAddModal customerId={customer._id} creator='customer' /></div>
                    {receiverDeleteLoading && <MySpinner />}
                    {receiverDeleteError && <AlertMessage msg={receiverDeleteMessage} />}
                    <Row>
                    {customer.addresses && customer.addresses.length !== 0 && customer.addresses.map((ad)=>(
                        <Col key={ad._id} md={6} className='mb-3'>
                            <Card>
                                <Card.Body>
                                    <Card.Title className='d-flex justify-content-between'>
                                        <div>{ad.name}</div>
                                        <div><Button
                                            variant='danger'
                                            className='m-0 py-0 px-2'
                                            onClick={()=>dispatch(deleteReceiver({customerId: customer._id, addressId: ad._id}))}
                                        >x</Button></div>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        {ad.company && ad.company} {ad.email && ', ' + ad.email}
                                    </Card.Subtitle>
                                    <div style={{fontSize: '14px'}}>
                                        <div><MdLocationOn /> {ad.street + ', ' + ad.city + ', ' + ad.state + ', ' + ad.postalcode + ', ' + ad.country}</div>
                                        <div><MdLocalPhone /> {ad.phonenumber1} {ad.phonenumber2 && ', ' + ad.phonenumber2}</div>
                                        {ad.remark && <div className='bg-warning-subtle'>Remark - {ad.remark}</div>}
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                    </Row>
                </div>
            }

        </div>
    )
}

export default CustomerAccountScreen