import { configureStore, combineReducers } from '@reduxjs/toolkit';
import customerReducer from './features/customer/customerSlice'
import customerAccountReducer from './features/customerAccount/customerAccountSlice'
import itemReducer from './features/item/itemSlice'
import staffReducer from './features/staff/staffSlice'
import rateReducer from './features/rate/rateSlice'
import shipmentReducer from './features/shipment/shipmentSlice'
import cutoffReducer from './features/cutoff/cutoffSlice'
import systemReducer from './features/system/systemSlice'
import boxsizeReducer from './features/boxsizes/boxsizeSlice'
import chargesnameReducer from './features/chargesname/chargesnameSlice'
import ositemReducer from './features/osItem/osItemSlice'
import galleryReducer from './features/gallery/gallerySlice'

const combinedReducer = combineReducers({
  customer: customerReducer,
  staff: staffReducer,
  item: itemReducer,
  rate: rateReducer,
  customerAccount: customerAccountReducer,
  shipment: shipmentReducer,
  cutoff: cutoffReducer,
  boxsize: boxsizeReducer,
  chargesname: chargesnameReducer,
  ositem: ositemReducer,
  gallery: galleryReducer,
  system: systemReducer,
})

const rootReducer = (state, action) => {
  if(action.type === 'Staff_Logout/fulfilled'){
    state = undefined;
  }
  if(action.type === 'Customer_Logout/fulfilled'){
    state = undefined;
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()],
});