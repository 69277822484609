import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import styles from './modals.module.css'
import { Modal, Button, FormControl, FormLabel, Spinner } from 'react-bootstrap'
import { MdAdd } from 'react-icons/md'
import { addItem } from '../features/item/itemSlice';
import { AlertMessage } from '../Components'

const AddNewItem = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')

    const { addError, addLoading, addSuccess, addMessage } = useSelector(state=>state.item.add)
    
    useEffect(()=>{
        if(addSuccess){
            setName('')
            setShow(false)
        }
    },[addSuccess])
    const handleShow = () => setShow(true);
    const handleSubmit = () => {
        dispatch(addItem({name}))
    }
    const handleClose = () => {
        setName('')
        setShow(false)
    }
    return (
        <div className={styles.add_item_main}>
            <Button variant="primary" onClick={handleShow} size='sm'>
                <MdAdd /> Add New Item
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                {addLoading ?
                <>
                <Spinner animation="border" />
                <Modal.Title> Please wait adding item...</Modal.Title>
                </>
                :
                <Modal.Title>Add New Item</Modal.Title>
                }
                </Modal.Header>
                <Modal.Body>
                    {addError && <AlertMessage msg={addMessage} />}
                    <FormLabel>Please type item name</FormLabel>
                    <FormControl 
                        type='text'
                        value={name}
                        autoFocus
                        onChange={e=>setName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddNewItem