
//import styles from './customercreateshipmentscreen.module.css'
import { Button, Card, Form, Table } from 'react-bootstrap';
import { AddItemToShipmentModal } from '../../../Modals';


const PackingList = ({boxes, setBoxes, noItem, setNoItem, customerId}) => {

    return (
        <div>
            <h3>Add items to the {boxes.length > 1 ? 'boxes' : 'box'}</h3>
            {/* <div className={`${styles.boxes_same} mb-3`}>
                <input
                    type='checkbox'
                    id='noitem'
                    value={noItem}
                    checked={noItem}
                    onChange={()=>{
                        setNoItem(prevState=>!prevState)
                        // let nBoxes = boxes
                        // nBoxes.forEach(b=>b.items=[])
                        // setBoxes(nBoxes)
                    }}
                />
                <label htmlFor='noitem'>I request NYSS to do this for me. <small className='text-danger'>(there will be a small charge for this)</small></label>
            </div> */}
            {boxes.map((b,i)=>(
                <Card key={i+1} className='mb-3'>
                    <Card.Body className='p-2'>
                        <div className='d-flex mb-2' style={{height: '30px'}} >
                            <Card.Title className='me-2 bg-warning px-2 rounded' style={{height: '26px'}}>Box {i+1}</Card.Title>
                            {/* <button size='sm' variant='success' className={styles.item_add_button} style={{height: '25px'}}>Add Item</button> */}
                            <AddItemToShipmentModal boxes={boxes} setBoxes={setBoxes} boxIdx={i} customerId={customerId} />
                            <div className='ms-auto d-flex'>
                            <Card.Title className='me-3'>{b.length}" x {b.width}" x {b.height}"</Card.Title>
                            <Card.Title>{b.weight} lb</Card.Title>
                            </div>
                        </div>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr style={{fontSize: '13px'}}>
                                    <th style={{width: '50px'}}>#</th>
                                    <th >Product Info</th>
                                    <th style={{width: '100px', textAlign: 'center'}}>Unit (pcs)</th>
                                    <th style={{width: '100px', textAlign: 'center'}}>Price ($)</th>
                                    <th style={{width: '100px', textAlign: 'center'}}>Value ($)</th>
                                    <th style={{width: '100px', textAlign: 'center'}}>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {b.items.map((itm, idx)=>(
                                    <tr key={idx} style={{fontSize: '15px'}}>
                                        <td>{idx+1}</td>
                                        <td>{itm.product}</td>
                                        <td>
                                            <Form.Control 
                                                type='number'
                                                min={0}
                                                style={{height: '25px', textAlign: 'center'}} 
                                                onKeyDown={e => /[+\-.,]$/.test(e.key) && e.preventDefault()}
                                                defaultValue={itm.unit}
                                                onChange={({target})=>{
                                                    let newBoxes = boxes
                                                    newBoxes[i].items[idx].unit = target.value
                                                    setBoxes([...newBoxes])
                                                }}
                                            />                                            
                                        </td>
                                        <td>
                                            <Form.Control 
                                                type='number'
                                                min={0}
                                                style={{height: '25px', textAlign: 'center'}} 
                                                defaultValue={itm.price}
                                                onChange={({target})=>{
                                                    let newBoxes = boxes
                                                    newBoxes[i].items[idx].price = target.value
                                                    setBoxes([...newBoxes])
                                                }}
                                            />  
                                        </td>
                                        <td className='text-center'>{(Number(itm.unit) * Number(itm.price)).toFixed(2)}</td>
                                        <td>
                                            <Button 
                                                size='sm' 
                                                variant='warning' 
                                                style={{height: '20px', margin: 'auto', display: 'flex', alignItems:'center'}} 
                                                onClick={()=>{
                                                    let newBoxes = boxes
                                                    newBoxes[i].items = newBoxes[i].items.filter(((_,index)=> index !== idx))
                                                    setBoxes([...newBoxes])
                                                }}
                                            >x</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>  
            ))}
            <div className='fs-4 float-end'>Total Declared Value - $ {(boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (Number(itm.price)*Number(itm.unit)), 0)), 0)).toFixed(2)}</div>            
        </div>
    )
}

export default PackingList