import styles from '../CustomerLoginScreen/customerloginscreen.module.css'
import { useState, useEffect } from 'react'
import { logogold } from '../../../assets'
import { MdKeyboardArrowRight, MdShortcut, MdKeyboardReturn } from 'react-icons/md'
import { toast } from 'react-toastify'
import { requestRegister, haveCode, registerConfirmCode, requestReset } from '../../../features/customer/customerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { MySpinner } from '../../../Components'
import { Alert } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const CustomerRegisterRequestScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ email, setEmail ] = useState('')
  const [ code, setCode ] = useState('')
  const [ hasCode, setHasCode ] = useState(false)
  const { requestLoading, requestError, requestSuccess, requestMessage, requestConfirmed, email: requestedEmail } = useSelector(state=>state.customer.request)

  useEffect(()=>{
    if(requestSuccess){
        setHasCode(true)
    }
    if(requestConfirmed){
        navigate('/register')
    }
  },[requestSuccess, requestConfirmed, navigate])

  const handleSubmit = (e) => {
    e.preventDefault()
    if(email === ''){
        toast.warning('Please enter email address.')
        return
    }
    dispatch(requestRegister(email))
  }

  const handleCodeSubmit = (e) => {
    e.preventDefault()
    if(code.length !== 8){
        toast.warning('Code should be 8 digit.')
        return
    }
    if(code === ''){
        toast.warning('Please enter code.')
        return
    }
    dispatch(registerConfirmCode({email: requestedEmail, code}))
  }

  const handleHaveCode = (e) => {
    e.preventDefault()
    if(email === ''){
        toast.warning('Please enter email first.')
        return
    }
    dispatch(haveCode(email))
  }

  const handleGoBack = () => {
    setEmail('')
    setCode('')
    dispatch(requestReset())
    setHasCode(false)
  }
  return (
    <div className={styles.customer_login_main}>
      <div className={styles.customer_login_content}>
        <div className={styles.customer_login_img}>
          <img src={logogold} alt='logogold' />
        </div>
        <div className={styles.customer_login_form}>

            

            {requestError && <Alert variant='danger'>{requestMessage}</Alert> }
            {requestLoading && <MySpinner />}
            {hasCode ? 
            <>
            <h4 className='mb-5'>Please check your email for confirmation code.</h4>
            <small className='text-danger'>The code will expire within 5 minutes</small>
            <form onSubmit={handleCodeSubmit}>
              <div className={styles.form_email}>
                <p>Enter Code</p>
                <input 
                  type='text'  
                  value={code}
                  onChange={({target})=>setCode(target.value)}
                />
              </div>
                <small style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
                    onClick={handleGoBack}
                ><MdKeyboardReturn /> Go back</small>
                <div className={styles.login_form_button}>
                  <button type='submit'>Next <span><MdKeyboardArrowRight size={20} /></span></button>
                </div>
              
            </form>
            </>
            :
            <>
            <h4 className='mb-5'>Please enter your email address</h4>
            
            <form onSubmit={handleSubmit}>
              <div className={styles.form_email}>
                <p>Email</p>
                <input 
                  type='email'  
                  value={email}
                  onChange={({target})=>setEmail(target.value)}
                />
              </div>
                <small style={{cursor: 'pointer', textDecoration: 'underline', color: 'blue'}}
                    onClick={handleHaveCode}
                >I already have code <MdShortcut /></small>
                <div className={styles.login_form_button}>
                  <button type='submit'>Next <span><MdKeyboardArrowRight size={20} /></span></button>
                </div>
              
            </form>  
            </>  
            }
        </div>
        
      </div>
    </div>
  )
}

export default CustomerRegisterRequestScreen