import { useState } from 'react';
import styles from './modals.module.css'
import { Modal, Button, Spinner, Alert, ListGroup, Form, Row, Col, Badge } from 'react-bootstrap'
import { MdPeople } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux';
import { getCustomerList } from '../features/customerAccount/customerAccountSlice';

const CustomerSelectModal = ({setSender, setReceiver, setCustomerId, setCustomerCode, setAddressList, update}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [searchKeyword, setSearchKeyword] = useState('')

    const { customerList, listLoading, listSuccess, listError, listMessage } = useSelector(state=>state.customerAccount.list)


    const handleShow = () => setShow(true)
    const handleClose = () => {setShow(false)}
    const hanldeSelectCustomer = (customer) => {
        setCustomerId(customer._id)
        setCustomerCode(customer.customerId)
        setAddressList(customer.addresses)
        setSender({
            name: customer.name,
            company: customer.company ? customer.company : null,
            email: customer.email,
            phonenumber1: customer.phonenumber1,
            phonenumber2: customer.phonenumber2 ? customer.phonenumber2 : null,
            street: customer.defaultAddress.street,
            city: customer.defaultAddress.city, 
            state: customer.defaultAddress.state,
            postalcode: customer.defaultAddress.postalcode,
            country: customer.defaultAddress.country
        })
        setShow(false)
    }

    return (
        <div>
            <Button onClick={handleShow} disabled={update}>
                <MdPeople size={20} className='pb-1' /> Choose Customer
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                onEnter={()=>{
                    dispatch(getCustomerList({searchKeyword, pageNumber:1, limit: 20}))
                    setReceiver({
                        name: "",
                        company: "",
                        email: "",
                        phonenumber1: "",
                        phonenumber2: "",
                        street: "",
                        city: "", 
                        state: "",
                        postalcode: "",
                        country: ""
                    })
                }}
                onExit={()=>{
                    setSearchKeyword('')
                }}
                size='lg'
            >
                <Modal.Header closeButton>               
                    <Modal.Title>Select Customer {listLoading && <Spinner />}</Modal.Title>              
                </Modal.Header>
                <Modal.Body style={{height: '80vh', overflow: 'scroll'}}>
                    {listError && <Alert variant='warning'>{listMessage}</Alert>}
                    <Row className='mb-3'>
                        <Col sm={9}>
                            <Form.Control 
                                type='text'
                                placeholder='Search customer name, id, company, email, phone, address...'
                                value={searchKeyword}
                                onChange={({target})=>setSearchKeyword(target.value)}
                                onKeyDown={e=>{
                                    if(e.key==='Enter' && searchKeyword !== ''){
                                        dispatch(getCustomerList({searchKeyword:e.target.value, pageNumber:1, limit: 20}))
                                    } else if(e.key==='Enter' && searchKeyword === ''){
                                        dispatch(getCustomerList({searchKeyword:'', pageNumber:1, limit: 10}))
                                    } else return
                                }}
                            />
                        </Col>
                        {/* <Col sm={3}>
                            <Pagination>
                                {[...Array(pages).keys()].map(p=>(
                                    <Pagination.Item key={p+1} active={p+1 === page} onClick={()=> dispatch(getCustomerList({searchKeyword, pageNumber: p+1, limit: 10})) }>
                                        {p+1}
                                    </Pagination.Item>
                                ))}
                            </Pagination>
                        </Col> */}
                    </Row>
                    
                    <ListGroup>
                        {listSuccess &&
                            customerList.map((c,i)=>(
                                <ListGroup.Item 
                                    key={i}
                                    className={`${styles.customer_list}`}
                                    onClick={()=>hanldeSelectCustomer(c)}
                                    disabled={c.isSuspended}
                                >
                                    <div className='bg-primary-subtle ps-2 rounded fw-semibold'>{c.name} - {c.customerId}
                                        <span className="blink_me">
                                            {c.isSuspended && <Badge bg='danger float-end' style={{height: '23px'}}>suspended</Badge>}
                                        </span>
                                    </div>
                                    <div style={{fontSize:'14px'}} className='ps-1'>
                                        <div className='d-flex'>
                                            {c.company && <div>{c.company + ','}&nbsp;</div>}
                                            <div>{c.email}</div>
                                        </div>
                                        <div>
                                            {c.defaultAddress.street + ', ' + c.defaultAddress.city + ', ' + c.defaultAddress.state + ', ' + c.defaultAddress.country + ', ' + c.defaultAddress.postalcode}
                                        </div>
                                        <div>{c.phonenumber1} {c.phonenumber2 && ', '+c.phonenumber2}</div>
                                    </div>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default CustomerSelectModal