import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOSItemList, getOSItemListByCusId, updateOSItem, deleteOSItem } from '../../features/osItem/osItemSlice'
import { Button, Table, Modal, Form, Row, Col } from 'react-bootstrap'
import { OSItemAdd } from '../../Modals'
import { AlertMessage, MySpinner, MyPagination, useWindowSize } from '../../Components'
import { MdClose, MdOutlineEditOff } from 'react-icons/md'
import { toast } from 'react-toastify'

const OSItemListScreen = () => {
    const dispatch = useDispatch()
    const size = useWindowSize()
    const params = useParams()
    const [keyword, setKeyword] = useState('')
    const limit = 50
    const pageNumber = params.pageNumber ? params.pageNumber : 1
    const [showEdit, setShowEdit] = useState(false)
    const [showDelete, setShowDelete] = useState(false)
    const [editItem, setEditItem] = useState({
        name: '',
        qty: '',
        itemId: '',
        shipped: false,
        shipmentId: ''
    })
    const [deleteId, setDeleteId] = useState('')

    const handleCloseEdit = () => setShowEdit(false)
    const handleCloseDelete = () => setShowDelete(false)

    const { ositemlist, customerlist, listLoading, listError, listSuccess, listMessage, page, pages } = useSelector(state=>state.ositem.list)
    const { updateLoading, updateError, updateSuccess, updateMessage } = useSelector(state=>state.ositem.update)
    const { deleteLoading, deleteError, deleteSuccess, deleteMessage } = useSelector(state=>state.ositem.delete)

    const fetchList = useCallback(()=>{
        return getOSItemList({keyword:'', pageNumber: pageNumber, limit: limit, isShipped: false})
    },[pageNumber])

    useEffect(()=>{
        dispatch(fetchList())
    },[dispatch, fetchList])

    useEffect(()=>{
        if(updateSuccess){
            setShowEdit(false)
        }
        if(deleteSuccess){
            setShowDelete(false)
        }
        
    },[updateSuccess, deleteSuccess])

    return (
        <div>
            {(listLoading || updateLoading || deleteLoading) && <MySpinner />}
            {listError && <AlertMessage type='danger' msg={listMessage} />}
            
            {deleteError && <AlertMessage type='danger' msg={deleteMessage} />}
            <div className='d-flex'>
                <div className='me-3'>
                    <OSItemAdd />
                </div>
                <div className='ms-auto'>
                    <MyPagination 
                        path={keyword !== "" ? `staff/ositem/search/${keyword}` : 'staff/ositem'} 
                        pages={pages}
                        page={page}
                    />
                </div>
            </div>
            <Row className='my-2'>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Control
                            type='text'
                            value={keyword}
                            size='sm'
                            placeholder='Search with item name or customer ID...'
                            maxLength={100}
                            onChange={({target})=>setKeyword(target.value)}
                            onKeyDown={(e)=>{
                                if(e.key === 'Enter'){
                                    if(e.target.value === ''){
                                        dispatch(getOSItemList({keyword:'', pageNumber: 1, limit: limit, isShipped: false}))
                                    } else {
                                        dispatch(getOSItemList({keyword:e.target.value, pageNumber: 1, limit: limit, isShipped: false}))
                                    }
                                }
                            }}
                        />
                    </div>                
                </Col>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Select size='sm' onChange={({target})=>{                            
                            if(target.value === 'all'){
                                dispatch(getOSItemList({keyword:'', pageNumber: 1, limit: limit, isShipped: false}))
                            } else {
                                dispatch(getOSItemListByCusId(target.value))
                            }
                        }}>
                            <option value="all">Select Customer</option>
                            {listSuccess && customerlist.map(c=>(
                                <option key={c._id} value={c._id}>{c.name}</option>
                            ))}
                            <option value="all">All</option>
                        </Form.Select>
                    </div>                
                </Col>
            </Row>
            
            <div className='my-4' style={{fontSize: '15px'}}>
                {size.width > 760 ?
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Customer ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Item</th>
                            <th>Qty</th>
                            <th>Update</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>{os.customerCode}</td>
                                    <td>{os.customerId.name}</td>
                                    <td>{os.customerId.email}</td>
                                    <td>{os.name}</td>
                                    <td>{os.qty}</td>
                                    <td>
                                        <Button
                                            className='m-0 py-0 px-2'
                                            variant='info'
                                            size='sm'
                                            style={{width: '50px'}}
                                            onClick={()=>{
                                                setEditItem({name: os.name, qty: os.qty, itemId: os._id, shipped: os.isShipped, shipmentId: ''})
                                                setShowEdit(true)
                                            }}
                                        ><MdOutlineEditOff /></Button>
                                    </td>
                                    <td>
                                        <Button
                                            className='m-0 py-0 px-2'
                                            variant='danger'
                                            size='sm'
                                            style={{width: '50px'}}
                                            onClick={()=>{
                                                setDeleteId(os._id)
                                                setShowDelete(true)
                                            }}
                                        ><MdClose  /></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Detail</th>
                            <th>Update</th>
                            <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>
                                        <div>{os.name} - {os.qty}</div>
                                        <div>{os.customerCode}</div>
                                        <div>{os.customerId.name}</div>
                                        <div>{os.customerId.email}</div>
                                    </td>
                                    <td>
                                        <Button
                                            className='m-0 py-0 px-2'
                                            variant='info'
                                            size='sm'
                                            style={{width: '50px'}}
                                            onClick={()=>{
                                                setEditItem({name: os.name, qty: os.qty, itemId: os._id, shipped: os.isShipped, shipmentId: ''})
                                                setShowEdit(true)
                                            }}
                                        ><MdOutlineEditOff /></Button>
                                    </td>
                                    <td>
                                        <Button
                                            className='m-0 py-0 px-2'
                                            variant='danger'
                                            size='sm'
                                            style={{width: '50px'}}
                                            onClick={()=>{
                                                setDeleteId(os._id)
                                                setShowDelete(true)
                                            }}
                                        ><MdClose  /></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
                
            </div>

            <div>
                <Modal show={showEdit} onHide={handleCloseEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Item Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {updateError && <AlertMessage type='danger' msg={updateMessage} />}
                        <div className='d-flex my-3'>
                            <div className='w-75 pe-3'>
                                <div className='mb-1'>Item Name</div>
                                <div>
                                    <Form.Control
                                        type='text'
                                        size='sm'
                                        value={editItem.name}
                                        onChange={({target})=>setEditItem({...editItem, name: target.value})}
                                    />
                                </div>
                            </div>
                            <div className='w-25'>
                                <div className='mb-1'>Item Qty</div>
                                <div>
                                    <Form.Control
                                        type='number'
                                        size='sm'
                                        value={editItem.qty}
                                        onChange={({target})=>setEditItem({...editItem, qty: target.value})}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='border p-2 rounded mb-3'>
                            <Form.Check
                                label='Mark this item is shipped.'
                                value={editItem.shipped}
                                onChange={()=>setEditItem({...editItem, shipped: !editItem.shipped})}
                            />
                        </div>
                        {editItem.shipped &&
                        <div>
                            <div className='mb-1'>Shipment ID</div>
                            <div>
                                <Form.Control
                                    type='text'
                                    size='sm'
                                    value={editItem.shipmentId}
                                    onChange={({target})=>setEditItem({...editItem, shipmentId: target.value})}
                                />
                            </div>
                        </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={handleCloseEdit} style={{width: '100px'}}>
                        Cancel
                    </Button>
                    <Button 
                        variant="success" 
                        size='sm' 
                        onClick={()=>{
                            console.log(editItem)
                            if(editItem.shipped && editItem.shipmentId === ''){
                                toast.warning('Shipment ID is needed if item is shipped')
                                return
                            }else{
                                dispatch(updateOSItem(editItem))
                            }
                        }} 
                        className='ms-auto' 
                        style={{width: '100px'}}
                    >
                        Save
                    </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={showDelete} onHide={handleCloseDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm delete?</Modal.Title>
                    </Modal.Header>
                    <Modal.Footer>
                    <Button variant="secondary" size='sm' onClick={handleCloseDelete} style={{width: '100px'}}>
                        No
                    </Button>
                    <Button variant="danger" size='sm' onClick={()=>dispatch(deleteOSItem(deleteId))} className='ms-auto' style={{width: '100px'}}>
                        Yes
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}

export default OSItemListScreen