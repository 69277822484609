import { Form } from 'react-bootstrap'
//import styles from './customercreateshipmentscreen.module.css'

const CargoType = ({cargoType, setCargoType}) => {
  return (
    <div>
        <h3>Cargo type</h3>
        <Form.Select value={cargoType} aria-label="Cargo select" onChange={({target})=>setCargoType(target.value)}>
            <option value="">Select</option>
            <option value="air">AIR</option>
            <option value="ocean">OCEAN</option>
            <option value="express">EXPRESS</option>
        </Form.Select>
        {/* <Row>
            <Col md={4} onClick={()=>setCargoType('air')}>
                <Card 
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='air' && '2px solid var(--color-gold)', background: cargoType==='air' && 'var(--color-hover)'}}
                    >                            
                    <Card.Body>
                        <Card.Title>AIR</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">1 - 14 days</Card.Subtitle>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4} onClick={()=>setCargoType('ocean')} >
                <Card 
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='ocean' && '2px solid var(--color-gold)', background: cargoType==='ocean' && 'var(--color-hover)'}}
                    >
                    <Card.Body>
                        <Card.Title>OCEAN</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">75 - 90 days</Card.Subtitle>
                    </Card.Body>
                </Card>
            </Col>
            <Col md={4} onClick={()=>setCargoType('express')}>
                <Card  
                    className={styles.cargotype_card} 
                    style={{border: cargoType==='express' && '2px solid var(--color-gold)', background: cargoType==='express' && 'var(--color-hover)'}}
                    >
                    <Card.Body>
                        <Card.Title>EXPRESS OCEAN</Card.Title>
                        <Card.Subtitle className="mb-2 text-muted">30 days</Card.Subtitle>
                    </Card.Body>
                </Card>
            </Col>
        </Row> */}
    </div>
  )
}

export default CargoType