import axios from 'axios'

const API_URL = '/api/cutoff'


//Get cut off list
const getCutOffList = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`?keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}`, config)
    return response.data
}


//Create new cut off 
const createCutOff = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, data, config)
    return response.data
}


//Get cut off detail
const getCutOffDetail = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/${id}`, config)
    return response.data
}

//Delete cut off
const deleteCutOff = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/${id}`, config)
    return response.data
}

//Add-Remove shipment from cut off
const updateCutOff = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+`/${data.cutoffId}`, data, config)
    return response.data
}

//Add tracking to a cut off
const addCutOffTracking = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/tracking/${data.cutoffId}`, data, config)
    return response.data
}

//remove tracking to a cut off
const deleteCutOffTracking = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/tracking/${data.cutoffId}?trackingId=${data.trackingId}`, config)
    return response.data
}



const cutoffServices = {    
    getCutOffList,
    createCutOff,
    getCutOffDetail,
    deleteCutOff,
    updateCutOff,
    addCutOffTracking,
    deleteCutOffTracking,
}

export default cutoffServices