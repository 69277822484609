import React, { useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import { Link } from 'react-router-dom'
import { Form, Row, Col, Button, Table, Pagination } from 'react-bootstrap'
import { MdCircle, MdExitToApp, MdOutlineSearch, MdOutlineWifiProtectedSetup } from 'react-icons/md'
import { toast } from 'react-toastify'
import { getFilteredShipments, filterReset } from '../../features/shipment/shipmentSlice'
import { AlertMessage, MySpinner, useWindowSize } from '../../Components'


const StaffShipmentFilterScreen = () => {
    const size = useWindowSize()
    const dispatch = useDispatch()
    const [ condition, setCondition ] = useState('')
    const [ timeline, setTimeline ] = useState('')
    const [ date, setDate ] = useState('')
    const [ startDate, setStartDate ] = useState('')
    const [ endDate, setEndDate ] = useState('')
    const [ month, setMonth ] = useState(new Date().toISOString().substring(0,7))
    const [ cargoType, setCargoType ] = useState('') 
    const { filterLoading, filterShipments, filterPage, filterPages, filterSuccess, filterError, filterMessage } = useSelector(state=>state.shipment.filter)


    const handleSubmit = (pageNumber) => {
        if(
            condition === '' || 
            timeline === '' || 
            (timeline === 'date' && date === '') || 
            (timeline === 'datetodate' && startDate === '') || 
            (timeline === 'datetodate' && endDate === '') || 
            (timeline === 'month' && month === '')
            ){
            toast.warning('* = required')
            return
        }
    
        const data = {
            condition,
            timeline,
            date,
            startDate,
            endDate,
            month,
            cargoType,
            page: pageNumber
        }
        dispatch(getFilteredShipments(data))
       //console.log(data)
    }

    const handleReset = () => {
        dispatch(filterReset())
        setCargoType('')
        setCondition('')
        setTimeline('')
        setDate('')
        setStartDate('')
        setEndDate('')
        setMonth('')
    }

    return (
        <div>
            {filterLoading && <MySpinner />}
            {filterError && <AlertMessage type='danger' msg={filterMessage} />}
            <Row>
                <Col sm={6} className='mb-3'>
                    <Form.Label className='m-0 p-0'>Cargo type</Form.Label>
                    <Form.Select value={cargoType} onChange={({target})=>setCargoType(target.value)}>
                        <option value=''>All</option>
                        <option value='air'>Air</option>
                        <option value='ocean'>Ocean</option>
                        <option value='express'>Express</option>      
                    </Form.Select>
                       
                </Col>

                <Col sm={6} className='mb-3'>
                    <Form.Label className='m-0 p-0'>Status *</Form.Label>
                    <Form.Select value={condition} onChange={({target})=>{
                        if(target.value === ''){
                            setTimeline('')
                            setDate('')
                            setStartDate('')
                            setEndDate('')
                            setMonth('')
                        }
                        setCondition(target.value)
                    }}>
                        <option value=''>Select</option>
                        <option value='created'>Created</option>
                        <option value='cutoff'>Cutoff</option>
                        <option value='shipped'>Shipped</option>                
                        <option value='received'>Received</option>                
                        <option value='delivered'>Delivered</option>
                    </Form.Select>
                </Col>
                
            </Row>

            <Row>
                <Col sm={6} className='mb-3'>
                    {condition !== '' &&
                        <>
                        <Form.Label className='m-0 p-0'>Timeline *</Form.Label>
                        <Form.Select value={timeline} onChange={({target})=>setTimeline(target.value)}>
                            <option value=''>Select</option>
                            <option value='date'>Date</option>
                            <option value='datetodate'>Date to Date</option>
                            <option value='month'>Month</option>      
                        </Form.Select>
                        </>
                    }
                </Col>
                <Col sm={6} className='mb-3'>
                    {timeline === 'date' &&
                    <>  
                        <Form.Label className='m-0 p-0'>Date *</Form.Label>
                        <Form.Control 
                            type='date'
                            value={date}
                            onChange={({target})=>setDate(target.value)}
                        />
                    </>
                    }
                    {timeline === 'datetodate' &&
                    <>
                        <Row>
                            <Col md={6} className='mb-3'>
                                <Form.Label className='m-0 p-0'>Start Date *</Form.Label>
                                <Form.Control 
                                    type='date'
                                    value={startDate}
                                    onChange={({target})=>setStartDate(target.value)}
                                />
                            </Col>
                            <Col md={6} className='mb-3'>
                                {startDate !== '' &&
                                <>
                                    <Form.Label className='m-0 p-0'>End Date *</Form.Label>
                                    <Form.Control 
                                        type='date'
                                        value={endDate}
                                        min={new Date(startDate).toISOString().substring(0,10)}
                                        onChange={({target})=>setEndDate(target.value)}
                                    />
                                </>
                                }
                            </Col>
                        </Row>
                    </>
                    }

                    {timeline === 'month' &&
                    <>
                        <Form.Label className='m-0 p-0'>Month *</Form.Label>
                        <Form.Control 
                            type='month'
                            value={month}
                            onChange={({target})=>setMonth(target.value)}
                        />
                    </>
                    }
                </Col>                
            </Row>

            <div className='d-flex justify-content-between'>
                <Button
                    style={{width: '150px'}}
                    variant='dark'
                    onClick={handleReset}
                ><MdOutlineWifiProtectedSetup size={25} /> Reset
                </Button>
                <Button 
                    style={{width: '150px'}}
                    onClick={()=>handleSubmit(1)}
                ><MdOutlineSearch size={25} /> Find</Button>                
            </div>

        {filterSuccess && 
            <div className='my-3'>
                {size.width > 750 ? 
                <>
                <div className='text-danger text-end' style={{fontSize:'13px'}}>C=Cutoff, S=Shipped, R=Received, P=Paid, D=Delivered</div>
                <Table striped hover bordered size='sm'>
                    <thead>
                        <tr className='text-center'>
                        <th>#</th>
                        <th>ID</th>
                        <th>Type</th>
                        <th>Box Qty</th>
                        <th>From/To</th>
                        <th>Total Weight</th>
                        <th>Created</th>
                        <th>C</th>
                        <th>S</th>
                        <th>R</th>
                        <th>P</th>
                        <th>D</th>
                        <th>Detail</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterShipments.length === 0 ? <tr><td>No Shipment Found.</td></tr> : filterShipments.map((s,i) => (
                        <tr key={s._id} className='text-center'>
                            <td>{(filterPage*50)-(50-(i+1))}</td>
                            <td>{s.shipmentId}</td>
                            <td>{s.cargoType.toUpperCase()}</td>
                            <td>{s.boxes.length}</td>
                            <td>
                            <div style={{fontSize:'13px'}}>
                                <div>{s.sender.name +', '+ s.sender.address.city +', '+  s.sender.address.country}</div>
                                <div>{s.receiver.name +', '+ s.receiver.address.city +', '+  s.receiver.address.country}</div>
                            </div>
                            </td>
                            <td>{s.totalWeight.toFixed(2)}</td>
                            <td>{new Date(s.createdAt).toLocaleDateString('en-TH',{day: '2-digit', month: 'short'})}</td>
                            <td>{s.isCutOffed ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                            <td>{s.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                            <td>{s.isReceived ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                            <td>{s.isPaid ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                            <td>{s.isDelivered ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                            <td className='text-center'>
                            <Link target={"_blank"} to={`/staff/shipments/${s._id}`}>
                                <Button 
                                    size='sm' 
                                    variant='flush' 
                                    style={{height: '30px', width: '50px'}} 
                                    className='m-0 p-0'
                                    // onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                                ><MdExitToApp size={30}  /></Button>
                            </Link>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                </>
                :
                <Table striped hover bordered size='sm'>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Detail</th>
                        <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterShipments.length === 0 ? <tr><td>No Shipment Found.</td></tr> : filterShipments.map((s,i) => (
                        <tr key={s._id}>
                            <td>{(filterPage*50)-(50-(i+1))}</td>
                            <td style={{fontSize: '15px'}}>
                            <div>ID - {s.shipmentId}</div>
                            <div>Cargo Type - {s.cargoType.toUpperCase()}</div>
                            <div>Box Qty - {s.boxes.length}</div>
                            <div>Receiver - {s.receiver.name}</div>
                            <div>{s.receiver.address.city}, {s.receiver.address.state}, {s.receiver.address.contry}</div>                  
                            <div>Total Weight - {s.totalWeight.toFixed(2)}</div>
                            <div>Paid - {s.isPaid ? 'Yes' : 'No'}</div>
                           
                            </td>
                            <td className='text-center'>
                                <Link target={"_blank"} to={`/staff/shipments/${s._id}`}>
                                    <Button 
                                        size='sm' 
                                        variant='flush' 
                                        style={{height: '40px', width: '50px'}} 
                                        className='m-0 p-0'
                                        // onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                                    ><MdExitToApp size={30}  /></Button>
                                </Link>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                }

                {filterPages &&
                <div className='d-flex justify-content-center'>
                    <Pagination size='sm'>
                        {[...Array(filterPages).keys()].map(p=>(
                            <Pagination.Item 
                                key={p+1} 
                                active={p+1 === filterPage} 
                                onClick={()=> {
                                    handleSubmit(p+1)
                                }}>
                                {p+1}
                            </Pagination.Item>
                        ))}
                    </Pagination>
                </div>
                }
            </div>
        }

            
        </div>
    )
}

export default StaffShipmentFilterScreen