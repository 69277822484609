import styles from './customercreateshipmentscreen.module.css'
import { Row, Col, Card, Form, OverlayTrigger, Button, Table } from 'react-bootstrap'
import { useState } from 'react'
import { addDays } from '../../../helpers/dateHelper'
import freePickupLocations from '../../../data/pickupLocations'
import { toast } from 'react-toastify'
import { MdClose } from 'react-icons/md'

const OtherServices = ({boxes, pickup, setPickup, noItem, sender, pickupData, setPickupData, otherCharges, setOtherCharges, chargesList}) => {
    const [ senderAdd, setSenderAdd ] = useState(false)
    const [ selectedCharge, setSelectedCharge ] = useState('')
    const [ otherChargeName, setOtherChargeName ] = useState('')

    const [ chargeAmount, setChargeAmount ] = useState('')
    const [ otherChargeAmount, setOtherChargeAmount ] = useState('')
    return (
        <div className={styles.other_services_main}>
            <h3>Additional Services </h3>
            <Row className='mt-3'>
                <Col md={6} className='mb-3' >
                    <Row>
                        <Card className='border-warning px-0'>
                            <Card.Header>Add Charges</Card.Header>
                            <Card.Body>
                                <Row className='mb-1'>                                    
                                    <Col xs={8}>Charge Name</Col>
                                    <Col xs={2}>Amount</Col>
                                    <Col xs={2}>
                                    </Col>
                                </Row>
                                <Row className='mb-3'>                                    
                                    <Col xs={8}>
                                        <Form.Select 
                                            size='sm' 
                                            value={selectedCharge} 
                                            onChange={({target})=>{
                                                if(target.value === 'Insurance'){
                                                    setChargeAmount(((boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (Number(itm.price)*Number(itm.unit)), 0)), 0)) * 0.1).toFixed(2))
                                                } else {
                                                    setChargeAmount('')
                                                }
                                                setSelectedCharge(target.value)
                                            }} >
                                            <option value="">Select</option>
                                            {chargesList.map((ch,i)=>(
                                                <option 
                                                    key={i} 
                                                    value={ch.name}
                                                    disabled={otherCharges.some(e=>e.name === ch)}
                                                >{ch.name}</option>
                                            ))}
                                            <option value="Other">Other</option>
                                        </Form.Select>
                                    </Col>
                                    {selectedCharge !== 'Other' &&
                                        <>
                                        <Col xs={2}>
                                            <Form.Control
                                                size='sm'
                                                value={chargeAmount}
                                                onChange={({target})=>setChargeAmount(target.value.replace(/[^0-9.]/g,''))}
                                            />
                                        </Col>
                                        <Col xs={2}>
                                            <Button
                                                className='w-100 '
                                                size='sm'
                                                disabled={selectedCharge === '' || chargeAmount === ''}
                                                onClick={()=>{
                                                    setOtherCharges([...otherCharges, {name: selectedCharge, amount: chargeAmount}])
                                                    setSelectedCharge('')
                                                    setChargeAmount('')
                                                }}
                                            >Add</Button>
                                        </Col>
                                        </>
                                    }
                                </Row>
                                {selectedCharge === 'Other' &&
                                <Row className='mb-4'>                                    
                                    <Col xs={8}>
                                        <Form.Control 
                                            size='sm'
                                            value={otherChargeName}
                                            onChange={({target})=>setOtherChargeName(target.value)}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <Form.Control 
                                            size='sm'
                                            value={otherChargeAmount}
                                            onChange={({target})=>setOtherChargeAmount(target.value.replace(/[^0-9.]/g,''))}
                                        />
                                    </Col>
                                    <Col xs={2}>
                                        <Button
                                            className='w-100 '
                                            size='sm'
                                            disabled={otherChargeName === '' || otherChargeAmount === ''}
                                            onClick={()=>{
                                                const index = otherCharges.findIndex(och=>och.name === otherChargeName)
                                                if(index === -1){
                                                    setOtherCharges([...otherCharges, {name: otherChargeName, amount: otherChargeAmount}])
                                                    setOtherChargeName('')
                                                    setOtherChargeAmount('')
                                                    setSelectedCharge('')
                                                } else {
                                                    toast.warning(`${otherChargeName} is already in the list`)
                                                    return
                                                }
                                                
                                            }}
                                        >Add</Button>
                                    </Col>
                                </Row>
                                }
                                <Table striped bordered size="sm" className='mb-3 text-center' style={{fontSize: '14px'}}>
                                    <thead>
                                        <tr>
                                        <th style={{width: '40px'}}>#</th>
                                        <th>Name</th>
                                        <th style={{width: '100px'}}>Amount</th>
                                        <th style={{width: '100px'}}>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {otherCharges.map((ch,i)=>(
                                            <tr key={i}>
                                                <td>{i+1}</td>
                                                <td>{ch.name}</td>
                                                <td>{ch.amount}</td>
                                                <td>
                                                    <Button
                                                        size='sm'
                                                        variant='danger'
                                                        className='p-0 m-0'
                                                        style={{width: '30px'}}
                                                        onClick={()=>{                                                            
                                                            setOtherCharges([
                                                                ...otherCharges.slice(0, i),
                                                                ...otherCharges.slice(i + 1)
                                                            ]); 
                                                        }}
                                                    >
                                                        <MdClose  />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                
                                <div>Charges Total - $ {(otherCharges.reduce((acc, c) => acc + Number(c.amount), 0)).toFixed(2)}</div>

                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                 <Col md={6} className='mb-3'>
                    <Card className='border-warning'>
                        <Card.Header>Schedule Pickup</Card.Header>
                        <Card.Header className='ps-1'>
                            <OverlayTrigger trigger="click" placement="right" rootClose={true} overlay={freePickupLocations}>
                                <Button variant="link" style={{height: '25px', paddingTop: '2px', marginLeft: '0px'}}>Free Pickup Available Areas</Button>
                            </OverlayTrigger>
                        </Card.Header>
                        <Card.Body>
                            {/* <Card.Text>We can pick up your boxes if your location is near our service area.</Card.Text> */}
                            <Card.Subtitle className="mb-2 text-muted">If your location is not in "Free Pickup Areas List", rate start at $ 1.50 per mile back and forth. </Card.Subtitle>
                            <Card.Text className="mb-2 text-danger">Total weight of the shipment must be 50 lbs or more</Card.Text>
                            
                            <Form.Select 
                                size='sm' 
                                value={pickup} 
                                disabled={(boxes.reduce((acc, b) => acc + Number(b.weight), 0)) < 50}
                                onChange={({target})=>{
                                    setPickup(target.value)
                                    if(pickup === 'yes'){
                                        setPickupData({ date:'',time: '',address: '',})
                                        setSenderAdd(false)
                                }
                            }}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                                
                            </Form.Select>
                            {pickup === 'yes' &&
                            <>
                                <Row className='my-4'>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Select Date</Form.Label>
                                            <Form.Control 
                                                type='date'
                                                min={new Date().toISOString().substring(0,10)}
                                                max={addDays(new Date(), 7)}
                                                defaultValue={pickupData.date.substring(0,10)}
                                                onChange={(e)=>{
                                                    if(new Date(e.target.value).getDay() === 0){                                                        
                                                        e.preventDefault()
                                                        toast.warning("We can't do schedule pickup on Sunday. Please select other day")
                                                        setPickupData({...pickupData, date:''})
                                                    } else {
                                                        setPickupData({...pickupData, date: e.target.value})
                                                    }                                                    
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Select Time <span className='text-danger' style={{fontSize:'13px'}}>9am - 5pm</span></Form.Label>
                                            <Form.Control 
                                                type='time'
                                                min="09:00" 
                                                max="14:00"
                                                value={pickupData.time}
                                                onChange={({target})=>setPickupData({...pickupData, time: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className='mt-4'>
                                    <Col>
                                        <Form.Group>
                                            <div className='d-flex'>
                                                <Form.Label className='fs-5'>Location</Form.Label>
                                                <div className={styles.pickup_checkbox}>
                                                    <input
                                                        type='checkbox'
                                                        id='senderaddress'                                                        
                                                        onChange={()=>{
                                                            setSenderAdd(prev=>!prev)
                                                            if(senderAdd === false){
                                                                setPickupData({...pickupData, street: sender.street, city: sender.city, state: sender.state, country: sender.country, postalcode: sender.postalcode}) 
                                                            } else {
                                                                setPickupData({...pickupData, street: '', city: '', state: '', country: '', postalcode: ''}) 
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor='senderaddress'>Sender Address</label>
                                                </div>
                                            </div>                                            
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                                <Form.Group>
                                    <Form.Label className='mb-0'>Address</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.street}
                                        onChange={({target})=>setPickupData({...pickupData, street: target.value})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='mb-0'>City</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.city}
                                        onChange={({target})=>setPickupData({...pickupData, city: target.value})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='mb-0'>State/Province</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.state}
                                        onChange={({target})=>setPickupData({...pickupData, state: target.value})}
                                    />
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className='mb-0'>Postal Code</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                readOnly={senderAdd}
                                                value={pickupData.postalcode}
                                                onChange={({target})=>setPickupData({...pickupData, postalcode: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className='mb-0'>Country</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                readOnly={senderAdd}
                                                value={pickupData.country}
                                                onChange={({target})=>setPickupData({...pickupData, country: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                            }
                        </Card.Body>
                    </Card>
                </Col>

                
            </Row>
        </div>
    )
}

export default OtherServices