import { useState, useRef } from 'react';
//import styles from './customercreateshipmentscreen.module.css'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import {QRCodeSVG} from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import { MdPrint } from 'react-icons/md'
import Barcode from 'react-barcode'

const PrintStickerLabel = ({box, shipmentId, boxNo, boxQty, sender, receiver}) => {
    const componentRef = useRef()
    const [show, setShow] = useState(false);   
    const handleShow = () => setShow(true); 
    const handleClose = () => setShow(false)
    
    return (
        <div>
            <Button size='sm' variant="link" onClick={handleShow}>4 x 6</Button>
            <Modal
                show={show}
                onHide={handleClose}
                size='lg'
            >
              <Modal.Header closeButton>               
                <Modal.Title> 4x6 Label </Modal.Title>
              
            </Modal.Header>
                <div className='me-5 ms-auto mt-3'>
                    <ReactToPrint
                        
                        trigger={() => <Button variant='info ' style={{width: '100px'}}><MdPrint /> Print </Button>}
                        content={() => componentRef.current}
                        
                        onAfterPrint={()=>setShow(false)}
                    />
                </div>
                <Modal.Body className='d-flex justify-content-center'>
                    <div style={{
                        
                        border: '0.2mm solid black',
                        }} ref={componentRef}>
                            <style type="text/css" media="print">{"\
                            @page { size: 4in 6in; margin: 2mm 2mm;}\
                            "}</style>
                                               
                        <Col sm={12} className='border-bottom border-dark text-center'>
                            <div>NYSS EXPRESS, US. INC</div>                                
                        </Col>
                        <Row>
                            <Col>
                                <div className='fs-4 fw-bold text-center align-middle pt-2'>{shipmentId}</div>
                                <div className='fs-4 fw-bold text-center align-middle'>{box.boxId}</div>
                            </Col>
                            <Col>
                                <div>AIR</div>
                                <div>{box.length}" x {box.width}" x {box.height}"</div>
                                <div>{box.actualWeight} lbs</div>
                                <div>Box {boxNo} of {boxQty}</div>
                            </Col>
                            
                        </Row>
                        <Row className='border-top border-bottom border-dark mx-1'>
                            
                            <Col xs={4} className='border-end border-dark py-2'>
                                <div>
                                <QRCodeSVG size={90} value={shipmentId+box.boxId} />
                                </div>
                            </Col>
                            <Col xs={8} style={{fontSize: '13px'}}>
                                <div className='text-center'>Sender</div>
                                <div>{sender.name}</div>
                                <div>{sender.address.street}, {sender.address.city}, {sender.address.state}, {sender.address.postalcode}, {sender.address.country}</div>                            
                                <div>{sender.phonenumber1} {sender.phonenumber2 && ", " + sender.phonenumber2}</div>                            
                                
                            </Col>

                        </Row>

                        <Row className='mx-1 border-bottom border-dark' style={{fontSize: '15px'}}>                
                            <Col className='py-2'>
                                <div className='text-center fs-5'>Receiver</div>                               
                                <div>{receiver.name}</div>     
                                <div>{receiver.address.street}, {receiver.address.city}, {receiver.address.state}, {receiver.address.postalcode}, {receiver.address.country}</div>
                                <div>{receiver.phonenumber1} {receiver.phonenumber2 && ", " + receiver.phonenumber2}</div>
                            </Col>
                        </Row>  
                        <div className=' mt-3 d-flex justify-content-center'>
                            <Barcode value={shipmentId+box.boxId} format={'CODE128'} height={100} width={1} />  
                        </div>                        
                                      
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PrintStickerLabel