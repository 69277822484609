import './footer.css'
import {logogold, facebook, youtube} from '../../../assets'
//import { instagram } from '../../../assets'
import { MdOutlineCall, MdAlternateEmail } from 'react-icons/md'
import {LinkContainer} from 'react-router-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Footer = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  return (
    <div className='footer'>
      <div className='footer-main'>
        <div className='footer-logo'>
          <img src={logogold} alt='logoGold' />
        </div>
        <div className='footer-list'>
          {/* <h4 className='mb-3'>{t('HELP')}</h4> */}
          <p
            onClick={()=>navigate('/contact')}
          >{t('Contact Us')}</p>
          <p
            onClick={()=>navigate('/gallery')}
          >Gallery</p>
        </div>
        <div className='footer-list'>
          {/* <h4 className='mb-3'>{t('INFORMATION')}</h4> */}
          <LinkContainer to='/faq'><p>{t('Frequently asked questions')}</p></LinkContainer>
          <LinkContainer to='/privacy'><p>{t('Privacy Policy')}</p></LinkContainer>
          <LinkContainer to='/terms'><p>{t('Terms')}</p></LinkContainer>
        </div>
        <div className='footer-list'>
          <h4 className='mb-3'>{t('FOLLOW US')}</h4>
          <div className='social-section'>
            <Link to='https://www.facebook.com/nyssexpress/' target='_blank'>
              <img src={facebook} alt='facebook' />
            </Link>
            {/* <img src={instagram} alt='instagram' /> */}
            <Link to='https://www.youtube.com/@thisisnyss' target='_blank'>
              <img src={youtube} alt='youtube' />
            </Link>
          </div>

          <div className='contact-section'>
            <a href='tel:+17188998389'><MdOutlineCall /> {t('CALL US')}</a>
            <a href='mailto:nyseashipping@gmail.com'><MdAlternateEmail /> {t('EMAIL US')}</a>
          </div>
        </div>
      </div>

      <div className='footer-social'>
        
        <p>Copyright &#169; {new Date().getFullYear()} NYSS. All Rights Reversed</p>
        
      </div>
    </div>
  )
}

export default Footer