import axios from 'axios'

const API_URL = '/api/ositem'

//Add New os item
const addNewOSItem = async (newItem, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, newItem, config)
    return response.data
}

//Get os item list
const getOSItemList = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const searchKeyword = data.keyword ? data.keyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`?keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}&isShipped=${data.isShipped}`, config)
    return response.data
}

//Get os item list by customer _id
const getOSItemListByCusId = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/bycustomerid/${id}`, config)
    return response.data
}

//Get shipped os item list by customer _id
const getOSItemShippedListByCusId = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/shippedbycustomerid/${id}`, config)
    return response.data
}

//Get my os item list
const getMyOSItemList = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const searchKeyword = data.keyword ? data.keyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    var query = `keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}`
    if(data.isShipped === false){
        query = query + `&isShipped=${data.isShipped}`
    }
    const response = await axios.get(API_URL+`/my?${query}`, config)
    return response.data
}


//Update an ositem
const updateOSItem = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+'/' + data.itemId, data, config)
    return response.data
}

//Delete a os item
const deleteOSItem = async (itemId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+'/'+ itemId, config)
    return response.data
}


const osItemServices = {
    addNewOSItem,
    getOSItemList,
    getOSItemListByCusId,
    getOSItemShippedListByCusId,
    getMyOSItemList,
    updateOSItem,
    deleteOSItem,
}

export default osItemServices