import axios from 'axios'
const API_URL = '/api/system'

//Add New Rate 
const verifyRCToken = async (data) => {
    const response = await axios.post(API_URL+'/verifytoken' , data)
    return response.data
}

//Add New Rate 
const sendContactUsMessage = async (data) => {
    const response = await axios.post(API_URL+'/contactus' , data)
    return response.data
}

//Get Message List
const getMessages = async (search, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/messages?keyword=${search}`, config)
    return response.data
}

//delet contact us message
const deleteMessage = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/messages?id=${id}`, config)
    return response.data
}

const systemServices = {
    verifyRCToken,
    sendContactUsMessage,
    getMessages,
    deleteMessage,
}

export default systemServices