import {useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Button, Row, Col, Table, ListGroup, Badge, Spinner } from 'react-bootstrap'
import { getShipmentDetail, deleteShipment, deleteReset, undoPickedUp, undoShipped, undoDelivered, undoReceived } from '../../features/shipment/shipmentSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { AlertMessage, MySpinner, useWindowSize, DeleteConfirm } from '../../Components'
import PrintBoxLabel from '../StaffScreens/StaffShipmentCreateScreen/PrintBoxLabel'
import PrintStickerLabel from '../StaffScreens/StaffShipmentCreateScreen/PrintStickerLabel'
import { ImageViewModal, InvoiceModal, SimpleConfirmModal } from '../../Modals'
// import PackingListPrint from './PackingListPrint'
import { AddTrackingModal, PaymentModal } from '../../Modals'
import { MdCircle, MdNorthEast } from 'react-icons/md'

const StaffShipmentDetailScreen = () => {
    const size = useWindowSize()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {id} = useParams()
    const { staff } = useSelector(state=>state.staff.auth)
    const { shipment, detailLoading, detailError, detailSuccess, detailMessage } = useSelector(state=>state.shipment.detail)
    const { pickedLoading, pickedError, pickedMessage } = useSelector(state=>state.shipment.picked)
    const { shippedLoading, shippedError, shippedMessage } = useSelector(state=>state.shipment.shipped)
    const { deliveredLoading, deliveredError, deliveredMessage } = useSelector(state=>state.shipment.delivered)

    useEffect(()=>{
        dispatch(getShipmentDetail(id))
    },[dispatch, id])
    return (
        <div>
            {detailLoading && <MySpinner />}
            {shippedLoading && <MySpinner />}
            {deliveredLoading && <MySpinner />}
            {detailError && <AlertMessage msg={detailMessage} />}
            <div className='d-flex mb-2'>
                <div className='me-auto'>
                    <Button variant='link' onClick={()=>navigate('/staff/shipments')}>Go Back</Button>
                </div>
                <div className='d-flex'>
                
                    <div>
                        <AddTrackingModal 
                            shipmentId = {id} 
                            className='m-2'
                            forShipment={true}
                        />
                    </div>
                    <div>
                        <Button
                            variant='info'
                            className='m-2'
                            disabled={shipment && shipment.paymentDetail.length !== 0}
                            onClick={()=>navigate('/staff/shipments/update', {state: {shipment: shipment, cusId: shipment.customerId._id}})}
                        >Update</Button>
                    </div>
                    {staff && staff.isAdmin && shipment !== null &&
                    <>
                        {staff.isAdmin && shipment &&
                        <div>
                            <PaymentModal grandTotal = {shipment.grandTotal} paymentDetail={shipment.paymentDetail} isPaid = {shipment.isPaid} />
                        </div>
                        }
                        <div>
                            <DeleteConfirm 
                                type=' shipment ' 
                                name={shipment.shipmentId} 
                                id={shipment._id} 
                                stateName={'shipment'}
                                deleteFunction={deleteShipment} 
                                location='/staff/shipments'
                                size='md'
                                doReset={true}
                                reset={deleteReset}
                                className='m-2'
                                disabled={shipment.isShipped || shipment.isCutOffed || shipment.isPaid}
                            />
                        </div>
                    </>
                    }
                </div>
            </div>
            
            {detailSuccess &&             
                <Card>
                    <Card.Header>
                        <div className={size.width > 760 ? 'd-flex justify-content-between' : ''}>
                            <div className='fs-3'>{shipment.shipmentId}</div>
                            <div className='fs-5 bg-warning px-2 py-1 rounded'>Status - {shipment.tracking.length === 0 ? 'Shipment Created' : shipment.tracking[0].status}</div>
                        </div>
                    </Card.Header>
                    <Card.Header as='h4'>{shipment.cargoType.toUpperCase()}</Card.Header>
                    <Card.Body>

                        
                        <Row>
                            <Col sm={6} xs={12} className='mb-3'>
                                <Card>
                                    <Card.Header className='bg-danger-subtle fs-5 d-flex justify-content-between'>
                                        <div>Sender</div>
                                        <div className='m-0 p-0'>                                            
                                            <Button
                                                variant='link'
                                                size='sm'
                                                className='m-0 p-0'
                                                onClick={()=>navigate(`/staff/customers/${shipment.customerId._id}`)}
                                            >
                                                view detail
                                            </Button>
                                        </div>
                                    </Card.Header>
                                    <Card.Body>
                                        <Card.Title>{shipment.sender.name} {shipment.customerCode && <span>({shipment.customerCode})</span>}</Card.Title>
                                        
                                        {shipment.sender.company && <div>{shipment.sender.company}</div>}
                                        <div>{shipment.sender.address.street}, {shipment.sender.address.city}, {shipment.sender.address.state}, {shipment.sender.address.postalcode}, {shipment.sender.address.country}</div>
                                        <div>{shipment.sender.phonenumber1} {shipment.sender.phonenumber2 && ', ' + shipment.sender.phonenumber2}</div>
                                        {shipment.sender.email && <div>{shipment.sender.email}</div>}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} xs={12} className='mb-3'>
                                <Card>
                                    <Card.Header className='bg-danger-subtle fs-5'>Receiver</Card.Header>
                                    <Card.Body>
                                        <Card.Title>{shipment.receiver.name}</Card.Title>
                                        {shipment.receiver.company && <div>{shipment.receiver.company}</div>}
                                        <div>{shipment.receiver.address.street}, {shipment.receiver.address.city}, {shipment.receiver.address.state}, {shipment.receiver.address.postalcode}, {shipment.receiver.address.country}</div>
                                        <div>{shipment.receiver.phonenumber1} {shipment.receiver.phonenumber2 && ', ' + shipment.receiver.phonenumber2}</div>
                                        {shipment.receiver.email && <div>{shipment.receiver.email}</div>}
                                        
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Card className='mb-3'>
                            <Card.Header as='h5' className='bg-danger-subtle'>Boxes</Card.Header>                            
                            <Card.Body>
                                {/* <div className='mb-3 d-flex justify-content-between'>
                                    <div/>
                                    {size.width > 760 &&
                                        <PackingListPrint 
                                            boxes={shipment.boxes} 
                                            items={shipment.boxes.length === 0 ? [] : shipment.boxes.length === 1 ? shipment.boxes[0].items : shipment.boxes.map(b=>b.items).flat()}
                                            customerId={shipment.customerId.customerId}
                                            shipmentId={shipment.shipmentId}
                                            sender={shipment.sender}
                                            receiver={shipment.receiver}
                                            cargoType={shipment.cargoType}
                                        />
                                    }
                                </div> */}
                                {size.width > 760 ? 
                                <Table bordered hover size="sm">
                                    <thead>
                                        <tr className='text-center bg-info-subtle'>
                                            <th>#</th>
                                            <th>Box ID</th>
                                            <th>Size <span className='text-danger'>inch</span></th>
                                            <th>Weight <span className='text-danger'>lb</span></th>
                                            <th>S</th>
                                            <th>R</th>
                                            <th>D</th>
                                            <th style={{width: '550px'}}>Items</th>
                                            <th>Label</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shipment.boxes.map((b,i)=>(
                                            <tr key={b.boxId} className='text-center'>
                                                <td>{i+1}</td>
                                                <td>{b.boxId}</td>
                                                <td>{b.length} x {b.width} x {b.height}</td>
                                                <td>{b.actualWeight.toFixed(2)}</td>
                                                <td>{b.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                                <td>{b.isReceived ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                                <td>{b.isDelivered ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                                <td>
                                                    <div style={{maxHeight: '200px', overflow: 'scroll'}}>
                                                        <Table bordered size="sm" style={{fontSize: '14px'}}>
                                                            <thead>
                                                                <tr className='bg-warning-subtle'>
                                                                    <th style={{width:'50px'}}>#</th>
                                                                    <th >Product</th>
                                                                    <th style={{width: '70px'}}>Unit</th>
                                                                    <th style={{width: '100px'}}>Price <span className='text-danger'>$</span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {b.items.map((itm,idx)=>(
                                                                    <tr key={idx}>
                                                                        <td>{idx+1}</td>
                                                                        <td>{itm.product}</td>
                                                                        <td>{itm.unit}</td>
                                                                        <td>{itm.price.toFixed(2)}</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </td>
                                                <td>
                                                    <PrintBoxLabel
                                                        box={b}
                                                        boxNo={i+1} 
                                                        shipmentId={shipment.shipmentId} 
                                                        boxQty={shipment.boxes.length} 
                                                        sender={shipment.sender}
                                                        receiver={shipment.receiver}
                                                     />
                                                     <PrintStickerLabel
                                                        box={b}
                                                        boxNo={i+1} 
                                                        shipmentId={shipment.shipmentId} 
                                                        boxQty={shipment.boxes.length} 
                                                        sender={shipment.sender}
                                                        receiver={shipment.receiver}
                                                     />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                :
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr className='bg-info-subtle'>
                                            <th>#</th>
                                            <th>Box ID</th>
                                            <th>Detail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {shipment.boxes.map((b,i)=>(
                                            <tr key={b.boxId} >
                                                <td>{i+1}</td>
                                                <td>{b.boxId}</td>
                                                <td>
                                                    <div>{b.length}" x {b.width}" x {b.height}"</div>
                                                    <div>{b.actualWeight} lbs</div>
                                                    <div>Shipped - {b.isShipped ? 'Yes' : 'No'}</div>
                                                    <div>Delivered - {b.isDelivered ? 'Yes' : 'No'}</div>
                                                    <div style={{maxHeight: '200px', overflow: 'scroll'}}>
                                                        <Table size="sm" style={{fontSize:'14px'}}>
                                                            <thead>
                                                                <tr className='bg-warning-subtle'>
                                                                    <th>#</th>
                                                                    <th>Detail</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {b.items.map((itm,idx)=>(
                                                                    <tr key={idx} >
                                                                        <td>{idx+1}</td>
                                                                        <td>
                                                                            <div>product: {itm.product}</div>
                                                                            <div>unit: {itm.unit}</div>
                                                                            <div>Price: ${itm.price.toFixed(2)}</div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                }                                
                            </Card.Body>
                        </Card>

                        <Card className='mb-3'>
                            <Card.Header as='h5' className='bg-danger-subtle'>Summary, Charges and Payment</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col className='mb-3' md={6}>
                                        <Card>
                                            <Card.Header className='bg-info-subtle'>Summary</Card.Header>
                                            <Card.Body>
                                                {shippedError && <AlertMessage type='danger' msg={shippedMessage} />}
                                                {deliveredError && <AlertMessage type='danger' msg={deliveredMessage} />}
                                                {pickedError && <AlertMessage type='danger' msg={pickedMessage} />}
                                                <ListGroup variant="flush">
                                                    {shipment.pickupRequested && <ListGroup.Item>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>Pickup Requested</div>
                                                            <div>
                                                                <Badge pill className={shipment.pickupRequested ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.pickupRequested ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        
                                                        <div style={{fontSize: '14px'}} className='bg-warning-subtle p-1 rounded mt-1 ms-2'>
                                                            <Row>
                                                                <Col xs={3}>Date</Col>
                                                                <Col xs={9}>{shipment.pickupData.date.substring(0,10)}</Col>
                                                            </Row>
                                                            <div className='border border-bottom' />
                                                            <Row>
                                                                <Col xs={3}>Time</Col>
                                                                <Col xs={9}>{shipment.pickupData.time}</Col>
                                                            </Row>
                                                            <div className='border border-bottom' />
                                                            <Row>
                                                                <Col xs={3}>Location</Col>
                                                                <Col xs={9}>{shipment.pickupData.street + ', ' + shipment.pickupData.city + ', ' + shipment.pickupData.state + ', ' + shipment.pickupData.country + ', ' + shipment.pickupData.postalcode}</Col>
                                                            </Row>
                                                            <div className='border border-bottom' />
                                                            <Row>
                                                                <Col xs={3}>Pick Up Done</Col>
                                                                <Col xs={9} className='d-flex justify-content-between'>
                                                                    {pickedLoading ? <Spinner size="sm" styles={{fontSize:'13px'}} /> :                                                                    
                                                                    <div>
                                                                        <Badge pill className={shipment.isPickedUp ? 'bg-success' : 'bg-danger'}>
                                                                            {shipment.isPickedUp ? 'Yes' : 'No'}
                                                                        </Badge>
                                                                    </div>
                                                                    }
                                                                    {/* <Button
                                                                        className='m-0 p-0'
                                                                        style={{fontSize:'14px'}}
                                                                        size='sm'
                                                                        variant='link'
                                                                        onClick={()=>dispatch(undoPickedUp({id}))}
                                                                    >
                                                                        Undo PickUp
                                                                    </Button> */}
                                                                    <SimpleConfirmModal 
                                                                        text="Undo PickUp"
                                                                        confirmFunction={undoPickedUp}
                                                                        passingData={{id}}
                                                                        variant='link'
                                                                        fontSize="14px"
                                                                        fontColor="red"
                                                                        disabled={shipment.isShipped}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                            {shipment.isPickedUp &&
                                                            <>
                                                                <div className='border border-bottom' />
                                                                <Row>
                                                                    <Col xs={3}>Pickedup At</Col>
                                                                    <Col xs={9}>
                                                                        {new Date(shipment.pickupData.pickedUpAt).toLocaleDateString('en-US', {month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            }
                                                        </div>                                                      
                                                        
                                                    </ListGroup.Item>}
                                                    <ListGroup.Item>
                                                        <div className='d-flex justify-content-between '>
                                                            <div>Cut Off</div>
                                                            <div>
                                                                <Badge pill className={shipment.isCutOffed ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.isCutOffed ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        {/* cut off date */}
                                                        {shipment.isCutOffed &&                                                        
                                                        <div className='bg-warning-subtle px-2 ms-2 rounded mt-2' style={{fontSize:'14px'}}>
                                                            <div className='border-bottom d-flex justify-content-between'>
                                                                <div>ID - {shipment.cutOffId}</div>
                                                                <div><Button 
                                                                    className='m-0 p-0' 
                                                                    variant='link' 
                                                                    size='sm'
                                                                    onClick={()=>navigate(`/staff/cutoff/${shipment.cutoffDetail._id}`)}
                                                                    >view <MdNorthEast /></Button></div>
                                                            </div>
                                                            <div className='border-bottom'>Date - {new Date(shipment.cutOffDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})}</div>
                                                        </div>
                                                        }
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>Shipped</div>
                                                            <div>
                                                                <Badge pill className={shipment.isShipped ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.isShipped ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        {/* ship date here */}
                                                        {shipment.isShipped &&                                                         
                                                        <div className='bg-warning-subtle px-2 ms-2 rounded mt-2' style={{fontSize:'14px'}}>                                                       
                                                            <div className='d-flex'>
                                                                <div>Date - {new Date(shipment.shippedDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</div>
                                                                <div className='ms-auto'>
                                                                    <SimpleConfirmModal 
                                                                        text="Undo Shipped"
                                                                        confirmFunction={undoShipped}
                                                                        passingData={{id}}
                                                                        variant='link'
                                                                        fontSize="14px"
                                                                        fontColor="red"
                                                                        disabled={shipment.isDelivered}
                                                                    />
                                                                    {/* <Button
                                                                        className='m-0 p-0'
                                                                        style={{fontSize:'14px', color: 'red'}}
                                                                        size='sm'
                                                                        variant='link'                                                                        
                                                                        onClick={()=>dispatch(undoShipped({id}))}
                                                                    >
                                                                        Undo Shipped
                                                                    </Button> */}
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                        }
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>Received</div>
                                                            <div>
                                                                <Badge pill className={shipment.isReceived ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.isReceived ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        {/* ship date here */}
                                                        {shipment.isReceived &&                                                         
                                                        <div className='bg-warning-subtle px-2 ms-2 rounded mt-2' style={{fontSize:'14px'}}>                                                       
                                                            <div className='d-flex'>
                                                                <div>Date - {new Date(shipment.shippedDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</div>
                                                                <div className='ms-auto'>
                                                                    <SimpleConfirmModal 
                                                                        text="Undo Received"
                                                                        confirmFunction={undoReceived}
                                                                        passingData={{id}}
                                                                        variant='link'
                                                                        fontSize="14px"
                                                                        fontColor="red"
                                                                        disabled={shipment.isDelivered}
                                                                    />
                                                                    {/* <Button
                                                                        className='m-0 p-0'
                                                                        style={{fontSize:'14px', color: 'red'}}
                                                                        size='sm'
                                                                        variant='link'                                                                        
                                                                        onClick={()=>dispatch(undoShipped({id}))}
                                                                    >
                                                                        Undo Shipped
                                                                    </Button> */}
                                                                </div>
                                                            </div>                                                            
                                                        </div>
                                                        }
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>Paid</div>
                                                            <div>
                                                                <Badge pill className={shipment.isPaid ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.isPaid ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        {/* payment info here */}
                                                        {shipment.paymentDetail && shipment.paymentDetail.length !== 0 &&                                                        
                                                        <div className='bg-warning-subtle px-2 ms-2 rounded mt-2' style={{fontSize:'14px'}}>
                                                            {shipment.paymentDetail.map((p,i)=>(
                                                                <div className='border-bottom d-flex' key={i}>
                                                                    <div style={{width: '100px'}}>{p.amount.toFixed(2)}</div>                                                                    
                                                                    <div style={{width: '70px'}}>{p.currency}</div>
                                                                    <div>{p.method}</div>
                                                                    <div className='ms-auto'>{new Date(p.paidAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: '2-digit'})}</div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                        }
                                                    </ListGroup.Item>
                                                    <ListGroup.Item>
                                                        <div className='d-flex justify-content-between'>
                                                            <div>Delivered</div>
                                                            <div>
                                                                <Badge pill className={shipment.isDelivered ? 'bg-success' : 'bg-danger'}>
                                                                    {shipment.isDelivered ? 'Yes' : 'No'}
                                                                </Badge>
                                                            </div>
                                                        </div>
                                                        {/* delivery date and pod here */}
                                                        {shipment.isDelivered &&                                                         
                                                        <div className='bg-warning-subtle px-2 ms-2 rounded mt-2' style={{fontSize:'14px'}}>                                                       
                                                            <div className='d-flex border-bottom'>
                                                                <div>Date - {new Date(shipment.deliveredDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</div>
                                                                <div className='ms-auto'>
                                                                    <SimpleConfirmModal 
                                                                        text="Undo Delivered"
                                                                        confirmFunction={undoDelivered}
                                                                        passingData={{id}}
                                                                        variant='link'
                                                                        fontSize="14px"
                                                                        fontColor="red"                                                                        
                                                                    />
                                                                    {/* <Button
                                                                        className='m-0 p-0'
                                                                        style={{fontSize:'14px', color: 'red'}}
                                                                        size='sm'
                                                                        variant='link'                                                                        
                                                                        onClick={()=>dispatch(undoDelivered({id}))}
                                                                    >
                                                                        Undo Delivered
                                                                    </Button> */}
                                                                </div>
                                                            </div>     

                                                            {shipment.podFiles && shipment.podFiles.image !== "" && 
                                                                <div className='d-flex border-bottom'>
                                                                    <div>Proof of delivery</div>
                                                                    <div className='ms-auto'>
                                                                        <ImageViewModal imageFile={shipment.podFiles.image} title="Proof Of Delivery" />
                                                                    </div>
                                                                </div>   
                                                            }    
                                                            {shipment.podFiles && shipment.podFiles.signature !== "" && 
                                                                <div className='d-flex border-bottom'>
                                                                    <div>Receiver signature</div>
                                                                    <div className='ms-auto'>
                                                                        <ImageViewModal imageFile={shipment.podFiles.signature} title="Receiver Signature" />
                                                                    </div>
                                                                </div>   
                                                            }                                                         
                                                        </div>
                                                        }
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>


                                    <Col className='mb-3' md={6}>
                                        <Card>
                                            <Card.Header className='bg-info-subtle'>Charges Detail</Card.Header>
                                            <Card.Body>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item className='d-flex justify-content-between py-1'>
                                                        <div>Total Weight</div>
                                                        <div>{shipment.totalWeight.toFixed(2)} lb</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-1'>
                                                        <div>Applied Rate</div>
                                                        <div>$ {shipment.totalRate.toFixed(2)}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-1'>
                                                        <div>Shipment Total Amount</div>
                                                        <div>$ {shipment.totalAmount.toFixed(2)}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between'>
                                                        <div style={{flex: 1}}>Other Charges</div>
                                                        <div style={{flex: 1}}>
                                                            <Table bordered size="sm">
                                                                <thead>
                                                                    <tr className='bg-warning-subtle'>
                                                                        <th>Name</th>
                                                                        <th>Amount</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {shipment.otherCharges.map((ch,idx)=>(
                                                                        <tr key={idx} style={{fontSize:'14px'}}>                                                                           
                                                                            <td>{ch.name}</td>
                                                                            <td>$ {ch.amount.toFixed(2)}</td>                                                                          
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-1'>
                                                        <div>Other Charges Total</div>
                                                        <div>$ {(shipment.otherCharges.reduce((acc, och) => acc + och.amount, 0)).toFixed(2)}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-1 bg-success-subtle'>
                                                        <div>Discount</div>
                                                        <div>$ -{shipment.discount ? (shipment.discount).toFixed(2) : 0}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between fs-4'>
                                                        <div>Grand Total</div>
                                                        <div>$ {shipment.grandTotal.toFixed(2)}</div>
                                                        {/* <div>$ {(shipment.totalAmount + (shipment.otherCharges.reduce((acc, och) => acc + och.amount, 0))).toFixed(2)}</div> */}
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-1 text-success'>
                                                        <div>Paid</div>
                                                        <div>$ {(shipment.paymentDetail.reduce((acc, p) => acc + p.amountInUSD, 0)).toFixed(2)}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between py-0 text-danger'>
                                                        <div>Balance</div>
                                                        <div>$ {(shipment.grandTotal - shipment.paymentDetail.reduce((acc, p) => acc + p.amountInUSD, 0)).toFixed(2)}</div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col md={6} className='mb-3'>
                                        <Card>
                                            <Card.Header className='bg-info-subtle'>Invoice</Card.Header>
                                            <Card.Body>
                                                <div className='d-flex justify-content-between'>
                                                    <div>Invoice</div>
                                                    <InvoiceModal />

                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={6}>
                                        <Card>
                                            <Card.Header className='bg-info-subtle'>Other</Card.Header>
                                            <Card.Body>
                                                <ListGroup variant="flush">
                                                    <ListGroup.Item className='d-flex justify-content-between'>
                                                        <div>Shipment is created by</div>
                                                        <div>{shipment.createdBy.name}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between'>
                                                        <div>Created At</div>
                                                        <div>{new Date(shipment.createdAt).toLocaleDateString('en-US', {month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</div>
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between'>
                                                        <div>Updated At</div>
                                                        <div>{new Date(shipment.updatedAt).toLocaleDateString('en-US', {month: 'long', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</div>  
                                                    </ListGroup.Item>
                                                    <ListGroup.Item className='d-flex justify-content-between'>
                                                        <div>Note</div>
                                                        <div>{shipment.note}</div>
                                                    </ListGroup.Item>
                                                </ListGroup>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                
                            </Card.Body>
                        </Card>
                        

                       
                    </Card.Body>
                </Card>
            }

        </div>
    )
}

export default StaffShipmentDetailScreen