import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { ChangePasswordModal } from '../../Modals'

const StaffMyAccountScreen = () => {

  const { staff } = useSelector(state=>state.staff.auth)

  return (
    <div>
      <div className='text-center fs-3 mb-5'>My Account</div>
      {staff && 
        <div>
          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Name</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={staff.name}
                readOnly
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Email</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={staff.email}
                readOnly
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Position</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={staff.position ? staff.position : '-'}
                readOnly
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Location</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={staff.location ? staff.location : '-'}
                readOnly
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Admin Access</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={staff.isAdmin ? 'Yes' : 'No'}
                readOnly
              />
            </Col>
          </Row>

          <Row className='mb-4'>
            <Col xs={4} className='text-center pt-2'>Created Date</Col>
            <Col xs={8} className='pe-5'>
              <Form.Control
                type='text'
                defaultValue={new Date(staff.createdAt).toLocaleDateString('en-US', {'day': '2-digit', 'month': 'short', 'year': 'numeric'})}
                readOnly
              />
            </Col>
          </Row>

          <div className='d-flex justify-content-center'>
            <ChangePasswordModal accountId={staff._id} />
          </div>

        </div>
      }

    </div>
    
  )
}

export default StaffMyAccountScreen