import { useEffect} from 'react'
import { Table } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { MySpinner, AlertMessage, useWindowSize } from '../../Components'
import { getPickupList, pickupReset } from '../../features/shipment/shipmentSlice'

const StaffPickupListScreen = () => {
  const size = useWindowSize()
  const dispatch = useDispatch()

  const { shipments, pickupLoading, pickupSuccess, pickupError, pickupMessage } = useSelector(state=>state.shipment.pickup)

  useEffect(()=>{
    dispatch(getPickupList())
    return()=>{
        dispatch(pickupReset())
    }
  },[dispatch])

  return (
    <>   
        {/* <div>
            <Row>
            <Col md={6} className='mb-3'>
                <Form.Control
                    type='text'
                    size='sm'
                    value={keyword}
                    placeholder={searchKeyword ? searchKeyword : 'search...'}
                    onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
                    onKeyDown={(e)=>{
                    if(e.key==='Enter'){
                        if(keyword === ''){
                        navigate(`/staff/shipments`)
                        } else {
                        navigate(`/staff/shipments/search/${keyword}`)
                        }                    
                    }
                    }}
                />
            </Col>
            <Col md={3} xs={6}>
                <div>
                <Button size='sm' onClick={()=>navigate('/staff/shipments/create')}>Create New Shipment</Button>
                </div>
            </Col>
            <Col md={3} xs={6}>
                <MyPagination
                path={keyword !== "" ? `staff/shipments/search/${keyword}` : 'staff/shipments'} 
                pages={pages}
                page={page}
            />
            </Col>
            </Row>
        </div> */}

        <h3 className='text-center mb-4'>Schedule Pick Up List</h3>
        {pickupError && <AlertMessage msg={pickupMessage} />}
        {pickupLoading && <MySpinner /> }

        {pickupSuccess && 
        <>
        {size.width > 750 ? 
        <Table striped hover bordered size='sm'>
            <thead>
                <tr className='text-center'>
                <th>#</th>
                <th>ID</th>
                <th>Type</th>
                <th>Box Qty</th>                
                <th>Total Weight</th>
                <th>Scheduled At</th>
                <th>Location</th>
                </tr>
            </thead>
            <tbody>
                {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
                <tr key={s._id} className='text-center'>
                    <td>{i+1}</td>
                    <td>{s.shipmentId}</td>
                    <td>{s.cargoType.toUpperCase()}</td>
                    <td>{s.boxes.length}</td>                    
                    <td>{s.totalWeight.toFixed(2)}</td>
                    <td>{new Date(s.pickupData.date).toLocaleDateString('en-TH',{day: '2-digit', month: 'short'})}, {s.pickupData.time}</td>
                    <td>{s.pickupData.street+', '+s.pickupData.city+', '+s.pickupData.state}</td>
                </tr>
                ))}
            </tbody>
        </Table>
        :
        <Table striped hover bordered size='sm'>
            <thead>
                <tr>
                <th>#</th>
                <th>Detail</th>
                <th>Scheduled</th>
                </tr>
            </thead>
            <tbody>
                {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
                <tr key={s._id}>
                    <td>{i+1}</td>
                    <td>
                        <div><span className='bg-warning-subtle rounded me-1'>Shipment ID : </span>{s.shipmentId}</div>
                        <div><span className='bg-warning-subtle rounded me-1'>Cargo Type : </span>{s.cargoType.toUpperCase()}</div>
                        <div><span className='bg-warning-subtle rounded me-1'>Box Qty : </span>{s.boxes.length}</div>
                        <div><span className='bg-warning-subtle rounded me-1'>Total Weight : </span>{s.totalWeight.toFixed(2)}</div>
                        <div><span className='bg-warning-subtle rounded me-1'>Location : </span>{s.pickupData.street+', '+s.pickupData.city+', '+s.pickupData.state}</div>
                    </td> 
                    <td>{new Date(s.pickupData.date).toLocaleDateString('en-TH',{day: '2-digit', month: 'short'})}, {s.pickupData.time}</td>
                </tr>
                ))}
            </tbody>
        </Table>
        }
        </>
        }
    </>
  )
}

export default StaffPickupListScreen