
const emptyCheck = async (data) => {
    var errorArray = []
    data.forEach(d => {
        if(!Object.values(d)[0] || Object.values(d)[0] === ""){
            errorArray.push(`${Object.keys(d)[0]}`)
        }
    })
    return errorArray
}

export default emptyCheck