import axios from 'axios'

const API_URL = '/api/rate'

//Add New Rate 
const addRate = async (newRate, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL , newRate, config)
    return response.data
}

//Get Rate List
const getRates = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL, config)
    return response.data
}

//Update a Rate
const updateRate = async (rateId, editedRate, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+'/' + rateId, {editedRate}, config)
    return response.data
}

//Delete a Rate
const deleteRate = async (rateId, index, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+'/'+ rateId +`?index=${index}`, config )
    return response.data
}

//Get price
const getPrice = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            },
    }
    //const response = await axios.get(API_URL+`/price?type=${data.type}&weight=${data.weight}`, config )
    let response
    if(data.staff && data.staff === true){
        response = await axios.post(API_URL+'/staff/price', data, config )
    } else {
        response = await axios.post(API_URL+'/price', data, config )
    }
    return response.data
}

//Get estimated cost
const getEstimatedCost = async (data) => {
    const response = await axios.get(API_URL+`/estimated?length=${data.boxSize.l}&width=${data.boxSize.w}&height=${data.boxSize.h}&weight=${data.weight}&cargoType=${data.cargoType}` )
  
    return response.data
}


const rateServices = {
    addRate,
    getRates,
    updateRate,
    deleteRate,
    getPrice,
    getEstimatedCost,
}

export default rateServices