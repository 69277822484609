import axios from 'axios'

const API_URL = '/api/shipment'


//Get Shipments
const getShipments = async (data, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`?keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}`, config)
    return response.data
}

//Get pickup list
const getPickupList = async (token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    // const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    // const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`/pickup`, config)
    return response.data
}

//Get My Shipments
const getMyShipments = async (data, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`/my?keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}`, config)
    return response.data
}

//Get My Shipment detail
const getMyShipmentDetail = async (id, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/my/${id}`, config)
    return response.data
}

//Get Shipment detail
const getShipmentDetail = async (id, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/${id}`, config)
    return response.data
}

//Add New Shipment
const addShipment = async (data, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    let response
    if(data.staff && data.staff===true){
        response = await axios.post(API_URL+'/staff/add', data.newShipment, config)
    } else {
        response = await axios.post(API_URL+'/add', data.newShipment, config)
    }
    return response.data
}

//update Shipment
const updateShipment = async (data, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    let response
    if(data.staff && data.staff===true){
        response = await axios.put(API_URL+`/${data.shipmentId}`, data.updatedShipment, config)
    } else {
        response = await axios.put(API_URL+`/my/${data.shipmentId}`, data.updatedShipment, config)
    }
    return response.data
}

//Delete Shipment detail
const deleteShipment = async (id, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/${id}`, config)
    return response.data
}

//Delete Shipment detail
const trackShipment = async (id) => {
    
    const response = await axios.get(API_URL+`/tracking/${id}`)
    return response.data
}

//Udpate Shipment tracking status
const addTrackingStatus = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/tracking/${data.shipmentId}`, data, config)
    return response.data
}

//delete Shipment tracking status
const deleteTrackingStatus = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/tracking/${data.shipmentId}?trackingId=${data.trackingId}`, config)
    return response.data
}

//Udpate Payment in shipment
const payShipment = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/payment`, data, config)
    return response.data
}

//Udpate Payment in shipment
const deletePaymentShipment = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+`/payment?shipmentId=${data.shipmentId}&paymentId=${data.paymentId}`, config)
    return response.data
}


//Get Shipments for cut off
const getShipmentsForCutOff = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/cutoff?condition=${data.condition}&fromDate=${data.fromDate}&toDate=${data.toDate}&startDate=${data.startDate}&cargoType=${data.cType}&keyword=${data.keyword}`, config)
    return response.data
}

//Get boxes by shipment ID for scanning
const getBoxesByShipmentId = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/boxes?shipmentId=${data.shipmentCode}&requestFor=${data.requestFor}`, config)
    return response.data
}

//mark as picked
const markAsPicked = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/pickup`, data, config)
    return response.data
}

//undo picked
const undoPickedUp = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+`/pickup`, data, config)
    return response.data
}

//mark as shipped
const markAsShipped = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/ship`, data, config)
    return response.data
}

//undo shipped
const undoShipped = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+`/ship`, data, config)
    return response.data
}

//mark as received
const markAsReceived = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/receive`, data, config)
    return response.data
}

//undo receive
const undoReceived = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+`/receive`, data, config)
    return response.data
}

//mark as delivered
const markAsDelivered = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/deliver`, data, config)
    return response.data
}

//undo delivered
const undoDelivered = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL+`/deliver`, data, config)
    return response.data
}


//mark as delivered
const markAsDeliveredWithPod = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/deliverwithpod`, data, config)
    return response.data
}

//get filtered shipments
const getFilteredShipments = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+`/filter`, data, config)
    return response.data
}

const shipmentServices = {
    getMyShipments,
    getPickupList,
    getMyShipmentDetail,
    getShipmentDetail,
    addShipment,
    updateShipment,
    getShipments,
    deleteShipment,
    trackShipment,
    addTrackingStatus,
    deleteTrackingStatus,
    getShipmentsForCutOff,
    payShipment,
    deletePaymentShipment,
    getBoxesByShipmentId,
    markAsPicked,
    undoPickedUp,
    markAsShipped,
    undoShipped,
    markAsReceived,
    undoReceived,
    markAsDelivered,
    undoDelivered,
    markAsDeliveredWithPod,
    getFilteredShipments,
}

export default shipmentServices