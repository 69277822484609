import { useState, useRef } from 'react';
import styles from './customercreateshipmentscreen.module.css'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import {QRCodeSVG} from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import { MdPrint } from 'react-icons/md'

const PrintBoxLabel = ({box, shipmentId, boxNo, boxQty, sender, receiver}) => {
    const componentRef = useRef()
    const [show, setShow] = useState(false);   
    const handleShow = () => setShow(true); 
    const handleClose = () => setShow(false)
    return (
        <div>
            <Button size='sm' variant="link" onClick={handleShow}>Print</Button>
            <Modal
                show={show}
                onHide={handleClose}
                
                size='lg'
            >
              <Modal.Header closeButton>               
                <Modal.Title> Print Box Label </Modal.Title>
              
            </Modal.Header>
                <div className='me-5 ms-auto mt-3'>
                    <ReactToPrint
                        
                        trigger={() => <Button variant='info ' style={{width: '100px'}}><MdPrint /> Print</Button>}
                        content={() => componentRef.current}
                        onAfterPrint={()=>setShow(false)}
                    />
                </div>
                <Modal.Body className='d-flex justify-content-center'>
                    <div className={`${styles.label_main} d-print-flex`} ref={componentRef}>
                    <div className={`${styles.label}`}>                            
                        <Col sm={12}>
                            <div className='text-center fs-3 fw-bold py-2 border-bottom border-dark'>NYSS EXPRESS, US. INC</div>                                
                        </Col>
                        <Row className='border-bottom border-dark mx-0'>
                            <Col className='border-end border-dark'>
                                <div className='fs-2 fw-bold text-center align-middle pt-2'>{shipmentId}</div>
                                <div className='fs-2 fw-bold text-center align-middle'>{box.boxId}</div>
                            </Col>
                            <Col className='border-end border-dark fs-5'>
                                <div className='mb-2'>AIR</div>
                                <div className='mb-2'>{box.length} x {box.width} x {box.height} inches</div>
                                <div className='mb-2'>{box.actualWeight} lbs</div>
                                <div>Box {boxNo} of {boxQty}</div>
                            </Col>
                            <Col>
                                <div className='p-3'>
                                    <QRCodeSVG value={shipmentId+box.boxId} />
                                </div>
                            </Col>
                        </Row>

                        <Row className='px-3' style={{fontSize: '15px'}}>
                            <Col className='border-end border-dark py-2'>
                                <div className='text-center fs-5'>Sender</div>
                                <Row className='d-flex'>
                                    <Col sm={3}>Name</Col>
                                    <Col sm={9}>{sender.name}</Col>
                                </Row>
                                <Row className='d-flex'>
                                    <Col sm={3}>Address</Col>
                                    <Col sm={9}>{sender.address.street}, {sender.address.city}, {sender.address.state}, {sender.address.postalcode}, {sender.address.country}</Col>
                                </Row>
                                <Row className='d-flex'>
                                    <Col sm={3}>Phone</Col>
                                    <Col sm={9}>{sender.phonenumber1} {sender.phonenumber2 && ", " + sender.phonenumber2}</Col>
                                </Row>
                                {sender.email &&
                                <Row className='d-flex'>
                                    <Col sm={3}>Email</Col>
                                    <Col sm={9}>{sender.email}</Col>
                                </Row>
                                }
                            </Col>
                            <Col className='py-2'>
                                <div className='text-center fs-5'>Receiver</div>
                                <Row className='d-flex'>
                                    <Col sm={3}>Name</Col>
                                    <Col sm={9}>{receiver.name}</Col>
                                </Row>
                                <Row className='d-flex'>
                                    <Col sm={3}>Address</Col>
                                    <Col sm={9}>{receiver.address.street}, {receiver.address.city}, {receiver.address.state}, {receiver.address.postalcode}, {receiver.address.country}</Col>
                                </Row>
                                <Row className='d-flex'>
                                    <Col sm={3}>Phone</Col>
                                    <Col sm={9}>{receiver.phonenumber1} {receiver.phonenumber2 && ", " + receiver.phonenumber2}</Col>
                                </Row>
                                {receiver.email &&
                                <Row className='d-flex'>
                                    <Col sm={3}>Email</Col>
                                    <Col sm={9}>{receiver.email}</Col>
                                </Row>
                                }
                            </Col>
                        </Row>                            
                    </div>                   
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PrintBoxLabel