import './services.css'
import { pack, pick, ship, box} from '../../../assets'
import { useTranslation } from 'react-i18next'

const Services = () => {
  const { t } = useTranslation()
  return (
    <div className='services' id='services'>

      <div className='heading'>
        <h2 className='gradient-text'>{t('Our Services')}</h2>
      </div>

      <div className='service'>
        <img src={pack} alt='pack' />
        <div className='service-text'>
          <h2>{t('Packing')}</h2>
          <div className='title'></div>
          <p>{t('servicePacking')}</p>
        </div>
      </div>

      <div className='service1'>
        <img src={pick} alt='pick' />
        <div className='service-text1'>
          <h2>{t('Pick Up')}</h2>
          <div className='title1'></div>  
          <p>{t('servicePickup')}</p>
        </div>
      </div>

      <div className='service'>
        <img src={ship} alt='ship' />
        <div className='service-text'>
          <h2>{t('Shipping')}</h2>
          <div className='title'></div>          
          <p>{t('serviceShipping')}</p>
        </div>
      </div>

      <div className='service1'>
        <img src={box} alt='box' />
        <div className='service-text1'>
          <h2>{t('Moving')}</h2>
          <div className='title1'></div>  
          <p>{t('serviceMoving')} <span className='learn-more'>Learn More</span></p>
        </div>
      </div>


    </div>
  )
}

export default Services