import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOSItemList, getOSItemShippedListByCusId} from '../../features/osItem/osItemSlice'
import { Table, Form, Row, Col } from 'react-bootstrap'
import { AlertMessage, MySpinner, MyPagination, useWindowSize } from '../../Components'

const OSItemShippedListScreen = () => {
    const dispatch = useDispatch()
    const size = useWindowSize()
    const params = useParams()
    const [keyword, setKeyword] = useState('')
    const limit = 50
    const pageNumber = params.pageNumber ? params.pageNumber : 1


    const { ositemlist, customerlist, listLoading, listError, listSuccess, listMessage, page, pages } = useSelector(state=>state.ositem.list)

    const fetchList = useCallback(()=>{
        return getOSItemList({keyword:'', pageNumber: pageNumber, limit: limit, isShipped: true})
    },[pageNumber])

    useEffect(()=>{
        dispatch(fetchList())
    },[dispatch, fetchList])


    return (
        <div>
            {listLoading && <MySpinner />}
            {listError && <AlertMessage type='danger' msg={listMessage} />}
            <div className='border-bottom border-dark pb-3 mb-4 fs-2'>Shipped OS Items List</div>
            <Row className='my-2'>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Control
                            type='text'
                            value={keyword}
                            size='sm'
                            placeholder='Search with item name or customer ID...'
                            maxLength={100}
                            onChange={({target})=>setKeyword(target.value)}
                            onKeyDown={(e)=>{
                                if(e.key === 'Enter'){
                                    if(e.target.value === ''){
                                        dispatch(getOSItemList({keyword:'', pageNumber: 1, limit: limit, isShipped: true}))
                                    } else {
                                        dispatch(getOSItemList({keyword:e.target.value, pageNumber: 1, limit: limit, isShipped: true}))
                                    }
                                }
                            }}
                        />
                    </div>                
                </Col>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Select size='sm' onChange={({target})=>{                            
                            if(target.value === 'all'){
                                dispatch(getOSItemList({keyword:'', pageNumber: 1, limit: limit, isShipped: true}))
                            } else {
                                dispatch(getOSItemShippedListByCusId(target.value))
                            }
                        }}>
                            <option value="all">Select Customer</option>
                            {listSuccess && customerlist.map(c=>(
                                <option key={c._id} value={c._id}>{c.name}</option>
                            ))}
                            <option value="all">All</option>
                        </Form.Select>
                    </div>                
                </Col>
                <Col md={4} className='mb-2'>
                    <div className='ms-auto'>
                        <MyPagination 
                            path={keyword !== "" ? `staff/shippedositem/search/${keyword}` : 'staff/shippedositem'} 
                            pages={pages}
                            page={page}
                        />
                    </div>
                </Col>
            </Row>
            
            <div className='my-4' style={{fontSize: '15px'}}>
                {size.width > 760 ?
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Customer ID</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Item</th>
                            <th>Qty</th>                                
                            <th>ShipmentID</th>
                            <th>Shipped Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>{os.customerCode}</td>
                                    <td>{os.customerId.name}</td>
                                    <td>{os.customerId.email}</td>
                                    <td>{os.name}</td>
                                    <td>{os.qty}</td>
                                    <td>{os.shipmentId ? os.shipmentId.shipmentId : '-'}</td>
                                    <td>{os.shippedDate ? new Date(os.shippedDate).toLocaleDateString() : '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>
                                        <div>{os.name} - {os.qty}</div>
                                        <div>{os.customerCode}</div>
                                        <div>{os.customerId.name}</div>
                                        <div>{os.customerId.email}</div>
                                        <div>{os.shipmentId ? os.shipmentId.shipmentId : '-'}</div>
                                        <div>{os.shippedDate ? new Date(os.shippedDate).toLocaleDateString() : '-'}</div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
                
            </div>

            
        </div>
    )
}

export default OSItemShippedListScreen