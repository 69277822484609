import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Container } from "react-bootstrap"


const CustomerPrivateRoutes = () => {
  const {customer} = useSelector(state=>state.customer.auth)
  
  return (
    customer ? 
      <Container fluid className='h-100' style={{padding: '100px 5% 2% 5%'}}>        
        <Outlet/>
      </Container>
    : <Navigate to='/login' />
  )
}

export default CustomerPrivateRoutes