import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useIdleTimer } from 'react-idle-timer'
import { Modal, Button } from 'react-bootstrap'
import { customerLogout } from '../features/customer/customerSlice'
import { logout } from '../features/staff/staffSlice'


const timeout = 60*60*1000 //1 hour
const promptBeforeIdle = 10*60*1000 //10 mins

export default function IdleTimerContainer() {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [remaining, setRemaining] = useState(timeout)

    const onIdle = () => {
        setShow(false)
        dispatch(logout())
        dispatch(customerLogout())     
    }

    const onActive = () => {
        setShow(false)
    }

    const onPrompt = () => {
        setShow(true)
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        throttle: 500,
        crossTab: true,
    })

    useEffect(() => {
        const interval = setInterval(() => {
            setRemaining(Math.ceil(getRemainingTime() / 1000))
        }, 500)

        return () => {
            clearInterval(interval)
        }
    })

    const handleStillHere = () => {
        activate()
    }

    return (
        <>
        <Modal show={show} onHide={handleStillHere} animation={false}>
            <Modal.Header closeButton>
            <Modal.Title>Idle Detection</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>Are you still here ?</div>
                <p>Logging out in {Math.ceil(remaining/60)} minutes</p>
            </Modal.Body>
                <Modal.Footer>               
                    <Button variant="secondary" className='w-100' onClick={handleStillHere}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
