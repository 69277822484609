import { useEffect, useState } from "react"
import styles from './staffscreens.module.css'
import { Button, Card, Row, Spinner, Badge } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { useParams, Link } from "react-router-dom"
import { AlertMessage, MySpinner, DeleteConfirm } from "../../Components"
import { getCustomerDetail, deleteCustomer, deleteReset, addressDeleteCustomer } from "../../features/customerAccount/customerAccountSlice"
import { MdOutlineClose } from 'react-icons/md'
import { AddressEditModal, AddressAddModal } from "../../Modals"

const StaffCustomerAccountDetailScreen = () => {
  const dispatch = useDispatch()
  // const [ addressSearch, setAddressSearch ] = useState('')
  const [ deleteAddressIndex, setDeleteAddressIndex ] = useState(null)
  const {id} = useParams()
  const { detailLoading, detailSuccess, detailError, detailMessage, customer } = useSelector(state=>state.customerAccount.detail)
  const { addressDeleteLoading, addressDeleteSuccess, addressDeleteError, addressDeleteMessage } = useSelector(state=>state.customerAccount.addressDelete)

  useEffect(()=>{
    dispatch(getCustomerDetail(id))
  },[dispatch, id])

  useEffect(()=>{
    if(addressDeleteSuccess){
      setDeleteAddressIndex(null)
    }
  },[addressDeleteSuccess])

  // const handleAddressSearch = (e) => {
  //   if(e.key === 'Enter'){
  //     dispatch(addressSearchCustomer(addressSearch))
  //   }
  //   return
  // }

  return (
    <div >
      {detailLoading && <MySpinner />}
      {detailError && <><AlertMessage msg={detailMessage}  /> <Link to='/staff/customers'>Go back to list</Link> </>}
      {detailSuccess && 
      <div>
        <div className="d-flex flex-row-reverse mb-3">
          
          <DeleteConfirm 
            type=' customer account ' 
            name={customer.name} 
            id={customer._id} 
            stateName={'customerAccount'}
            deleteFunction={deleteCustomer} 
            doReset={true}
            reset={deleteReset}
            location='/staff/customers'
          />
          <Link to={`/staff/customers/update/${customer._id}`} className='me-2'>
            <Button variant='info' size='sm' style={{width: '80px'}}>Edit</Button>
          </Link>
          {!customer.isSuspended && <Link to={`/staff/shipments/create?customer=${customer._id}`}>
            <Button variant='primary' size='sm' className="me-2">Create Shipment</Button>
          </Link>}
        </div>
        <Card>
          <Card.Header className={styles.customer_detail_heading} >
          
            <p className="mb-0">{customer.name}{customer.isSuspended && <Badge bg='danger' className='float-end blink_me ms-3 mt-1'>suspended</Badge>}</p>  
            <p className="mb-0">ID : {customer.customerId}</p> 
          
          </Card.Header>
                  
          <Card.Body>
            <div className={styles.customer_detail_defaultAddress}>
              <p>Company : {customer.company ? customer.company : 'undefined'}</p>
              <p>Email : <span className="bg-info px-2 rounded">{customer.email}</span></p>
              <p>Phone: {customer.phonenumber1} {customer.phonenumber2 && ', ' + customer.phonenumber2} {customer.phonenumber3 && ', ' + customer.phonenumber3}</p>
              <p>Total Shipments : {customer.shipmentCount}</p>
            </div>
            <hr />

            <h4>Customer Address</h4>
            <div className={styles.customer_detail_defaultAddress}>
              <p>{customer.defaultAddress.street}</p>
              <p>{customer.defaultAddress.city}</p>
              <p>{customer.defaultAddress.state}</p>
              <p>{customer.defaultAddress.postalcode}</p>
              <p>{customer.defaultAddress.country}</p>
            </div>

            <hr />
            <div className="d-flex mb-3">
              <h4 className="me-3">Address Book</h4>
              <AddressAddModal customerId={customer._id} creator='staff' />
              
                {/* <Form.Group className='ms-auto'>
                  <Form.Control 
                      type='text'
                      size='sm'
                      placeholder="Search.."
                      value={addressSearch}
                      onChange={({target})=>setAddressSearch(target.value)}
                      onKeyDown={(e) => {if (!/[0-9,a-z,A-Z, ]/.test(e.key)) {e.preventDefault()}}}
                      onKeyUp={handleAddressSearch}
                  /> 
                </Form.Group> */}
            
            </div>
            <Row>
            {addressDeleteError && <AlertMessage msg={addressDeleteMessage} type='danger' />}
            {customer.addresses.map((ad,i)=>(
              // <Col key={i} sm={12} md={6} lg={4} className="mb-3" >
                <Card key={i} style={{fontSize: '15px', width: '400px'}} className={`${styles.customer_detail_address_card} m-2 px-0`}>
                  <Card.Body className="p-2">
                    <Card.Title className="d-flex">
                      {addressDeleteLoading && i === deleteAddressIndex && <Spinner className="me-3" />}
                      <div>{ad.name}</div>
                      <div className="d-flex ms-auto">
                      <AddressEditModal customerId={customer._id} address={ad} />
                      <Button variant="danger" size='sm'
                        onClick={()=> {
                          setDeleteAddressIndex(i)
                          dispatch(addressDeleteCustomer({customerId: customer._id, addressId: ad._id}))
                        }}
                      ><MdOutlineClose className="pb-1 pe-1" size={23} /></Button>
                      </div>
                    </Card.Title>
                    {ad.company && <Card.Subtitle className="mb-2 text-muted">{ad.company}</Card.Subtitle>}
                    {ad.email && <Card.Subtitle className="mb-2 text-muted">{ad.email}</Card.Subtitle>}
                    
                      <div className={styles.customer_detail_defaultAddress}>
                        <p>{ad.street.concat(", ",ad.city,", ",ad.state,", ",ad.postalcode,", ",ad.country)}</p>
                        <p>{ad.phonenumber1} {ad.phonenumber2 ? ", " + ad.phonenumber2 : null}</p>
                      </div>
                    {ad.remark && <small className="text-danger">remark : {ad.remark}</small>}
                  </Card.Body>
                </Card>
              // </Col>
            ))}
            </Row>
          </Card.Body>
        </Card>
        </div>
      }
    </div>
  )
}

export default StaffCustomerAccountDetailScreen