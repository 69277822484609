import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import itemServices from './itemService'
import { toast } from 'react-toastify';

const initialState = {
    add: {
        item: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        itemList: [],
        page: null,
        pages: null,
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    update: {
        item: null,
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
        updateMessage: ''
    },
    delete: {
        item: null,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    }
}


//Add new item
export const addItem = createAsyncThunk('Item_Add', async (newItem, thunkAPI) => {
    try {      

        const token = thunkAPI.getState().staff.auth.staff.token        
        return await itemServices.addItem(newItem, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get items
export const getItems = createAsyncThunk('Item_List', async (data, thunkAPI) => {
    try {
        return await itemServices.getItems(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update items
export const updateItem = createAsyncThunk('Item_Update', async ({itemId, name}, thunkAPI) => {
    try {
        return await itemServices.updateItem(itemId, name)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete items
export const deleteItem = createAsyncThunk('Item_Delete', async (itemId, thunkAPI) => {
    try {
        return await itemServices.deleteItem(itemId)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const itemSlice = createSlice({
    name: 'Item',
    initialState,
    reducers: {
        reset: (state) => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addItem.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
        }) 
        .addCase(addItem.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            toast.success('new item added successfully.')
            state.list.itemList = [action.payload, ...state.list.itemList]
        })  
        .addCase(addItem.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = true
            state.add.addMessage = action.payload
            state.add.item = null
        })
        //get Item list
        .addCase(getItems.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
        }) 
        .addCase(getItems.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.itemList = action.payload.items
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getItems.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listMessage = action.payload
            state.list.itemList = []
        })
        //update adn Item 
        .addCase(updateItem.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateError = false
            state.update.updateSuccess = false
        }) 
        .addCase(updateItem.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            toast.success('Item name updated successfully.')
            const itemIndex = state.list.itemList.findIndex(e => e._id === action.payload._id)
            state.list.itemList = [...state.list.itemList.slice(0, itemIndex), action.payload, ...state.list.itemList.slice(itemIndex + 1)]
        })  
        .addCase(updateItem.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateMessage = action.payload
            state.update.item = null
        })
        //deelte adn Item 
        .addCase(deleteItem.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
        }) 
        .addCase(deleteItem.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            toast.success('Item deleted successfully.')
            const itemIndex = state.list.itemList.findIndex(e => e._id === action.payload)
            state.list.itemList.splice(itemIndex,1)
        })  
        .addCase(deleteItem.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteMessage = action.payload
            state.update.item = null
        })


    },
})


export const { reset, addReset } = itemSlice.actions
export default itemSlice.reducer