import {useEffect, useState} from 'react'
import { Form, Button, Row, Col } from 'react-bootstrap'
import styles from './staffscreens.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { addCustomer, addReset } from '../../features/customerAccount/customerAccountSlice'
import { useNavigate } from 'react-router-dom'
import { AlertMessage } from '../../Components'
import { emptyCheck } from '../../helpers'
import { toast } from 'react-toastify'
import { MySpinner } from '../../Components'

const StaffCustomerAccountCreateScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [empty, setEmpty] = useState([])
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [company, setCompany] = useState('')
  const [password, setPassword] = useState('')
  const [passView, setPassView] = useState(false)
  const [phonenumber1, setPhonenumber1] = useState('')
  const [phonenumber2, setPhonenumber2] = useState('')
  const [phonenumber3, setPhonenumber3] = useState('')
  const [defaultAddress, setDefaultAddress] = useState({
    street: "",
    city: "",
    state: "",
    postalcode: "",
    country: ""
  })
  const [receiverAddress, setReceiverAddress] = useState({
    name: "",
    email: "",
    company: "",
    street: "",
    city: "",
    state: "",
    postalcode: "",
    country: "",
    phonenumber1: "",
    phonenumber2: "",
    remark: "",
  })
  const { addLoading, addSuccess, addError, addMessage } = useSelector(state=>state.customerAccount.add)
  
  useEffect(()=>{
    if(addSuccess){
      navigate('/staff/customers')
    }
    return()=>{
      setEmpty([])
      dispatch(addReset())
    }
  },[addSuccess, navigate, dispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()

    const dataToCheck = [
      {'name': name},
      {'email': email},
      {'password': password},
      {'phonenumber1': phonenumber1},
      {'dstreet': defaultAddress.street},
      {'dcity': defaultAddress.city},
      {'dstate': defaultAddress.state},
      {'dpostalcode': defaultAddress.postalcode},
      {'dcountry': defaultAddress.country},
      {'rname': receiverAddress.name},
      {'rstreet': receiverAddress.street},
      {'rcity': receiverAddress.city},
      {'rstate': receiverAddress.state},
      {'rpostalcode': receiverAddress.postalcode},
      {'rcountry': receiverAddress.country},
      {'rphonenumber1': receiverAddress.phonenumber1}
    ]
    const emptyList = await emptyCheck(dataToCheck)
    if(emptyList.length !== 0){
      setEmpty(emptyList)
      toast.error('Please check required fields')
      return
    } else {
      const newCustomer = {
        name,
        email,
        company,
        password,
        defaultAddress,
        phonenumber1,
        phonenumber2,
        phonenumber3,
        addresses: [receiverAddress],
      }
      //console.log(newCustomer)
      dispatch(addCustomer(newCustomer))
    }
    
  }
  return (
    <div className={styles.customer_add_main} style={{opacity: addLoading ? 0.5 : 10}}>

      <h2 className='text-center mb-4'>Create New Customer Account</h2>
      {addError && <AlertMessage msg={addMessage} />}
      {addLoading && <MySpinner />}
      <Form>
        <Form.Group className="mb-2" controlId="name">
          <Form.Label>Full Name <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type="text" 
            value={name}
            onChange={({target})=>{
              setName(target.value)
              setEmpty([...empty.filter(x=>x!=='name')])
            }}
            isInvalid={empty.includes('name')}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="email">
          <Form.Label>Email Address <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type="email"  
            value={email}
            onChange={({target})=>{
              setEmail(target.value)
              setEmpty([...empty.filter(x=>x!=='email')])
            }}
            isInvalid={empty.includes('email')}
          />
        </Form.Group>

        <Form.Group className="mb-2" controlId="company">
          <Form.Label>Company</Form.Label>
          <Form.Control 
            type="text" 
            value={company}
            onChange={({target})=>setCompany(target.value)} 
          />
        </Form.Group>

        <Form.Group className="mb-1" >
          <Form.Label>Password  <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type={passView ? 'text' : "password" }
            value={password}
            minLength="8"
            onChange={({target})=>{
              setPassword(target.value)
              setEmpty([...empty.filter(x=>x!=='password')])
            }}
            isInvalid={empty.includes('password')}
          />
          {password.length !== 0 && password.length < 8 && <small className='text-danger'>Password min-length 8 characters</small>}
        </Form.Group>
        <Form.Group className="mb-2" controlId="password">
          <Form.Check type="checkbox" label="View password" onChange={()=>{setPassView((prev)=>!prev)}} />
        </Form.Group>

        <Row>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber1">
              <Form.Label>Phone Number 1 <small className='text-danger'>* required</small></Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber1}
                onChange={({target})=>{
                  setPhonenumber1(target.value)
                  setEmpty([...empty.filter(x=>x!=='phonenumber1')])
                }} 
                isInvalid={empty.includes('phonenumber1')}
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber2">
              <Form.Label>Phone Number 2</Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber2}
                onChange={({target})=>setPhonenumber2(target.value)} 
              />
            </Form.Group>
          </Col>
          <Col md={4}>
            <Form.Group className="mb-2" controlId="phonenumber3">
              <Form.Label>Phone Number 3</Form.Label>
              <Form.Control 
                type="tel" 
                value={phonenumber3}
                onChange={({target})=>setPhonenumber3(target.value)} 
              />
            </Form.Group>
          </Col>
        </Row>

        <hr />
        <h3 className='text-center'>Addresses</h3>
        <h5>Consignee Address</h5>
        <Form.Group className='mb-1'>
          <Form.Label>Address <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.street}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, street: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dstreet')])
            }}
            isInvalid={empty.includes('dstreet')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>City <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.city}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, city: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dcity')])
            }}
            isInvalid={empty.includes('dcity')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>State/Province <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.state}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, state: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dstate')])
            }}
            isInvalid={empty.includes('dstate')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>Postal Code <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={defaultAddress.postalcode}
            onChange={e=>{
              setDefaultAddress({...defaultAddress, postalcode: e.target.value.replace(/[^\w\s]/gi, "")})
              setEmpty([...empty.filter(x=>x!=='dpostalcode')])
            }}
            isInvalid={empty.includes('dpostalcode')}
          /> 
        </Form.Group>

        <Form.Group>
          <Form.Label>Select Country <small className='text-danger'>* required</small></Form.Label>
          <Form.Select value={defaultAddress.country}
            onChange={(e)=>{
              setDefaultAddress({...defaultAddress, country: e.target.value})
              setEmpty([...empty.filter(x=>x!=='dcountry')])
            }}
            isInvalid={empty.includes('dcountry')}
          >
            <option value=''></option>
            <option value='Thailand'>Thailand</option>
            <option value='Myanmar'>Myanmar</option>
            <option value='Lao'>Lao</option>
            <option value='United States'>United States</option>
          </Form.Select>
        </Form.Group>


        <h5 className='mt-5'>Receiver</h5>

        <Form.Group className='mb-1'>
          <Form.Label>Name <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.name}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, name: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rname')])
            }}
            isInvalid={empty.includes('rname')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>Company</Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.company}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, company: e.target.value})
            }}
          /> 
        </Form.Group>

        <Form.Group className='mb-1'>
          <Form.Label>Address <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.street}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, street: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rstreet')])
            }}
            isInvalid={empty.includes('rstreet')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>City <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.city}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, city: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rcity')])
            }}
            isInvalid={empty.includes('rcity')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>State/Province <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.state}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, state: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rstate')])
            }}
            isInvalid={empty.includes('rstate')}
          /> 
        </Form.Group>
        <Form.Group className='mb-1'>
          <Form.Label>Postal Code <small className='text-danger'>* required</small></Form.Label>
          <Form.Control 
            type='text'
            value={receiverAddress.postalcode}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, postalcode: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rpostalcode')])
            }}
            isInvalid={empty.includes('rpostalcode')}
          /> 
        </Form.Group>

        <Form.Group className='mb-1'>
          <Form.Label>Select Country <small className='text-danger'>* required</small></Form.Label>
          <Form.Select value={receiverAddress.country}
            onChange={(e)=>{
              setReceiverAddress({...receiverAddress, country: e.target.value})
              setEmpty([...empty.filter(x=>x!=='rcountry')])
            }}
            isInvalid={empty.includes('rcountry')}
          >
            <option value=''></option>
            <option value='Thailand'>Thailand</option>
            <option value='Myanmar'>Myanmar</option>
            <option value='Lao'>Lao</option>
            <option value='United States'>United States</option>
          </Form.Select>
        </Form.Group>
        <Row className='mt-2'>
          <Col md={6}>
            <Form.Group className="mb-2" controlId="receiver-phonenumber1">
              <Form.Label>Phone Number 1 <small className='text-danger'>* required</small></Form.Label>
              <Form.Control 
                type="tel" 
                value={receiverAddress.phonenumber1}
                onChange={({target})=>{
                  setReceiverAddress({...receiverAddress, phonenumber1: target.value})
                  setEmpty([...empty.filter(x=>x!=='rphonenumber1')])
                }} 
                isInvalid={empty.includes('rphonenumber1')}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-2" controlId="receiver-phonenumber2">
              <Form.Label>Phone Number 2</Form.Label>
              <Form.Control 
                type="tel" 
                value={receiverAddress.phonenumber2}
                onChange={({target})=>setReceiverAddress({...receiverAddress, phonenumber2: target.value})} 
              />
            </Form.Group>
          </Col>
        </Row>
        <Form.Group className='mb-1'>
          <Form.Label>Email</Form.Label>
          <Form.Control 
            type='email'
            value={receiverAddress.email}
            onChange={e=>{
              setReceiverAddress({...receiverAddress, email: e.target.value})
            }}
          /> 
        </Form.Group>

        {addError && <AlertMessage msg={addMessage} type='danger' />}
        <Button
          type='button'
          className='my-5 w-100'
          onClick={handleSubmit}
        >Create Customer Account</Button>

      </Form>
    </div>
  )
}

export default StaffCustomerAccountCreateScreen