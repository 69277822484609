import styles from './customercreateshipmentscreen.module.css'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CargoType from './CargoType'
import Boxes from './Boxes'
import PackingList from './PackingList'
import CostDetail from './CostDetail'
import { getItems } from '../../../features/item/itemSlice'
import { getPrice, reset } from '../../../features/rate/rateSlice'
import OtherServices from './OtherServices'
import MyProgressBar from './MyProgressBar'
import { MdArrowRightAlt, MdKeyboardBackspace, MdDone } from 'react-icons/md'
import { handleAddress, handleBoxes, handlePickup } from './handleFunctions'
import { Alert, Button } from 'react-bootstrap'
import { updateShipment, updateReset } from '../../../features/shipment/shipmentSlice'
import { getBoxsizes } from '../../../features/boxsizes/boxsizeSlice'
import { getCustomerDetail } from '../../../features/customerAccount/customerAccountSlice'
import { getChargesNameList } from '../../../features/chargesname/chargesnameSlice'
import { MySpinner } from '../../../Components'
import LabelAndTrackingUpdate from './LabelAndTrackingUpdate'
import { useNavigate, useLocation } from 'react-router-dom'
import CustomerAndAddress from './CustomerAndAddress'

const StaffShipmentUpdateScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const update = true
    const { shipment, cusId } = useLocation().state
    const {customer} = useSelector(state=>state.customerAccount.detail)
    const {rate} = useSelector(state=>state.rate.rate)


    const [cargoType, setCargoType] = useState(shipment.cargoType)
    const [samebox, setSamebox] = useState(false)
    const [addressList, setAddressList] = useState(customer ? customer.addresses : [])
    const [sender, setSender] = useState({
        name: shipment.sender.name,
        company: shipment.sender.company ? shipment.sender.company : '',
        email: shipment.sender.email,
        phonenumber1: shipment.sender.phonenumber1,
        phonenumber2: shipment.sender.phonenumber2 ? shipment.sender.phonenumber2 : '',
        street: shipment.sender.address.street,
        city: shipment.sender.address.city, 
        state: shipment.sender.address.state,
        postalcode: shipment.sender.address.postalcode,
        country: shipment.sender.address.country
    })
    //const [senderDifferent, setSenderDifferent] = useState(false)
    const [receiver, setReceiver] = useState({
        name: shipment.receiver.name,
        company: shipment.receiver.company ? shipment.receiver.company : '',
        email: shipment.receiver.email ? shipment.receiver.email : '',
        phonenumber1: shipment.receiver.phonenumber1,
        phonenumber2: shipment.receiver.phonenumber2 ? shipment.receiver.phonenumber2 : '',
        street: shipment.receiver.address.street,
        city: shipment.receiver.address.city, 
        state: shipment.receiver.address.state,
        postalcode: shipment.receiver.address.postalcode,
        country: shipment.receiver.address.country
    })
    //const [senderSave, setSenderSave] = useState(false)
    const [receiverSave, setReceiverSave] = useState(false)
    const [boxes, setBoxes] = useState(shipment.boxes)
    const [noItem, setNoItem] = useState(false)
    const [pickup, setPickup] = useState(shipment.pickupRequested ? 'yes' : 'no')
    const [pickupData, setPickupData] = useState(shipment.pickupRequested ? shipment.pickupData : {        
        date:'',
        time: '',
        street: '',
        city: '',
        state: '',
        country: '',
        postalcode: '',
    })
    const [note, setNote] = useState(shipment.note ? shipment.note : '')
    const [progress , setProgress] = useState(20)
    const [warning, setWarning] = useState(false)
    const [dataCheck, setDataCheck] = useState('')
    const [otherCharges, setOtherCharges] = useState(shipment.otherCharges)
    const [discount, setDiscount] = useState(shipment.discount)
    const { boxsizes } = useSelector(state=>state.boxsize.list)
    const { chargesnames } = useSelector(state=>state.chargesname.list)
    const { updateLoading, updateSuccess, updateError, updateMessage } = useSelector(state=>state.shipment.update)

    useEffect(()=>{
        dispatch(getCustomerDetail(cusId))
        if(updateSuccess){
            setProgress(100)
        }
        dispatch(getItems({searchKeyword: '', pageNumber: 1, limit: 100}))   
        dispatch(getBoxsizes()) 
        dispatch(getChargesNameList())
    },[dispatch, updateSuccess, cusId ])
    

    const handleSubmit = (e) => {
        e.preventDefault()
        var updatedShipment = {
            cargoType,
            customerId: shipment.customerId._id,
            boxes: rate.boxes,
            sender: {
                name: sender.name,
                company: sender.company,
                email: sender.email,
                phonenumber1: sender.phonenumber1,
                phonenumber2: sender.phonenumber2,
                address: {
                    street: sender.street,
                    city: sender.city,
                    state: sender.state,
                    postalcode: sender.postalcode,
                    country: sender.country,
                }
            },
            receiver: {
                name: receiver.name,
                company: receiver.email,
                email: receiver.email,
                phonenumber1: receiver.phonenumber1,
                phonenumber2: receiver.phonenumber2,
                address: {
                    street: receiver.street,
                    city: receiver.city,
                    state: receiver.state,
                    postalcode: receiver.postalcode,
                    country: receiver.country,
                }
            },
            otherCharges,
            isInsured: otherCharges.some(x=>x.name === 'Insurance'),
            receiverSave,
            pickupRequested: pickup === 'yes' ? true : false,
            pickupData: pickup === 'yes' ? pickupData : null,
            totalRate: rate.totalRate,
            totalAmount: rate.totalAmount,
            totalWeight: rate.totalWeight,
            discount,
            note,
            updator: 'staff'
        }            
        dispatch(updateShipment({updatedShipment, shipmentId: shipment._id, staff: true}))
        
    }

    
    
    return (
        <div className={styles.create_shipment_main}>
            <div className={styles.create_shipment_contents}>
     
                <h1>Update Shipment {shipment.shipmentId}</h1>
                {updateLoading && <MySpinner />}
                <MyProgressBar progress={progress} setProgress={setProgress} />
                {updateError && <Alert variant='danger'>{updateMessage}</Alert>}
                {progress === 20 &&
                <>
                    <CargoType cargoType={cargoType} setCargoType={setCargoType} />
                    <hr />
                    <CustomerAndAddress 
                      sender={sender}
                      receiver={receiver}
                      setReceiver={setReceiver}
                      setSender={setSender} 
                      setAddressList={setAddressList}
                      addressList={addressList}
                      receiverSave={receiverSave}
                      setReceiverSave={setReceiverSave}
                      update={update}
                    />
                    {/* <Addresses 
                        receiver={receiver} 
                        setReceiver={setReceiver} 
                        sender={sender} 
                        setSender={setSender} 
                        customer = {customer}
                        senderDifferent = {senderDifferent}
                        setSenderDifferent = {setSenderDifferent}
                        senderSave = {senderSave}
                        setSenderSave = {setSenderSave}
                        receiverSave = {receiverSave}
                        setReceiverSave = {setReceiverSave}
                    /> */}
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                    <Button 
                        size='lg' 
                        onClick={()=>{                            
                            navigate(`/staff/shipments/${shipment._id}`)
                            dispatch(updateReset())
                        }} 
                        variant='dark'><MdKeyboardBackspace /> Cancel
                    </Button>
                    <Button 
                        variant='success'
                        onClick={async ()=> {
                            const result = await handleAddress(cargoType, receiver)
                            if(result.length !== 0){
                                setWarning(true)
                                setDataCheck("Please re-check " + result.join(', '))                               
                            } else {
                                setWarning(false)
                                setDataCheck('')
                                setProgress(40)
                            }
                        }}
                    >Next <MdArrowRightAlt /></Button>
                    </div>
                </> }

                {progress === 40 &&
                <>
                    <Boxes samebox={samebox} setSamebox={setSamebox} boxes={boxes} setBoxes={setBoxes} boxSizes={boxsizes} />
                    <hr />
                    <PackingList boxes={boxes} setBoxes={setBoxes} noItem={noItem} setNoItem={setNoItem} />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                        <Button size='lg' variant='dark' onClick={()=>setProgress(20)}><MdKeyboardBackspace /> Back</Button>
                        <Button
                            size='lg'
                            variant='success'
                            onClick={async ()=> {
                                const result = await handleBoxes(boxes, noItem)
                                if(result.length !== 0){
                                    setWarning(true)
                                    setDataCheck("Please recheck " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                } else {
                                    setWarning(false)
                                    setDataCheck('')
                                    setProgress(60)
                                }
                            }}
                        >Next <MdArrowRightAlt /></Button>
                    </div>
                </> }

                {progress === 60 &&
                <>
                    <OtherServices 
                        pickup={pickup}
                        pickupData={pickupData}
                        setPickupData={setPickupData}
                        setPickup={setPickup}
                        noItem={noItem}
                        boxes={boxes}
                        sender={sender}
                        otherCharges={otherCharges}
                        setOtherCharges={setOtherCharges}
                        update={update}
                        chargesList={chargesnames}
                    />
                    <hr />
                    {warning && <Alert variant='info'>{dataCheck}</Alert>}
                    <div className='d-flex justify-content-between'>
                        <Button variant='dark' size='lg' onClick={()=>setProgress(40)}><MdKeyboardBackspace /> Back</Button>
                        <Button
                            variant='success'
                            size='lg'
                            onClick={async ()=> {
                                //dispatch(getPrice({type: cargoType, weight: boxes.reduce((acc, b) => acc + Number(b.weight), 0)}))
                                if(pickup === 'yes'){
                                   const result = await handlePickup(pickupData)
                                    if(result.length !== 0){
                                        setWarning(true)
                                        setDataCheck("Please recheck Pickup " + result.join(', ') + ". Values can't be 0 or empty.")                               
                                    } else {
                                        setWarning(false)
                                        setDataCheck('')
                                        const boxesWithActualWeight = boxes.map(b=>{
                                            b.weight = b.volumeWeight ? b.actualWeight : b.weight
                                            return b
                                        })
                                        dispatch(getPrice({boxes: boxesWithActualWeight, cargoType, staff: true}))
                                        setProgress(80)
                                    }
                                } else {
                                    setWarning(false)
                                    setDataCheck('')
                                    const boxesWithActualWeight = boxes.map(b=>{
                                        b.weight = b.volumeWeight ? b.actualWeight : b.weight
                                        return b
                                    })
                                    dispatch(getPrice({boxes: boxesWithActualWeight, cargoType, staff: true}))
                                    setProgress(80)
                                }
                                
                            }}>Next <MdArrowRightAlt />
                        </Button>
                    </div>
                </> }

                {progress === 80 &&
                <>
                    <CostDetail 
                        cargoType={cargoType} 
                        boxes={boxes} 
                        pickup={pickup}
                        note={note}
                        setNote={setNote}
                        otherCharges={otherCharges}
                        discount={discount}
                        setDiscount={setDiscount}
                    />
                    {updateError && <Alert variant='danger'>{updateMessage}</Alert>}
                    <div className='d-flex justify-content-between'>
                        <Button
                            variant='dark'
                            size='lg' 
                            onClick={()=>{
                                dispatch(reset())
                                setProgress(60)
                            }}
                        ><MdKeyboardBackspace /> Back</Button>
                        <Button 
                            variant='success'
                            size='lg'
                            onClick={handleSubmit}>Next <MdArrowRightAlt />
                        </Button>
                    </div>
                </> }
                
                {progress === 100 &&
                <>
                    <LabelAndTrackingUpdate />
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <div/>
                        <Button 
                            variant='success'
                            size='lg'
                            onClick={()=>{
                                dispatch(updateReset())
                                navigate(`/staff/shipments/${shipment._id}`)
                            }}>Done <MdDone />
                        </Button>
                    </div>
                </>
                
                }
                
                
                
                {/* <button onClick={handleSubmit}>Create Shipment</button> */}
            </div>
            
        </div>
    )
}

export default StaffShipmentUpdateScreen