import './aboutus.css'
import Images from '../ImageGallery/ImageGallery'
import { useTranslation } from 'react-i18next'

const AboutUs = () => {
  const {t} = useTranslation()
  return (
    <div className='aboutus-main' id='aboutus'>
      <div className='aboutus-gallery'>
        <Images />
      </div>
      <div className='aboutus-text'>
        <div></div>
        <p>{t('whatWeDo')}</p>
      </div>
      
    </div>
  )
}

export default AboutUs