import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import galleryServices from './galleryService'
import { toast } from 'react-toastify';

const initialState = {
    add: {
        url: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        urlList: [],
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    delete: {
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    }
}


//Add new gallery url
export const addUrlToGallery = createAsyncThunk('Gallery_Url_Add', async (data, thunkAPI) => {
    try {      

        const token = thunkAPI.getState().staff.auth.staff.token        
        return await galleryServices.addUrlToGallery(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get gallery
export const getUrlsGallery = createAsyncThunk('Gallery_Url_List', async (data, thunkAPI) => {
    try {
        return await galleryServices.getUrlsGallery(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//delete gallery url
export const deleteUrlGallery = createAsyncThunk('Gallery_Url_Delete', async (urlId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token       
        return await galleryServices.deleteUrlGallery(urlId, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const gallerySlice = createSlice({
    name: 'Gallery',
    initialState,
    reducers: {
        reset: (state) => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        //add new url to gallery
        .addCase(addUrlToGallery.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
            state.add.addMessage = ''
        }) 
        .addCase(addUrlToGallery.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            toast.success('New image added successfully.')
            state.list.urlList = [action.payload, ...state.list.urlList]
        })  
        .addCase(addUrlToGallery.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = true
            state.add.addMessage = action.payload
            state.add.url = null
        })

        //get url list gallery
        .addCase(getUrlsGallery.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getUrlsGallery.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.error = false
            state.list.urlList = action.payload
        })  
        .addCase(getUrlsGallery.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.urlList = []
        })

        //delete url from gallery
        .addCase(deleteUrlGallery.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        }) 
        .addCase(deleteUrlGallery.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = true
            toast.success('Image deleted successfully.')
            const urlIndex = state.list.urlList.findIndex(e => e._id === action.payload)
            state.list.urlList.splice(urlIndex,1)
        })  
        .addCase(deleteUrlGallery.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })
    },
})


export const { reset, addReset } = gallerySlice.actions
export default gallerySlice.reducer