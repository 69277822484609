import { Row, Col, Card} from 'react-bootstrap'
import styles from './customercreateshipmentscreen.module.css'
import { MdAdd } from 'react-icons/md'

const Boxes = ({samebox, setSamebox, boxes, setBoxes, boxSizes}) => {

    const handleBoxWeight = (boxInd, value) => {
        var newB = boxes
        newB[boxInd].weight = value
        setBoxes([...newB])
    }

    const handleBoxDetail = (boxIdx, value, name) => {
        var newBoxes = boxes
        if(samebox){
            newBoxes.forEach(box=>{
                box[name] = value
            })
        } else {
            newBoxes[boxIdx][name] = value           
        }
         setBoxes([...newBoxes])
    }

    const handleSizeSelect = (bidx, vidx) => {
        let newBs = boxes
        if(samebox === true) {
            newBs.forEach(b=>{
                b.length = boxSizes[vidx].l
                b.width = boxSizes[vidx].w
                b.height = boxSizes[vidx].h
            })
        } else {
            newBs[bidx].length = boxSizes[vidx].l
            newBs[bidx].width = boxSizes[vidx].w
            newBs[bidx].height = boxSizes[vidx].h
        }
        
        setBoxes([...newBs])
    }

    return (
        <div className={styles.boxes_section}>
            <div className={styles.boxes_heading}>
                <h3>Boxes</h3>                
           
            </div>
            <div className={styles.boxes_options}>
                <button 
                    onClick={()=>{
                        if(samebox){
                            setBoxes([...boxes, boxes[0]])
                        } else {
                            setBoxes([...boxes, {length: 0, width: 0, height: 0, weight: 0, items: []}])
                        }
                        
                    }}
                ><MdAdd /> Add Box</button>
                <div className={styles.boxes_same}>
                    <input
                        type='checkbox'
                        id='samebox'
                        value={samebox}
                        disabled={boxes.length === 1}
                        onChange={()=>setSamebox(prevState=>!prevState)}
                    />
                    <label htmlFor='samebox'>All boxes are same size</label>
                </div>
            </div>

            <Row>
                {boxes.map((box, idx)=>(
                    <Col md={4} key={idx} className='mb-3'>
                        <Card>
                            <Card.Header className={`${styles.box_header} py-1`}>
                                <div className='pt-1 me-2 bg-warning rounded px-1'>Box {idx+1} </div>
                                <select className='pt-1 me-auto' style={{width: '120px', height: '30px', fontSize: '15px'}}
                                    onChange={({target})=>{
                                        if(target.value === 'sizes'){
                                            return
                                        }
                                        handleSizeSelect(idx, Number(target.value))
                                    }}
                                >
                                    <option value='sizes'>sizes</option>
                                    {boxSizes.map((b,i)=>(
                                        <option style={{fontSize: '13px'}} key={i} value={i}>{b.l} x {b.w} x {b.h}</option>
                                    ))}
                                </select>
                                <button
                                    disabled={boxes.length === 1}
                                    onClick={()=>{
                                        setBoxes([
                                            ...boxes.slice(0, idx),
                                            ...boxes.slice(idx + 1)
                                        ]);
                                    }}
                                >X</button>
                            </Card.Header>
                            <Card.Body>
                                <div className={styles.boxes_card}>
                                    <div className={styles.boxes_detail}>
                                        <p>Length </p>
                                        <input 
                                            type='number'
                                            value={box.length}
                                            name='length'
                                            onChange={e=>handleBoxDetail(idx, e.target.value, e.target.name)}
                                        />
                                    </div>
                                    
                                    <div className={styles.boxes_detail}>
                                        <p>Width </p>
                                        <input 
                                            type='number'
                                            value={box.width}
                                            name='width'
                                            onChange={e=>handleBoxDetail(idx, e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className={styles.boxes_detail}>
                                        <p>Height </p>
                                        <input 
                                            type='number'
                                            value={box.height}
                                            name='height'
                                            onChange={e=>handleBoxDetail(idx, e.target.value, e.target.name)}
                                        />
                                    </div>
                                    <div className={styles.boxes_detail}>
                                        <p>Weight</p>
                                        <input 
                                            type='number'
                                            value={box.weight}
                                            name='weight'
                                            onChange={e=>handleBoxWeight(idx, e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
            <div className='fs-4 float-end'>Total Weight - {(boxes.reduce((acc, b) => acc + Number(b.weight), 0)).toFixed(2)} lb</div>
        </div>
    )
}

export default Boxes