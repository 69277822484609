import pack from './pack.jpeg'
import pick from './pick.jpeg'
import ship from './ship.jpeg'
import box from './box.jpeg'
import box18 from './box18.png'
import box24 from './box24.png'
import box28 from './box28.png'
import pic1 from './pic1.jpg'
import pic2 from './pic2.jpg'
import pic3 from './pic3.jpg'
import pic4 from './pic4.jpg'
import pic5 from './pic5.jpg'
import pic6 from './pic6.jpg'
import pic7 from './pic7.jpg'
import pic8 from './pic8.jpg'
import pic9 from './pic9.jpg'
import pic10 from './pic10.jpg'
import pic11 from './pic11.jpg'
import pic12 from './pic12.jpg'
import pic13 from './pic13.jpg'
import pic14 from './pic14.jpg'
import pic15 from './pic15.jpg'
import pic16 from './pic16.jpg'
import pic17 from './pic17.jpg'
import pic18 from './pic18.jpg'
import pic19 from './pic19.jpg'
import pic20 from './pic20.jpg'
import pic21 from './pic21.jpg'
import pic22 from './pic22.jpg'
import pic23 from './pic23.jpg'
import pic24 from './pic24.jpg'
import pic25 from './pic25.jpg'
import facebook from './facebook.svg'
import instagram from './instagram.svg'
import youtube from './youtube.svg'
import logogold from './logo-gold.png'
import noise from './noise.png'
import boxsizes from './boxsizes.png'
import usflag from './usflag.png'
import mmflag from './mmflag.png'
import thflag from './thflag.png'
import boxlwd from './boxlwd.png'
import beep from './beep.mp3'
import yg from './yg.png'
import ny from './ny.png'

const images = [
    {original: pic1, thumbnail: pic1},
    {original: pic2, thumbnail: pic2},
    {original: pic3, thumbnail: pic3},
    {original: pic4, thumbnail: pic4},
    {original: pic5, thumbnail: pic5},
    {original: pic6, thumbnail: pic6},
    {original: pic7, thumbnail: pic7},
    {original: pic8, thumbnail: pic8},
    {original: pic9, thumbnail: pic9},
    {original: pic10, thumbnail: pic10},
    {original: pic11, thumbnail: pic11},
    {original: pic12, thumbnail: pic12},
    {original: pic13, thumbnail: pic13},
    {original: pic14, thumbnail: pic14},
    {original: pic15, thumbnail: pic15},
    {original: pic16, thumbnail: pic16},
    {original: pic17, thumbnail: pic17},
    {original: pic18, thumbnail: pic18},
    {original: pic19, thumbnail: pic19},
    {original: pic20, thumbnail: pic20},
    {original: pic21, thumbnail: pic21},
    {original: pic22, thumbnail: pic22},
    {original: pic23, thumbnail: pic23},
    {original: pic24, thumbnail: pic24},
    {original: pic25, thumbnail: pic25}
]

export {
    pack,
    pick,
    ship,
    box,
    box18,
    box24,
    box28,
   images,
   facebook,
   instagram,
   youtube,
   logogold,
   noise,
   boxsizes,
   usflag,
   mmflag,
   thflag,
   boxlwd,
   beep,
   yg,
   ny,
}