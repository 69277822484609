import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import boxsizeServices from './boxsizeService'
import { toast } from 'react-toastify';

const initialState = {
    add: {
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        boxsizes: [],
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    update: {
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
        updateMessage: ''
    },
    delete: {
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    }
}


//Add new boxsize
export const addNewBoxsize = createAsyncThunk('Boxsize_Add', async (newBoxsize, thunkAPI) => {
    try {    
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await boxsizeServices.addNewBoxsize(newBoxsize, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get boxsizes
export const getBoxsizes = createAsyncThunk('Boxsize_List', async (_, thunkAPI) => {
    try {
        return await boxsizeServices.getBoxsizes()
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update a boxsize
export const updateBoxsize = createAsyncThunk('Boxsize_Update', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await boxsizeServices.updateBoxsize(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete boxsize
export const deleteBoxsize = createAsyncThunk('Boxsize_Delete', async (boxId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await boxsizeServices.deleteBoxsize(boxId, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const boxsizeSlice = createSlice({
    name: 'Boxsize',
    initialState,
    reducers: {
        reset: (state) => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addNewBoxsize.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
            state.add.addMessage = ''
        }) 
        .addCase(addNewBoxsize.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            toast.success('new boxsize added successfully.')
            state.list.boxsizes = [action.payload, ...state.list.boxsizes]
        })  
        .addCase(addNewBoxsize.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = true
            state.add.addMessage = action.payload
        })
        //get Boxsize list
        .addCase(getBoxsizes.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getBoxsizes.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.boxsizes = action.payload
        })  
        .addCase(getBoxsizes.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.boxsizes = []
        })
        //update a Boxsize 
        .addCase(updateBoxsize.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateError = false
            state.update.updateSuccess = false
            state.update.updateMessage = ''
        }) 
        .addCase(updateBoxsize.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            toast.success('Box size updated successfully.')
            const boxIndex = state.list.boxsizes.findIndex(e => e._id === action.payload._id)
            state.list.boxsizes = [...state.list.boxsizes.slice(0, boxIndex), action.payload, ...state.list.boxsizes.slice(boxIndex + 1)]
        })  
        .addCase(updateBoxsize.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateSuccess = false
            state.update.updateMessage = action.payload
        })
        //delete a Boxsize 
        .addCase(deleteBoxsize.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        }) 
        .addCase(deleteBoxsize.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            toast.success('Boxsize deleted successfully.')
            const boxIndex = state.list.boxsizes.findIndex(e => e._id === action.payload)
            state.list.boxsizes.splice(boxIndex,1)
        })  
        .addCase(deleteBoxsize.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })


    },
})


export const { reset, addReset } = boxsizeSlice.actions
export default boxsizeSlice.reducer