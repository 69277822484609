import { useState, useRef } from "react";
import styles from './modals.module.css'
import { useWindowSize } from '../Components'
import { useSelector } from "react-redux";
import { Button, Row, Col, Modal, Card, Image, Table} from "react-bootstrap"
import { MdPrint, MdOutlineDocumentScanner } from 'react-icons/md'
import ReactToPrint from 'react-to-print';
import nyssData from '../data/nyssData'

const InvoiceModal = () => {
    const componentRef = useRef()
    const size = useWindowSize()
    const [show, setShow] = useState(false);
    const { shipment } = useSelector(state=>state.shipment.detail)

    return (
        <>
            <Button variant="info" onClick={() => setShow(true)}>
                <MdOutlineDocumentScanner /> View
            </Button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                size='lg'
                dialogClassName="modal-90w"
                aria-labelledby="inovice-modal"
            >
                <Modal.Header closeButton>
                <Modal.Title id="inovice-modal">
                    Invoice
                </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {size.width > 760 && <div className='d-flex justify-content-between mb-4'>
                        <div />
                            <ReactToPrint                            
                                trigger={() => <Button variant='info ' style={{width: '100px'}}><MdPrint /> Print</Button>}
                                content={() => componentRef.current}
                            />
                        </div>
                    }
                    <div ref={componentRef} className={styles.invoice_main}>
                        <Row className="mb-2">
                            <Col md={8}>
                                <Card >
                                    <Card.Header className="bg-info-subtle fw-bold">
                                        <div className="d-flex justify-content-between">
                                            <div>INVOICE NO - {shipment.invoiceId ? shipment.invoiceId : '-'}</div>
                                            <div className={shipment.isPaid ? 'text-success' : 'text-danger'}>{shipment.isPaid ? 'PAID' : 'UNPAID'}</div>
                                        </div>
                                    </Card.Header>
                                    <Card.Body className="py-1 px-2" style={{fontSize: '14px'}}>                                       
                                            <Row >
                                                <Col xs={3}>BILLING DATE</Col>
                                                <Col xs={9}>{new Date(shipment.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: 'numeric'})}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>TERMS</Col>
                                                <Col xs={9}>FOB</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>CARGO TYPE</Col>
                                                <Col xs={9}>{shipment.cargoType.toUpperCase()}</Col>
                                            </Row>
                                    </Card.Body>
                                    <hr className="m-0 p-0" />
                                    <Card.Body className="py-1 px-2" style={{fontSize: '14px'}}>
                                       
                                            <Row  style={{fontSize: '16px'}}>
                                                <Col xs={3}>SHIPPER</Col>
                                                <Col xs={9}>{nyssData.name}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>PHONE</Col>
                                                <Col xs={9}>{nyssData.phonenumber1}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>EMAIL</Col>
                                                <Col xs={9}>{nyssData.email}</Col>
                                            </Row>
                                            <Row>
                                                <Col xs={3}>ADDRESS</Col>
                                                <Col xs={9}>{nyssData.street + ', ' + nyssData.city + ', ' +  nyssData.state + ', ' +  nyssData.postalcode + ', ' +  nyssData.country }</Col>
                                            </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {size.width > 760 &&
                            <Col md={4} className="d-flex justify-content-center">
                                <Image src='/nyseaLogo.png' alt='nyseaLogo' style={{height: '170px'}} />
                            </Col>
                            }
                        </Row>

                        <Row className='mb-1'>
                            <Col sm={6} xs={12} className="mb-1 pe-1">
                                <Card>
                                    <Card.Header className="bg-info-subtle lh-1">BILL TO</Card.Header>
                                    <Card.Body style={{fontSize: '13px'}} className="py-1 px-2">
                                        <div style={{fontSize: '16px'}}>{shipment.sender.name}</div>                                        
                                        {shipment.sender.company && <div>{shipment.sender.company}</div>}
                                        <div>{shipment.sender.address.street}, {shipment.sender.address.city}, {shipment.sender.address.state}, {shipment.sender.address.postalcode}, {shipment.sender.address.country}</div>
                                        <div>{shipment.sender.phonenumber1} {shipment.sender.phonenumber2 && ', ' + shipment.sender.phonenumber2}</div>
                                        {shipment.sender.email && <div>{shipment.sender.email}</div>}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} xs={12} className="ps-1">
                                <Card>
                                    <Card.Header className="bg-info-subtle lh-1">SHIP TO</Card.Header>
                                    <Card.Body style={{fontSize: '13px'}} className="py-1 px-2">
                                        <div style={{fontSize: '16px'}}>{shipment.receiver.name}</div>
                                        {shipment.receiver.company && <div>{shipment.receiver.company}</div>}
                                        <div>{shipment.receiver.address.street}, {shipment.receiver.address.city}, {shipment.receiver.address.state}, {shipment.receiver.address.postalcode}, {shipment.receiver.address.country}</div>
                                        <div>{shipment.receiver.phonenumber1} {shipment.receiver.phonenumber2 && ', ' + shipment.receiver.phonenumber2}</div>
                                        {shipment.receiver.email && <div>{shipment.receiver.email}</div>}
                                        
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <hr className="my-2 p-0" />

                        <Card className="mb-2">
                            <Card.Header className="bg-info-subtle lh-1">BOXES & DETAIL</Card.Header>
                            <Table bordered hover size="sm" style={{fontSize: '15px'}} >
                                <thead className="lh-1">
                                    <tr className='text-center bg-warning-subtle'>
                                        <th>#</th>
                                        <th>Box ID</th>
                                        <th>Size <span className='text-danger'>inch</span></th>
                                        <th>Weight <span className='text-danger'>lb</span></th>
                                        <th>Charges <span className='text-danger'>$</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {shipment.boxes.map((b,i)=>(
                                        <tr key={b.boxId} className='text-center'>
                                            <td>{i+1}</td>
                                            <td>{b.boxId}</td>
                                            <td>{b.length} x {b.width} x {b.height}</td>
                                            <td>{b.actualWeight.toFixed(2)}</td>
                                            <td>{b.amount.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Row className='mb-1 px-1'>
                                <Col sm={6} xs={12} className="mb-1 pe-1">
                                    <Card>
                                        <Card.Header className="bg-info-subtle lh-1">OTHER CHARGES LIST</Card.Header>
                                        <Card.Body style={{fontSize: '14px'}} className="py-1 px-2">
                                            {shipment.otherCharges.length === 0 ? 'No charges' : 
                                            shipment.otherCharges.map((c,i)=>(
                                                <div key={i} className="d-flex justify-content-between">
                                                    <div>{c.name}</div>
                                                    <div>$ {c.amount.toFixed(2)}</div>
                                                </div>
                                            ))
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col sm={6} xs={12} className="ps-1">
                                    <Card>
                                        <Card.Header className="bg-info-subtle lh-1">SUMMARY</Card.Header>
                                        <Card.Body style={{fontSize: '14px'}} className="py-1 px-2">
                                            <div className="d-flex justify-content-between">
                                                <div>TOTAL WEIGHT</div>
                                                <div>{shipment.totalWeight.toFixed(2)} lb</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>RATE APPLIED</div>
                                                <div>$ {shipment.totalRate.toFixed(2)}</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>SHIPMENT CHARGE TOTAL</div>
                                                <div>$ {shipment.totalAmount.toFixed(2)}</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>OTHER CHARGES TOTAL</div>
                                                <div>$ {(shipment.otherCharges.reduce((acc, och) => acc + och.amount, 0)).toFixed(2)}</div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <div>DISCOUNT</div>
                                                <div>$ -{shipment.discount ? shipment.discount.toFixed(2) : 0}</div>
                                            </div>
                                            <hr className="pt-1 m-0" />
                                            <div className="d-flex justify-content-between fs-5">
                                                <div>GRAND TOTAL</div>
                                                <div>$ {shipment.grandTotal.toFixed(2)}</div>
                                                {/* <div>$ {(shipment.totalAmount + (shipment.otherCharges.reduce((acc, och) => acc + och.amount, 0))).toFixed(2)}</div> */}
                                            </div>                                            
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card>

                        <Row>
                            <Col sm={6} className='mb-2 pe-1'>
                                <Card>
                                    <Card.Header className="bg-info-subtle lh-1">PAYMENT OPTIONS</Card.Header>
                                    <Card.Body style={{fontSize: '13px'}} className="py-1 px-2">
                                        <Row>
                                            <Col xs={4}>Beneficiary & Check Payable to</Col>
                                            <Col xs={8}>
                                                <div>{nyssData.company}</div>
                                                <div>{nyssData.street + ', ' + nyssData.city + ', ' +  nyssData.state + ', ' +  nyssData.postalcode + ', ' +  nyssData.country }</div>
                                            </Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>Wire</Col>
                                            <Col xs={8}>
                                                <Row>
                                                    <Col sm={5}>Bank Name </Col>
                                                    <Col>{nyssData.bankName}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={5}>Account No </Col>
                                                    <Col>{nyssData.bankAccountNo}</Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={5}>ABA No </Col>
                                                    <Col>{nyssData.abaNo}</Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>Zelle</Col>
                                            <Col xs={8}>
                                                <div>{nyssData.zelle}</div>
                                            </Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>Vemo</Col>
                                            <Col xs={8}>
                                                <div>{nyssData.vemo}</div>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6} className='mb-2 ps-1'>
                                <Card>
                                    <Card.Header className="bg-info-subtle lh-1">PAYMENT DETAIL</Card.Header>
                                    <Card.Body style={{fontSize: '13px'}} className="p-1">
                                        {shipment.paymentDetail.length === 0 ? 
                                        <div className="text-center">No payment found</div> :
                                        <Table bordered size="sm">
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Amount</th>
                                                <th>Currency</th>
                                                <th>Method</th>
                                                <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipment.paymentDetail.map((p,i)=>(
                                                <tr key={i}>
                                                    <td>{i+1}</td>
                                                    <td>{p.amount.toFixed(2)}</td>
                                                    <td>{p.currency}</td>
                                                    <td>{p.method}</td>
                                                    <td>{new Date(p.paidAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', year: '2-digit'})}</td> 
                                                </tr>
                                                ))}
                                            </tbody>
                                        </Table>}
                                        {/* <Row>
                                            <Col xs={4}>PAID BY</Col>
                                            <Col xs={8}></Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>PAID AT</Col>
                                            <Col xs={8}></Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>PAID AMOUNT</Col>
                                            <Col xs={8}></Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>CURRENCY</Col>
                                            <Col xs={8}></Col>
                                        </Row>
                                        <hr className="m-0 p-0" />
                                        <Row>
                                            <Col xs={4}>NOTE</Col>
                                            <Col xs={8}></Col>
                                        </Row> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </>
  )
}

export default InvoiceModal