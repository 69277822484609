import { Stack } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import styles from './staffscreens.module.css'
import { LinkContainer } from 'react-router-bootstrap'
import { useWindowSize } from '../../Components'
import { useState } from 'react'
import { MdChevronRight, MdContentCut, MdOutlineEngineering, MdOutlineLocalShipping, MdOutlinePriceChange, MdOutlineSettings, MdPeople, MdQrCodeScanner, MdShoppingCart } from 'react-icons/md'


const StaffSideBar = () => {
    const size = useWindowSize()
    const { staff } = useSelector(state=>state.staff.auth)
    const [showSystemMenu, setShowSystemMenu] = useState(false)
    const [showScanMenu, setShowScanMenu] = useState(false)
    const [showShipmentMenu, setShowShipmentMenu] = useState(false)
    const [showOsItemMenu, setShowOsItemMenu] = useState(false)

    const SystemMenu = () => (
        <div className={`${styles.system_menu}`}>
            <LinkContainer to='/staff/items'>
                <div className={styles.staff_sidebar_items_menu}>Items</div>
            </LinkContainer>
            <LinkContainer to='/staff/contactus'>
                <div className={styles.staff_sidebar_items_menu}>Messages</div>
            </LinkContainer>
            {/* <LinkContainer to='/staff/faq'>
                <div className={styles.staff_sidebar_items_menu}>FAQ List</div>
            </LinkContainer> */}
            <LinkContainer to='/staff/boxsizes'>
                <div className={styles.staff_sidebar_items_menu}>Box Sizes</div>
            </LinkContainer>
            <LinkContainer to='/staff/charges'>
                <div className={styles.staff_sidebar_items_menu}>Charges Name</div>
            </LinkContainer>
            <LinkContainer to='/staff/gallery'>
                <div className={styles.staff_sidebar_items_menu}>Gallery</div>
            </LinkContainer>
        </div>
    )

    const ShipmentMenu = () => (
        <div className={`${styles.system_menu}`}>
            <LinkContainer to='/staff/shipments'>
                <div className={styles.staff_sidebar_items_menu}>Shipments</div>
            </LinkContainer>
            <LinkContainer to='/staff/shipments/pickup'>
                <div className={styles.staff_sidebar_items_menu}>Pick Up</div>
            </LinkContainer>            
            <LinkContainer to='/staff/shipments/filter'>
                <div className={styles.staff_sidebar_items_menu}>Filter</div>
            </LinkContainer>
        </div>
    )

    const OsItemMenu = () => (
        <div className={`${styles.system_menu}`}>
            <LinkContainer to='/staff/ositem'>
                <div className={styles.staff_sidebar_items_menu}>Received</div>
            </LinkContainer>
            <LinkContainer to='/staff/shippedositem'>
                <div className={styles.staff_sidebar_items_menu}>Shipped</div>
            </LinkContainer> 
        </div>
    )

    const ScanMenu = () => (
        <div className={`${styles.system_menu}`}>
            <LinkContainer to='/staff/scan/pickup'>
                <div className={styles.staff_sidebar_items_menu}>Picked Up</div>
            </LinkContainer>
            <LinkContainer to='/staff/scan/shipped'>
                <div className={styles.staff_sidebar_items_menu}>Shipped</div>
            </LinkContainer>
            <LinkContainer to='/staff/scan/received'>
                <div className={styles.staff_sidebar_items_menu}>Received</div>
            </LinkContainer>
            <LinkContainer to='/staff/scan/delivered'>
                <div className={styles.staff_sidebar_items_menu}>Delivered</div>
            </LinkContainer>
        </div>
    )
    return (
        <>
            {size.width > 750 ? 
            <>
                <Stack>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowScanMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowScanMenu(true)}
                        onMouseLeave={()=>setShowScanMenu(false)}
                        >SCAN <MdChevronRight />
                        {showScanMenu && <ScanMenu />}
                    </div>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowShipmentMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowShipmentMenu(true)}
                        onMouseLeave={()=>setShowShipmentMenu(false)}
                        >SHIPMENT <MdChevronRight />
                        {showShipmentMenu && <ShipmentMenu />}
                    </div>
                    <LinkContainer to='/staff/cutoff'>
                        <div 
                            className={styles.staff_sidebar_items} 
                            onClick={()=>setShowSystemMenu(false)}
                            >CUT OFF
                        </div>
                    </LinkContainer>
                    {staff && staff.isAdmin && <LinkContainer to='/staff/rate'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            >RATE
                        </div>
                    </LinkContainer>}
                    <LinkContainer to='/staff/customers'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            >CUSTOMERS
                        </div>
                    </LinkContainer>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowOsItemMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowOsItemMenu(true)}
                        onMouseLeave={()=>setShowOsItemMenu(false)}
                        >OS ITEMS <MdChevronRight />
                        {showOsItemMenu && <OsItemMenu />}
                    </div>
                    {staff && staff.isAdmin && <LinkContainer to='/staff/accounts'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            >STAFFS
                        </div>
                    </LinkContainer>}
                    
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowSystemMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowSystemMenu(true)}
                        onMouseLeave={()=>setShowSystemMenu(false)}
                        >SYSTEM <MdChevronRight />
                        {showSystemMenu && <SystemMenu />}
                    </div>
                   
                    
                </Stack>
            </>
            :
            <>
                <Stack className='text-center'>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowScanMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowScanMenu(true)}
                        onMouseLeave={()=>setShowScanMenu(false)}
                        ><MdQrCodeScanner size={30} />
                        {showScanMenu && <ScanMenu />}
                    </div>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowShipmentMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowShipmentMenu(true)}
                        onMouseLeave={()=>setShowShipmentMenu(false)}
                        ><MdOutlineLocalShipping size={30} />
                        {showShipmentMenu && <ShipmentMenu />}
                    </div>
                    <LinkContainer to='/staff/cutoff'>
                        <div 
                            className={styles.staff_sidebar_items} 
                            onClick={()=>setShowSystemMenu(false)}
                            ><MdContentCut size={30} />
                        </div>
                    </LinkContainer>
                    {staff && staff.isAdmin && <LinkContainer to='/staff/rate'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            ><MdOutlinePriceChange size={30} />
                        </div>
                    </LinkContainer>}
                    <LinkContainer to='/staff/customers'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            ><MdPeople size={30} />
                        </div>
                    </LinkContainer>
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowOsItemMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowOsItemMenu(true)}
                        onMouseLeave={()=>setShowOsItemMenu(false)}
                        ><MdShoppingCart size={30} />
                        {showOsItemMenu && <OsItemMenu />}
                    </div>
                    {staff && staff.isAdmin && <LinkContainer to='/staff/accounts'>
                        <div 
                            className={styles.staff_sidebar_items}
                            onClick={()=>setShowSystemMenu(false)}
                            ><MdOutlineEngineering size={30} />
                        </div>
                    </LinkContainer>}
                    
                    <div className={styles.staff_sidebar_items}
                        onClick={()=>setShowSystemMenu(prev=>!prev)}
                        onMouseEnter={()=>setShowSystemMenu(true)}
                        onMouseLeave={()=>setShowSystemMenu(false)}
                        ><MdOutlineSettings size={30} />
                        {showSystemMenu && <SystemMenu />}
                    </div>
                   
                    
                </Stack>
            </>
            }
            
        
        </>
    )
}

export default StaffSideBar