import styles from './customercreateshipmentscreen.module.css'
import { CustomerSelectModal, ReceiverSelectModal } from '../../../Modals'
import { Row, Col, Form } from 'react-bootstrap'
import { countries } from '../../../data/countries'

const CustomerAndAddress = ({
    sender, 
    setSender, 
    receiver, 
    setReceiver, 
    receiverSave,
    setReceiverSave,
    customerId, 
    setCustomerId, 
    customerCode,
    setCustomerCode,
    addressList, 
    setAddressList,
    update
}) => {
  return (
    <div>    
        <Row>
            <Col lg={6}>
                <CustomerSelectModal 
                    update={update} 
                    setSender={setSender} 
                    setCustomerId={setCustomerId} 
                    setReceiver={setReceiver} 
                    setAddressList={setAddressList}
                    customerCode={customerCode}
                    setCustomerCode={setCustomerCode}
                    />
                <div className='text-center fs-4 bg-primary-subtle rounded my-3'>Sender</div>
                <div className='mb-3'>
                    <Form.Label className='mb-1 bg-primary-subtle px-2 rounded bg-primary-subtle px-3'>Full Name</Form.Label>
                    <Form.Control 
                        type='text'
                        value={sender.name}
                        onChange={({target})=>setSender({...sender, name: target.value})}
                        
                    />
                </div>
                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Company</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.company}
                                onChange={({target})=>setSender({...sender, company: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Email</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.email}
                                readOnly
                                // onChange={({target})=>setSender({...sender, email: target.value})}
                            />
                        </div>
                    </Col>
                </Row>
                
                
                <div className='mb-3'>
                    <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Address</Form.Label>
                    <Form.Control 
                        type='text'
                        value={sender.street}
                        onChange={({target})=>setSender({...sender, street: target.value})}
                        
                    />
                </div>

                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>City</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.city}
                                onChange={({target})=>setSender({...sender, city: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>State/Province</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.state}
                                onChange={({target})=>setSender({...sender, state: target.value})}
                                
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Zip/Postal</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.postalcode}
                                onChange={({target})=>setSender({...sender, postalcode: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Country</Form.Label>
                            <Form.Select value={sender.country} onChange={({target})=>setSender({...sender, country: target.value})}>
                                {countries.map(c=>(
                                    <option key={c.name} value={c.value}>{c.name}</option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Phone 1</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.phonenumber1}
                                onChange={({target})=>setSender({...sender, phonenumber1: target.value.replace(/\D/g, "")})}
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-primary-subtle px-2 rounded'>Phone 2</Form.Label>
                            <Form.Control 
                                type='text'
                                value={sender.phonenumber2}
                                onChange={({target})=>setSender({...sender, phonenumber2: target.value.replace(/\D/g, "")})}
                            />
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col>
                <ReceiverSelectModal update={update} addressList={addressList} customerId={customerId} setReceiver={setReceiver} />
                <div className='text-center fs-4 bg-warning-subtle rounded my-3'>Receiver</div>
                <div className='mb-3'>
                    <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Full Name</Form.Label>
                    <Form.Control 
                        type='text'
                        value={receiver.name}
                        onChange={({target})=>setReceiver({...receiver, name: target.value})}
                        
                    />
                </div>

                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Company</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.company}
                                onChange={({target})=>setReceiver({...receiver, company: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Email</Form.Label>
                            <Form.Control 
                                type='email'
                                value={receiver.email}
                                // onChange={({target})=>{
                                //     const reg = /[^0-9A-Za-z!@#$%&*()_\-+={[}\]|:;"'<,>.?/\\~`]/g
                                //     if (reg.test(target.value)){
                                //         setReceiver({...receiver, email: target.value})
                                //     }
                                // }}
                                onChange={({target})=>setReceiver({...receiver, email: target.value.replace(/[^0-9A-Za-z@.]/g, "")})}
                            />
                        </div>
                    </Col>
                </Row>
                
                
                <div className='mb-3'>
                    <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Address</Form.Label>
                    <Form.Control 
                        type='text'
                        value={receiver.street}
                        onChange={({target})=>setReceiver({...receiver, street: target.value})}
                        
                    />
                </div>

                 <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>City</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.city}
                                onChange={({target})=>setReceiver({...receiver, city: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>State/Province</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.state}
                                onChange={({target})=>setReceiver({...receiver, state: target.value})}
                                
                            />
                        </div>
                    </Col>
                </Row>
                
                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Zip/Postal</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.postalcode}
                                onChange={({target})=>setReceiver({...receiver, postalcode: target.value})}
                                
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Country</Form.Label>
                            <Form.Select value={receiver.country} onChange={({target})=>setReceiver({...receiver, country: target.value})}>
                                <option value=''>Select</option>
                                <option value='Thailand'>Thailand</option>
                                <option value='Myanmar'>Myanmar</option>
                                <option value='Lao'>Lao</option>
                                <option value='United States'>United States</option>
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Phone 1</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.phonenumber1}
                                onChange={({target})=>setReceiver({...receiver, phonenumber1: target.value.replace(/\D/g, "")})}
                            />
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className='mb-3'>
                            <Form.Label className='mb-1 bg-warning-subtle px-2 rounded'>Phone 2</Form.Label>
                            <Form.Control 
                                type='text'
                                value={receiver.phonenumber2}
                                onChange={({target})=>setReceiver({...receiver, phonenumber2: target.value.replace(/\D/g, "")})}
                            />
                        </div>
                    </Col>
                </Row>
                
                
                
                
                
                <div className={styles.boxes_same}>
                    <input
                        type='checkbox'
                        id='savereceiver'
                        value={receiverSave}
                        onChange={()=>setReceiverSave(prevState=>!prevState)}
                    />
                    <label htmlFor='savereceiver'>Save in receiver list</label>
                </div>
            </Col>
        </Row>
    </div>
  )
}

export default CustomerAndAddress