import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Form, Row, Col, Button, Table, Card, ListGroup } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { getShipmentsForCutOff, removeFromCutOffList } from '../../features/shipment/shipmentSlice'
import { AlertMessage, MySpinner, useWindowSize } from '../../Components'
import { MdClose, MdOutlineWifiProtectedSetup } from 'react-icons/md'
import { createCutOff, addReset } from '../../features/cutoff/cutoffSlice'
import { cutoffReset } from '../../features/shipment/shipmentSlice'

const CutOffCreateScreen = () => {
    const size = useWindowSize()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ keyword, setKeyword ] = useState('')
    const [ condition, setCondition ] = useState('')
    const [ cType, setCType ] = useState('')
    const [ fromDate, setFromDate ] = useState('')
    const [ toDate, setToDate ] = useState('')
    const [ startDate, setStartDate ] = useState('')

    const { shipments, cutoffLoading, cutoffError, cutoffSuccess, cutoffMessage} = useSelector(state=>state.shipment.cutoff)
    const { cutoff, addLoading, addError, addSuccess, addMessage } = useSelector(state=>state.cutoff.add)

    useEffect(()=>{
        if(addSuccess){            
            navigate(`/staff/cutoff/${cutoff}`)
        }
        return()=>{
            setKeyword('')
            setCondition('')
            setCType('')
            setFromDate('')
            setToDate('')
            setStartDate('')
            dispatch(cutoffReset())
            dispatch(addReset())
        }
    },[navigate, addSuccess, cutoff, dispatch])

    const handleGetShipments = (e) => {
        e.preventDefault()
        if(condition === 'dates' && (fromDate === '' || toDate === '')){
            toast.warning('From and To dates required')
            return
        }
        if(condition === 'from' && startDate === ''){
            toast.warning('Date required')
            return
        }
        const data = {
            condition,
            keyword,
            cType,
            fromDate,
            toDate,
            startDate
        }
        dispatch(getShipmentsForCutOff(data))
    }

    const handleCreateCutOff = () => {
        if(shipments.length === 0){
            toast.warning('No shipment')
            return
        }
        const shipmentIds = shipments.map(s=>s._id)
        const data = {
            shipmentIds,
            totalWeight: (shipments.reduce((acc, s) => acc + s.totalWeight, 0)).toFixed(2),
            totalBoxQty: shipments.reduce((acc, s) => acc + s.boxQty, 0)
        }
        dispatch(createCutOff(data))
    }
    return (
        <div >
            <h3 className='text-center'>Create New Cut Off</h3>
            {cutoffLoading && <MySpinner />}
            {addLoading && <MySpinner />}
            {cutoffError && <AlertMessage msg={cutoffMessage} />}
            {addError && <AlertMessage msg={addMessage} />}

            <Row>
                <Col md={6}>
                    <Row>
                        <Col md={6}>
                            <Form.Group className='mb-4'>
                                <Form.Label>Choose Condition</Form.Label>
                                <Form.Select value={condition} onChange={({target})=>setCondition(target.value)}>
                                    <option value=''>Select</option>
                                    <option value='all'>All shipment which are not cut yet</option>
                                    <option value='dates'>Between dates</option>
                                    <option value='from'>All shipment until</option>
                                    <option value='keyword'>Specific keywords</option>
                                </Form.Select>
                            </Form.Group>                        
                        </Col>
                        <Col md={6}>
                            <Form.Group className='mb-4'>
                                <Form.Label>Cargo Type</Form.Label>
                                <Form.Select value={cType} onChange={({target})=>setCType(target.value)}>
                                    <option value=''>Select</option>
                                    <option value='air'>Air</option>
                                    <option value='ocean'>Ocean</option>
                                    <option value='express'>Express Ocean</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    {condition === 'keyword' &&
                    <div>
                        <Form.Control 
                            type="text"
                            value={keyword}
                            maxLength={20}
                            placeholder='Shipment Id, Reciver/Sender name'
                            onChange={({target})=>setKeyword(target.value)}
                        />
                    </div>
                    }
                    

                    {condition === 'dates' &&
                    <div>
                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label className='mb-0 ps-1'>From</Form.Label>
                                    <Form.Control 
                                        type="date"
                                        value={fromDate}
                                        onChange={({target})=>setFromDate(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label className='mb-0 ps-1'>To</Form.Label>
                                    <Form.Control 
                                        type="date"
                                        value={toDate}
                                        onChange={({target})=>setToDate(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    }

                    {condition === 'from' &&
                    <div>
                        <Form.Group>
                            <Form.Label className='mb-0 ps-1'>Date</Form.Label>
                            <Form.Control 
                                type="date"
                                value={startDate}
                                onChange={({target})=>setStartDate(target.value)}
                            />
                        </Form.Group>
                    </div>
                    }

                    <div className='mt-3'>
                        <Button 
                            onClick={handleGetShipments}
                            disabled={condition === ''}
                        >Get Shipments</Button>
                        <Button
                            variant='flush'
                            onClick={()=>{
                                setCondition('')
                                setKeyword('')
                                setCType('')
                                setFromDate('')
                                setToDate('')
                                setStartDate('')
                                dispatch(cutoffReset())
                            }}
                        >
                            <MdOutlineWifiProtectedSetup /> Reset
                        </Button>
                    </div>
                    
                </Col>

                <Col md={6}>
                    {cutoffSuccess && 
                    <div className='my-3'>
                        <Card>
                            <Card.Header>Summary</Card.Header>
                            <ListGroup variant="flush">
                                <ListGroup.Item>
                                    <div>Total Shipment Qty - {shipments.length}</div>
                                    <div className='ms-3 bg-warning-subtle px-2 rounded' style={{fontSize:'14px', width: '200px'}}>
                                        <div className='d-flex justify-content-between border-bottom'><div>AIR </div><div>{shipments.filter((obj) => obj.cargoType === 'air').length}</div></div>
                                        <div className='d-flex justify-content-between border-bottom'><div>OCEAN</div><div>{shipments.filter((obj) => obj.cargoType === 'ocean').length}</div></div>
                                        <div className='d-flex justify-content-between'><div>EXPRESS_OCEAN</div><div>{shipments.filter((obj) => obj.cargoType === 'express').length}</div></div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>Total Weight - {(shipments.reduce((acc, s) => acc + s.totalWeight, 0)).toFixed(2)} lbs</ListGroup.Item>
                                <ListGroup.Item>Total Boxes Qty - {shipments.reduce((acc, s) => acc + s.boxQty, 0)} pcs</ListGroup.Item>
                                <ListGroup.Item>
                                    <div>
                                        <Button
                                            variant='success'
                                            onClick={handleCreateCutOff}
                                        >Create Cut Off</Button>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card>
                    </div>
                    }
                </Col>
            </Row>
            <div className='mt-4'>
                {cutoffSuccess && 
                <>
                    {size.width > 760 ?
                    <Table striped bordered hover size="sm" className='text-center'>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Shipment ID</th>
                                <th>Cargo Type</th>
                                <th>Box Qty</th>
                                <th>Created Date</th>
                                <th>Total Weight</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shipments.map((s,i)=>(
                                <tr key={s._id}>
                                    <td>{i+1}</td>
                                    <td>{s.shipmentId}</td>
                                    <td>{s.cargoType.toUpperCase()}</td>
                                    <td>{s.boxQty}</td>
                                    <td>{new Date(s.createdAt).toLocaleDateString('en-US',{day: '2-digit', month: 'short'})}</td>
                                    <td>{s.totalWeight.toFixed(2)}</td>
                                    <td>
                                        <Button
                                            variant='outline-danger'
                                            className='m-0 py-0 px-2'
                                            size='sm'
                                            onClick={()=>dispatch(removeFromCutOffList(s._id))}
                                        ><MdClose /></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                     <Table striped bordered hover size="sm" style={{fontSize: '14px'}} >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Summary</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {shipments.map((s,i)=>(
                                <tr key={s._id}>
                                    <td>{i+1}</td>
                                    <td>
                                        <div>Shipment ID - {s.shipmentId}</div>
                                        <div>Cargo Type - {s.cargoType.toUpperCase()}</div>
                                        <div>Box Qty - {s.boxQty}</div>
                                        <div>Created Date - {new Date(s.createdAt).toLocaleDateString('en-US',{day: '2-digit', month: 'short'})}</div>
                                        <div>Total Weight - {s.totalWeight.toFixed(2)} lbs</div>
                                    </td>
                                    <td>
                                        <Button
                                            variant='outline-danger'
                                            className='m-0 py-0 px-2'
                                            size='sm'
                                            onClick={()=>dispatch(removeFromCutOffList(s._id))}
                                        ><MdClose /></Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    }
                </>}
            </div>
        </div>
    )
}

export default CutOffCreateScreen