import './landingheader.css'
import { useState } from 'react'
import { MdViewHeadline } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'


const LandingHeader = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [menuToggle, setMenuToggle] = useState(false)
    const Menu = () => (
        <>
            <a href="#welcome">{t('Home')}</a>
            <a href="#tracking">{t('Tracking')}</a>
            <a href="#services">{t('Services')}</a>
            {/* <a href="#boxsizes">{t('Box Sizes')}</a> */}
            <a href="#aboutus">{t('About Us')}</a>
            <a href="#team">{t('Contact')}</a>
            <a href="#/" onClick={()=>navigate('/gallery')}>Gallery</a>
        </>
    )
    return (
        <>
            <div className='landing-header-main'>
                <div className='landing-header-bigscreen'>
                    <Menu />
                </div>
            </div>

            <div className='landing-header-smallscreen'>
                <div onClick={()=>setMenuToggle(!menuToggle)} className='small-menu-button'>Menu <MdViewHeadline /></div>
                { menuToggle && 
                <div className='small-menu-wrapper '>
                    <div className='small-menu scale-up-center' onClick={()=>setMenuToggle(false)}><Menu /></div>
                </div>}
            </div>
        
        </>
    )
}

export default LandingHeader