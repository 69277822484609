import React, { useRef } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap'
import styles from './customercreateshipmentscreen.module.css'
import { useSelector } from 'react-redux'
import {QRCodeSVG} from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import PrintBoxLabel from './PrintBoxLabel';

const LabelAndTrackingUpdate = () => {
    const componentRef = useRef()
    const { shipment } = useSelector(state=>state.shipment.update)
    return (
        <div>
            {/* <div className='fs-3'>Shipment Created <MdDownloadDone color='green' size={30} className='pb-1' /></div> */}

            <div className='d-flex justify-content-between my-4'>
                <div className='fs-3'>Shipment Id - {shipment && shipment.shipmentId}</div>
                <div>
                    <ReactToPrint
                        trigger={() => <Button variant='link' className='fs-5'>Print all labels</Button>}
                        content={() => componentRef.current}
                    />
                </div>
            </div>
            


            <div>
                <Table striped hover size="sm">
                    <thead>
                        <tr>
                            <th>Box #</th>
                            <th>Box Id</th>
                            <th>Detail</th>
                            <th>Item Qty</th>
                            <th>Print Label</th>
                        </tr>
                    </thead>
                    <tbody>
                        {shipment && shipment.boxes.map((b,i)=>(
                            <tr key={i}>
                                <td>Box {i+1}</td>
                                <td>{b.boxId}</td>
                                <td>
                                    <div style={{fontSize: '15px'}}>
                                        <div>{b.length}" x {b.width}" x {b.height}"</div>
                                        <div>{b.actualWeight} lb</div>
                                    </div>
                                </td>
                                <td>{b.items.length}</td>
                                <td>
                                    <PrintBoxLabel 
                                        box={b}
                                        boxNo={i+1} 
                                        shipmentId={shipment.shipmentId} 
                                        boxQty={shipment.boxes.length} 
                                        sender={shipment.sender}
                                        receiver={shipment.receiver}
                                        />
                                </td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
         
            <div className={`${styles.label_main} d-none d-print-flex`} ref={componentRef}>
                {shipment && shipment.boxes.map((b,i)=>(
                    <React.Fragment key={b.boxId}>
                        <div className={`${styles.label}`}>                            
                            <Col sm={12}>
                                <div className='text-center fs-3 fw-bold py-2 border-bottom border-dark'>NYSS EXPRESS, US. INC</div>                                
                            </Col>
                            <Row className='border-bottom border-dark mx-0'>
                                <Col className='border-end border-dark'>
                                    <div className='fs-2 fw-bold text-center align-middle pt-2'>{shipment.shipmentId}</div>
                                    <div className='fs-2 fw-bold text-center align-middle'>{b.boxId}</div>
                                </Col>
                                <Col className='border-end border-dark fs-5'>
                                    <div className='mb-2'>AIR</div>
                                    <div className='mb-2'>{b.length} x {b.width} x {b.height} inches</div>
                                    <div className='mb-2'>{b.actualWeight} lbs</div>
                                    <div>Box {i+1} of {shipment.boxes.length}</div>
                                </Col>
                                <Col>
                                    <div className='p-3'>
                                        <QRCodeSVG value={shipment.shipmentId+b.boxId} />
                                    </div>
                                </Col>
                            </Row>

                            <Row className='px-3' style={{fontSize: '15px'}}>
                                <Col className='border-end border-dark py-2'>
                                    <div className='text-center fs-5'>Sender</div>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Name</Col>
                                        <Col sm={9}>{shipment.sender.name}</Col>
                                    </Row>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Address</Col>
                                        <Col sm={9}>{shipment.sender.address.street}, {shipment.sender.address.city}, {shipment.sender.address.state}, {shipment.sender.address.postalcode}, {shipment.sender.address.country}</Col>
                                    </Row>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Phone</Col>
                                        <Col sm={9}>{shipment.sender.phonenumber1} {shipment.sender.phonenumber2 && ", " + shipment.sender.phonenumber2}</Col>
                                    </Row>
                                    {shipment.sender.email &&
                                    <Row className='d-flex'>
                                        <Col sm={3}>Email</Col>
                                        <Col sm={9}>{shipment.sender.email}</Col>
                                    </Row>
                                    }
                                </Col>
                                <Col className='py-2'>
                                    <div className='text-center fs-5'>Receiver</div>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Name</Col>
                                        <Col sm={9}>{shipment.receiver.name}</Col>
                                    </Row>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Address</Col>
                                        <Col sm={9}>{shipment.receiver.address.street}, {shipment.receiver.address.city}, {shipment.receiver.address.state}, {shipment.receiver.address.postalcode}, {shipment.receiver.address.country}</Col>
                                    </Row>
                                    <Row className='d-flex'>
                                        <Col sm={3}>Phone</Col>
                                        <Col sm={9}>{shipment.receiver.phonenumber1} {shipment.receiver.phonenumber2 && ", " + shipment.receiver.phonenumber2}</Col>
                                    </Row>
                                    {shipment.receiver.email &&
                                    <Row className='d-flex'>
                                        <Col sm={3}>Email</Col>
                                        <Col sm={9}>{shipment.receiver.email}</Col>
                                    </Row>
                                    }
                                </Col>
                            </Row>                            
                        </div>
                        <div className={styles.page_break} />
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}

export default LabelAndTrackingUpdate

