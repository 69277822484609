import React from 'react';
//import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'react-bootstrap/dist/react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store } from './store';
import './i18n'
import { NYSSWave } from './Components';


//React 17
// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//        <React.Suspense fallback={<NYSSWave />}>
//          <App />
//          <ToastContainer position="top-center" hideProgressBar autoClose={2000} theme="dark" />
//        </React.Suspense>   
//      </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// React 18
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <React.Suspense fallback={<NYSSWave />}>
          <App />
        <ToastContainer position="top-center" hideProgressBar autoClose={2000} theme="dark" />
      </React.Suspense>   
    </Provider>
  </>
);

//set HTTPS=true&&
// <ToastContainer 
//         position="top-center"
//         autoClose={2000}
//         hideProgressBar={false}
//         newestOnTop={false}
//         closeOnClick
//         rtl={false}
//         pauseOnFocusLoss
//         draggable={false}
//         pauseOnHover={false}
//         theme="colored"
//       />