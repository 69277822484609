import styles from './customercreateshipmentscreen.module.css'
import { Row, Col, Card, Form, OverlayTrigger, Button } from 'react-bootstrap'
import { useState } from 'react'
import { addDays } from '../../../helpers/dateHelper'
import freePickupLocations from '../../../data/pickupLocations'
import { toast } from 'react-toastify'

const OtherServices = ({boxes, insurance, setInsurance, pickup, setPickup, noItem, sender, pickupData, setPickupData, otherCharges, setOtherCharges, update}) => {
    const [ senderAdd, setSenderAdd ] = useState(false)
 
    return (
        <div className={styles.other_services_main}>
            <h3>Additional Services </h3>
            <small>(click next if you want no additional services)</small>
            <Row className='mt-3'>
                <Col md={6} className='mb-3' >
                    <Card className='border-warning'>
                        <Card.Header>Insurance</Card.Header>
                        <Card.Body>
                            <Card.Text>10% of declared value</Card.Text>
                            {noItem &&
                            <Card.Text className='text-danger'>
                                {`No item in the ${boxes.length === 1 ? 'box' : 'boxes'}`}
                            </Card.Text>
                            }
                            <Form.Select 
                                size='sm' 
                                defaultValue={insurance} 
                                onChange={({target})=>setInsurance(target.value)} 
                                disabled={noItem}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                                
                            </Form.Select>
                        </Card.Body>
                    </Card>
                </Col>
                 <Col md={6} className='mb-3'>
                    <Card className='border-warning'>
                        <Card.Header>Schedule Pickup</Card.Header>
                        <Card.Header className='ps-1'>
                            <OverlayTrigger trigger="click" placement="right" rootClose={true} overlay={freePickupLocations}>
                                <Button variant="link" style={{height: '25px', paddingTop: '2px', marginLeft: '0px'}}>Free Pickup Available Areas</Button>
                            </OverlayTrigger>
                        </Card.Header>
                        <Card.Body>
                            {/* <Card.Text>We can pick up your boxes if your location is near our service area.</Card.Text> */}
                            <Card.Subtitle className="mb-2 text-muted">If your location is not in "Free Pickup Areas List", rate start at $ 1.50 per mile back and forth. </Card.Subtitle>
                            <Card.Text className="mb-2 text-danger">Total weight of the shipment must be 50 lbs or more</Card.Text>
                            
                            <Form.Select 
                                size='sm' 
                                value={pickup} 
                                disabled={(boxes.reduce((acc, b) => acc + Number(b.weight), 0)) < 50}
                                onChange={({target})=>{
                                setPickup(target.value)
                                if(pickup === 'yes'){
                                    setPickupData({ date:'',time: '',address: '',})
                                    setSenderAdd(false)
                                }
                            }}>
                                <option value="no">No</option>
                                <option value="yes">Yes</option>
                                
                            </Form.Select>
                            {pickup === 'yes' &&
                            <>
                                <Row className='my-4'>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Select Date</Form.Label>
                                            <Form.Control 
                                                type='date'
                                                min={new Date().toISOString().substring(0,10)}
                                                max={addDays(new Date(), 7)}
                                                defaultValue={pickupData.date.substring(0,10)}
                                                onChange={(e)=>{
                                                    if(new Date(e.target.value).getDay() === 0){                                                        
                                                        e.preventDefault()
                                                        toast.warning("We can't do schedule pickup on Sunday. Please select other day")
                                                        setPickupData({...pickupData, date:''})
                                                    } else {
                                                        setPickupData({...pickupData, date: e.target.value})
                                                    }                                                    
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label>Select Time <span className='text-danger' style={{fontSize:'13px'}}>9am - 5pm</span></Form.Label>
                                            <Form.Control 
                                                type='time'
                                                min="09:00" 
                                                max="14:00"
                                                value={pickupData.time}
                                                onChange={({target})=>setPickupData({...pickupData, time: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className='mt-4'>
                                    <Col>
                                        <Form.Group>
                                            <div className='d-flex'>
                                                <Form.Label className='fs-5'>Location</Form.Label>
                                                <div className={styles.pickup_checkbox}>
                                                    <input
                                                        type='checkbox'
                                                        id='senderaddress'                                                        
                                                        onChange={()=>{
                                                            setSenderAdd(prev=>!prev)
                                                            if(senderAdd === false){
                                                                setPickupData({...pickupData, street: sender.street, city: sender.city, state: sender.state, country: sender.country, postalcode: sender.postalcode}) 
                                                            } else {
                                                                setPickupData({...pickupData, street: '', city: '', state: '', country: '', postalcode: ''}) 
                                                            }
                                                        }}
                                                    />
                                                    <label htmlFor='senderaddress'>Sender Address</label>
                                                </div>
                                            </div>                                            
                                        </Form.Group>
                                    </Col>
                                </Row>
                                
                                <Form.Group>
                                    <Form.Label className='mb-0'>Address</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.street}
                                        onChange={({target})=>setPickupData({...pickupData, street: target.value})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='mb-0'>City</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.city}
                                        onChange={({target})=>setPickupData({...pickupData, city: target.value})}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label className='mb-0'>State/Province</Form.Label>
                                    <Form.Control 
                                        type='text'
                                        readOnly={senderAdd}
                                        value={pickupData.state}
                                        onChange={({target})=>setPickupData({...pickupData, state: target.value})}
                                    />
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className='mb-0'>Postal Code</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                readOnly={senderAdd}
                                                value={pickupData.postalcode}
                                                onChange={({target})=>setPickupData({...pickupData, postalcode: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group>
                                            <Form.Label className='mb-0'>Country</Form.Label>
                                            <Form.Control 
                                                type='text'
                                                readOnly={senderAdd}
                                                value={pickupData.country}
                                                onChange={({target})=>setPickupData({...pickupData, country: target.value})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default OtherServices