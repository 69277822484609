import Popover from 'react-bootstrap/Popover';

const locations = [
    'Astoria',
    'Auburndale',
    'Bayside',
    'Bellerose',
    'Briarwood',
    'CollegePoint',
    'Corona',
    'Douglaston-LittleNeck',
    'EastElmhurst',
    'Elmhurst',
    'FloralPark',
    'Flushing',
    'ForestHills',
    'FreshMeadows',
    'FreshPond',
    'GlenOaks',
    'Glendale',
    'JacksonHeights',
    'JacksonHeights',
    'KewGardens',
    'KewGardensHills',
    'LongIslandCity',
    'Maspeth',
    'MiddleVillage',
    'RegoPark',
    'Ridgewood',
    'Sunnyside',
    'Whitestone',
    'Woodside',
]

const freePickupLocations = (
    <Popover id="popover-basic">
        <Popover.Header as="h3">Service Areas</Popover.Header>
        <Popover.Body>
            {locations.map((l,i)=>(
                <div key={i}>{l}</div>
            ))}
        </Popover.Body>
    </Popover>
)

export default freePickupLocations