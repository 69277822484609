//import styles from './customercreateshipmentscreen.module.css'
import { useSelector } from "react-redux"
import { Alert, Spinner, Card, ListGroup, Form, Table, Row, Col } from "react-bootstrap"

const OtherServices = ({cargoType, boxes, note, setNote, otherCharges, discount, setDiscount}) => {

    const {rateLoading, rateError, rateMessage, rate} = useSelector(state=>state.rate.rate)

    return (
        <div>
            <h3>Shipping Rate & Charges {rateLoading && <Spinner />}</h3>
            {rateError && <Alert>{rateMessage}</Alert>}
            {/* <div className={styles.shipping_rate_main}>
                <h4>Shipment Summary</h4>

            </div> */}

            <Card className='mb-5'>
                <Card.Header as="h5">Shipment Summary</Card.Header>
                <Card.Body>
                    <ListGroup variant="flush">
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Cargo type</div>
                            <div>{cargoType.charAt(0).toUpperCase() + cargoType.slice(1)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Total box</div>
                            <div>{boxes.length}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Total Declared Value </div>
                            <div>$ {(boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (Number(itm.price)*Number(itm.unit)), 0)), 0)).toFixed(2)}</div>
                        </ListGroup.Item>
                        {/* <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Total weight</div>
                            <div>{(boxes.reduce((acc, b) => acc + Number(b.weight), 0)).toFixed(2)} lb</div>
                        </ListGroup.Item> */}
                    </ListGroup>
                </Card.Body>
            </Card>
             <Card className='mb-5'>
                <Card.Header as="h5"><span className="text-danger">Estimated</span> Charges Summary</Card.Header>
                <Card.Body>
                    {rate && <>
                    <ListGroup variant="flush">
                        <ListGroup.Item >
                            <div className='d-flex justify-content-between'>
                                <div className="w-50">
                                    <div>Other Charges Total</div>
                                    <div style={{fontSize: '14px'}} className="bg-warning-subtle px-2 rounded ms-4">
                                        {otherCharges.map((oth, i) => (
                                            <Row key={i}>
                                                <Col xs={6}>{oth.name}</Col>
                                                <Col xs={2}>$ {Number(oth.amount).toFixed(2)}</Col>
                                            </Row>
                                        ))}
                                    </div>
                                </div>
                                <div>
                                    $ {(otherCharges.reduce((acc, c) => acc + Number(c.amount), 0)).toFixed(2)}
                                </div>
                            </div> 
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex flex-column'>
                            <div className="mb-3">Boxes </div>
                            {/* <div>{(boxes.reduce((acc, b) => acc + Number(b.weight), 0) * rate).toFixed(2)} $</div> */}
                            <div style={{fontSize: '14px'}}>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th>Box #</th>
                                            <th>Measurements</th>
                                            <th>Weight</th>
                                            <th style={{width: '400px'}}>Rate</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rate.boxes.map((b, i)=>(
                                            <tr key={i}>
                                                <td>Box {i+1}</td>
                                                <td>{b.length} x {b.width} x {b.height}</td>
                                                <td><div className="d-flex flex-column">
                                                    {b.volumeWeight && <div>Volume Weight : <span className="bg-warning-subtle px-2">{b.weight.toFixed(2)} lb</span></div>}
                                                    <div>Actual Weight : <span className="bg-warning-subtle px-2">{b.actualWeight.toFixed(2)} lb </span></div>
                                                </div></td>
                                                <td><div className="d-flex flex-column">
                                                    <div>Applied Rate : <span className="bg-warning-subtle px-2">$ {b.appliedRate.toFixed(2)} </span></div>
                                                    <div>Amount  : <span className="bg-warning-subtle px-2">$ {b.amount.toFixed(2)} </span></div>
                                                    {b.amount === 0 && <div className="text-danger">There is error computing rate and we will update you when we receive your shipment in NYSS.</div>}
                                                </div></td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Total Weight</div>
                            <div>{rate.totalWeight.toFixed(2)} lb</div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Applied Rate on Total Weight (per lb)</div>
                            <div>$ {rate.totalRate.toFixed(2)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between'>
                            <div>Total Amount for All Boxes <span className="text-danger">
                                (saved $ {(rate.boxes.reduce((acc, b) => acc + b.amount, 0) - rate.totalAmount).toFixed(2)})
                                </span></div>
                            <div>$ {rate.totalAmount.toFixed(2)}</div>
                        </ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between bg-danger-subtle py-1'>
                            <div className="pt-1">Discount</div>
                            <div className="d-flex" >
                                <div className="fs-5 me-2">$</div>
                                <div className="m-0 p-0">
                                    <Form.Control
                                        type='number'
                                        style={{width: '100px', fontSize: '17px'}}
                                        className="py-0 my-0 text-end"
                                        size='sm'
                                        min={0}
                                        max={rate.totalAmount}
                                        value={discount}
                                        onChange={({target})=>setDiscount(target.value)}
                                    />
                                </div>
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="m-0 p-0"><div className="border-bottom m-0 py-0 pt-2" /></ListGroup.Item>
                        <ListGroup.Item className='d-flex justify-content-between fs-4'>
                            <div>Grand Total</div>
                            <div className="fw-bold">$ {(((otherCharges.reduce((acc, c) => acc + Number(c.amount), 0))+rate.totalAmount)-discount).toFixed(2)}</div>
                        </ListGroup.Item>
                    </ListGroup>
                    </>}
                </Card.Body>
            </Card>

                <Card className='mb-5'>
                <Card.Header as="h5">Note</Card.Header>                
                <Card.Body>
                    
                    <Form.Control 
                        as='textarea'
                        value={note}
                        maxLength={200}
                        onChange={({target})=>setNote(target.value)}
                    />
                    <Card.Subtitle className="my-2 text-muted">{200-note.length} characters left</Card.Subtitle>
                </Card.Body>
            </Card>
        </div>
    )
}

export default OtherServices