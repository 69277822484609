import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import staffServices from './staffService'
import {toast} from 'react-toastify'

// Get user from localStorage
const staff = JSON.parse(localStorage.getItem('staff'))

const initialState = {    
    auth: {
        staff: staff ? staff : null,
        authLoading: false,
        authError: false,
        authSuccess: false,
        authMessage: ''
    },
    add: {
        staff: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        staffList: [],
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    update: {
        staff: null,
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
        updateMessage: ''
    },
    delete: {
        staff: null,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    },
    myAccount: {
        myAccount: null,
        myAccountLoading: false,
        myAccountSuccess: false,
        myAccountError: false,
        myAccountMessage: ''
    },
    password: {
        passwordLoading: false,
        passwordSuccess: false,
        passwordError: false,
        passwordMessage: ''
    }
}

//Register user
export const register = createAsyncThunk('Staff_Register', async (newStaff, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.register(newStaff,token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//Login user
export const login = createAsyncThunk('Staff_Login', async (credential, thunkAPI) => {
    try {
        return await staffServices.login(credential)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Get staff account list
export const getAccounts = createAsyncThunk('Staff_Account_List', async (search='', thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.getAccounts(search, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Update staff account
export const updateAccount = createAsyncThunk('Staff_Account_Update', async ({staffId, updatedStaff}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.updateAccount(staffId, updatedStaff, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})


//Delete staff account
export const deleteAccount = createAsyncThunk('Staff_Account_Delete', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.deleteAccount(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//Logout user
export const logout = createAsyncThunk('Staff_Logout', async () => {
    await staffServices.logout()
})

//get my account staff
export const getMyAccount = createAsyncThunk('Staff_Account_My', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.getMyAccount(id, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

//change my account password
export const changePasswordAccount = createAsyncThunk('Staff_Account_My_Password', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await staffServices.changePasswordAccount(data, token)
    } catch (error) {
        const message = (error.response && error.response.data && error.response.data.message ) || error.message || error.toString()
        return thunkAPI.rejectWithValue(message)
    }
})

export const authSlice = createSlice({
    name: 'Staff',
    initialState,
    reducers: {
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
        deleteReset: (state) => {
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        },
        updateReset: (state) => {
            state.update.updateLoading = false
            state.update.updateError = false
            state.update.updateSuccess = false
            state.update.updateMessage = ''
        },
        passwordReset: (state) => {
            state.password.passwordLoading = false
            state.password.passwordError = false
            state.password.passwordSuccess = false
            state.password.passwordMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        //add-create a  staff account
        .addCase(register.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
        }) 
        .addCase(register.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = true
            toast.success('New staff account added succesfully')
        })  
        .addCase(register.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = true
            state.add.addSuccess = false
            state.add.addMessage = action.payload
        })
        // Login/auth staff
        .addCase(login.pending, (state)=>{
            state.auth.authLoading = true
            state.auth.authSuccess = false
            state.auth.authError = false
        }) 
        .addCase(login.fulfilled, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authSuccess = true
            state.auth.authError = false
            state.auth.staff = action.payload
        })  
        .addCase(login.rejected, (state, action)=>{
            state.auth.authLoading = false
            state.auth.authError = true
            state.auth.authSuccess = false
            state.auth.authMessage = action.payload
            state.auth.staff = null
        })
        // Staff account list
        .addCase(getAccounts.pending, (state)=>{
            state.list.listLoading = true
            state.list.listSuccess = false
            state.list.listError = false
        }) 
        .addCase(getAccounts.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.staffList = action.payload
        })  
        .addCase(getAccounts.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.staffList = null
        })
        // Update staff account
        .addCase(updateAccount.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateSuccess = false
            state.update.updateError = false
        }) 
        .addCase(updateAccount.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            state.update.updateError = false
            toast.success('Staff account updated successfully.')
        })  
        .addCase(updateAccount.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateSuccess = false
            state.update.updateMessage = action.payload
            state.update.staff = null
        })
        // Delete staff account
        .addCase(deleteAccount.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteSuccess = false
            state.delete.deleteError = false
        }) 
        .addCase(deleteAccount.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            state.delete.deleteError = false
            toast.success('Staff account deleted successfully.')
            const index = state.list.staffList.findIndex(e => e._id === action.payload)
            state.list.staffList.splice(index,1)
        })  
        .addCase(deleteAccount.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
            state.delete.staff = null
        })
        //logout staff account
        .addCase(logout.fulfilled, (state)=>{
            state.auth.authLoading = false
            state.auth.authError = false
            state.auth.authSuccess = false
            state.auth.authMessage = ""
            state.auth.staff = null
        })
        // my account staff
        .addCase(getMyAccount.pending, (state)=>{
            state.myAccount.myAccountLoading = true
            state.myAccount.myAccountSuccess = false
            state.myAccount.myAccountError = false
        }) 
        .addCase(getMyAccount.fulfilled, (state, action)=>{
            state.myAccount.myAccountLoading = false
            state.myAccount.myAccountSuccess = true
            state.myAccount.myAccountError = false
            state.myAccount.myAccountMessage = ''
            state.myAccount.myAccount = action.payload
        })  
        .addCase(getMyAccount.rejected, (state, action)=>{
            state.myAccount.myAccountLoading = false
            state.myAccount.myAccountError = true
            state.myAccount.myAccountSuccess = false
            state.myAccount.myAccountMessage = action.payload
            state.myAccount.myAccount = null
        })
         //change my account password
        .addCase(changePasswordAccount.pending, (state)=>{
            state.password.passwordLoading = true
            state.password.passwordSuccess = false
            state.password.passwordError = false
        }) 
        .addCase(changePasswordAccount.fulfilled, (state, action)=>{
            state.password.passwordLoading = false
            state.password.passwordSuccess = true
            state.password.passwordError = false
            state.password.passwordMessage = ''
        })  
        .addCase(changePasswordAccount.rejected, (state, action)=>{
            state.password.passwordLoading = false
            state.password.passwordError = true
            state.password.passwordSuccess = false
            state.password.passwordMessage = action.payload
        })
    },
})


export const { addReset, updateReset, deleteReset, passwordReset } = authSlice.actions
export default authSlice.reducer