import styles from './components.module.css'

const NYSSWave = () => {
  return (
    <div className={styles.loadingSpinnerContainer}>
      <div className={styles.header_name}>
        <h2>NYSS</h2>
        <h2>NYSS</h2>
      </div>
    </div>
  )
}

export default NYSSWave