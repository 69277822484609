import { Pagination } from "react-bootstrap"
//import { LinkContainer } from "react-router-bootstrap"
import { useNavigate } from "react-router-dom"
import styles from './components.module.css'

const MyPagination = ({path, pages, page}) => {
    const navigate = useNavigate()

    if(!pages || pages === null) {return (<></>)} else
    return (
        <div className="float-end my_pagination">
        {pages <= 5 ?
            <>
                <Pagination size='sm'>
                    {[...Array(pages).keys()].map(p=>(
                        <Pagination.Item key={p+1} active={p+1 === page} onClick={()=> navigate(`/${path}/page/${p+1}`)}>
                            {p+1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </>
            :
            <>
                <Pagination size='sm' className={`${styles.paginate_buttons} mx-1`}>
                    <Pagination.First disabled={page === 1} onClick={()=> navigate(`/${path}/page/1`)}/>
                    <Pagination.Prev disabled={page === 1} onClick={()=> navigate(`/${path}/page/${page-1}`)} />
                    <Pagination.Item active>{page}</Pagination.Item>                    
                    <Pagination.Next disabled={page === pages} onClick={()=> navigate(`/${path}/page/${page+1}`)}/>
                    <Pagination.Last disabled={page === pages} onClick={()=> navigate(`/${path}/page/${pages}`)} />
                </Pagination>
                {/* <Pagination size='sm' className={`${styles.paginate_buttons} mx-1`}>
                    <Pagination.First disabled={page === 3} onClick={()=> navigate(`/${path}/page/1`)}/>
                    <Pagination.Prev disabled={page === 3} onClick={()=> navigate(`/${path}/page/${page-1}`)} />
                    {(page === 2 || page === 3) && <Pagination.Item onClick={()=> navigate(`/${path}/page/1`)} >{1}</Pagination.Item>}
                    {page === 3 && <Pagination.Item onClick={()=> navigate(`/${path}/page/2`)}>{2}</Pagination.Item>}
                    {page > 3 && <Pagination.Item >{page-3}</Pagination.Item>}
                    {page > 3 && <Pagination.Ellipsis />}
                    <Pagination.Item active>{page}</Pagination.Item>
                    {page <= (pages-3) && <Pagination.Ellipsis />}
                    {page <= (pages-3) && <Pagination.Item >{page+3}</Pagination.Item>}
                    {page === (pages-2) && <Pagination.Item >{pages-1}</Pagination.Item>}
                    {page === (pages-2) && <Pagination.Item >{pages}</Pagination.Item>}
                    <Pagination.Next disabled={page === (pages-2)} onClick={()=> navigate(`/${path}/page/${page+1}`)}/>
                    <Pagination.Last disabled={page === (pages-2)} onClick={()=> navigate(`/${path}/page/${pages}`)} />
                </Pagination> */}
            </>
        }
        </div>
    )
}

export default MyPagination