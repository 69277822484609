import axios from 'axios'

const API_URL = '/api/customer/auth'

//register customer
const register = async (customerData) => {
    const response = await axios.post(API_URL+'/register', customerData)

    if(response.data) {
        localStorage.setItem('customer', JSON.stringify(response.data))
        localStorage.removeItem('requester')
    }

    return response.data
}

//request register customer
const requestRegister = async (email) => {
    const response = await axios.post(API_URL+'/requestregister', {email})

    return response.data
}

//request register customer
const registerConfirmCode = async (data) => {
    const response = await axios.put(API_URL+'/requestregister', data)
    if(response.data) {
        localStorage.setItem('requester', JSON.stringify(response.data))
    }
    return response.data
}

//login customer
const login = async (userData) => {
    const response = await axios.post(API_URL + '/login', userData)

    if(response.data) {
        localStorage.setItem('customer', JSON.stringify(response.data))
    }

    return response.data
}

//forgot password customer
const forgotPasswordCustomer = async (email) => {
    const response = await axios.post(API_URL + '/forgot', {email})
    return response.data
}

//confirm password reset code customer
const confirmPasswordResetCodeCustomer = async (data) => {
    const response = await axios.put(API_URL + '/forgot', data)
    return response.data
}

//change password customer
const changePasswordCustomer = async (data) => {
    const response = await axios.post(API_URL + '/changepassword', data)
    return response.data
}

//delete a receiver address
const deleteReceiver = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(`/api/customeraccount/address/${data.customerId}/${data.addressId}`, config)
    return response.data
}

//delete a receiver address
const addReceiver = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(`/api/customeraccount/address/${data.customerId}`, data.receiverAddress, config)
    return response.data
}

//update account Detail
const updateAccountDetail = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL + '/account', data, config)
    return response.data
}


//logout customer
const logout = async () => {
    localStorage.removeItem('customer')
    return true
}

const customerServices = {
    requestRegister,
    registerConfirmCode,
    register, 
    logout,
    forgotPasswordCustomer,
    confirmPasswordResetCodeCustomer,
    changePasswordCustomer,
    login,
    deleteReceiver,
    addReceiver,
    updateAccountDetail,
}

export default customerServices