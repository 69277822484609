import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { deleteMessage, getMessages } from '../../features/system/systemSlice'
import { MySpinner, AlertMessage} from '../../Components'
import { MdEmail, MdPerson, MdComment } from 'react-icons/md'

const StaffContactUsListScreen = () => {
    const dispatch = useDispatch()
    const [ search, setSearch ] = useState('')
    const { messages, messagesLoading, messagesSuccess, messagesError, messagesMessage, deleteLoading, deleteMessage:delMsg } = useSelector(state=>state.system.messages)

    useEffect(()=>{
        dispatch(getMessages(''))
    },[dispatch])


    return (
        <div>

            {messagesLoading && <MySpinner />}
            {deleteLoading && <MySpinner />}
            {messagesError && <AlertMessage msg={messagesMessage} type='danger' />}
            {delMsg !== '' && <AlertMessage msg={delMsg} type='danger' />}
            <div className='mb-3'>
                <Form.Control 
                    type='text'
                    value={search}
                    placeholder='Search name, email and message'
                    onChange={({target})=>setSearch(target.value)}
                    onKeyDown={(e)=>{
                        if(e.key === 'Enter'){
                            if(e.target.value === ''){
                                dispatch(getMessages(''))
                            }else{
                                dispatch(getMessages(e.target.value))
                            }
                        }
                    }}
                />
            </div>
            <Row>
            {messagesSuccess && messages.map(m=>(
                <Col md={6} key={m._id} >
                    <div className='border p-2 rounded my-2 shadow' style={{minHeight: '150px'}}>
                        <div className='border-bottom pb-2'>  
                            <Row>
                                <Col xs={10}>
                                    <Row>
                                        <Col md={6}>
                                            <div className='me-4'><MdPerson size={23} /> {m.name}</div> 
                                        </Col>
                                        <Col md={6}>
                                            <div><MdEmail size={23} /> {m.email}</div>
                                        </Col>
                                    </Row>
                                    
                                    
                                </Col>
                                <Col xs={2} className='d-flex justify-content-end'>
                                    <div className='ms-auto'><Button 
                                        className='m-0 py-0 px-2'
                                        variant='danger'
                                        size='sm'
                                        onClick={()=>dispatch(deleteMessage(m._id))}
                                    >x</Button></div>
                                </Col>
                            </Row>
                            
                            
                        </div>
                        <div className='mt-2'><MdComment size={23} /> {m.message}</div>
                    </div>
                </Col>
            ))}
            </Row>
        </div>
    )
}

export default StaffContactUsListScreen