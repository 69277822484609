import './App.css';
import {Header, CustomerPrivateRoutes} from './Common'
import {BrowserRouter, Routes, Route, Navigate} from 'react-router-dom'
import {
  CustomerLoginScreen, 
  CustomerRegisterScreen, 
  CustomerRegisterRequestScreen,
  CustomerShipmentScreen,
  CustomerCreateShipmentScreen,
  CustomerUpdateShipmentScreen,
  CustomerShipemntDetailScreen,
  PackingListScreen,
  CustomerAccountScreen,
  CustomerOSItemListScreen,
} from './Screens/CustomerScreens';

import {
  StaffPrivateRoutes,
  StaffLoginScreen,
  StaffAccountListScreen,
  StaffAccountAddScreen,
  StaffAccountUpdateScreen,
  StaffShipmentListScreen,
  StaffShipmentFilterScreen,
  StaffShipmentDetailScreen,
  StaffShipmentCreateScreen,
  StaffShipmentUpdateScreen,
  CutOffListScreen,
  CutOffCreateScreen,
  StaffRateScreen,
  StaffCustomerAccountListScreen,  
  StaffItemListScreen,
  StaffCustomerAccountCreateScreen,
  StaffCustomerAccountDetailScreen,
  StaffPickupListScreen,
  CutOffDetailScreen,
  CutOffAddShipmentScreen,
  StaffMyAccountScreen,
  StaffContactUsListScreen,
  StaffFAQListScreen,
  StaffFAQCreateScreen,
  StaffChargesNameListScreen,
  StaffBoxsizeListScreen,
  OSItemListScreen,
  OSItemShippedListScreen,
  StaffGalleryScreen,
} from './Screens/StaffScreens';
import Landing from './Screens/Landing/Landing';
import FAQ from './Screens/Landing/FAQ/FAQ';
import GalleryPage from './Screens/Landing/GalleryPage/GalleryPage';
import NotFoundPage from './Common/NotFoundPage';
import StaffCustomerAccountUpdateScreen from './Screens/StaffScreens/StaffCustomerAccountUpdateScreen';
import { IdleTimerContainer, TrackingDetailScreen, ContactUsScreen, PrivacyPolicy, Terms } from './Components';
import { useSelector } from 'react-redux';
import { DeliverWithPOD, PickupScan, ReceiveScan, ShipScan } from './Screens/Scanning';


//app
function App() {
  const { customer } = useSelector(state=>state.customer.auth)
  const { staff } = useSelector(state=>state.staff.auth)
  return (
    <div className='app'>
      <BrowserRouter>
        <Header />
        {customer && <IdleTimerContainer />}
        {staff && <IdleTimerContainer />}
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/faq' element={<FAQ />} />
          <Route path='/gallery' element={<GalleryPage />} />
          <Route path='/contact' element={<ContactUsScreen />} />
          <Route path='/privacy' element={<PrivacyPolicy />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/login' element={customer ? <Navigate to='/shipments' /> : <CustomerLoginScreen /> }/>
          <Route path='/register' element={customer ? <Navigate to='/shipments' /> : <CustomerRegisterScreen />} />
          <Route path='/request' element={customer ? <Navigate to='/shipments' /> : <CustomerRegisterRequestScreen />} />
          <Route path='/staff' element={<StaffLoginScreen />} />
          <Route path='/shipment/tracking/:shipmentId' element={<TrackingDetailScreen />} />
            
            {/* Staff Private Routes */}
            <Route element={<StaffPrivateRoutes />}>  
              <Route path='staff/shipments'>
                <Route index element={<StaffShipmentListScreen />} />
                <Route path='filter' element={<StaffShipmentFilterScreen />} />
                <Route path='create' element={<StaffShipmentCreateScreen />} />
                <Route path='update' element={<StaffShipmentUpdateScreen />} />
                <Route path='pickup' element={<StaffPickupListScreen />} />
                <Route path='search/:searchKeyword' element={<StaffShipmentListScreen />} />
                <Route path='page/:pageNumber' element={<StaffShipmentListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<StaffShipmentListScreen />} />
                <Route path=':id' element={<StaffShipmentDetailScreen />} />
              </Route>
              
              <Route path='staff/rate' element={staff && staff.isAdmin ? <StaffRateScreen /> : <Navigate to='/staff' />} />
              
              <Route path='staff/customers' >
                <Route index element={<StaffCustomerAccountListScreen />} />
                <Route path='search/:searchKeyword' element={<StaffCustomerAccountListScreen />} />
                <Route path='page/:pageNumber' element={<StaffCustomerAccountListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<StaffCustomerAccountListScreen />} />
                <Route path='add' element={<StaffCustomerAccountCreateScreen />} />
                <Route path='update/:id' element={<StaffCustomerAccountUpdateScreen />} />
                <Route path=':id' element={<StaffCustomerAccountDetailScreen />} />
              </Route>

              <Route path='staff/scan' >
                <Route path='pickup' element={<PickupScan />} />
                <Route path='shipped' element={<ShipScan />} />
                <Route path='received' element={<ReceiveScan />} />
                <Route path='delivered' element={<DeliverWithPOD />} />
              </Route>

              <Route path='staff/cutoff'>
                <Route index element={<CutOffListScreen />} />
                <Route path='create' element={<CutOffCreateScreen />} />
                <Route path='addshipment' element={<CutOffAddShipmentScreen />} />
                <Route path='search/:searchKeyword' element={<CutOffListScreen />} />
                <Route path='page/:pageNumber' element={<CutOffListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<CutOffListScreen />} />
                <Route path=':id' element={<CutOffDetailScreen />} />
              </Route>

              <Route path='staff/items' element={<StaffItemListScreen />}>
                <Route path='search/:searchKeyword' element={<StaffItemListScreen />} />
                <Route path='page/:pageNumber' element={<StaffItemListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<StaffItemListScreen />} />
              </Route>

              <Route path='staff/ositem' element={<OSItemListScreen />}>
                <Route path='search/:searchKeyword' element={<OSItemListScreen />} />
                <Route path='page/:pageNumber' element={<OSItemListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<OSItemListScreen />} />
              </Route>

              <Route path='staff/shippedositem' element={<OSItemShippedListScreen />}>
                <Route path='search/:searchKeyword' element={<OSItemShippedListScreen />} />
                <Route path='page/:pageNumber' element={<OSItemShippedListScreen />} />
                <Route path='search/:searchKeyword/page/:pageNumber' element={<OSItemShippedListScreen />} />
              </Route>

              
              <Route path='staff/accounts'>
                <Route index element={staff && staff.isAdmin ? <StaffAccountListScreen /> : <Navigate to='/staff' />} />
                <Route path='search/:search' element={staff && staff.isAdmin ? <StaffAccountListScreen /> : <Navigate to='/staff' />} />
                <Route path='add' element={staff && staff.isAdmin ? <StaffAccountAddScreen /> : <Navigate to='/staff' /> } />
                <Route path='update' element={staff && staff.isAdmin ? <StaffAccountUpdateScreen /> : <Navigate to='/staff' /> } />
              </Route>

              <Route path='/staff/myaccount' element={<StaffMyAccountScreen />} />
              <Route path='/staff/contactus' element={<StaffContactUsListScreen />} />
              <Route path='/staff/boxsizes' element={<StaffBoxsizeListScreen />} />
              <Route path='/staff/charges' element={<StaffChargesNameListScreen />} />
              <Route path='/staff/gallery' element={<StaffGalleryScreen />} />

              <Route path='staff/faq'>
                <Route index element={staff && staff.isAdmin ? <StaffFAQListScreen /> : <Navigate to='/staff' />} />
                <Route path='add' element={staff && staff.isAdmin ? <StaffFAQCreateScreen /> : <Navigate to='/staff' /> } />
                {/* <Route path='update' element={staff && staff.isAdmin ? <StaffAccountUpdateScreen /> : <Navigate to='/staff' /> } /> */}
              </Route>

            </Route>
            
       
          
          {/* Customer Private Routes */}
          <Route element={<CustomerPrivateRoutes />}>
            <Route path='/account' element={<CustomerAccountScreen />} />
            <Route path='/ositems' element={<CustomerOSItemListScreen />} />
            <Route path='/shipments' >
              <Route index element={<CustomerShipmentScreen />} />
              <Route path='search/:searchKeyword' element={<CustomerShipmentScreen />} />
              <Route path='page/:pageNumber' element={<CustomerShipmentScreen />} />
              <Route path='search/:searchKeyword/page/:pageNumber' element={<CustomerShipmentScreen />} />
              <Route path='create' element={<CustomerCreateShipmentScreen />} />
              <Route path='update' element={<CustomerUpdateShipmentScreen />} />
              <Route path=':id' element={<CustomerShipemntDetailScreen />} />
              <Route path=':id/packinglist' element={<PackingListScreen />} />
            </Route>
          </Route>
          
          
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
