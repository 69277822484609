import {useState} from 'react'
import { Spinner, Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './tracking.module.css'
import { trackShipment } from '../../../features/shipment/shipmentSlice'
import { MdWarningAmber, MdArrowOutward } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const Tracking = () => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [shipmentId, setShipmentId] = useState('')

  const { tracking, trackingLoading, trackingSuccess, trackingError, trackingMessage } = useSelector(state=>state.shipment.tracking.detail)

  const handleTracking = (e) => {
    e.preventDefault()
    if(shipmentId === ''){
      return
    }
    dispatch(trackShipment(shipmentId))
  }

  return (
    <div id='tracking' className={styles.tracking_main}>
      <div>
        <div className={styles.track}>
          <h1>{t('Tracking Shipment')} <span style={{fontSize: '14px'}} className='text-danger'>(eg. SA9999AAAA)</span></h1>
          <div className={styles.input_tracking}>
            <input 
              type='text'
              spellCheck={false}
              autoFocus={true}
              placeholder={t('Shipment ID')}
              value={shipmentId}
              maxLength={10}
              onChange={({target})=>setShipmentId(target.value.toUpperCase())}
            />
            <button
              onClick={handleTracking}
            >{trackingLoading ? <Spinner /> : `${t('Go')}`}</button>
          </div>
          {trackingError && <div className='my-2 bg-danger-subtle ps-2 rounded' style={{width: '90%'}}><MdWarningAmber color={'red'} className='me-2' />{trackingMessage}</div>}
        </div>

      
      </div>
      <div className='m-2'>
        {trackingSuccess && tracking.tracking.length === 0 ?
          <Card>
            <Card.Header className='bg-info-subtle'>{t('Last Status')} - {shipmentId}</Card.Header>
            <Card.Body>
              <Card.Title>Shipment Created</Card.Title>
              <Card.Text>
                {new Date(tracking.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'long', year: 'numeric'})}
              </Card.Text>
              <Button 
                className='m-0 p-0' 
                variant="link" 
                onClick={()=>navigate(`/shipment/tracking/${shipmentId}`)}
                disabled={shipmentId === ''}
                >More Detail<MdArrowOutward /></Button>
            </Card.Body>
          </Card>
          : trackingSuccess && tracking.tracking.length !== 0 &&
          <Card>
            <Card.Header className='bg-info-subtle'>{t('Last Status')} - {shipmentId}</Card.Header>
            <Card.Body>
              <Card.Title>{tracking.tracking[0].status}</Card.Title>
              <Card.Text>
                {new Date(tracking.tracking[0].date).toLocaleDateString('en-US', {day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit'})}
              </Card.Text>
              <Button 
                className='m-0 p-0' 
                variant="link" 
                onClick={()=>navigate(`/shipment/tracking/${shipmentId}`)}
                disabled={shipmentId === ''}
                >More Detail<MdArrowOutward /></Button>
            </Card.Body>
          </Card>
        }
      </div>
    </div>
  )
}

export default Tracking