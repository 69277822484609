import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Offcanvas, Form, Spinner, Alert, Table, Card } from 'react-bootstrap'
import { getCustomerForOSItem, listReset } from '../features/customerAccount/customerAccountSlice'
import { addNewOSItem } from '../features/osItem/osItemSlice'
import { toast } from 'react-toastify'
import { AlertMessage, MySpinner } from '../Components'

const OSItemAdd = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [customer, setCustomer] = useState(null)
    const [searchKey, setSearchKey] = useState('')
    const [itemName, setItemName] = useState('')
    const [itemQty, setItemQty] = useState(1)

    const { customerList, listLoading, listError, listSuccess, listMessage } = useSelector(state=>state.customerAccount.list)
    const { addLoading, addError, addSuccess, addMessage } = useSelector(state=>state.ositem.add)

    useEffect(()=>{
        if(addSuccess){
            setSearchKey('')
            setCustomer(null)
            setItemName('')
            setItemQty(1)
            setShow(false)
        }
    },[addSuccess])

    const handleClose = () => {
        setSearchKey('')
        setCustomer(null)
        setItemName('')
        setItemQty(1)
        setShow(false)
    }
    const handleShow = () => setShow(true)

    const handleSubmit = () => {
        if(customer === null || itemName === '' || itemQty <= 0){
            toast.warning('Complete the data')
            return
        }
        const data = {
            customer,
            name: itemName,
            qty: Number(itemQty)
        }
        dispatch(addNewOSItem(data))
    }
    return (
        <>
            <Button variant="primary" size='sm' onClick={handleShow}>
                Receive OS Item
            </Button>
            
            <Offcanvas show={show} onHide={handleClose} placement={'end'} backdrop={false} style={{width: '700px'}}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Receive and online shopping item</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {addLoading && <MySpinner />}
                    {addError && <AlertMessage type='danger' msg={addMessage} />}
                    <div>
                        <Form.Label className='text-primary'>&#128269; Search Customer (Name, ID, Email)</Form.Label>
                        <Form.Control 
                            type='text'
                            value={searchKey}
                            placeholder={'search'}
                            onChange={({target})=>setSearchKey(target.value)}
                            onKeyDown={(e)=>{                                
                                if(e.key === 'Enter'){
                                    if(e.target.value === '') {
                                        return
                                    }
                                    dispatch(getCustomerForOSItem(e.target.value))
                                }
                            }}
                        />
                        <div className='my-3 w-100'>
                            {listLoading ? <Spinner /> : listError ? <Alert variant='danger'>{listMessage}</Alert> : listSuccess &&
                            <div style={{fontSize:'14px', maxHeight: '500px', overflow: 'scroll'}}>
                                <Table bordered hover size="sm">                                
                                    <tbody>
                                        {customerList && customerList.length === 0 && <tr>Not found</tr>}
                                        {customerList.map(c=>(
                                            <tr 
                                                key={c._id} 
                                                style={{cursor:'pointer'}} 
                                                onClick={()=>{
                                                    setCustomer(c)
                                                    setSearchKey('')
                                                    dispatch(listReset())
                                                }}
                                            >
                                                <td>{c.customerId}</td>
                                                <td>{c.name}</td>
                                                <td>{c.email}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </div>
                            }
                        </div>
                    </div>

                    <div className='mb-3'>
                        {customer !== null &&
                        <>
                            <Card>
                                <Card.Body>
                                    <Card.Title>{customer.customerId} - {customer.name}</Card.Title>
                                    <Card.Text>
                                        {customer.email}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                            <div className='d-flex my-3'>
                                <div className='w-75 pe-3'>
                                    <div className='mb-1'>Item Name</div>
                                    <div>
                                        <Form.Control
                                            type='text'
                                            value={itemName}
                                            onChange={({target})=>setItemName(target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='w-25'>
                                    <div className='mb-1'>Item Qty</div>
                                    <div>
                                        <Form.Control
                                            type='number'
                                            value={itemQty}
                                            onChange={({target})=>setItemQty(target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>

                    <div className='d-flex mt-4'>
                        <Button
                            style={{width: '100px'}}
                            variant='secondary'
                            onClick={()=>handleClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            className='ms-auto'
                            style={{width: '100px'}}
                            variant='success'
                            onClick={handleSubmit}
                        >Save</Button>
                    </div>
                </Offcanvas.Body>
                
            </Offcanvas>

            
        </>
  )
}

export default OSItemAdd