import './faq.css'
import {faq} from '../../../data/faq'
import { Accordion } from 'react-bootstrap'
import Footer from '../Footer/Footer'
import { MdQuestionAnswer } from 'react-icons/md'

const FAQ = () => {
  return (
    <>
      <div className='faq-main'>   
      <h2 className='faq-heading'><MdQuestionAnswer />Frequently asked questions</h2>   
        {faq.map((f,i)=>(
          <div  key={i} className='faq-accordion'>
            <h3>{f.title}</h3>
            <Accordion>
              {f.list.map((aq, idx)=>(
                <Accordion.Item eventKey={idx} key={idx}>
                  <Accordion.Header>{aq.question}</Accordion.Header>
                  <Accordion.Body>
                    {aq.answer}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        ))}
      
      </div>
      <Footer />
    </>
  )
}

export default FAQ