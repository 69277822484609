import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import rateServices from './rateService'
import { toast } from 'react-toastify';

const initialState = {
    add: {
        rate: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    list: {
        rateList: [],
        listLoading: false,
        listSuccess: false,
        listError: false,
        listMessage: ''
    },
    update: {
        rate: null,
        updateLoading: false,
        updateSuccess: false,
        updateError: false,
        updateMessage: ''
    },
    delete: {
        rate: null,
        deleteLoading: false,
        deleteSuccess: false,
        deleteError: false,
        deleteMessage: ''
    },
    rate: {
        rate: 0,
        rateLoading: false,
        rateSuccess: false,
        rateError: false,
        rateMessage: ''
    },
    estimate: {
        estimate: null,
        estimateLoading: false,
        estimateSuccess: false,
        estimateError: false,
        estimateMessage: ''
    }
}


//Add new rate
export const addRate = createAsyncThunk('Rate_Add', async (newRate, thunkAPI) => {
    try {      

        const token = thunkAPI.getState().staff.auth.staff.token        
        return await rateServices.addRate(newRate, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get rates
export const getRates = createAsyncThunk('Rate_List', async (_, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token 
        return await rateServices.getRates(token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update rate
export const updateRate = createAsyncThunk('Rate_Update', async ({rateId, editedRate}, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token 
        return await rateServices.updateRate(rateId, editedRate, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete rate
export const deleteRate = createAsyncThunk('Rate_Delete', async ({rateId, index}, thunkAPI) => {
    try {   
        const token = thunkAPI.getState().staff.auth.staff.token      
        return await rateServices.deleteRate(rateId, index, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get price
export const getPrice = createAsyncThunk('Rate_Get_Price', async (data, thunkAPI) => {
    try {        
        let token
        if (data.staff && data.staff === true){
            token = thunkAPI.getState().staff.auth.staff.token
        } else {
            token = thunkAPI.getState().customer.auth.customer.token
        }
        
        return await rateServices.getPrice(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get price
export const getEstimatedCost = createAsyncThunk('Rate_Get_Estimated', async (data, thunkAPI) => {
    try {          
        return await rateServices.getEstimatedCost(data)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const rateSlice = createSlice({
    name: 'rate',
    initialState,
    reducers: {
        reset: (state) => initialState,
        estimateReset: (state) => {
            state.estimate.estimate = null
            state.estimate.estimateLoading = false
            state.estimate.estimateError = false
            state.estimate.estimateSuccess = false
            state.estimate.estimateMessage = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addRate.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
        }) 
        .addCase(addRate.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            toast.success('new rate added successfully.')
            state.list.rateList = [action.payload, ...state.list.rateList]
        })  
        .addCase(addRate.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = true
            state.add.addMessage = action.payload
            state.add.rate = null
        })
        //get Rate list
        .addCase(getRates.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
        }) 
        .addCase(getRates.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.rateList = action.payload
        })  
        .addCase(getRates.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listMessage = action.payload
            state.list.rateList = []
        })
        //update a rate 
        .addCase(updateRate.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateError = false
            state.update.updateSuccess = false
        }) 
        .addCase(updateRate.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            toast.success('Rate detail updated successfully.')
            const rateIndex = state.list.rateList.findIndex(e => e._id === action.payload._id)
            state.list.rateList = [...state.list.rateList.slice(0, rateIndex), action.payload, ...state.list.rateList.slice(rateIndex + 1)]
        })  
        .addCase(updateRate.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateMessage = action.payload
            state.update.rate = null
        })
        //delete a rate 
        .addCase(deleteRate.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
        }) 
        .addCase(deleteRate.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            toast.success('Rate deleted successfully.')
            state.list.rateList.splice(action.payload,1)
        })  
        .addCase(deleteRate.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteMessage = action.payload
            state.update.rate = null
        })
        //get price
        .addCase(getPrice.pending, (state)=>{
            state.rate.rateLoading = true
            state.rate.rateError = false
            state.rate.rateSuccess = false
        }) 
        .addCase(getPrice.fulfilled, (state, action)=>{
            state.rate.rateLoading = false
            state.rate.rateSuccess = true
            state.rate.rate = action.payload
        })  
        .addCase(getPrice.rejected, (state, action)=>{
            state.rate.rateLoading = false
            state.rate.rateError = true
            state.rate.rateMessage = action.payload
            state.rate.rate = 0
        })
        //get estimated
        .addCase(getEstimatedCost.pending, (state)=>{
            state.estimate.estimateLoading = true
            state.estimate.estimateError = false
            state.estimate.estimateSuccess = false
        }) 
        .addCase(getEstimatedCost.fulfilled, (state, action)=>{
            state.estimate.estimateLoading = false
            state.estimate.estimateError = false
            state.estimate.estimateSuccess = true
            state.estimate.estimate = action.payload
        })  
        .addCase(getEstimatedCost.rejected, (state, action)=>{
            state.estimate.estimateLoading = false
            state.estimate.estimateError = true
            state.estimate.estimateMessage = action.payload
            state.estimate.estimate = 0
        })

    },
})


export const { reset, estimateReset } = rateSlice.actions
export default rateSlice.reducer