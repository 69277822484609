import { Table } from 'react-bootstrap'
import { beep } from '../../assets'
import useSound from 'use-sound'
import { useEffect } from 'react';

function filterResults(results) {
  
  let filteredResults = [];
  for (var i = 0; i < results.length; ++i) {
    if (i === 0) {
      filteredResults.push(results[i]);
      continue;
    }

    if (results[i].decodedText !== results[i - 1].decodedText) {
      filteredResults.push(results[i]);
    }
  }
  return filteredResults;
}

const ResultContainerTable = ({ data }) => {
  
  const results = filterResults(data);
  return (
    <Table bordered size='sm'>
      <thead>
        <tr>
          <td>#</td>
          <td>Shipment ID</td>
          <td>Box ID</td>
          {/* <td>Remove</td> */}
        </tr>
      </thead>
      <tbody>
        {results.map((result, i) => {
          return (
            <tr key={i}>
              <td>{i+1}</td>
              <td>{result.decodedText.substring(0,10)}</td>
              <td>{result.decodedText.substring(10,19)}</td>
              {/* <td>{result.result.format.formatName}</td> */}
              {/* <td><Button className='m-0 py-0 px-2' size='sm' variant='danger'>x</Button></td> */}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const ResultContainerPlugin = (props) => {
  const [play] = useSound(beep); 
  const results = filterResults(props.results);
  useEffect(()=>{
    play()
  })
  return (
    <div className="Result-container">
      <div className="Result-header">Scanned results ({results.length})</div>
      <div className="Result-section">
        <ResultContainerTable data={results} />
      </div>
    </div>
  );
};

export default ResultContainerPlugin;
