import ImageGallery from 'react-image-gallery';
import {
    images
} from '../../../assets'
import '../../../../node_modules/react-image-gallery/styles/css/image-gallery.css'
import './imagegallery.css'

const Images = () => {
  return (
    <div className='gallary-main'>
    <ImageGallery 
      items={images} 
      thumbnailPosition={'left'} 

    />
    </div>
  )
}

export default Images