import { Outlet, Navigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { Container, Row, Col } from "react-bootstrap"
import StaffSideBar from "./StaffSideBar"
import styles from './staffscreens.module.css'

const PrivateRoutes = () => {
  const {staff} = useSelector(state=>state.staff.auth)
  
  return (
    staff ? 
      <Container fluid className='h-100'>
        <Row className='justify-content-center vh-100'>
          {staff &&
            <Col className={`h-100 mx-0 px-0 ${styles.staff_sidebar}`}>
              <StaffSideBar />
            </Col>
          }
          <Col className={styles.staff_contents}>
            <Outlet/>
          </Col>
        </Row>
      </Container>
    : <Navigate to='/staff' />
  )
}

export default PrivateRoutes