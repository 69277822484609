import { useEffect } from 'react'
import { Row, Col} from 'react-bootstrap'
import styles from './customercreateshipmentscreen.module.css'
import {AddressBook} from '../../../Modals'
import { countries } from '../../../data/countries'

const Addresses = ({
    receiver, 
    setReceiver, 
    sender, 
    setSender, 
    senderDifferent, 
    setSenderDifferent, 
    customer,
    senderSave,
    setSenderSave,
    reveiverSave,
    setReceiverSave
    }) => {

    useEffect(()=>{
        if(senderDifferent){
            setSender({
                name: "",
                company: "",
                email: "",
                phonenumber1: "",
                phonenumber2: "",
                street: "",
                city: "", 
                state: "",
                postalcode: "",
                country: ""
            })
        } else {
            setSender({
                name: customer.name,
                company: customer.company ? customer.company : null,
                email: customer.email,
                phonenumber1: customer.phonenumber1,
                phonenumber2: customer.phonenumber2 ? customer.phonenumber2 : null,
                street: customer.defaultAddress.street,
                city: customer.defaultAddress.city, 
                state: customer.defaultAddress.state,
                postalcode: customer.defaultAddress.postalcode,
                country: customer.defaultAddress.country
            })
        }
        // eslint-disable-next-line
    },[senderDifferent])
    
    // const handleDifferentSender = () => {
    //     if(senderDifferent){
    //         setSender({
    //             name: customer.name,
    //             company: customer.company ? customer.company : null,
    //             email: customer.email,
    //             phonenumber1: customer.phonenumber1,
    //             phonenumber2: customer.phonenumber2 ? customer.phonenumber2 : null,
    //             street: customer.defaultAddress.street,
    //             city: customer.defaultAddress.city, 
    //             state: customer.defaultAddress.state,
    //             postalcode: customer.defaultAddress.postalcode,
    //             country: customer.defaultAddress.country
    //         })
    //     } else {
    //         setSender({
    //             name: "",
    //             company: "",
    //             email: "",
    //             phonenumber1: "",
    //             phonenumber2: "",
    //             street: "",
    //             city: "", 
    //             state: "",
    //             postalcode: "",
    //             country: ""
    //         })
    //     }
    // }

    return (
        <div className={styles.receiver_info}>
            {senderDifferent ? 
                <Row className={styles.sender_form}>
                    <Col lg={12}>
                        <div className={styles.sender_heading}>
                            <h3>Sender Information</h3>
                            {/* <AddressBook sender={true} setSender={setSender}  />
                            <div className={styles.sender_checkbox}>
                                <input
                                    type='checkbox'
                                    checked={senderDifferent}
                                    onChange={()=>setSenderDifferent(prev=>!prev)}
                                />
                                <p>I want to use different address</p>
                            </div> */}
                            
                        </div>                        
                    </Col>
                    <Col md={4}>
                        <p>Full Name</p>
                        <input 
                            type='text'
                            value={sender.name}
                            onChange={({target})=>setSender({...sender, name: target.value})}
                            
                        />
                    </Col>
                    <Col md={4}>
                        <p>Company</p>
                        <input 
                            type='text'
                            value={sender.company}
                            onChange={({target})=>setSender({...sender, company: target.value})}
                            
                        />
                    </Col>
                    <Col md={4}>
                        <p>Email</p>
                        <input 
                            value={sender.email}
                            onChange={({target})=>setSender({...sender, email: target.value})}
                        />
                    </Col>
                    <Col md={6}>
                        <p>Address</p>
                        <input 
                            type='text'
                            value={sender.street}
                            onChange={({target})=>setSender({...sender, street: target.value})}
                            
                        />
                    </Col>
                    <Col md={3}>
                        <p>City</p>
                        <input 
                            type='text'
                            value={sender.city}
                            onChange={({target})=>setSender({...sender, city: target.value})}
                            
                        />
                    </Col>
                    <Col md={3}>
                        <p>State/Province</p>
                        <input 
                            type='text'
                            value={sender.state}
                            onChange={({target})=>setSender({...sender, state: target.value})}
                            
                        />
                    </Col>
                    <Col md={3}>
                        <p>Zip/Postal Code</p>
                        <input 
                            type='text'
                            value={sender.postalcode}
                            onChange={({target})=>setSender({...sender, postalcode: target.value})}
                            
                        />
                    </Col>
                    <Col md={3}>
                        <p>Country</p>
                        <select value={sender.country} onChange={({target})=>setSender({...sender, country: target.value})}>
                            {countries.map(c=>(
                                <option key={c.name} value={c.value}>{c.name}</option>
                            ))}
                        </select>
                    </Col>
                    <Col md={3}>
                        <p>Phone 1</p>
                        <input 
                            value={sender.phonenumber1}
                            onChange={({target})=>setSender({...sender, phone: target.value.replace(/\D/g, "")})}
                        />
                    </Col>
                    <Col md={3}>
                        <p>Phone 2</p>
                        <input 
                            value={sender.phonenumber2}
                            onChange={({target})=>setSender({...sender, phone: target.value.replace(/\D/g, "")})}
                        />
                    </Col>
                    <div className={styles.save_address}>
                        <input
                            type='checkbox'
                            value={senderSave}
                            onChange={()=>setSenderSave(prev=>!prev)}
                        />
                        <p>Save in Address Book</p>
                    </div>
                </Row>                
                :
                <div>
                    <div className={styles.sender_heading}>
                        <h3>Sender Information</h3>
                        {/* <div className={styles.sender_checkbox}>
                            <input
                                type='checkbox'
                                checked={senderDifferent}
                                onChange={()=>setSenderDifferent((prev)=>!prev)}
                            />
                            <p>I want to use different address</p>
                        </div> */}
                        
                    </div>
                    <div className={styles.sender_detail}>
                        <h4>{customer.name}</h4>
                        {customer.company && <h5>{customer.company}</h5>}
                        <p>{customer.defaultAddress.street}, {customer.defaultAddress.city}, {customer.defaultAddress.state}, {customer.defaultAddress.postalcode}, {customer.defaultAddress.country}</p>
                        {customer.email && <p>{customer.email}</p>}
                        <div className={styles.sender_detail_phone}>
                            {customer.phonenumber1 && <p>{customer.phonenumber1}</p>}
                            {customer.phonenumber2 && <p>, {customer.phonenumber2}</p>}
                            {customer.phonenumber3 && <p>, {customer.phonenumber3}</p>}
                        </div>
                    </div>
                </div>
            }

            <hr />
            <div className={styles.receiver_heading}>
                <h3>Recevier Information</h3>
                <AddressBook receiver={true} setReceiver={setReceiver} />
            </div>
            <Row>
                <Col md={4}>
                    <p>Full Name</p>
                    <input 
                        type='text'
                        value={receiver.name}
                        onChange={({target})=>setReceiver({...receiver, name: target.value})}
                    />
                </Col>
                <Col md={4}>
                    <p>Company</p>
                    <input 
                        value={receiver.company}
                        onChange={({target})=>setReceiver({...receiver, company: target.value})}
                    />
                </Col>
                <Col md={4}>
                    <p>Email</p>
                    <input 
                        value={receiver.email}
                        onChange={({target})=>setReceiver({...receiver, email: target.value})}
                    />
                </Col>
                <Col md={6}>
                    <p>Address</p>
                    <input 
                        value={receiver.street}
                        onChange={({target})=>setReceiver({...receiver, street: target.value})}
                    />
                </Col>
                <Col md={3}>
                    <p>City</p>
                    <input 
                        value={receiver.city}
                        onChange={({target})=>setReceiver({...receiver, city: target.value})}
                    />
                </Col>
                <Col md={3}>
                    <p>State/Province</p>
                    <input 
                        value={receiver.state}
                        onChange={({target})=>setReceiver({...receiver, state: target.value})}
                    />
                </Col>
                <Col md={3}>
                    <p>Zip/Postal Code</p>
                    <input 
                        value={receiver.postalcode}
                        onChange={({target})=>setReceiver({...receiver, postalcode: target.value})}
                    />
                </Col>
                <Col md={3}>
                    <p>Country</p>
                    <select value={receiver.country} onChange={({target})=>setReceiver({...receiver, country: target.value})}>
                        {countries.map(c=>(
                            <option key={c.name} value={c.value}>{c.name}</option>
                        ))}
                    </select>
                </Col>
                <Col md={3}>
                    <p>Phone 1</p>
                    <input 
                        value={receiver.phonenumber1}
                        onChange={({target})=>setReceiver({...receiver, phonenumber1: target.value.replace(/\D/g, "")})}
                    />
                </Col>
                <Col md={3}>
                    <p>Phone 2</p>
                    <input 
                        value={receiver.phonenumber2}
                        onChange={({target})=>setReceiver({...receiver, phonenumber2: target.value.replace(/\D/g, "")})}
                    />
                </Col>
                <div className={styles.save_address}>
                    <input
                        type='checkbox'
                        value={reveiverSave}
                        onChange={()=>setReceiverSave(prev=>!prev)}
                    />
                    <p>Save in Address Book</p>
                </div>
            </Row>
        </div>
    )
}

export default Addresses