import axios from 'axios'

const API_URL = '/api/item'

//Add New Item 
const addItem = async (newItem, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL+'/add', newItem, config)
    return response.data
}

//Get Item List
const getItems = async (data, page) => {
    const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const response = await axios.get(API_URL+`?keyword=${searchKeyword}&page=${pageNumber}&limit=${data.limit}`)
    return response.data
}

//Add New Item 
const updateItem = async (itemId, newName) => {
    const response = await axios.put(API_URL+'/' + itemId, {newName})
    return response.data
}

//Add New Item 
const deleteItem = async (itemId) => {
    const response = await axios.delete(API_URL+'/'+ itemId)
    return response.data
}


const itemServices = {
    addItem,
    getItems,
    updateItem,
    deleteItem,
}

export default itemServices