import axios from 'axios'

const API_URL = '/api/system/charges'

//Add New charges name
const addNewChargesName = async (newName, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, newName, config)
    return response.data
}

//Get Charges name list
const getChargesNameList = async (token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL, config)
    return response.data
}

//Delete charges name
const deleteChargesName = async (chargesId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+'/'+ chargesId, config)
    return response.data
}


const chargesnameServices = {
    addNewChargesName,
    getChargesNameList,
    deleteChargesName,
}

export default chargesnameServices