const handleAddress = async (cargoType, receiver) => {
    let result = []
    if(!cargoType){
        result.push('Service type')        
    }   
    if(receiver.name === "") {
        result.push('Name')
    }
    if(receiver.street === "" ) {
        result.push('Street')
    }
    if(receiver.city === "" ) {
        result.push('City')
    }
    if(receiver.state === "" ) {
        result.push('State')
    }
    if(receiver.postalcode === "" ) {
        result.push('Postal Code')
    }
    if(receiver.country === "" ) {
        result.push('Country')
    }
    if(receiver.phonenumber1 === "" ) {
        result.push('Phone 1')
    }
    return result
}

const handleBoxes = async (boxes, noItem) => {
    let result = []
    boxes.forEach((b, i)=>{
        if(Number(b.length) === 0 || Number(b.width) === 0 || Number(b.height) === 0 || Number(b.weight) === 0){
            result.push(`Box ${i+1} size and weight`)
        }
    })
    if(!noItem){
        boxes.forEach((b, i)=>{
            if(b.items.length === 0){
                result.push((`Box ${i+1} no item`))
            } else {
                b.items.forEach((itm)=>{
                    if(Number(itm.unit) === 0 || Number(itm.price) === 0){
                        result.push(`Box ${i+1}-${itm.product} unit and price`)
                    }
                })
            }            
        })
    }
    return result
}

const handlePickup = async (pickupData) => {
    let result = []
    if(pickupData.date === "" ) {
        result.push('Date')
    }
    if(pickupData.time === "" ) {
        result.push('Time')
    }
    if(pickupData.street === "" ) {
        result.push('Street')
    }
    if(pickupData.city === "" ) {
        result.push('City')
    }
    if(pickupData.state === "" ) {
        result.push('State')
    }
    if(pickupData.postalcode === "" ) {
        result.push('Postal Code')
    }
    if(pickupData.country === "" ) {
        result.push('Country')
    }
    return result
}

export { handleAddress, handleBoxes, handlePickup}