import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import styles from './modals.module.css'
import { Modal, Button, FormControl, FormLabel, Spinner } from 'react-bootstrap'
import { MdEditSquare } from 'react-icons/md'
import { updateItem } from '../features/item/itemSlice';
import { AlertMessage } from '../Components'

const EditItem = ({itemId, itemName}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [name, setName] = useState(itemName)

    const { updateError, updateLoading, updateSuccess, updateMessage } = useSelector(state=>state.item.update)
    
    useEffect(()=>{
        if(updateSuccess){
           
            setShow(false)
        }        
    },[updateSuccess, dispatch])
    const handleShow = () => setShow(true);
    const handleSubmit = () => {
        dispatch(updateItem({itemId, name}))
    }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <div className={styles.edit_item_main}>
            <Button variant='info' onClick={handleShow} className='mx-auto' style={{height: '25px', widht: '50px', display: 'flex'}}><MdEditSquare className='pb-1' /></Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                {updateLoading ?
                <>
                <Spinner animation="border" />
                <Modal.Title> Please wait updating item...</Modal.Title>
                </>
                :
                <Modal.Title>Add New Item</Modal.Title>
                }
                </Modal.Header>
                <Modal.Body>
                    {updateError && <AlertMessage msg={updateMessage} />}
                    <FormLabel>Please type item name</FormLabel>
                    <FormControl 
                        type='text'
                        value={name}
                        autoFocus
                        onChange={e=>setName(e.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditItem