import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { Modal, Button, Form } from 'react-bootstrap'
import { AlertMessage, MySpinner } from '../Components'
import { addTrackingStatus, deleteTrackingStatus } from '../features/shipment/shipmentSlice';
import { addCutOffTracking, deleteCutOffTracking} from '../features/cutoff/cutoffSlice'
import { toast } from 'react-toastify';
import { MdKeyboardDoubleArrowUp } from 'react-icons/md'

const AddTrackingModal = ({shipmentId, cutoffId, className, text, forCutoff, forShipment}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [ status, setStatus ] = useState('')
    const [ location, setLocation ] = useState('')
    const [ now, setNow ] = useState(true)
    const [ date, setDate ] = useState('')
    const { shipment } = useSelector(state=>state.shipment.detail)
    const { cutoff } = useSelector(state=>state.cutoff.detail)
    const { addTrackingLoading, addTrackingError, addTrackingSuccess, addTrackingMessage } = useSelector(state=>state.shipment.tracking.add)
    const { deleteTrackingLoading, deleteTrackingError, deleteTrackingMessage } = useSelector(state=>state.shipment.tracking.delete)
    const { addCutoffTrackingLoading, addCutoffTrackingError, addCutoffTrackingSuccess, addCutoffTrackingMessage } = useSelector(state=>state.cutoff.tracking.add)
    const { deleteCutoffTrackingLoading, deleteCutoffTrackingError, deleteCutoffTrackingSuccess, deleteCutoffTrackingMessage } = useSelector(state=>state.cutoff.tracking.delete)
    
    useEffect(()=>{
        if(addTrackingSuccess || addCutoffTrackingSuccess || deleteCutoffTrackingSuccess){
            setShow(false)
            setStatus('')
            setLocation('')
            setNow(true)
            setDate('')
        }
    },[addTrackingSuccess, addCutoffTrackingSuccess, deleteCutoffTrackingSuccess])
    
    const handleShow = () => setShow(true);
    const handleSubmit = (e) => {
        e.preventDefault()
        if(status === '' || location === ''){
            toast.warning('Status and location is requried.')
            return
        }
        if(forCutoff === true){
            dispatch(addCutOffTracking({cutoffId, status, location, now, date}))
        }
        if(forShipment === true){
            dispatch(addTrackingStatus({shipmentId, status, location, now, date}))
        }
        
    }
    const handleClose = (e) => {
        setShow(false)
        setStatus('')
        setLocation('')
        setNow(true)
        setDate('')
    }
    return (
        <div>
            <Button variant="secondary" onClick={handleShow} className={className} >
               {text}
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size='lg'
               
            >
                <Modal.Header closeButton>
                
                <Modal.Title>Add Tracking Status</Modal.Title>
                
                </Modal.Header>
                <Modal.Body >
                    {addTrackingError && <AlertMessage msg={addTrackingMessage} />}
                    {deleteTrackingError && <AlertMessage msg={deleteTrackingMessage} />}
                    {addCutoffTrackingError && <AlertMessage msg={addCutoffTrackingMessage} />}
                    {deleteCutoffTrackingError && <AlertMessage msg={deleteCutoffTrackingMessage} />}
                    {addTrackingLoading && <MySpinner />}
                    {deleteTrackingLoading && <MySpinner />}
                    {addCutoffTrackingLoading && <MySpinner />}
                    {deleteCutoffTrackingLoading && <MySpinner />}
                    <Form.Group className='mb-3'>
                        <Form.Label className='mb-0'>Status</Form.Label>
                        <Form.Control 
                            type='text'
                            value={status}
                            onChange={({target})=>setStatus(target.value.replace(/[^\w\s,./-]/gi, ""))}
                        />
                    </Form.Group>

                    <Form.Group className='mb-3'>
                        <Form.Label className='mb-0'>Location</Form.Label>
                        <Form.Control 
                            type='text'
                            value={location}
                            onChange={({target})=>setLocation(target.value.replace(/[^\w\s,./-]/gi, ""))}
                        />
                    </Form.Group>

                    <Form.Label className='mb-0'>Date</Form.Label>
                    <Form.Group 
                        className='mb-3 border p-2 rounded' 
                        //onClick={()=>{setNow(prev=>!prev); setDate('')}}
                        >
                        <Form.Check 
                            type="checkbox"
                            label="Now"
                            checked={now}
                            onChange={()=>setNow(prev => !prev)}
                        />
                    </Form.Group>

                    {!now && <Form.Group className='mb-3'>
                        <Form.Label className='mb-0'>Date</Form.Label>
                        <Form.Control 
                            type='date'
                            value={date}
                            onChange={({target})=>setDate(target.value)}
                        />
                    </Form.Group>}

                    <div className='d-flex justify-content-between'>
                        <Button variant="secondary" onClick={handleClose} style={{width: '150px'}}>
                            Cancel
                        </Button>
                        <Button variant="success" onClick={handleSubmit} style={{width: '150px'}}>Save</Button>
                    </div>

                </Modal.Body>
                {forShipment && <div  style={{height: '300px', overflow: 'scroll'}}>
                    <Modal.Footer className='d-flex justify-content-center flex-column'>
                        {shipment && shipment.tracking.map((t,i)=>(
                            <div key={i}>
                                <div className='text-center d-flex'>
                                    <div className='me-3'>{t.status}, {t.location}</div>
                                    <div><Button
                                            variant='danger'
                                            size='sm'
                                            className='m-0 py-0 px-2'
                                            onClick={()=>dispatch(deleteTrackingStatus({shipmentId, trackingId: t._id}))}
                                        >x</Button>
                                    </div>
                                </div>
                                <div className='text-center'>{new Date(t.date).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                                <div className='text-center'>< MdKeyboardDoubleArrowUp /></div>
                                {/* {(i+1) !== shipment.tracking.length && <div className='text-center'>< MdKeyboardDoubleArrowUp /></div>} */}
                            </div>
                        ))}
                            {shipment && <div>
                                <div className='text-center'>Shipment Created, NYSS</div>
                                <div className='text-center'>{new Date(shipment.createdAt).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                            </div>}
                    </Modal.Footer>
                </div>}
                {forCutoff && <div  style={{height: '300px', overflow: 'scroll'}}>
                    <Modal.Footer className='d-flex justify-content-center flex-column'>
                        {cutoff && cutoff.tracking.map((t,i)=>(
                            <div key={i}>
                                <div className='text-center d-flex'>
                                    <div className='me-3'>{t.status}, {t.location}</div>
                                    <div><Button
                                            variant='danger'
                                            size='sm'
                                            className='m-0 py-0 px-2'
                                            onClick={()=>dispatch(deleteCutOffTracking({cutoffId, trackingId: t._id}))}
                                        >x</Button>
                                    </div>
                                </div>
                                <div className='text-center'>{new Date(t.date).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                                <div className='text-center'>< MdKeyboardDoubleArrowUp /></div>
                                {/* {(i+1) !== shipment.tracking.length && <div className='text-center'>< MdKeyboardDoubleArrowUp /></div>} */}
                            </div>
                        ))}
                            {cutoff && <div>
                                <div className='text-center'>Cut Off Created, NYSS</div>
                                <div className='text-center'>{new Date(cutoff.createdAt).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                            </div>}
                    </Modal.Footer>
                </div>}
            </Modal>
        </div>
    )
}

AddTrackingModal.defaultProps = {
    className: '',
    text: 'Tracking'
}

export default AddTrackingModal