import styles from '../staffscreens.module.css'
import { Form,Button, Container, Col } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../../../features/staff/staffSlice'
import { useNavigate } from 'react-router-dom'
import { AlertMessage, MySpinner } from '../../../Components'
import { MdHome } from 'react-icons/md'

const StaffLoginScreen = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passView, setPassView] = useState(false)

  const { staff, authLoading, authSuccess, authError, authMessage} = useSelector(state=>state.staff.auth)

  useEffect(()=>{
    if(staff || authSuccess){
      navigate('/staff/shipments')
    }
  },[navigate, staff, authSuccess])

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(login({email,password}))
  }
  return (
    <Container className={styles.staff_login_main}>
      <Col lg={5} md={9} className='mx-auto mt-5'>
      {authLoading && <MySpinner />}
      <Form className='border p-4 shadow rounded' onSubmit={handleSubmit}>

        <div className='d-flex justify-content-between mb-4 border-bottom'>
          <h3>Staff Login</h3>
          <Button
            variant='flush'
            onClick={()=>navigate('/')}
          ><MdHome /> Home</Button>
        </div>
        
        
        {authError && <AlertMessage msg={authMessage} />}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Email address</Form.Label>
          <Form.Control 
            type="email" 
            placeholder="Enter email" 
            value={email}
            required
            onChange={e=>setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label>Password</Form.Label>
          <Form.Control 
            type={passView ? 'text' : "password" }
            placeholder="Password"
            required 
            value={password}
            onChange={e=>setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="View password" onChange={()=>{setPassView((prev)=>!prev)}} />
        </Form.Group>
        <div className='d-grid'>
        <Button variant="secondary" type="submit">
          Login
        </Button>
        </div>
      </Form>
      </Col>
    </Container>
  )
}

export default StaffLoginScreen