import {useEffect, useState} from 'react'
import styles from './staffscreens.module.css'
import { useSelector ,useDispatch } from 'react-redux'
import { Col, Container, Row, Form, Button, ListGroup, Card, Alert, Badge } from 'react-bootstrap'
import { getCustomerList } from '../../features/customerAccount/customerAccountSlice'
import { MySpinner, AlertMessage, MyPagination } from '../../Components'
import { useParams, useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { MdAdd } from 'react-icons/md'
import { Link } from 'react-router-dom'

const StaffCustomerListScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const searchKeyword = params.searchKeyword
  const pageNumber = params.pageNumber || 1
  const [keyword, setKeyword] = useState('')
  
  const { customerList, listLoading, listError, listMessage, pages, page } = useSelector(state=>state.customerAccount.list)

  useEffect(()=>{
    dispatch(getCustomerList({searchKeyword, pageNumber, limit: 10}))
  },[dispatch, searchKeyword, pageNumber])


  return (
    <Container fluid className='mb-5'>

      <Row className="mb-3">
        <Col md={4} className='mb-3'>
          <Form.Control            
            placeholder={searchKeyword ? searchKeyword : "Search customer..."}
            size='sm'
            value={keyword}
            onChange={({target})=>setKeyword(target.value)}
            // onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                if(keyword === ''){
                  navigate(`/staff/customers`)
                } else {
                  navigate(`/staff/customers/search/${keyword}`)
                }                    
              }
            }}
          />    
          <small className='text-muted'>name, id, email, company, phone numbers, address,...</small>      
        </Col>
        <Col className='mb-3'>
          <LinkContainer to='/staff/customers/add'>
            <Button variant='outline-secondary text-dark' size='sm'><MdAdd size={20} /> New Customer</Button>
          </LinkContainer>
        </Col>
        <Col>
            <MyPagination 
              path={keyword !== "" ? `staff/customers/search/${keyword}` : 'staff/customers'} 
              pages={pages}
              page={page}
           />
        </Col>
      </Row>

      <Col>
        {listLoading && <MySpinner />}
        {listError && <AlertMessage msg={listMessage} />}
         <ListGroup>
        {customerList.length === 0 ? <Alert value='info'>Not Found</Alert> : customerList.map((customer,i) => (        
          <Card key={customer._id} className={`${styles.customer_card}`}>
            <Row>
              <Col className={`${styles.customer_card_detail} mb-2`}>
                <h5 className='bg-primary-subtle' >{(pageNumber*10)+(i+1)-10}. {customer.name}, {customer.customerId} {customer.isSuspended && <Badge bg='danger' className='float-end blink_me pt-1'>suspended</Badge>}</h5>
                <h6 className='my-0'>{customer.company && <span>{customer.company}, </span>} {customer.email}</h6>
                <p className='my-0'>
                  {customer.defaultAddress.street}, &nbsp;
                  {customer.defaultAddress.city}, &nbsp;
                  {customer.defaultAddress.state}, &nbsp;
                  {customer.defaultAddress.postalcode}, &nbsp;
                  {customer.defaultAddress.country}
                </p>
                <div className={styles.customer_card_phones}>
                <p>{customer.phonenumber1}</p> 
                {customer.phonenumber2 && <p>{", " + customer.phonenumber2} </p>} 
                {customer.phonenumber3 && <p>{", " + customer.phonenumber3} </p>}
                </div>
              </Col>
              <Col md={3} className="d-flex justify-content-between pb-2">
                {!customer.isSuspended && <Link to={`/staff/shipments/create?customer=${customer._id}`}>
                  <Button variant='primary' size='sm'>Create Shipment</Button>
                </Link>}
                <Link to={`/staff/customers/${customer._id}`} state={customer._id}>
                  <Button variant='success' size='sm' style={{width: '100px'}}>Detail</Button>
                </Link>
              </Col>
            </Row>
          </Card>
            
        ))}
        </ListGroup>
      </Col>
      
      
    </Container>
  )
}

export default StaffCustomerListScreen