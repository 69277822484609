import { useState, useRef } from 'react';
import styles from './customershipmentscreen.module.css'
import { Modal, Button, Col, Row, Table, Card } from 'react-bootstrap'
import ReactToPrint from 'react-to-print';
import { MdPrint,MdOutlineDocumentScanner } from 'react-icons/md'
import nyssData from '../../../data/nyssData'

const PackingListPrint = ({boxes, items, customerId, shipmentId, sender, receiver, cargoType}) => {
    const componentRef = useRef()
    const [show, setShow] = useState(false);   
    const handleShow = () => setShow(true); 
    const handleClose = () => setShow(false)
    
    return (
        <div>
            <Button variant="info" onClick={handleShow}><MdOutlineDocumentScanner /> Packing List </Button>
            <Modal
                show={show}
                onHide={handleClose}             
                size='lg'
            >
            <Modal.Header closeButton>               
            <Modal.Title> Packing List </Modal.Title>
              
            </Modal.Header>
                <div className='me-5 ms-auto mt-3'>
                    <ReactToPrint
                        
                        trigger={() => <Button variant='info ' style={{width: '100px'}}><MdPrint /> Print</Button>}
                        content={() => componentRef.current}
                        onAfterPrint={()=>setShow(false)}
                    />
                </div>
                <Modal.Body className='d-flex justify-content-center w-100'>
                    <div className={`${styles.packing_list_main} d-print-flex`} ref={componentRef}>
                    <div className={`${styles.packing_list}`}>                            
                        
                        <div className='text-center fs-3 fw-bold py-1'>Packing List</div>                                
                        <div className='text-center'>Shipper: {nyssData.company}</div>                                
                        <div className='text-center mb-3'>{nyssData.street + ', ' + nyssData.state + ', ' + nyssData.postalcode}</div>                                
                        
                        <Row className='mb-3'>
                            <Col sm={6}>
                                <Card >
                                    <Card.Header>CUSTOMER ID : {customerId}</Card.Header>
                                    <Card.Body style={{fontSize: '14px'}}>
                                        <Row className='d-flex'>
                                            <Col sm={3}>NAME</Col>
                                            <Col sm={9}>{sender.name.toUpperCase()}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>PHONE</Col>
                                            <Col sm={9}>{sender.phonenumber1} {sender.phonenumber2 && ', ' + sender.phonenumber2}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>EMAIL</Col>
                                            <Col sm={9}>{sender.email.toUpperCase()}</Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col sm={6}>
                                <Card>
                                    <Card.Header>RECEIVER</Card.Header>
                                    <Card.Body style={{fontSize: '14px'}}>
                                        <Row className='d-flex'>
                                            <Col sm={3}>NAME</Col>
                                            <Col sm={9}>{receiver.name.toUpperCase()}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>PHONE</Col>
                                            <Col sm={9}>{receiver.phonenumber1} {receiver.phonenumber2 && ', ' + receiver.phonenumber2}</Col>
                                        </Row>
                                        <Row className='d-flex'>
                                            <Col sm={3}>ADDRESS</Col>
                                            <Col sm={9}>{(receiver.address.street + ', ' + receiver.address.city + ', ' + receiver.address.state + ', ' + receiver.address.country + ', ' + receiver.address.postalcode).toUpperCase()}</Col>
                                        </Row>
                                        {receiver.email && <Row className='d-flex'>
                                            <Col sm={3}>EMAIL</Col>
                                            <Col sm={9}>{receiver.email.toUpperCase()}</Col>
                                        </Row>}
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <Card className='mb-3'>
                            <Card.Body>
                                <div>SHIPMENT ID : {shipmentId}</div>
                                <div>CARGO TYPE : {cargoType.toUpperCase()}</div>
                                <div>TERM : FOB</div>
                                <div>TOTAL INVOICE VALUE : $ {(boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + itm.price*itm.unit, 0)), 0)).toFixed(2)}</div>
                            </Card.Body>
                        </Card>

                        <Table bordered size="sm">
                            <thead style={{fontWeight: '300'}}>
                                <tr className='text-center'>
                                    <th style={{width: '50px'}}>#</th>
                                    <th>Product Name</th>
                                    <th style={{width: '50px'}}>Unit</th>
                                    <th style={{width: '100px'}}>Unit Price</th>
                                    <th style={{width: '100px'}} className='text-end'>Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {items.length > 0 && items.map((itm,i)=>(
                                    <tr key={i}>
                                        <td className='text-center'>{i+1}</td>
                                        <td>{itm.product}</td>
                                        <td className='text-center'>{itm.unit}</td>
                                        <td className='text-center'>$ {itm.price.toFixed(2)}</td>
                                        <td className='text-end'>$ {(itm.unit * itm.price).toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        <div className='text-end fs-5'>Total Invoice Value - $ {(boxes.reduce((acc, b) => acc + (b.items.reduce((ac, itm) => ac + (itm.price*itm.unit), 0)), 0)).toFixed(2)}</div>
                                                 
                    </div>                   
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PackingListPrint