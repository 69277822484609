import { useEffect } from "react"
import { deleteRate, getRates } from '../../features/rate/rateSlice'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Spinner,  Button } from "react-bootstrap"
import { AddRateModal, EditRateModal } from "../../Modals"
import { AlertMessage, useWindowSize } from "../../Components"

const StaffRateScreen = () => {
  const dispatch = useDispatch()
  const size = useWindowSize()
  const { rateList, listLoading, listError, listMessage } = useSelector(state=>state.rate.list)
  useEffect(()=>{
    dispatch(getRates())
  },[dispatch])

  return (
    <div>
      <AddRateModal />
      {listError && <AlertMessage msg={listMessage} />}
      {listLoading ? <Spinner /> : 

      size.width > 760 ? 
      <Table striped bordered hover size="sm" style={{maxWidth: '600px'}}>
        <thead>
          <tr>
            <th>Type</th>
            <th>Weight</th>
            <th className="text-center">Price $</th>
            <th className="text-center">Edit</th>
            <th className="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          {rateList.map((rate, idx)=>(
            <tr key={rate._id}>
              <td className="text-capitalize">{rate.name}</td>
              <td>{rate.weightStart} - {rate.weightEnd}</td>
              <td style={{width: '100px'}} className="text-center">{rate.unitPrice.toFixed(2)}</td>
              <td style={{width: '70px'}} className="text-center"><EditRateModal rate={rate} idx={idx} /></td>
              <td style={{width: '80px'}} className="text-center">
                <Button 
                  size='sm' 
                  variant="danger"
                  className="m-0 py-0 px-3"
                  onClick={()=>dispatch(deleteRate({rateId: rate._id,index: idx}))}
                >Delete</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>
      :
      <Table striped bordered hover size="sm" style={{maxWidth: '600px'}}>
        <thead>
          <tr>
            <th>Detail</th>
            <th className="text-center">Edit</th>
            <th className="text-center">Delete</th>
          </tr>
        </thead>
        <tbody>
          {rateList.map((rate, idx)=>(
            <tr key={rate._id}>
              <td>
                <div className="text-capitalize">Type : {rate.name}</div>
                <div>Weight : {rate.weightStart} - {rate.weightEnd}</div>
                <div>Price : {rate.unitPrice.toFixed(2)}</div>
              </td>
              <td style={{width: '70px'}} className="text-center"><EditRateModal rate={rate} idx={idx} /></td>
              <td style={{width: '80px'}} className="text-center">
                <Button 
                  size='sm' 
                  variant="danger"
                  className="m-0 py-0 px-3"
                  onClick={()=>dispatch(deleteRate({rateId: rate._id,index: idx}))}
                >Delete</Button></td>
            </tr>
          ))}
        </tbody>
      </Table>

      }      
    </div>
  )
}

export default StaffRateScreen


      