import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { Modal, Button, Spinner, Form, Row, Col } from 'react-bootstrap'
import { updateRate } from '../features/rate/rateSlice';
import { AlertMessage } from '../Components'
import { toast } from 'react-toastify';

const EditRateModal = ({rate}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [name, setName] = useState(rate.name)
    const [weightStart, setWeightStart]= useState(rate.weightStart)
    const [weightEnd, setWeightEnd] = useState(rate.weightEnd)
    const [unitPrice, setUnitPrice] = useState(rate.unitPrice)

    const { updateError, updateLoading, updateSuccess, updateMessage } = useSelector(state=>state.rate.update)
    
    useEffect(()=>{
        if(updateSuccess){
            setShow(false)
        }
    },[updateSuccess])
    const handleShow = () => setShow(true);
    const handleSubmit = (e) => {
        e.preventDefault()
        if(name === '' || weightStart === '' || weightEnd === '' || unitPrice === ''){
            toast.warning('Complete all fields')
            return
        }
        const editedRate = {name, weightStart, weightEnd, unitPrice}
        const data = {
            rateId: rate._id,
            editedRate
        }
        dispatch(updateRate(data))
    }
    const handleClose = () => {
        setShow(false)
    }
    return (
        <div>
            <Button size='sm' variant="info" className='m-0 py-0 px-3' onClick={handleShow}>Edit</Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                {updateLoading ?
                <>
                <Spinner animation="border" />
                <Modal.Title> Please wait adding rate...</Modal.Title>
                </>
                :
                <Modal.Title>Add New Rate</Modal.Title>
                }
                </Modal.Header>
                <Modal.Body>
                    {updateError && <AlertMessage msg={updateMessage} />}
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Cargo Type</Form.Label>
                            <Form.Select onChange={({target})=>setName(target.value)}>
                                <option value=''>Choose</option>
                                <option value='air'>Air</option>
                                <option value='ocean'>Ocean</option>
                                <option value='express'>Express_Ocean</option>
                            </Form.Select>
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Start Weight <span className='text-muted'>(lb)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={weightStart}
                                        min='1'
                                        max='500'
                                        onChange={({target})=>setWeightStart(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>End Weight <span className='text-muted'>(lb)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={weightEnd}
                                        min='1'
                                        max='500'
                                        onChange={({target})=>setWeightEnd(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Unit Price <span className='text-muted'>($)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={unitPrice}
                                        min='1'
                                        max='100'
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onChange={({target})=>setUnitPrice(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EditRateModal