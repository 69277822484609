import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MdHome } from 'react-icons/md'

const NotFoundPage = () => {
    const navigate = useNavigate()
  return (
    <div style={{width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
        <div style={{width: 'auto', padding: '100px', textAlign: 'center',borderRadius: '50px'}}>
            <h1 className='gradient-text' style={{fontSize: '200px'}}>404</h1>
            <p className='gradient-text' style={{fontSize: '50px',  fontWeight: '900'}}>Page Not Found</p>
            <button
                style={{
                    width: '300px',
                    height: '50px',
                    border: 'none',
                    background: 'var(--color-gold)',
                    fontSize: '20px',
                    borderRadius: '20px'
                }}
                onClick={()=>navigate('/')}
            ><MdHome size={30} /> Go Back Home</button>
        </div>
    </div>
  )
}

export default NotFoundPage