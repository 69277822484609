import styles from './staffscreens.module.css'
import { useSelector,useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { useNavigate, useParams, Link } from "react-router-dom"
import { getAccounts, deleteAccount } from "../../features/staff/staffSlice"
import { Badge, Button, Card, Col, Form, Row } from "react-bootstrap"
import { AlertMessage, DeleteConfirm } from "../../Components"
import { MdCoPresent,MdAdd } from 'react-icons/md'
import { LinkContainer } from 'react-router-bootstrap'
import {MySpinner} from '../../Components'

const StaffAccountListScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {search} = useParams()
  const [keyword, setKeyword] = useState('')
  const { staffList, listLoading, listError, listSuccess, listMessage} = useSelector(state=>state.staff.list)
  const { deleteLoading, deleteError, deleteMessage} = useSelector(state=>state.staff.delete)
  const { staff } = useSelector(state=>state.staff.auth)

  useEffect(()=>{
    dispatch(getAccounts(search))
  },[dispatch,search])

  // const handleDelete = (id) => {
  //   if(window.confirm('Are you sure you want to do this?')){
  //     dispatch(deleteAccount(id))
  //   }
  // }
  return (
    <div>
      <Row className="mb-3">
        <Col md={6} className='mb-3'>
          <Form.Control            
            placeholder={search ? search : "Search staff..."}
            value={keyword}
            onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                if(keyword === ''){
                  navigate(`/staff/accounts`)
                } else {
                  navigate(`/staff/accounts/search/${keyword}`)
                }                    
              }
            }}
          />          
        </Col>
        <Col>
          <LinkContainer to='/staff/accounts/add'>
            <Button variant='outline-secondary text-dark'><MdAdd size={20} /> New Staff</Button>
          </LinkContainer>
        </Col>
      </Row>
      
        
      {listLoading && <MySpinner />}
      {deleteLoading && <MySpinner />}
      {listError && <AlertMessage msg={listMessage} />}
      {deleteError && <AlertMessage msg={deleteMessage} />}
      
      {listSuccess &&
        <Row>
          {staffList.map(stf=>(
            <Col md={6} key={stf._id} className='mb-3'>
              <Card className={styles.staff_account_card}>
                <Card.Body>
                  <div className={styles.staff_account_card_title}>
                    <div className={styles.staff_account_name}>
                      <Card.Title><MdCoPresent className='me-2' size={25}/>{stf.name}</Card.Title>
                      <Badge 
                        bg={stf.isSuspended ? 'danger' : 'success'} 
                        style={{height: '20px'}}
                        className='mt-1 ms-3'
                        >
                          {stf.isSuspended ? 'Suspended' : 'Active'}</Badge>
                        {stf.isAdmin && <Badge style={{height: '20px'}} className='mt-1 ms-3' bg='dark'>Admin</Badge>}
                    </div>
                    
                  </div>
                  <div className={styles.staff_account_card_body}>
                    <Card.Subtitle className="mb-2 text-muted">{stf.position ? stf.position : 'Position undefined'}, {stf.email}</Card.Subtitle>
                    <Card.Text>{stf.location ? stf.location : 'Location undefined'}, Joined on {new Date(stf.createdAt).toLocaleString("en-US",{ year: 'numeric', month: 'long', day: 'numeric' })}</Card.Text>

                  </div>
                </Card.Body>
                {staff.isAdmin &&
                    <Card.Footer>
                      <Link to='/staff/accounts/update' state={stf}>
                        <Button variant='info' size='sm' className='me-2' style={{width: '60px'}}>Edit</Button>
                      </Link>
                      {/* <Button variant='info' size='sm'
                        disabled={staff._id === stf._id}
                        onClick={()=>handleDelete(stf._id)}
                      >Delete</Button> */}
                      <DeleteConfirm 
                        type=' staff account ' 
                        name={stf.name} 
                        id={stf._id} 
                        stateName={'staff'}
                        deleteFunction={deleteAccount} 
                        //reset={deleteReset}
                        //location='/staff/customers'
                      />
                    </Card.Footer>
                    }
              </Card>
            </Col>
          ))}
        </Row>
      }
    </div>
  )
}

export default StaffAccountListScreen