const faq = [
    {
        title: "BEFORE YOU SHIP",
        list: [
            {
                question: "HOW OFTEN DO YOU SHIP FROM NEW YORK TO YANGON?",
                answer: "For Air Cargo, we ship every Tue or Wed, and our cut-off is every Monday at 3PM. For Sea Cargo, our cut-off is every 3rd Wednesday of the every month. Please send your package before the cut-off dates."
            },
            {
                question: "WHAT IS AIR/SEA FREIGHT SHIPPING COST FROM NEW YORK TO YANGON?",
                answer: "Please refer to this chart for Air Freight Cost. Please refer to this chart for Sea Freight Cost."
            },
            {
                question: "DO YOU PROVIDE PICK UP SERVICE?",
                answer: "Yes, we provide FREE pick-up service within one-mile radius."
            },
            {
                question: "IS 3RD PARTY PICK UP AVAIABLE?",
                answer: "Yes, 3rd party pick up via UPS is available. Please provide us with Pick Up Address, Box Weight & Dimensions for a quote."
            },
            {
                question: "WHERE CAN I GET A PACKING LIST?",
                answer: "Please contact us via phone, email (nyseashipping@gmail.com) or reach out to one of our staff by messaging us on Facebook. We will provide your with a Packing List."
            },
            {
                question: "HOW DO I WRITE A PACKING LIST?",
                answer: "list items"
            },
            {
                question: "I DON'T HAVE TIME TO WRITE A PACKING LIST, CAN YOU HELP WRITE A PACKING LIST FOR ME?",
                answer: "Sure! We write your Packing List for free as long as they are less than 10 items. If your packages have more than 10 different items, we charge a $25 Packing List Documentation Fee. Please write your own Packing List to avoid this cost."
            },
            {
                question: "CAN I BUY BOXES AT YOUR WAREHOUSE? HOW MUCH WILL IT COST?",
                answer: "Yes, you can pick from three box sizes. M: 18'' x 18''x 16'' (Weight limit: 65 LBS), L: 18'' x 18''x 24'' (Weight limit: 85 LBS), XL: 18'' x 18''x 28'' (Weight limit: 110 LBS). One box cost $5. All boxes cost the same. Purchase of a box includes FREE Yangon Local Delivery."
            },
            {
                question: "DO YOU PROVIDE REPACKAGING SERVICE?",
                answer: "Yes, we do. You can buy one of our boxes and we will repack it for you with a charge of $5 for each box. To avoid this cost, please pack your boxes before dropping off. Please refer to the Packing Tips below!"
            },
            {
                question: "WHAT'S THE BEST WAY TO PACK MY PACKAGES?",
                answer: "list items"
            },
            {
                question: "I WOULD LIKE TO SHIP DIRECTLY TO YOUR WAREHOUSE VIA AMAZON/COSTCO/BESTBUY/BJ/WALMART/SEPHORA/ETC. IS THAT POSSIBLE? HOW SHOULD I LABEL MY PACKAGES?",
                answer: "Yes, you may ship directly to our warehouse. For items being shipped directly to our facility, please use the following format."
            },
            {
                question: "DO YOU PROVIDE INSPECTION SERVICE?",
                answer: "No, we do not. Inspection service is not provided unless requested by customers for a negotiated price according to the amount of packages and items you are shipping. Otherwise, all packages go to Myanmar the same way customers sent to our warehouse."
            },
            {
                question: "HOW MUCH INSURANCE IS COVERED IF MY PACKAGES GET LOST OR DAMAGED?",
                answer: "Insurance of 3% is covered for products value declared in the Packing List. For full insurance coverage, 30% of the declared value in packing list must be paid to qualify for FULL insurance coverage. Purchase receipt or invoice of shipped package must be provided if requested by the shipper."
            },
            {
                question: "HOW MUCH IS CUSTOM DUTY FEE FOR ELECTRONICS AND CAR PARTS?",
                answer: "Please refer to this chart for electronic and car parts custom duty fee. Custom Duty Fee"
            },
            {
                question: "WHAT'S THE PAYMENT POLICY?",
                answer: "Payment must be prepaid in the US before shipment. If customers can only make payment in Myanmar, customers must pay 3% of the transfer fee."
            }
        ]
    },
    {
        title: "DURING SHIPMENT",
        list: [
            {
                question: "HOW LONG WILL IT TAKE FOR MY PACKAGES TO ARRIVE IN YANGON?",
                answer: "For air cargo, the lead time is 7~14 days. For sea cargo, the lead time is 70~85 days."
            },
          
         
        ]
    },
    {
        title: "AFTER PACKAGE ARRIVAL",
        list: [
            {
                question: "HOW DO I KNOW IF MY PACKAGES HAVE ARRIVED IN YANGON?",
                answer: "We announce our package arrive on our Facebook page. Please check our page for your shipment location update. Once the packages are ready for pick up or delivery our Myanmar Team will contact your receiver."
            },
            {
                question: "WHERE CAN I COME PICK UP MY PACKAGES?",
                answer: "You can pick it up at: No. 273/19, Ayechantharyar Street, Middle Shwegondine Ward, Bahan Township, Yangon , Myanmar. Note: Please call our Myanmar representative at +959 502-1148 before coming for pick up.",
            },
            {
                question: "DO YOU PROVIDE YANGON DELIVERY SERVICE?",
                answer: "We provide FREE local delivery for packages over 50 LBS. For packages below 50 LBS, delivery fee is $5. Please note that FREE local delivery time is determined by our courier and packages will only be delivered to the ground floor. Please contact our team for earlier delivery service and non-ground delivery for a negotiated price.",
            },
            {
                question: "DO YOU PROVIDE DELIVERY TO OTHER REGIONS OF MYANMAR?",
                answer: "Yes, we do. We provide delivery to other regions of Myanmar with a price of 15,000 MMK (~ 10 USD).",
            }         
        ]
    },
    

]

export { faq }