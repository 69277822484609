import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import cutoffServices from './cutoffService'
import { toast } from 'react-toastify';

const initialState = {
    list: {
        cutofflist: [],  
        page: null,
        pages: null,
        listLoading : false,
        listError : false,
        listSuccess : false,
        listMessage : '',
    },
    add: {
        cutoff: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    detail: {
        cutoff: null,  
        detailLoading : false,
        detailError : false,
        detailSuccess : false,
        detailMessage : '',
        detailFilter: false
    },
    update: {
        cutoff: null,
        updateLoading : false,
        updateError : false,
        updateSuccess : false,
        updateMessage : '',
    },
    delete: {
        deleteLoading : false,
        deleteError : false,
        deleteSuccess : false,
        deleteMessage : '',
    },
    tracking: {
        add : {
            addCutoffTrackingLoading : false,
            addCutoffTrackingError : false,
            addCutoffTrackingSuccess : false,
            addCutoffTrackingMessage : '',
        },
        delete : {
            deleteCutoffTrackingLoading : false,
            deleteCutoffTrackingError : false,
            deleteCutoffTrackingSuccess : false,
            deleteCutoffTrackingMessage : '',
        }
    },

}

//get cut off list
export const getCutOffList = createAsyncThunk('Cutoff_List', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.getCutOffList(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//create cutoff
export const createCutOff = createAsyncThunk('Cutoff_Create', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.createCutOff(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//get cut off detail
export const getCutOffDetail = createAsyncThunk('Cutoff_Detail', async (id, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.getCutOffDetail(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get cut off detail
export const cutOffShipmentFilter = createAsyncThunk('Cutoff_Shipment_Filter', async (id, thunkAPI) => {
    try {          
        return id
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete cut off
export const deleteCutOff = createAsyncThunk('Cutoff_Delete', async (id, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.deleteCutOff(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//add-remove shipment from cut off
export const updateCutOff = createAsyncThunk('Cutoff_Add_Remove_Shipment', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.updateCutOff(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//add cutoff tracking
export const addCutOffTracking = createAsyncThunk('Cutoff_Add_Tracking', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.addCutOffTracking(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete cutoff tracking
export const deleteCutOffTracking = createAsyncThunk('Cutoff_Delete_Tracking', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await cutoffServices.deleteCutOffTracking(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


export const cutoffSlice = createSlice({
    name: 'Cutoff',
    initialState,
    reducers: {
        reset: () => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
            state.add.cutoff = null
        },
        deleteReset: (state) => {
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        },
        updateReset: (state) => {
            state.update.updateLoading = false
            state.update.updateError = false
            state.update.updateSuccess = false
            state.update.updateMessage = ''
        }
    },
    extraReducers: (builder) => {
        builder        
        //get get off list
        .addCase(getCutOffList.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
        }) 
        .addCase(getCutOffList.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = false
            state.list.listSuccess = true
            state.list.cutofflist = action.payload.cutofflist
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getCutOffList.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.cutofflist = []
        })
        //get cut off list
        .addCase(createCutOff.pending, (state)=>{
            state.add.addLoading = true
            state.add.addError = false
            state.add.addSuccess = false
        }) 
        .addCase(createCutOff.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = true
            state.add.addMessage = ''
            state.add.cutoff = action.payload
            toast.success('Cutoff created successfully.')
        })  
        .addCase(createCutOff.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = true
            state.add.addSuccess = false
            state.add.addMessage = action.payload
            state.add.cutoff = null
        })
        //get cut off detail
        .addCase(getCutOffDetail.pending, (state)=>{
            state.detail.detailLoading = true
            state.detail.detailError = false
            state.detail.detailSuccess = false
            state.detail.detailFilter = false
        }) 
        .addCase(getCutOffDetail.fulfilled, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = false
            state.detail.detailSuccess = true
            state.detail.detailMessage = ''
            state.detail.detailFilter = false
            state.detail.cutoff = action.payload
        })  
        .addCase(getCutOffDetail.rejected, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = true
            state.detail.detailSuccess = false
            state.detail.detailFilter = false
            state.detail.detailMessage = action.payload
            state.detail.cutoff = null
        })
        //cutoff shipment filter
        .addCase(cutOffShipmentFilter.fulfilled, (state, action)=>{
            state.detail.cutoff.shipmentIds = state.detail.cutoff.shipmentIds.filter(item=> new RegExp(action.payload, 'i').test(item.shipmentId))
            state.detail.detailFilter = true
        }) 
        //delete cut off
        .addCase(deleteCutOff.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
        }) 
        .addCase(deleteCutOff.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = true
            state.delete.deleteMessage = ''
            toast.success('Cutoff deleted.')
        })  
        .addCase(deleteCutOff.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })
        //add-remove shipment from cut off
        .addCase(updateCutOff.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateError = false
            state.update.updateSuccess = false
        }) 
        .addCase(updateCutOff.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = false
            state.update.updateSuccess = true
            state.update.updateMessage = ''
            state.detail.cutoff = action.payload
        })  
        .addCase(updateCutOff.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateSuccess = false
            state.update.updateMessage = action.payload
        })
        //add cutoff tracking
        .addCase(addCutOffTracking.pending, (state)=>{
            state.tracking.add.addCutoffTrackingLoading = true
            state.tracking.add.addCutoffTrackingError = false
            state.tracking.add.addCutoffTrackingSuccess = false
        }) 
        .addCase(addCutOffTracking.fulfilled, (state, action)=>{
            state.tracking.add.addCutoffTrackingLoading = false
            state.tracking.add.addCutoffTrackingError = false
            state.tracking.add.addCutoffTrackingSuccess = true
            state.tracking.add.addCutoffTrackingMessage = ''
            toast.success('Tracking added.')
            state.detail.cutoff.tracking.unshift(action.payload)
        })  
        .addCase(addCutOffTracking.rejected, (state, action)=>{
            state.tracking.add.addCutoffTrackingLoading = false
            state.tracking.add.addCutoffTrackingError = true
            state.tracking.add.addCutoffTrackingSuccess = false
            state.tracking.add.addCutoffTrackingMessage = action.payload
        })
        //delete cutoff tracking
        .addCase(deleteCutOffTracking.pending, (state)=>{
            state.tracking.delete.deleteCutoffTrackingLoading = true
            state.tracking.delete.deleteCutoffTrackingError = false
            state.tracking.delete.deleteCutoffTrackingSuccess = false
        }) 
        .addCase(deleteCutOffTracking.fulfilled, (state, action)=>{
            state.tracking.delete.deleteCutoffTrackingLoading = false
            state.tracking.delete.deleteCutoffTrackingError = false
            state.tracking.delete.deleteCutoffTrackingSuccess = true
            state.tracking.delete.deleteCutoffTrackingMessage = ''
            toast.success('Tracking deleted.')
        })  
        .addCase(deleteCutOffTracking.rejected, (state, action)=>{
            state.tracking.delete.deleteCutoffTrackingLoading = false
            state.tracking.delete.deleteCutoffTrackingError = true
            state.tracking.delete.deleteCutoffTrackingSuccess = false
            state.tracking.delete.deleteCutoffTrackingMessage = action.payload
        })
    },
})


export const { reset, addReset, deleteReset, updateReset } = cutoffSlice.actions
export default cutoffSlice.reducer