import './contact.css'
import { Row, Col, Image } from 'react-bootstrap'
import { MdLocalPhone, MdSmartphone, MdOutlineEmail } from 'react-icons/md'
import { useTranslation } from 'react-i18next'
import { nyTeam, ygTeam } from './data'
import { yg, ny} from '../../../assets'

const Contact = () => {
  const {t} = useTranslation()
  return (
    <>
    <div className='contact-main' id='team'>
      {/* <p className='gradient-text text-center fs-1' style={{fontSize: '3rem', lineHeight : '100px'}}>{t('Talk to our Team')}!</p> */}
      {/* <div className='team-section'>        

        <div className='team-card'>
          <div className='team-card-up'>
              <MdAccountCircle size={70} />
              <div>
                <h4>Nipapone Khempilar</h4>
                <h4>(Nicky)</h4>
                <p>CEO & Co-founder</p>
              </div>
          </div>
          <div className='team-card-down'>
            <a href='tel:+17188998389'><MdLocalPhone /> Office : (718) 899-8389</a>
            <a href='tel:+16468251621'><MdSmartphone /> Mobile  : (646) 825-1621</a>
            <a href='tel+18772553993'><MdPointOfSale /> Toll Free : (877) 255-3993</a>
          </div>
        </div>

        <div className='team-card'>
          <div className='team-card-up'>
              <MdAccountCircle size={70} />
              <div>
                <h4>Zaw Than Htaik</h4>
                <p>CEO & Co-founder</p>
              </div>
          </div>
          <div className='team-card-down'>
            <a href='tel:+17188998389'><MdLocalPhone /> Office : (718) 899-8389</a>
            <a href='tel:+19293209957'><MdSmartphone /> Mobile  : (929) 320-9957</a>
            <a href='mailto:zaw.nyss@gmail.com'><MdOutlineEmail /> Email : zaw.nyss@gmail.com</a>
          </div>
        </div>

        <div className='team-card'>
          <div className='team-card-up'>
              <MdAccountCircle size={70} />
              <div>
                <h4>Ei Ze Yar</h4>
                <p>General Manager </p>
                <p className='fs-6'>(Sales & Operations) Yangon, Myanmar</p>
              </div>
          </div>
          <div className='team-card-down'>
            <a href='tel:+9595021148'><MdSmartphone /> Mobile  : +959 5021148</a>
            <a href='mailto:eizeyarnyssm@gmail.com<'><MdOutlineEmail /> Email : eizeyarnyssm@gmail.com</a>
          </div>
        </div>

        <div className='team-card'>
          <div className='team-card-up'>
              <MdAccountCircle size={70} />
              <div>
                <h4>Youvapa Maida</h4>
                <h4>(Deng) MD, DC, VA Location</h4>
                <p>Agent</p>
              </div>
          </div>
          <div className='team-card-down'>
            <a href='tel:+13018144953'><MdSmartphone /> Mobile  : (301)814-4953</a>
          </div>
        </div>
      </div> */}
      

      {/* <div className='contact-general'>
        <div className='contact-general-item'>
          <h3>THAILAND</h3>
          <a href='tel:+6621076066'><MdLocalPhone /> Direct Call : 02-107 6066</a>
        </div>
        <div className='contact-general-item'>
          <h3>HOURS</h3>
          <p>Monday-Friday: 9AM - 5:30PM</p>
          <p>Saturday: 9AM - 4PM </p>
          <p>Sunday: By appointment only</p>
        </div>
        <div className='contact-general-item'>
          <h3>EMAIL</h3>
          <div><a href='mailto:info@nyseashipping.com'><MdOutlineEmail /> info@nyseashipping.com</a></div>
          <div><a href='mailto:nyseashipping@gmail.com'><MdOutlineEmail /> nyseashipping@gmail.com</a></div>
        </div>
      </div> */}      
    
    <p className='gradient-text text-center fs-1' style={{fontSize: '3rem', lineHeight : '50px'}}>{t('Talk to our Team')}!</p>
    <Row>
      <Col xs={12}>        
        <Row>
          <Col xs={12} className='d-flex border-bottom mb-3' >
            <Image src={ny} rounded  className='team_icon' style={{height: '100px'}} />   
            {/* <div className='text-center ms-auto pt-5 fs-5'><MdOutlineLocationOn size={30} /> 3739 58<sup>th</sup> Street, Woodside, New York 11377</div>          */}
          </Col>
          <Col xs={12} >
            <Row>
              {nyTeam.map((t,i)=>(
                <Col xs={12} sm={6} md={3} key={i} className='d-flex justify-content-center p-2'>
                  <div className='w-100 new_card border rounded py-2 px-3' style={{overflow: 'hidden'}}>
                    <div className='fw-bold border-bottom' style={{fontSize: '1.1rem'}}>{t.name.toUpperCase()}</div>
                    <div><MdLocalPhone /> OFFICE : {t.office1}</div>
                    <div><MdSmartphone /> MOBILE : {t.mobile1}</div>
                    <div className='new_card_link'><a href={`mailto:${t.email}`}><MdOutlineEmail /> EMAIL : {t.email}</a></div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
          
        </Row>
      </Col>

      <Col xs={12} className='mt-5'>
        <Row>
          <Col xs={12} className='d-flex border-bottom mb-3'>
            <div></div>
            {/* <div className='text-center pt-5 fs-5'><MdOutlineLocationOn size={30} /> No. 351, Room 6, Yan Aye 2 Lane, 5 Ward, West Yankin. Yankin Township. Yangon, Myanmar.</div>  */}
            {/* <div className='gradient-text' style={{lineHeight: '50px', fontSize: '4rem', paddingTop: '40px'}}>YG TEAM</div> */}
            <Image src={yg} rounded style={{height: '100px'}} className='team_icon ms-auto' />            
          </Col>
          <Col xs={12} >
            <Row>
              {ygTeam.map((t,i)=>(
                <Col xs={12} sm={6} md={3} key={i} className='d-flex justify-content-center p-2'>
                  <div className='w-100 new_card border rounded py-2 px-3' style={{overflow: 'hidden'}}>
                    <div className='fw-bold border-bottom' style={{fontSize: '1.1rem'}}>{t.name.toUpperCase()}</div>
                    <div><MdLocalPhone /> OFFICE : {t.office1}</div>
                    <div><MdSmartphone /> MOBILE : {t.mobile1}</div>
                    <div><MdOutlineEmail /> EMAIL : {t.email}</div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
    </div>

    {/* <div>AIzaSyBoc5LobvWVXy2z0yxdzQFevhtQUeiOD1E</div> */}
    <Row className='d-flex contact-map'>
      <Col md={6} className='w-'>
        <iframe 
          //src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBoc5LobvWVXy2z0yxdzQFevhtQUeiOD1E&q=Space+Needle,Seattle+WA"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317.71039646617345!2d-73.9047116822445!3d40.74909672147307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25f1c75d86e1f%3A0x37509f5a1118ce7d!2sNY%20SEA%20SHIPPING%2C%20LLC!5e0!3m2!1sen!2sth!4v1676867770909!5m2!1sen!2sth" 
          width="100%" 
          height="450" 
          loading="lazy" 
          referrerPolicy="no-referrer-when-downgrade"
          title='nyssnylocation'
        ></iframe>
      </Col>

      <Col md={6} className=''>
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3818.998888777087!2d96.1590132598204!3d16.82641122425543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30c193515cff49ed%3A0xa4d3fdcba44457b6!2zNSBZYW4gQXllIExhbmUgMiwgWWFuZ29uLCDguYDguKHguLXguKLguJnguKHguLLguKPguYwgKOC4nuC4oeC5iOC4sik!5e0!3m2!1sth!2sth!4v1686888111768!5m2!1sen!2sth" 
          width="100%" 
          height="450" 
          loading="lazy" 
          title='nyssyglocation'
        ></iframe>
      </Col>
    </Row>
    
    </>
  )
}

export default Contact