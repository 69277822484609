import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { MdAdd } from 'react-icons/md'
import { AlertMessage, MySpinner } from '../Components'
import { emptyCheck } from '../helpers';
import { toast } from 'react-toastify';
import { addressAddCustomer, addressAddReset } from '../features/customerAccount/customerAccountSlice';
import { addReceiver } from '../features/customer/customerSlice';
import { countries } from '../data/countries';

const AddressAddModal = ({customerId, creator}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false)
    const [empty, setEmpty] = useState([])
    const [receiverAddress, setReceiverAddress] = useState({
        name: "",
        email: "",
        company: "",
        street: "",
        city: "",
        state: "",
        postalcode: "",
        country: "",
        phonenumber1: "",
        phonenumber2: "",
        remark: "",
    })
    const { addressAddLoading, addressAddSuccess, addressAddError, addressAddMessage } = useSelector(state=>state.customerAccount.addressAdd)
    const { receiverAddLoading, receiverAddSuccess, receiverAddError, receiverAddMessage } = useSelector(state=>state.customer.receiverAdd)
    useEffect(()=>{
        if(addressAddSuccess || receiverAddSuccess){
            setReceiverAddress({
                name: "",
                email: "",
                company: "",
                street: "",
                city: "",
                state: "",
                postalcode: "",
                country: "",
                phonenumber1: "",
                phonenumber2: "",
                remark: "",
            })
            setEmpty([])
            setShow(false)
        }
    },[addressAddSuccess, receiverAddSuccess])
    const handleShow = () => setShow(true);

    const dataToCheck = [
      {'rname': receiverAddress.name},
      {'rstreet': receiverAddress.street},
      {'rcity': receiverAddress.city},
      {'rstate': receiverAddress.state},
      {'rpostalcode': receiverAddress.postalcode},
      {'rcountry': receiverAddress.country},
      {'rphonenumber1': receiverAddress.phonenumber1}
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        const emptyList = await emptyCheck(dataToCheck)
        if(emptyList.length !== 0){
            setEmpty(emptyList)
            toast.error('Please check required fields')
            return
        } else {
            //console.log(customerId, receiverAddress)
            const data = {customerId, receiverAddress}
            if(creator === 'staff'){dispatch(addressAddCustomer(data))}
            if(creator === 'customer'){dispatch(addReceiver(data))}
        }
    }
    const handleClose = () => {
        setReceiverAddress({
            name: "",
            email: "",
            company: "",
            street: "",
            city: "",
            state: "",
            postalcode: "",
            country: "",
            phonenumber1: "",
            phonenumber2: "",
            remark: "",
        })
        setEmpty([])
        setShow(false)
        dispatch(addressAddReset())
    }
    return (
        <>
            <Button style={{height: '30px'}} onClick={handleShow} className="m-0 py-0" variant="success" ><MdAdd className="mb-1" />New</Button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton><Modal.Title>Add New Address</Modal.Title> </Modal.Header>
                <Modal.Body>
                    {addressAddLoading && <MySpinner />}
                    {receiverAddLoading && <MySpinner />}
                    {addressAddError && <AlertMessage msg={addressAddMessage} />}
                    {receiverAddError && <AlertMessage msg={receiverAddMessage} />}
                    <Form.Group className='mb-1'>
                        <Form.Label>Name <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.name}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, name: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rname')])
                            }}
                            isInvalid={empty.includes('rname')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>Company</Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.company}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, company: e.target.value})
                            }}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>Address <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.street}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, street: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rstreet')])
                            }}
                            isInvalid={empty.includes('rstreet')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>City <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.city}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, city: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rcity')])
                            }}
                            isInvalid={empty.includes('rcity')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>State/Province <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.state}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, state: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rstate')])
                            }}
                            isInvalid={empty.includes('rstate')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>Postal Code <small className='text-danger'>* required</small></Form.Label>
                        <Form.Control 
                            type='text'
                            value={receiverAddress.postalcode}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, postalcode: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rpostalcode')])
                            }}
                            isInvalid={empty.includes('rpostalcode')}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>Select Country <small className='text-danger'>* required</small></Form.Label>
                        <Form.Select value={receiverAddress.country}
                            onChange={(e)=>{
                            setReceiverAddress({...receiverAddress, country: e.target.value})
                            setEmpty([...empty.filter(x=>x!=='rcountry')])
                            }}
                            isInvalid={empty.includes('rcountry')}
                        >
                            {countries.map(c=>(
                                <option key={c.name} value={c.value}>{c.name}</option>
                            ))}
                        </Form.Select>
                    </Form.Group>
                    <Row className='mt-2'>
                        <Col md={6}>
                            <Form.Group className="mb-2" controlId="receiver-phonenumber1">
                            <Form.Label>Phone Number 1 <small className='text-danger'>* required</small></Form.Label>
                            <Form.Control 
                                type="text" 
                                value={receiverAddress.phonenumber1}
                                onChange={({target})=>{
                                setReceiverAddress({...receiverAddress, phonenumber1: target.value.replace(/\D/,'')})
                                setEmpty([...empty.filter(x=>x!=='rphonenumber1')])
                                }} 
                                isInvalid={empty.includes('rphonenumber1')}
                            />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group className="mb-2" controlId="receiver-phonenumber2">
                            <Form.Label>Phone Number 2</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={receiverAddress.phonenumber2}
                                onChange={({target})=>setReceiverAddress({...receiverAddress, phonenumber2: target.value.replace(/\D/,'')})} 
                            />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group className='mb-1'>
                        <Form.Label>Email</Form.Label>
                        <Form.Control 
                            type='email'
                            value={receiverAddress.email}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, email: e.target.value})
                            }}
                        /> 
                    </Form.Group>

                    <Form.Group className='mb-1'>
                        <Form.Label>Remark</Form.Label>
                        <Form.Control 
                            as='textarea'
                            value={receiverAddress.remark}
                            onChange={e=>{
                            setReceiverAddress({...receiverAddress, remark: e.target.value})
                            }}
                        /> 
                    </Form.Group>
                  
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddressAddModal