import styles from './customerloginscreen.module.css'
import { logogold } from '../../../assets'
import { MdKeyboardArrowRight, MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { useState, useEffect } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { authReset, forgotPasswordCustomer, forgotReset, login, confirmPasswordResetCodeCustomer, confirmReset, changePasswordCustomer, passwordReset } from '../../../features/customer/customerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { AlertMessage, MySpinner } from '../../../Components'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Alert } from 'react-bootstrap'

const CustomerLoginScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [ email, setEmail ] = useState('')
  const [ password, setPassword ] = useState('')
  const [ view, setView ] = useState(false)
  const [ newView, setNewView ] = useState(false)
  const [ forgotPassWord, setForgotPassword ] = useState(false)
  const [ code, setCode ] = useState('')
  const [ newPassword, setNewPassword ] = useState('')

  const { authError, authSuccess, authLoading, authMessage } = useSelector(state=>state.customer.auth)
  const { forgotEmail, forgotError, forgotSuccess, forgotLoading, forgotMessage } = useSelector(state=>state.customer.forgot)
  const { confirmEmail, confirmError, confirmSuccess, confirmLoading, confirmMessage } = useSelector(state=>state.customer.confirm)
  const { passwordError, passwordSuccess, passwordLoading, passwordMessage } = useSelector(state=>state.customer.password)

  function checkPassword(str){
      var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      return re.test(str);
  }
  
  const getData = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/")
      return res.data.IPv4
    } catch (error) {
      return email
    }    
  }

  useEffect(()=>{
    if(authSuccess){
      dispatch(passwordReset())
      navigate('/shipments')
    }
    if(passwordSuccess){
      setForgotPassword(prev=>!prev)
      setCode('')
      dispatch(confirmReset())
      dispatch(forgotReset())
      dispatch(authReset())
    }
  },[authSuccess, navigate, passwordSuccess, dispatch])

  const handleSubmit = async (e) => {
    e.preventDefault()  
    if(email === '' || password === ''){
      toast.warning('Email (or) customer ID and password are required')
      return
    }
    const ip = await getData()
    dispatch(login({email, password, ip}))
  }

  const clickForgotPassword = () => {
    setForgotPassword(prev=>!prev)
    setCode('')
    dispatch(confirmReset())
    dispatch(forgotReset())
    dispatch(authReset())
  }

  const handleForgotPassword = () => {
    if(email === ''){
      toast.warning('Email is requried')
      return
    }
    
    dispatch(forgotPasswordCustomer(email))
  }

  const handleConfirmCode = () => {
    if(code === ''){
      toast.warning('Code is empty')
      return
    }
    const data = {
      email: forgotEmail,
      code,
    }
    dispatch(confirmPasswordResetCodeCustomer(data))
  }

  const changePassword = () => {
    const data = {
      newPassword,
      confirmEmail,
    }
    if(checkPassword(newPassword)){
      dispatch(changePasswordCustomer(data))
    }else{
      toast.warning('New pasword does not meet requirement.')
      return
    }    
  }

  return (
    <div className={styles.customer_login_main}>
      <div className={styles.customer_login_content}>
        <div className={styles.customer_login_img}>
          <img src={logogold} alt='logogold' />
        </div>
        <div className={styles.customer_login_form}>

            <h3>Customer Login</h3>
            <h5 className='border-bottom pb-2'>Don't have a NYSS account ? <LinkContainer to='/request'><span>Register</span></LinkContainer></h5>

            {confirmSuccess &&
              <div>
                {passwordLoading && <MySpinner />}
                {passwordError && <AlertMessage msg={passwordMessage} />}
                <div>
                  <p>New Password</p>
                  <div className={styles.form_password}>
                    <input
                      type={newView ? 'text' : 'password'}
                      value={newPassword}
                      onChange={({target})=>setNewPassword(target.value)}
                    />
                    <div className={styles.password_view} onClick={()=>setNewView(!newView)}>
                      {newView ? <MdVisibilityOff size={25} /> : <MdVisibility size={25} />}
                    </div>
                  </div>
                </div>
                <div style={{fontSize: '13px', marginTop: '10px'}}>
                  <span className='text-danger'>Requirement: </span><span>minimum 8 charators, mixed with uppercase, lowercase, special charactor and number.</span>
                </div>
                <div className={styles.forgot_password_button}>
                  <button type='button' onClick={()=>clickForgotPassword()}>Cancel</button>
                </div>

                <div className={styles.login_form_button}>
                    <button type='button' onClick={()=>changePassword()}>Submit <span><MdKeyboardArrowRight size={20} /></span></button>
                  </div>  
              </div>
            }

            {forgotSuccess &&
              <>
              <Alert variant='success'>Please check your email inbox/junk/spam folder for password reset code which is valid for 10 minutes</Alert>
              <div>
                  {confirmError && <AlertMessage msg={confirmMessage} />}
                  {confirmLoading && <MySpinner />}
                  <div className={styles.form_email}>
                    <p>Code</p>
                    <input 
                      type='text'
                      value={code}
                      onChange={({target})=>setCode(target.value)}
                      autoFocus
                    />
                  </div>
                  <div className={styles.forgot_password_button}>
                    <button type='button' onClick={()=>clickForgotPassword()}>Cancel</button>
                  </div>
                  <div className={styles.login_form_button}>
                    <button type='button' onClick={()=>handleConfirmCode()}>Next <span><MdKeyboardArrowRight size={20} /></span></button>
                  </div>              
                </div>
              </> 
            }


    
            {forgotPassWord && !forgotSuccess && !confirmSuccess &&
              <div>
                {forgotError && <AlertMessage msg={forgotMessage} />}
                {forgotLoading && <MySpinner />}
                <div className={styles.form_email}>
                  <p>Email</p>
                  <input 
                    type='text'
                    value={email}
                    onChange={({target})=>setEmail(target.value)}
                    autoFocus
                  />
                </div>
                <div className={styles.forgot_password_button}>
                  <button type='button' onClick={()=>clickForgotPassword()}>Back to Login</button>
                </div>
                <div className={styles.login_form_button}>
                  <button type='button' onClick={()=>handleForgotPassword()}>Next <span><MdKeyboardArrowRight size={20} /></span></button>
                </div>
              
              </div>
            }

            {!forgotPassWord && !forgotSuccess && !confirmSuccess &&
              <form onSubmit={handleSubmit}>
                {authError && <AlertMessage msg={authMessage} />}
                {authLoading && <MySpinner />}
                <div className={styles.form_email}>
                  <p>Email (or) Customer ID</p>
                  <input 
                    type='text'
                    value={email}
                    onChange={({target})=>setEmail(target.value)}
                    autoFocus
                  />
                </div>
                <div>
                  <p>Password</p>
                  <div className={styles.form_password}>
                    <input
                      type={view ? 'text' : 'password'}
                      value={password}
                      onChange={({target})=>setPassword(target.value)}
                    />
                    <div className={styles.password_view} onClick={()=>setView(!view)}>
                      {view ? <MdVisibilityOff size={25} /> : <MdVisibility size={25} />}
                    </div>
                  </div>
                </div>

                <div className={styles.forgot_password_button}>
                  <button type='button' onClick={()=>clickForgotPassword()}>Forgot password </button>
                </div>
                <div className={styles.login_form_button}>
                  <button type='submit'>Login <span><MdKeyboardArrowRight size={20} /></span></button>
                </div>
              </form>          
            }
          
          
            
        </div>
        
      </div>
    </div>
  )
}

export default CustomerLoginScreen