import { useState, useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux'
import styles from './modals.module.css'
import { Modal, Button, Spinner, Form, Row, Col } from 'react-bootstrap'
import { MdAdd } from 'react-icons/md'
import { addRate } from '../features/rate/rateSlice';
import { AlertMessage } from '../Components'
import { toast } from 'react-toastify';

const AddRateModal = () => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [name, setName] = useState('')
    const [weightStart, setWeightStart]= useState("")
    const [weightEnd, setWeightEnd] = useState("")
    const [unitPrice, setUnitPrice] = useState("")

    const { addError, addLoading, addSuccess, addMessage } = useSelector(state=>state.rate.add)
    
    useEffect(()=>{
        if(addSuccess){
            setName('')
            setWeightStart('')
            setWeightEnd('')
            setUnitPrice('')
            setShow(false)
        }
    },[addSuccess])
    const handleShow = () => setShow(true);
    const handleSubmit = (e) => {
        e.preventDefault()
        if(name === '' || weightStart === '' || weightEnd === '' || unitPrice === ''){
            toast.warning('Complete all fields')
            return
        }
        dispatch(addRate({name, weightStart, weightEnd, unitPrice}))
    }
    const handleClose = () => {
        setName('')
        setWeightStart('')
        setWeightEnd('')
        setUnitPrice('')
        setShow(false)
    }
    return (
        <div className={styles.add_item_main}>
            <Button variant="primary" onClick={handleShow} className='mb-3'>
                <MdAdd /> Add New Rate
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                size='lg'
            >
                <Modal.Header closeButton>
                {addLoading ?
                <>
                <Spinner animation="border" />
                <Modal.Title> Please wait adding rate...</Modal.Title>
                </>
                :
                <Modal.Title>Add New Rate</Modal.Title>
                }
                </Modal.Header>
                <Modal.Body>
                    {addError && <AlertMessage msg={addMessage} />}
                    <Form>
                        <Form.Group className='mb-3'>
                            <Form.Label>Cargo Type</Form.Label>
                            <Form.Select onChange={({target})=>setName(target.value)}>
                                <option value=''>Choose</option>
                                <option value='air'>Air</option>
                                <option value='ocean'>Ocean</option>
                                <option value='express'>Express_Ocean</option>
                            </Form.Select>
                        </Form.Group>

                        <Row>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Start Weight <span className='text-muted'>(lb)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={weightStart}
                                        min='1'
                                        max='500'
                                        onChange={({target})=>setWeightStart(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>End Weight <span className='text-muted'>(lb)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={weightEnd}
                                        min='1'
                                        max='500'
                                        onChange={({target})=>setWeightEnd(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={4}>
                                <Form.Group>
                                    <Form.Label>Unit Price <span className='text-muted'>($)</span></Form.Label>
                                    <Form.Control 
                                        type='number'
                                        value={unitPrice}
                                        min='1'
                                        max='100'
                                        pattern="^\d*(\.\d{0,2})?$"
                                        onChange={({target})=>setUnitPrice(target.value)}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddRateModal