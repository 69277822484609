import React from 'react'
import { Form } from 'react-bootstrap'

const StaffFAQCreateScreen = () => {
  return (
    <div>
        <div>
            <Form.Control
                as='textarea'
                style={{height: '500px', width: '100%'}}
            />
        </div>
    </div>
  )
}

export default StaffFAQCreateScreen