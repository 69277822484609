import { Form,Button, Container, Col, Spinner } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { register, addReset } from '../../features/staff/staffSlice'
import { AlertMessage } from '../../Components'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import  {toast} from 'react-toastify'
import { countries } from '../../data/countries'

const StaffAccountAddScreen = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [position, setPosition] = useState('')
  const [location, setLocation] = useState('')
  const [admin, isAdmin] = useState('false')
  const [password, setPassword] = useState('')
  const [passView, setPassView] = useState(false)

  const { addError, addLoading, addSuccess, addMessage} = useSelector(state => state.staff.add)

  useEffect(()=>{
    if(addSuccess){
      navigate('/staff/accounts')
    }
    return()=>{
      dispatch(addReset())
    }
  },[addSuccess, navigate, dispatch])

  const handleSubmit = (e) => {
    e.preventDefault()
    if(password.length < 8){
      toast.error('Password minimum length is 8 characters')
      return
    }
    const newUser = {
        name,
        email,
        password,
        location,
        position,
        admin: admin==='true' ? true : false,
    }
    dispatch(register(newUser))
  }
  return (
    <Container>
      <Col lg={8} className='mx-auto mt-5'>      
      <Form className='border p-4 shadow rounded' onSubmit={handleSubmit}>
        <h3 className='text-center'>{addLoading ? <Spinner /> : 'Create staff account'}</h3>
        {addError && < AlertMessage msg={addMessage} />}
        <Form.Group className="mb-3" >
          <Form.Label>Full Name *</Form.Label>
          <Form.Control 
            type="text" 
            value={name}
            required
            onChange={e=>setName(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" >
          <Form.Label>Email address *</Form.Label>
          <Form.Control 
            type="email" 
            value={email}
            required
            onChange={e=>setEmail(e.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>Password * <span className='text-danger'>(min 8 characters)</span></Form.Label>
          <Form.Control 
            type={passView ? 'text' : "password" }
            required 
            value={password}
            onChange={e=>setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="View password" onChange={()=>{setPassView((prev)=>!prev)}} />
        </Form.Group>

        <Form.Group className="mb-3" >
          <Form.Label>Position <span className='text-muted'>(Optional)</span></Form.Label>
          <Form.Control 
            type="text" 
            value={position}
            onChange={e=>setPosition(e.target.value)}
          />
        </Form.Group>

        <Form.Group>
            <Form.Label>Location <span className='text-muted'>(Optional)</span></Form.Label>
            <Form.Select value={location} aria-label="User Location" placeholder='Location' className="mb-3" onChange={(e)=>setLocation(e.target.value)}>
                {countries.map(c=>(
                  <option key={c.name} value={c.value}>{c.name}</option>
                ))}
            </Form.Select>
        </Form.Group>

        <Form.Group>
            <Form.Label>Admin Access</Form.Label>
            <Form.Select aria-label="Admin Access" className="mb-3" onChange={(e)=>isAdmin(e.target.value)}>
                <option value="">Select</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
            </Form.Select>
        </Form.Group>


        <div className='d-grid mt-5' >
        <Button variant="secondary" type="submit" className='mb-3'>
          Submit
        </Button>
        <LinkContainer to='/staff/accounts'>
          <Button variant='outline-secondary'>Cancel</Button>
        </LinkContainer>
        </div>
      </Form>
      </Col>
    </Container>
  )
}

export default StaffAccountAddScreen