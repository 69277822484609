import {useState, useEffect} from 'react'
//import styles from './customershipmentscreen.module.css'
import { Button, Row, Col, Form, Table } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MyPagination, MySpinner, AlertMessage } from '../../../Components'
import { getMyShipments } from '../../../features/shipment/shipmentSlice'
import { MdExitToApp } from 'react-icons/md'
import { useWindowSize } from '../../../Components'

const CustomerShipmentScreen = () => {
  const size = useWindowSize()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {searchKeyword} = useParams()
  const {pageNumber} = useParams()
  const [keyword, setKeyword] = useState('')

  const { shipments, listLoading, listError, listMessage, page, pages } = useSelector(state=>state.shipment.list)

  useEffect(()=>{
    dispatch(getMyShipments({searchKeyword, pageNumber, limit: 10}))
  },[dispatch, searchKeyword, pageNumber])

  return (
    <>   
      <div>
        <Row>
          <Col md={6} className='mb-3'>
              <Form.Control
                type='text'
                size='sm'
                value={keyword}
                placeholder={searchKeyword ? searchKeyword : 'search...'}
                onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
                onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                    if(keyword === ''){
                      navigate(`/shipments`)
                    } else {
                      navigate(`/shipments/search/${keyword}`)
                    }                    
                  }
                }}
              />
              {/* <Link to={`/staff/items/search/${keyword}`}>
                <Button variant="outline-secondary" >
                  Search
                </Button>
              </Link> */}
          </Col>
          <Col md={3} xs={6}>
            <div>
              <Button size='sm' onClick={()=>navigate('/shipments/create')}>Create New Shipment</Button>
            </div>
          </Col>
          <Col md={3} xs={6}>
            <MyPagination
              path={keyword !== "" ? `shipments/search/${keyword}` : 'shipments'} 
              pages={pages}
              page={page}
           />
          </Col>
        </Row>
      </div>

      {listError && <AlertMessage msg={listMessage} />}
      {listLoading && <MySpinner /> }

      {shipments && 
      <>
      {size.width > 750 ? 
        <Table striped hover bordered size='sm'>
          <thead>
            <tr className='text-center'>
              <th>#</th>
              <th>ID</th>
              <th>Cargo Type</th>
              <th>Created At</th>
              <th>Box Qty</th>
              <th>Receiver</th>
              <th>Total Weight</th>
              <th>Paid</th>
              <th>Last Status</th>
              <th>View Detail</th>
            </tr>
          </thead>
          <tbody>
            {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
              <tr key={s._id} className='text-center'>
                <td>{i+1}</td>
                <td>{s.shipmentId}</td>
                <td>{s.cargoType.toUpperCase()}</td>
                <td>{new Date(s.createdAt).toLocaleString('en-US',{month: '2-digit', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</td>
                <td>{s.boxes.length}</td>
                <td style={{fontSize: '15px'}} className='m-1 p-0'>
                  <div>{s.receiver.name}</div>
                  <div>{s.receiver.address.city}, {s.receiver.address.state}, {s.receiver.address.country}</div>
                </td>
                <td>{s.totalWeight.toFixed(2)} lbs</td>
                <td>{s.isPaid ? 'Yes' : 'No'}</td>
                <td>{s.tracking.length === 0 ? 'Shipment Created' : s.tracking[s.tracking.length-1].status}</td>
                <td className='text-center'>
                  <Button 
                    size='sm' 
                    variant='outline-primary' 
                    style={{height: '30px', width: '50px'}} 
                    className='m-0 p-0'
                    onClick={()=>navigate(`/shipments/${s._id}`)}
                  ><MdExitToApp size={30}  /></Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      :
      <Table striped>
          <thead>
            <tr>
              <th>#</th>
              <th>Detail</th>
              <th>View</th>
            </tr>
          </thead>
          <tbody>
            {shipments.length === 0 ? <tr><td>No Data Found.</td></tr> : shipments.map((s,i) => (
              <tr key={s._id}>
                <td>{i+1}</td>
                <td style={{fontSize: '15px'}}>
                  <div>ID - {s.shipmentId}</div>
                  <div>Cargo Type - {s.cargoType.toUpperCase()}</div>
                  <div>Box Qty - {s.boxes.length}</div>
                  <div>Receiver - {s.receiver.name}</div>
                  <div>{s.receiver.address.city}, {s.receiver.address.state}, {s.receiver.address.contry}</div>                  
                  <div>Total Weight - {s.totalWeight.toFixed(2)}</div>
                  <div>Paid - {s.isPaid ? 'Yes' : 'No'}</div>
                  <div>Status - {s.tracking.length === 0 ? 'Shipment Created' : s.tracking[s.tracking.length-1].status}</div>
                </td>
                <td className='text-center'>
                  <Button 
                    size='sm' 
                    variant='outline-primary' 
                    style={{height: '40px', width: '50px'}} 
                    className='m-0 p-0'
                    onClick={()=>navigate(`/shipments/${s._id}`)}
                  ><MdExitToApp size={30}  /></Button></td>
              </tr>
            ))}
          </tbody>
        </Table>
      }
      </>
      }
    </>
  )
}

export default CustomerShipmentScreen