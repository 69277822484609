import { useState, useEffect } from 'react'
import { Table, Button, Form, Alert } from "react-bootstrap"
import { toast } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addReset, addNewChargesName, getChargesNameList, deleteChargesName } from '../../features/chargesname/chargesnameSlice'
import { MySpinner, AlertMessage } from '../../Components'
import { MdDangerous } from 'react-icons/md'

const StaffChargesNameListScreen = () => {
    const dispatch = useDispatch()
    const [isAdd, setIsAdd] = useState(false)
    const [name, setName] = useState('')

    const { addLoading, addError, addSuccess, addMessage } = useSelector(state=>state.chargesname.add)
    const { deleteLoading, deleteError, deleteSuccess, deleteMessage } = useSelector(state=>state.chargesname.delete)
    const { chargesnames, listLoading, listError, listSuccess, listMessage } = useSelector(state=>state.chargesname.list)

    useEffect(()=>{
        dispatch(getChargesNameList())
    },[dispatch])

    useEffect(()=>{
        if(addSuccess){
            setName('')
            setIsAdd(false)
        }
    },[addSuccess, deleteSuccess])

    const handleAddNew = () => {
        if(name === ""){
            toast.warning('Name can not be empty')
            return
        }
        const newCharge = {
            name
        }
        dispatch(addNewChargesName(newCharge))
               
    }
    return (
        <div>
            {addLoading && <MySpinner />}
            {listLoading && <MySpinner />}
            {deleteLoading && <MySpinner />}
            {listError && <AlertMessage type='danger' msg={listMessage} />}
            {deleteError && <AlertMessage type='danger' msg={deleteMessage} />}
            
            <div className='d-flex justify-content-center'>
                <div style={{width: '500px'}}>
                        <div className="d-flex justify-content-between mb-3">
                            <div className="fs-2 text-center">Charges List</div>
                            <div className="pt-2">
                                <Button
                                    size='sm'
                                    variant={isAdd ? 'secondary' : 'primary'}
                                    onClick={()=>{
                                        setName("")
                                        dispatch(addReset())
                                        setIsAdd(prev=>!prev)
                                    }}
                                >{isAdd ? 'Cancel' : 'Add New'}</Button>
                            </div>
                        </div>

                        {isAdd && 
                            <div className='mb-4 border border-dark p-2 rounded shadow' >
                                {/* style={{position:'absolute', width: '500px', background: 'white'}} */}
                                <div>                                    
                                  <div className='mb-1'>Name</div>
                                  <div >
                                      <Form.Control 
                                          type='text'
                                          style={{height: '30px'}}
                                          className='w-100 border-dark'
                                          value={name}
                                          onChange={({target})=>setName(target.value)}
                                      />
                                  </div>                                    
                                </div>
                                {addError && <Alert variant='danger' className='my-2'>{addMessage}</Alert>}
                                <div className='d-flex mt-3 pe-1'>
                                    <Button
                                        className='ms-auto'
                                        variant='success'
                                        style={{width: '100px'}}
                                        size='sm'
                                        onClick={handleAddNew}
                                    >Save</Button>
                                </div>
                            </div>
                        }
                        
                        <Table bordered hover variant="warning" size='sm'>
                            <thead>
                                <tr>
                                <th>#</th>
                                <th>Name</th>
                                <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listSuccess && chargesnames.map((cn,i)=>(
                                    <tr key={cn._id}>
                                        <td>{i+1}</td>
                                        <td>{cn.name}</td>                                        
                                        <td>
                                            <Button
                                                className='m-0 px-2 py-0'
                                                size='sm'
                                                variant='danger'
                                                onClick={()=>dispatch(deleteChargesName(cn._id))}
                                            >
                                                <MdDangerous size={24} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                </div>
            </div>
        </div>
    )
}

export default StaffChargesNameListScreen