import { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Resizer from "react-image-file-resizer"
import SignaturePad from "react-signature-canvas"
import { AlertMessage, MySpinner } from '../../Components'
import styles from './scanning.module.css'
import { Alert, Button, Form, InputGroup, Table } from 'react-bootstrap'
import {toast} from 'react-toastify'
import { boxesReset, getBoxesByShipmentId, markAsDeliveredWithPod } from '../../features/shipment/shipmentSlice'

const DeliverWithPOD = (props) => {
    const dispatch = useDispatch()
    const [ shipmentCode, setShipmentCode ] = useState('')
    const [ imageResizeError, setImageResizeError ] = useState('')
    const [ imageFile64, setImageFile64 ] = useState('')
    const sigPad = useRef()
    const [canvas, setCanvas] = useState('')
    const [canvasVisibility, setCanvasVisibility] = useState(false)
    const { boxes, boxesLoading, boxesError, boxesSuccess, boxesMessage } = useSelector(state=>state.shipment.boxes)
    const { deliveredLoading, deliveredError, deliveredSuccess, deliveredMessage } = useSelector(state=>state.shipment.delivered)

    useEffect(()=>{
        if(deliveredSuccess){
            setShipmentCode('')
            setImageResizeError('')
            setImageFile64('')
            setCanvas('')
            setCanvasVisibility('')
            dispatch(boxesReset())
        }
        return()=>{
            dispatch(boxesReset())
        }
    },[deliveredSuccess, dispatch])

    const clearSignatureCanvas = useCallback(() => {
        sigPad?.current?.clear()
        setCanvas(undefined)
        setCanvasVisibility(false)
    }, []);

    const handleGetCanvas = useCallback(() => {
        //const canvas = sigPad?.current?.toDataURL()
        const canvas = sigPad?.current?.getTrimmedCanvas().toDataURL()
        setCanvas(canvas)
        setCanvasVisibility(true)
    }, []);


    const handleGetBoxes = () =>{
        if(shipmentCode === ''){
            toast.warning('Enter Shipment ID')
            return
        }
        dispatch(getBoxesByShipmentId({shipmentCode, requestFor: 'deliver'}))
    }

    const resizeFile = (file) => new Promise((resolve) => {
        Resizer.imageFileResizer(file,300,400,"JPEG", 100, 0,(uri) => {
            resolve(uri)
        },"base64")
    })

    const handleImage = async (e) => {
         try {
            setImageResizeError('')
            const file = e.target.files[0];
            const image = await resizeFile(file);
            setImageFile64(image)
        } catch (err) {
            setImageResizeError(err);
        }
    }


    const handleMarkPicked = () => {
        if(boxes===null){
            toast.warning('Find a shipment to deliver')
            return
        }
        if(imageFile64 === ''){
            toast.warning('Delivery image is empty')
            return
        }
        if(canvasVisibility === false){
            toast.warning('Save receiver signature.')
            return
        }
        let data = {
            shipmentId: shipmentCode,
            image: imageFile64,
            signature: canvas
        }
        dispatch(markAsDeliveredWithPod(data))
    }

    return (
        <div className={styles.App}>  

            <div className={styles.App_section_title}><span className='bg-dark px-2 rounded text-white'>Delivery</span></div>    
            
            {deliveredError && <AlertMessage msg={deliveredMessage} type='danger' />}
            {boxesLoading && <MySpinner />}
            {deliveredLoading && <MySpinner />}

            <div>
                {boxesError && <AlertMessage msg={boxesMessage} type='danger' />}
                <InputGroup className="mb-3">
                    <Form.Control
                        size='lg'
                        type='text'
                        placeholder='Shipment ID'
                        value={shipmentCode}
                        maxLength={10}
                        onChange={({target})=>setShipmentCode(target.value.toUpperCase())}

                    />
                    <Button     
                        variant="secondary" 
                        size='lg'
                        onClick={()=>handleGetBoxes()}
                    >
                        Find
                    </Button>
                </InputGroup>

                {boxesSuccess && 
                <>
                    <div className='text-start bg-warning-subtle p-2 mb-2' style={{fontSize: '14px'}}>
                        <div>From: {boxes.sender}</div>
                        <div>To: {boxes.receiver}</div>
                        <div>Cargo Type: {boxes.cargoType && boxes.cargoType.toUpperCase()}</div>
                        <div>Scheduled at: {new Date(boxes.pickupData.date).toLocaleDateString('en-US',{day:'2-digit', month: '2-digit', year: '2-digit'})}, {boxes.pickupData.time}</div>
                    </div>
                    <Table size='sm' bordered style={{fontSize: '14px'}}>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Box Detail</th>
                            </tr>
                        </thead>
                        <tbody>
                            {boxes.boxes.map((b,i)=>(
                                <tr key={i}>
                                    <td className='text-center'>{i+1}</td>
                                    <td className='text-start'>
                                        <div>{b.boxId}</div>
                                        <div>{b.length}x{b.width}x{b.height} : {b.actualWeight} lbs</div>
                                        <div></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </>
                }

                {imageResizeError !== '' && <Alert>{imageResizeError}</Alert>}
                <div>
                    <Form.Control
                        type='file'
                        accept='Image/*'
                        onChange={(e)=>handleImage(e)}
                    />
                </div>

                {imageFile64 !== '' &&
                <div className='w-100 d-flex justify-content-center my-3'>
                    <div style={{width: '300px'}}>
                        <img src={imageFile64} alt='imgFile' />
                    </div>
                </div>
                }
                <hr />

                <div>
                    <div className='text-center mb-3'>Signature Pad</div>
                    {canvasVisibility ? <img src={canvas} alt="signature" /> :
                        <div className='d-flex justify-content-center'>                    
                            <div className='border border-dark rounded shadow' style={{width: '300px', height: '300px'}}>
                                <SignaturePad
                                    canvasProps={{width: 300, height: 300}}
                                    ref={sigPad}
                                />
                                
                            </div>                    
                        </div>    
                    }                
                </div>

                <div className='d-flex justify-content-center m-2'>
                    <div className='d-flex justify-content-between' style={{width: '300px'}}>
                        <Button
                            size='sm'
                            variant='dark'                            
                            onClick={clearSignatureCanvas}
                        >{canvasVisibility ? 'Remove Signature' : 'Clear Pad'}</Button>
                        <Button
                            size='sm'
                            variant='success'
                            disabled={canvasVisibility}
                            onClick={handleGetCanvas}
                        >Save Signature</Button>
                    </div>
                </div>
                
                {deliveredError && <AlertMessage msg={deliveredMessage} type='danger' />}
                <Button
                    variant='dark'
                    onClick={()=>handleMarkPicked()}
                    className='my-4 w-100'
                >Save</Button>
            </div>
            
        </div>
    );
};

export default DeliverWithPOD;