import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customerAccountServices from './customerAccountService'
import { toast } from 'react-toastify'

const initialState = {
    list: {
        customerList: [],
        pages: null,
        page: null, 
        listError: false,
        listSuccess: false,
        listLoading: false,
        listMessage: '', 
    },
    add: {
        customer: null,
        addError: false,
        addSuccess: false,
        addLoading: false,
        addMessage: '', 
    },
    detail: {
        customer: null,
        detailError: false,
        detailSuccess: false,
        detailLoading: false,
        detailMessage: '', 
    },
    update: {
        updateError: false,
        updateSuccess: false,
        updateLoading: false,
        updateMessage: '', 
    },
    delete: {
        deleteError: false,
        deleteSuccess: false,
        deleteLoading: false,
        deleteMessage: '', 
    },
    addressAdd: {
        address: null,
        addressAddError: false,
        addressAddSuccess: false,
        addressAddLoading: false,
        addressAddMessage: '', 
    },
    addressUpdate: {
        address: null,
        addressUpdateError: false,
        addressUpdateSuccess: false,
        addressUpdateLoading: false,
        addressUpdateMessage: '', 
    },
    addressSearch: {
        addressSearchError: false,
        addressSearchSuccess: false,
        addressSearchLoading: false,
        addressSearchMessage: '', 
    },
    addressDelete: {
        addressDeleteError: false,
        addressDeleteSuccess: false,
        addressDeleteLoading: false,
        addressDeleteMessage: '', 
    }
}


//get customer list
export const getCustomerList = createAsyncThunk('Customer_Get_List', async (data, thunkAPI) => {
    try {        
        const token = thunkAPI.getState().staff.auth.staff.token
        return await customerAccountServices.getCustomerList(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get customer list for os item
export const getCustomerForOSItem = createAsyncThunk('Customer_List_OSItem', async (searchkey, thunkAPI) => {
    try {        
        const token = thunkAPI.getState().staff.auth.staff.token
        return await customerAccountServices.getCustomerForOSItem(searchkey, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//add new customer
export const addCustomer = createAsyncThunk('Customer_Add', async (newCustomer, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await customerAccountServices.addCustomer(newCustomer, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get a customer by id
export const getCustomerDetail = createAsyncThunk('Customer_Get_Detail', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token
        return await customerAccountServices.getCustomerDetail(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update a customer detail by id
export const updateCustomerDetail = createAsyncThunk('Customer_Update_Detail', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token
        return await customerAccountServices.updateCustomerDetail(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete customer
export const deleteCustomer = createAsyncThunk('Customer_Delete', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token     
        return await customerAccountServices.deleteCustomer(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//add customer receiver address
export const addressAddCustomer = createAsyncThunk('Customer_Address_Add', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token   
        return await customerAccountServices.addressAddCustomer(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update customer receiver address
export const addressUpdateCustomer = createAsyncThunk('Customer_Address_Update', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token   
        return await customerAccountServices.addressUpdateCustomer(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//search customer receiver address
export const addressSearchCustomer = createAsyncThunk('Customer_Address_Search', async (searchKey, thunkAPI) => {
    try {  
        return searchKey
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete customer receiver address
export const addressDeleteCustomer = createAsyncThunk('Customer_Address_Delete', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await customerAccountServices.addressDeleteCustomer(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


export const customerAccountSlice = createSlice({
    name: 'Customer_Account',
    initialState,
    reducers: {
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = false
            state.add.addMessage = ''
            state.add.customer = null
        },
        listReset: (state) => {
            state.list.listLoading = false
            state.list.listSuccess = false
            state.list.listError = false
            state.list.listMessage = ''
            state.list.customerList = []
        },
        updateReset: (state) => {
            state.update.updateLoading = false
            state.update.updateSuccess = false
            state.update.updateError = false
            state.update.updateMessage = ''
        },
        deleteReset: (state) => {
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = false
            state.delete.deleteError = false
            state.delete.deleteMessage = ''
        },
        addressAddReset: (state) => {
            state.addresslist.listress= null
            state.addressAdd.addressAddError= false
            state.addressAdd.addressAddSuccess= false
            state.addressAdd.addressAddLoading= false
            state.addressAdd.addressAddMessage= ''
        },
    },
    extraReducers: (builder) => {
        builder
        //customer account list
        .addCase(getCustomerList.pending, (state)=>{
            state.list.listLoading = true
            state.list.listSuccess = false
            state.list.listError = false
            state.list.listMessage = ""
        }) 
        .addCase(getCustomerList.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ""
            state.list.customerList = action.payload.customers
            state.list.pages = action.payload.pages
            state.list.page = action.payload.page
        })  
        .addCase(getCustomerList.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = false
            state.list.listError = true
            state.list.listMessage = action.payload
            state.list.customerList = []
        })
        //customer account list for os items
        .addCase(getCustomerForOSItem.pending, (state)=>{
            state.list.listLoading = true
            state.list.listSuccess = false
            state.list.listError = false
            state.list.listMessage = ""
        }) 
        .addCase(getCustomerForOSItem.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ""
            state.list.customerList = action.payload
        })  
        .addCase(getCustomerForOSItem.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = false
            state.list.listError = true
            state.list.listMessage = action.payload
            state.list.customerList = []
        })
        //add customer account 
        .addCase(addCustomer.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
        }) 
        .addCase(addCustomer.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            state.add.customer = action.payload
            toast.success('New customer account added.')
        })  
        .addCase(addCustomer.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = true
            state.add.addMessage = action.payload
            state.add.customer = null
        })
        //get customer account by id
        .addCase(getCustomerDetail.pending, (state)=>{
            state.detail.detailLoading = true
            state.detail.detailSuccess = false
            state.detail.detailError = false
        }) 
        .addCase(getCustomerDetail.fulfilled, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailSuccess = true
            state.detail.detailError = false
            state.detail.customer = action.payload
        })  
        .addCase(getCustomerDetail.rejected, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailSuccess = false
            state.detail.detailError = true
            state.detail.detailMessage = action.payload
            state.detail.customer = null
        })
        //update customer detail
        .addCase(updateCustomerDetail.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateSuccess = false
            state.update.updateError = false
        }) 
        .addCase(updateCustomerDetail.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            state.update.updateError = false
            toast.success(`Cusotmer Detail updated.`)
        })  
        .addCase(updateCustomerDetail.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = false
            state.update.updateError = true
            state.update.updateMessage = action.payload
        })
        //delete customer account 
        .addCase(deleteCustomer.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteSuccess = false
            state.delete.deleteError = false
        }) 
        .addCase(deleteCustomer.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = true
            state.delete.deleteError = false
            //state.list.customerList = state.list.customerList.filter((c)=>c._id !== action.payload)
            toast.success('Customer account deleted.')
        })  
        .addCase(deleteCustomer.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteSuccess = false
            state.delete.deleteError = true
            state.delete.deleteMessage = action.payload
        })
        //add customer recevier address 
        .addCase(addressAddCustomer.pending, (state)=>{
            state.addressAdd.addressAddLoading = true
            state.addressAdd.addressAddSuccess = false
            state.addressAdd.addressAddError = false
        }) 
        .addCase(addressAddCustomer.fulfilled, (state, action)=>{
            state.addressAdd.addressAddLoading = false
            state.addressAdd.addressAddSuccess = true
            state.addressAdd.addressAddError = false
            state.detail.customer.addresses = [action.payload, ...state.detail.customer.addresses ]                    
            toast.success(`${action.payload.name}'s address is Added.`)
        })  
        .addCase(addressAddCustomer.rejected, (state, action)=>{
            state.addressAdd.addressAddLoading = false
            state.addressAdd.addressAddSuccess = false
            state.addressAdd.addressAddError = true
            state.addressAdd.addressAddMessage = action.payload
        })
        //update customer recevier address 
        .addCase(addressUpdateCustomer.pending, (state)=>{
            state.addressUpdate.addressUpdateLoading = true
            state.addressUpdate.addressUpdateSuccess = false
            state.addressUpdate.addressUpdateError = false
        }) 
        .addCase(addressUpdateCustomer.fulfilled, (state, action)=>{
            state.addressUpdate.addressUpdateLoading = false
            state.addressUpdate.addressUpdateSuccess = true
            state.addressUpdate.addressUpdateError = false
            const index = state.detail.customer.addresses.findIndex(x=>x._id === action.payload._id)            
            if(state.detail.customer.addresses.length === 1){
                state.detail.customer.addresses = [action.payload]
            } else {
                state.detail.customer.addresses = [...state.detail.customer.addresses.slice(0, index), action.payload, ...state.detail.customer.addresses.slice(index + 1)]
            }           
            toast.success(`${action.payload.name}'s address is updated.`)
        })  
        .addCase(addressUpdateCustomer.rejected, (state, action)=>{
            state.addressUpdate.addressUpdateLoading = false
            state.addressUpdate.addressUpdateSuccess = false
            state.addressUpdate.addressUpdateError = true
            state.addressUpdate.addressUpdateMessage = action.payload
        })
        //search customer recevier address 
        .addCase(addressSearchCustomer.pending, (state)=>{
            state.addressSearch.addressSearchLoading = true
            state.addressSearch.addressSearchSuccess = false
            state.addressSearch.addressSearchError = false
        }) 
        .addCase(addressSearchCustomer.fulfilled, (state, action)=>{
            state.addressSearch.addressSearchLoading = false
            state.addressSearch.addressSearchSuccess = true
            state.addressSearch.addressSearchError = false
            state.detail.customer.addresses = state.detail.customer.addresses.filter(obj => Object.values(obj).some(val => val.includes(action.payload)))
        })  
        .addCase(addressSearchCustomer.rejected, (state, action)=>{
            state.addressSearch.addressSearchLoading = false
            state.addressSearch.addressSearchSuccess = false
            state.addressSearch.addressSearchError = true
            state.addressSearch.addressSearchMessage = 'Error searching'
        })
        //delete customer recevier address 
        .addCase(addressDeleteCustomer.pending, (state)=>{
            state.addressDelete.addressDeleteLoading = true
            state.addressDelete.addressDeleteSuccess = false
            state.addressDelete.addressDeleteError = false
        }) 
        .addCase(addressDeleteCustomer.fulfilled, (state, action)=>{
            state.addressDelete.addressDeleteLoading = false
            state.addressDelete.addressDeleteSuccess = true
            state.addressDelete.addressDeleteError = false
            state.detail.customer.addresses = state.detail.customer.addresses.filter(x=>x._id !== action.payload)
            toast.success(`Address deleted.`)
        })  
        .addCase(addressDeleteCustomer.rejected, (state, action)=>{
            state.addressDelete.addressDeleteLoading = false
            state.addressDelete.addressDeleteSuccess = false
            state.addressDelete.addressDeleteError = true
            state.addressDelete.addressDeleteMessage = action.payload
        })
        
    },
})


export const { addReset, listReset, updateReset, deleteReset, addressAddReset } = customerAccountSlice.actions
export default customerAccountSlice.reducer