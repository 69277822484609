import React, { useEffect, useState } from 'react'
import {Alert, Button, Modal, Spinner} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function DeleteConfirm({type, name, id, deleteFunction, stateName, reset, doReset, location, size, className, disabled}) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [show, setShow] = useState(false)

  const { deleteLoading, deleteError, deleteSuccess, deleteMessage } = useSelector(state=>state[stateName].delete)
  const handleClose = () => {
    setShow(false)
    if(doReset){dispatch(reset())} 
  }
  const handleShow = () => setShow(true)
  useEffect(()=>{
    if(deleteSuccess){
        setShow(false)
        if(doReset){dispatch(reset())}        
        if(location !== ''){navigate(location)}
    }
    // eslint-disable-next-line
  },[dispatch, deleteSuccess, navigate])

  return (
    <>
      <Button variant="danger" size={size} onClick={handleShow} className={className} disabled={disabled}>
        Delete
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{deleteLoading ? <Spinner /> : 'Confrim ?'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {deleteError && <Alert variant='danger'>{deleteMessage}</Alert>}
            <div>
                Please confirm you are deleting {type} <span className='bg-warning px-2 rounded'>{name}</span> ?
            </div>
            
            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button 
            variant="danger" 
            onClick={()=>{
                dispatch(deleteFunction(id))
            }}
            disabled={deleteError}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

DeleteConfirm.defaultProps = {
  location: '',
  doReset: false,
  size: 'sm',
  className: '',
  disabled: false,
  detail: ''
}

export default DeleteConfirm