import axios from 'axios'

const API_URL = '/api/customeraccount'

//get customer list
const getCustomerList = async (data, token) => {
    const searchKeyword = data.searchKeyword ? data.searchKeyword : ''
    const pageNumber = data.pageNumber ? data.pageNumber : 1
    const limit = data.limit
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`?keyword=${searchKeyword}&page=${pageNumber}&limit=${limit}`, config)
    return response.data
}

//get customer list for os item
const getCustomerForOSItem = async (searchKeyword, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL+`/ositem?keyword=${searchKeyword}`, config)
    return response.data
}

//create customer account
const addCustomer = async (newCustomer, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, newCustomer, config)
    return response.data
}

//get a customer
const getCustomerDetail = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL + `/${id}`, config)
    return response.data
}

//update a customer detail
const updateCustomerDetail = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL + `/${data.customerId}`, data.customer, config)
    return response.data
}

//create customer account
const deleteCustomer = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL + `/${id}`, config)
    return response.data
}

//Add new customer receiver address
const addressAddCustomer = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL + `/address/${data.customerId}`, data.receiverAddress, config)
    return response.data
}


//update a customer receiver address
const addressUpdateCustomer = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL + `/address/${data.customerId}/${data.addressId}`, data.receiverAddress, config)
    return response.data
}


//delete a customer receiver address
const addressDeleteCustomer = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL + `/address/${data.customerId}/${data.addressId}`, config)
    return response.data
}

const customerAccountServices = {
    getCustomerList,
    getCustomerForOSItem,
    addCustomer,
    deleteCustomer,
    getCustomerDetail,
    updateCustomerDetail,
    addressAddCustomer,
    addressUpdateCustomer,
    addressDeleteCustomer,
}

export default customerAccountServices