import { useEffect } from 'react'
import styles from './customerregisterscreen.module.css'
import { useState } from 'react'
import { Col, Row, Spinner } from 'react-bootstrap'
import { MdKeyboardArrowLeft, MdKeyboardArrowRight, MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { register, authReset, requestReset } from '../../../features/customer/customerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {AlertMessage} from '../../../Components'
import { toast } from 'react-toastify'
import ReceiverDetails from './ReceiverDetails'
import { countries } from '../../../data/countries'

const CustomerRegisterScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [view, setView] = useState(false)
    // const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [company, setCompany] = useState("")
    const [password, setPassword] = useState("")
    const [password2, setPassword2] = useState("")
    const [passwordMsg, setPasswordMsg] = useState("")
    const [phonenumber1, setPhonenumber1] = useState("")
    const [phonenumber2, setPhonenumber2] = useState("")
    const [phonenumber3, setPhonenumber3] = useState("")
    const [defaultAddress, setDefaultAddress] = useState({
        street: "",
        city: "",
        state: "",
        postalcode: "",
        country: ""
    })
    const [receiverDetail, setReceiverDetail] = useState({
        name: "",
        company: "",
        email: "",
        street: "",
        city: "",
        state: "",
        postalcode: "",
        country: "",
        phonenumber1: "",
        phonenumber2: ""

    })
    const [addReceiverNow, setAddRecevierNow] = useState(false)

    const { authError, authSuccess, authLoading, authMessage } = useSelector(state=>state.customer.auth)
    const { email: requestedEmail } = useSelector(state=>state.customer.request)
    
    useEffect(()=>{
        if(!requestedEmail){
            navigate('/request')
        }
        if(authSuccess){
            navigate('/shipments')
            dispatch(requestReset())
        }
        return()=>{
            dispatch(authReset())
        }
    },[dispatch, navigate, authSuccess, requestedEmail])

    const handlePassword = (passwordValue) => {
        const strengthChecks = {
        length: 0,
        hasUpperCase: false,
        hasLowerCase: false,
        hasDigit: false,
        hasSpecialChar: false,
        };

        strengthChecks.length = passwordValue.length >= 8 ? true : false;
        strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
        strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
        strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
        strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

        let verifiedList = Object.values(strengthChecks).filter((value) => value);

        let strength =
        verifiedList.length === 5
            ? "Strong"
            : verifiedList.length >= 2
            ? "Medium"
            : "Weak";

        setPassword(passwordValue);
        setPasswordMsg(strength);

        // console.log("verifiedList: ", `${(verifiedList.length / 5) * 100}%`);
    }

    const getActiveColor = (type) => {
        if (type === "Strong") return "#183A1D";
        if (type === "Medium") return "#F99417";
        return "#CD0404";
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if(password !== password2){
            toast.error('Passwords are not matching.')
            return
        }
        const newCustomer = {
            name,
            email: requestedEmail,
            company,
            password,
            phonenumber1,
            phonenumber2,
            phonenumber3,
            defaultAddress,
            receiverDetail: addReceiverNow ? receiverDetail : null,
        }
        //console.log(newCustomer)
        dispatch(register(newCustomer))
    }

    const handleCancelRegister = () => {
        localStorage.removeItem('requester')
        dispatch(requestReset())
        navigate('/')
    }

  return (
    <div className={styles.customer_register_main}>
        
        <div className={styles.customer_register_form}>
            <h3 className='mb-5'>Register NYSS customer account<span>(* = required)</span></h3>
            {authError && <AlertMessage msg={authMessage}  />}
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col sm={12} md={6} className="mb-4">
                        <p>Email address <span>*</span></p>
                        <input 
                            type="email" 
                            maxLength="50"
                            value={requestedEmail}
                            readOnly
                            //onChange={({target})=>setEmail(target.value)}
                            //required
                        />                    
                    </Col>
                    <Col sm={12} md={6} className="mb-4">
                        <p>Full Name <span>*</span></p>
                        <input 
                            type="text" 
                            maxLength="60"
                            value={name}
                            onChange={({target})=>setName(target.value)}
                            required
                        />                        
                    </Col>

                    <Col lg={12} className="mb-4">
                        <p>Company <span className='text-muted'>(Optional)</span></p>
                        <input 
                            type="text" 
                            maxLength="100"
                            value={company}
                            onChange={({target})=>setCompany(target.value)}
                        />                        
                    </Col>
                
                    <Col sm={12} md={6} className='mb-4'>                        
                        <p>Password (A-Z,a-z,@#$,0-9) <span>* (min-length - 8 characters)</span></p>
                        
                        <div className={styles.form_password}>
                            <input
                                type={view ? 'text' : 'password'}
                                minLength="8"
                                value={password}
                                onChange={({ target }) => {
                                    handlePassword(target.value);
                                    setPassword(target.value)
                                }}
                                required
                            />
                            <div className={styles.password_view} onClick={()=>setView(!view)}>
                                {view ? <MdVisibilityOff size={25} /> : <MdVisibility size={25} />}
                            </div>
                        </div>
                        {password.length !== 0 ? (
                            <p className={styles.password_msg} style={{ color: getActiveColor(passwordMsg) }}>
                            {passwordMsg}
                            </p>
                        ) : null}
                    </Col>
                    <Col sm={12} md={6} className='mb-4'>                        
                        <p>Re-type Password <span>*</span></p>
                        <div className={styles.form_password}>
                            <input
                                type={view ? 'text' : 'password'}
                                value={password2}
                                onChange={({ target }) => {
                                   setPassword2(target.value);
                                }}
                                required
                            />
                            <div className={styles.password_view} onClick={()=>setView(!view)}>
                                {view ? <MdVisibilityOff size={25} /> : <MdVisibility size={25} />}
                            </div>
                        </div>
                    </Col>

                    <Col sm={12} md={4} className='mb-4'>
                        <p>Phone Number 1 <span>*</span></p>
                        <input 
                            type='tel'
                            value={phonenumber1}
                            onChange={e=>setPhonenumber1(e.target.value.replace(/\D/g, ""))}                           
                            required
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>Phone Number 2 <span className='text-muted'>(Optional)</span></p>
                        <input 
                            type='tel'
                            value={phonenumber2}
                            onChange={e=>setPhonenumber2(e.target.value.replace(/\D/g, ""))}
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>Phone Number 3 <span className='text-muted'>(Optional)</span></p>
                        <input 
                            type='tel'
                            value={phonenumber3}
                            onChange={e=>setPhonenumber3(e.target.value.replace(/\D/g, ""))}
                        />
                    </Col>
                </Row>
                <hr />
                <Row>                    
                    <h4 className='mb-5'>Your Address </h4>
                    <Col sm={12} md={8} className='mb-4'>
                        <p>Street <span>*</span></p>
                        <input 
                            type='text'
                            value={defaultAddress.street}
                            onChange={e=>{setDefaultAddress({...defaultAddress, street: e.target.value})}}
                            required
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>City <span>*</span></p>
                        <input 
                            type='text'
                            value={defaultAddress.city}
                            onChange={e=>{setDefaultAddress({...defaultAddress, city: e.target.value})}}
                            required
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>State / Province <span>*</span></p>
                        <input 
                            type='text'
                            value={defaultAddress.state}
                            onChange={e=>{setDefaultAddress({...defaultAddress, state: e.target.value})}}
                            required
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>Postal code <span>*</span></p>
                        <input 
                            type='text'
                            value={defaultAddress.postalcode}
                            onChange={e=>{setDefaultAddress({...defaultAddress, postalcode: e.target.value})}}
                            required
                        />
                    </Col>
                    <Col sm={12} md={4} className='mb-4'>
                        <p>Country <span>*</span></p>
                        {/* <input 
                            type='text'
                            value={defaultAddress.country}
                            onChange={e=>{setDefaultAddress({...defaultAddress, country: e.target.value})}}
                            required
                        /> */}
                        <select value={defaultAddress.country} onChange={e=>{setDefaultAddress({...defaultAddress, country: e.target.value})}} required>
                            {countries.map(c=>(
                                <option key={c.name} value={c.value}>{c.name}</option>
                            ))}
                        </select>
                    </Col>                       
                </Row>
                <hr />
                <Row>
                    <Col className='mb-4'>
                        <div className={styles.add_receiver_now}>
                            <input
                                type='checkbox'
                                id='add-receiver-now'
                                value={addReceiverNow}
                                onChange={()=>setAddRecevierNow(prevState=>!prevState)}
                            />
                            <label htmlFor='add-receiver-now'>Add receiver detail now.</label>
                        </div>
                    </Col>
                    {addReceiverNow && <ReceiverDetails addReceiverNow={addReceiverNow} receiverDetail={receiverDetail} setReceiverDetail={setReceiverDetail} />}
                </Row>

            <hr />
            {authError && <AlertMessage msg={authMessage}  />}

            
            <div className={styles.register_submit} style={{opacity: authLoading && '0.4'}}>                
                <button type='submit' >{authLoading && <Spinner size='sm' animation="border" /> }Register <span><MdKeyboardArrowRight size={25} color='black' className='mb-1' /></span></button>                
            </div>
            <div className={styles.register_submit_cancel} style={{opacity: authLoading && '0.4'}}>
                <button
                    onClick={handleCancelRegister}
                ><span><MdKeyboardArrowLeft size={25} color='black' className='mb-1' /></span>Cancel</button>
            </div>
            </form>
        </div>

    </div>
  )
}

export default CustomerRegisterScreen