import axios from 'axios'

const API_URL = '/api/staff/'

//register user
const register = async (userData, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL + 'account', userData, config)
    return response.data
}

//login user
const login = async (userData) => {
    const response = await axios.post(API_URL + 'login', userData)
    if(response.data) {
        localStorage.setItem('staff', JSON.stringify(response.data))
    }
    return response.data
}

//get staff account list
const getAccounts = async (search, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL + `account?keyword=${search}`, config )
    return response.data
}

//update staff account
const updateAccount = async (id,staff, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.put(API_URL + `/${id}` , staff, config )
    return response.data
}

//delete staff account
const deleteAccount = async (id, token) => {
    const config = {
        headers: {
        Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL + id , config)
    return response.data
}

//logout user
const logout = async () => {
    localStorage.removeItem('staff')
    return true
}

//get staff my account
const getMyAccount = async (id, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.get(API_URL + `myaccount?id=${id}`, config )
    return response.data
}

//update staff account password
const changePasswordAccount = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL + `/password/${data.accountId}`, data, config )
    return response.data
}

const staffServices = {
    register, 
    logout,
    login,
    getAccounts,
    deleteAccount,
    updateAccount,
    getMyAccount,
    changePasswordAccount,
}

export default staffServices