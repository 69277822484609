import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getMyOSItemList } from '../../features/osItem/osItemSlice'
import { Table, Form, Row, Col } from 'react-bootstrap'
import { AlertMessage, MySpinner, MyPagination, useWindowSize } from '../../Components'
import { MdCircle } from 'react-icons/md'

const CustomerOSItemListScreen = () => {
    const dispatch = useDispatch()
    const size = useWindowSize()
    const params = useParams()
    const [keyword, setKeyword] = useState('')
    const limit = 50
    const pageNumber = params.pageNumber ? params.pageNumber : 1

    const { ositemlist, listLoading, listError, listSuccess, listMessage, page, pages } = useSelector(state=>state.ositem.list)

    useEffect(()=>{
        dispatch(getMyOSItemList({keyword:'', pageNumber: pageNumber, limit: limit}))
    },[dispatch, pageNumber])

    return (
        <div>
            {listLoading && <MySpinner />}
            {listError && <AlertMessage type='danger' msg={listMessage} />}
            
            <div className='d-flex'>
                <div className='ms-auto'>
                    <MyPagination 
                        path={keyword !== "" ? `staff/ositem/search/${keyword}` : 'staff/ositem'} 
                        pages={pages}
                        page={page}
                    />
                </div>
            </div>
            <Row className='my-2'>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Control
                            type='text'
                            value={keyword}
                            size='sm'
                            placeholder='Search with item name'
                            maxLength={100}
                            onChange={({target})=>setKeyword(target.value)}
                            onKeyDown={(e)=>{
                                if(e.key === 'Enter'){
                                    if(e.target.value === ''){
                                        dispatch(getMyOSItemList({keyword:'', pageNumber: 1, limit: limit}))
                                    } else {
                                        dispatch(getMyOSItemList({keyword:e.target.value, pageNumber: 1, limit: limit}))
                                    }
                                }
                            }}
                        />
                    </div>                
                </Col>
                <Col md={4} className='mb-2'>
                    <div>
                        <Form.Select size='sm' onChange={({target})=>{
                            if(target.value === 'not'){
                                dispatch(getMyOSItemList({keyword:'', pageNumber: 1, limit: limit, isShipped: false}))
                            }else{
                                dispatch(getMyOSItemList({keyword:'', pageNumber: 1, limit: limit}))
                            }
                        }}>
                            <option value='all'>All</option>
                            <option value='not'>Items not yet shipped</option>
                        </Form.Select>
                    </div>
                </Col>
            </Row>
            
            <div className='my-4' style={{fontSize: '15px'}}>
                {size.width > 760 ?
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Item</th>
                                <th>Qty</th>
                                <th>Received Date</th>
                                <th>Shipped</th>
                                <th>Shipment ID</th>
                                <th>Shipped Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>{os.name}</td>
                                    <td>{os.qty}</td>
                                    <td>{new Date(os.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', 'year' : '2-digit'})}</td>
                                    <td>{os.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}</td>
                                    <td>{os.isShipped ? os.shipmentId.shipmentId : '-'}</td>
                                    <td>{os.isShipped ? new Date(os.shippedDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', 'year' : '2-digit'}) : '-'}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    :
                    <Table bordered hover size="sm">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Detail</th>
                                <th>Shipped</th>
                            </tr>
                        </thead>
                        <tbody>
                            {listSuccess && ositemlist.map((os,i)=>(
                                <tr key={os._id} className={i%2===0 ? 'bg-warning-subtle' : 'bg-none'}>
                                    <td>{pageNumber === 1 ? (i+1) : (((Number(pageNumber)-1) * limit) + (i+1))}</td>
                                    <td>
                                        <div>{os.name} - {os.qty}</div>
                                        <div>received - {new Date(os.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: 'short', 'year' : '2-digit'})}</div>
                                        <div>shipment - {os.isShipped ? os.shipmentId.shipmentId : "-"}</div>
                                        <div>shipped date - {os.isShipped ? new Date(os.shippedDate).toLocaleDateString('en-US', {day: '2-digit', month: 'short', 'year' : '2-digit'}) : '-'}</div>
                                    </td>
                                    <td>
                                        {os.isShipped ? <MdCircle color='green' /> : <MdCircle color='red' />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                }
                
            </div>

            
        </div>
    )
}

export default CustomerOSItemListScreen