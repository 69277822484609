import {useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from './tracking.module.css'
import { Form, Row, Col, Button, Popover, OverlayTrigger, Spinner } from 'react-bootstrap'
import { getEstimatedCost, estimateReset } from '../../../features/rate/rateSlice'
import { MdWifiProtectedSetup, MdWarningAmber } from 'react-icons/md'
import { useTranslation } from 'react-i18next'

const CostCheck = ({boxSizes}) => {
  const {t} = useTranslation()
  const dispatch = useDispatch()
  const [weight, setWeight] = useState(1)
  const [bs, setBs] = useState('')
  const [selectBs, setSelectBs] = useState(false)
  const [cargoType, setCargoType] = useState('')
  const [selectCT, setSelectCT] = useState(false)
  const [customBs, setCustomBs] = useState({
    h: 0,
    l: 0,
    w: 0
  })
  const { estimate, estimateLoading, estimateSuccess, estimateError, estimateMessage } = useSelector(state=>state.rate.estimate)

  const handleGetCost = () => {
    if(cargoType === ''){
      setSelectCT(true)
      return
    }
    if(bs === '' || bs === null){
      setSelectBs(true)
      return
    }
    if(bs === 'c'){
      if(customBs.l === 0 || customBs.w === 0 || customBs.h === 0){
        return
      }
      dispatch(getEstimatedCost({boxSize: customBs, weight: Number(weight), cargoType}))
    } else {
      dispatch(getEstimatedCost({boxSize: boxSizes[bs], weight: Number(weight), cargoType}))
    }    
  }

  const handleReset = () => {
    setWeight(1)
    setBs('')
    setCargoType('')
    setSelectBs(false)
    setSelectCT(false)
    setCustomBs({l:0,w:0,h:0})
    dispatch(estimateReset())
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Volume weigth is applied
      </Popover.Body>
    </Popover>
  );

  return (
    <div>

      <div className={styles.calculate}>
        <div className='d-flex justify-content-between mb-4'>
          <h4 className=''>{t('Check estimated cost for a box')}</h4>
          <Button 
            size='sm' 
            variant='link'
            onClick={handleReset}
            ><MdWifiProtectedSetup /> {t('Reset')}</Button>
        </div>
          
          <Row>
            <Col xs={6}>
              <Form.Label className='ms-1'>{t('Cargo Type')}</Form.Label>
              <Form.Select 
                className='mb-3' 
                value={cargoType}
                onChange={(e)=>{
                  setCargoType(e.target.value)
                  setSelectCT(false)
                }} 
                isInvalid={selectCT}>
                <option value=''>Select</option>
                <option value='air'>Air - 1 to 14 days</option>
                <option value='ocean'>Ocean - 75 to 90 days</option>
                <option value='express'>Express Ocean - 30 days</option>
              
              </Form.Select>
            </Col>
            <Col xs={6}>
              <Form.Label className='ms-1'>{t('Box Size')} <span className='text-danger' style={{fontSize: '13px'}}>(inch)</span></Form.Label>
              <Form.Select 
                className='mb-3' 
                value={bs}
                onChange={(e)=>{
                  setBs(e.target.value)
                  setSelectBs(false)
                }} 
                isInvalid={selectBs}>
                <option value=''>Select</option>
                {boxSizes.map((bs,i)=>(
                  <option key={i} value={i}>{bs.l} x {bs.w} x {bs.h}</option>
                ))}
                <option value='c'>Custom Box Size</option>
              </Form.Select>
            </Col>
          </Row>
            
            {bs === 'c' &&
            <div className=' mb-3 bg-danger-subtle p-2'>
              <div className='text-center border-bottom border-danger mb-2'>Please enter box size in inch.</div>
              <div className='d-flex justify-content-between'>
                <div className={styles.size_input}>
                  <div className='me-2 py-1'>Length </div>
                  <Form.Control 
                    style={{height: '30px'}}
                    type='number'
                    value={customBs.l}
                    onChange={({target})=>setCustomBs({...customBs, l: target.value})}
                  />
                </div>
                <div className={styles.size_input}>
                  <div className='me-2 py-1'>Width</div>
                  <Form.Control 
                    style={{height: '30px'}}
                    type='number'
                    value={customBs.w}
                    onChange={({target})=>setCustomBs({...customBs, w: target.value})}
                  />
                </div>
                <div className={styles.size_input}>
                  <div className='me-2 py-1'>Heigth</div>
                  <Form.Control 
                    style={{height: '30px'}}
                    type='number'
                    value={customBs.h}
                    onChange={({target})=>setCustomBs({...customBs, h: target.value})}
                  />
                </div>
              </div>
            </div>
            }
            
        
            <div className='bg-info-subtle p-2 rounded'>
              <Form.Label>{t('Weight')} <span className='text-danger' style={{fontSize: '13px'}}>(lb)</span></Form.Label>
              <Form.Range 
                min={1}
                max={500}
                value={weight}
                className={styles.input_range}
                onChange={(e)=>
                  setWeight(e.target.value)
                }
                onMouseUp={handleGetCost}
              />
              <div style={{paddingLeft: `${Math.ceil((weight/95)*18)}%`, width: '95%'}}>{weight}</div>
              {estimateError && <div className='text-danger fw-bold'>{estimateMessage}</div>}
            
            </div>

            <div className='mt-2 fs-5'>
              <Row  className='m-0 p-0'>
                <Col className='bg-warning-subtle p-3 text-center me-1 rounded'>
                  <div>{t('COST')} {estimate && estimate.isVolumeWeight && 
                  <>
                  <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                    <Button className='m-0 p-0' variant="flush"><MdWarningAmber color={'red'} /></Button>
                  </OverlayTrigger>
                  </>
                  
                  }</div>
                  <div style={{fontSize: '14px'}}>{t('applied rate')} ${estimate ? estimate.rate.toFixed(2) : '0.00'} per lb</div>
                </Col>
                <Col className='bg-warning-subtle p-3 ms-1 fw-bold fs-2 rounded' style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  $ {estimateLoading ? <Spinner /> : estimateSuccess ? estimate.cost.toFixed(2) : '0.00'}
                </Col>

              </Row>
            </div>
       
      </div>
    </div>
  )
}

export default CostCheck