import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Image, Modal, Button } from 'react-bootstrap'
import { MdCameraAlt, MdHome, MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { getUrlsGallery } from '../../../features/gallery/gallerySlice'
import { AlertMessage, MySpinner } from '../../../Components'

const GalleryPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const [viewImg, setViewImg] = useState('')
    const [viewIndex, setViewIndex] = useState(0)
    const { urlList, listLoading, listError, listSuccess, listMessage } = useSelector(state=>state.gallery.list)

    useEffect(()=>{
        dispatch(getUrlsGallery())
    },[dispatch])

    const handleClose = ()=> setShow(false)
    
    return (
        <div style={{padding: '100px 5% 50px 5%'}}>

            <div className='d-flex justify-content-between border-bottom mb-4'>
                <div className='fs-3'><MdCameraAlt /> NYSS GALLERY</div>
                <div className='pt-2' style={{cursor: 'pointer'}} onClick={()=>navigate('/')}><MdHome /> Home</div>
            </div>
            {listLoading && <MySpinner />}
            {listError && <AlertMessage type="danger" msg={listMessage} />}
            <Row>
                {listSuccess && urlList.map((im,i)=>(
                    <Col xs={6} sm={6} md={4} lg={3} xl={2} key={i} className='p-2'>                       
                        <Image 
                            className='w-100 rounded shadow-sm' 
                            src={im.imageUrl} 
                            thumbnail 
                            style={{cursor: 'pointer', height: '150px', objectFit: 'cover'}}
                            onClick={()=>{
                                setViewImg(im.imageUrl)
                                setViewIndex(i)
                                setShow(true)
                            }}
                            />
                      
                    </Col>
                ))}
            </Row>

            <Modal show={show} onHide={handleClose} size='lg' centered>   
                <div className='d-flex justify-content-center'>
                    <Button 
                        variant='flush' 
                        className='m-0 p-0'                         
                        style={{width: '5%'}}
                        disabled={viewIndex === 0}
                        onClick={()=>{
                            setViewImg(urlList[viewIndex-1].imageUrl)
                            setViewIndex(viewIndex-1)
                        }}
                    ><MdKeyboardArrowLeft size={40} /></Button>
                    <Image
                        src={viewImg}   
                        style={{width: '90%'}}                     
                        className='h-100'
                        onClick={()=>{
                            setShow(false)
                        }}
                    />  
                    <Button 
                        variant='flush' 
                        className='m-0 p-0' 
                        style={{width: '5%'}}
                        disabled={viewIndex === (urlList.length-1)}
                        onClick={()=>{
                            setViewImg(urlList[viewIndex+1].imageUrl)
                            setViewIndex(viewIndex+1)
                        }}
                    ><MdKeyboardArrowRight size={40} /></Button>
                </div>              
            </Modal>
            
        </div>
    )
}

export default GalleryPage