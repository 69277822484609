import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Table, Form, Row, Col } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { getCutOffList } from '../../features/cutoff/cutoffSlice'
import { AlertMessage, MySpinner, MyPagination, useWindowSize } from '../../Components'
import { MdOutlineArrowOutward } from 'react-icons/md'

const CutOffListScreen = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const size = useWindowSize()
  const [ keyword, setKeyword ] = useState('')
  const {searchKeyword} = useParams()
  const {pageNumber} = useParams()
  // const [keyword, setKeyword] = useState('') 

  const { cutofflist, listLoading, listError, listSuccess, listMessage, pages, page } = useSelector(state=>state.cutoff.list)

  useEffect(()=>{
    dispatch(getCutOffList({searchKeyword, pageNumber, limit: 10}))
  },[dispatch, searchKeyword, pageNumber])
  return (
    <div>
      <Row className='d-flex justify-content-between'>

        <Col md={9}>
          <Row>
            <Col md={6} className='mt-3'>
              <Form.Control 
                type='text'
                value={keyword}
                placeholder={searchKeyword ? searchKeyword : 'Search ID'}
                onChange={({target})=>setKeyword(target.value.replace(/[^\w\s]/gi, ""))}
                onKeyDown={(e)=>{
                  if(e.key==='Enter'){
                    if(keyword === ''){
                      navigate(`/staff/cutoff`)
                    } else {
                      navigate(`/staff/cutoff/search/${keyword}`)
                    }                    
                  }
                }}
              />
            </Col>
            <Col md={6} className='mt-3'>
              <Button onClick={()=>navigate('/staff/cutoff/create')}>Create CutOff</Button>
            </Col>
          </Row>
        </Col>

        <Col md={3}>
          <MyPagination
              path={keyword !== "" ? `staff/cutoff/search/${keyword}` : 'staff/cutoff'} 
              pages={pages}
              page={page}
           />
        </Col>

      </Row>
      
      {listLoading && <MySpinner />}
      {listError && <AlertMessage msg={listMessage} />}

      <div>
        
      </div>
      <div className='mt-3'>
        {size.width > 760 ? 
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>ID</th>
              <th>Shipment Qty</th>
              <th>Totoal Weight</th>
              <th>Total Box Qty</th>
              <th>Created Date</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {listSuccess &&
              cutofflist.map((c,i)=>(
                <tr key={c._id}>
                  <td>{i+1}</td>
                  <td>{c.cutoffId}</td>
                  <td>{c.shipmentIds.length}</td>                  
                  <td>{c.totalWeight}</td>
                  <td>{c.totalBoxQty}</td>
                  <td>{new Date(c.createdAt).toLocaleDateString('en-US',{day: '2-digit', month: 'short', year: 'numeric'})}</td>
                  <td>
                    <Button
                      className='m-0 py-0 px-2'
                      size='sm'
                      variant='outline-primary'
                      onClick={()=>navigate(`/staff/cutoff/${c._id}`)}
                    >
                      More<MdOutlineArrowOutward />
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        :
        <Table striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>Summary</th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            {listSuccess &&
              cutofflist.map((c,i)=>(
                <tr key={c._id}>
                  <td>{i+1}</td>
                  <td>
                    <div>ID : {c.cutoffId}</div>
                    <div>Shipment Qty : {c.shipmentIds.length}</div>
                    <div>Total Weight : {c.totalWeight.toFixed(2)}</div>
                    <div>Total Box Qty : {c.totalBoxQty}</div>
                    <div>Created Date - {new Date(c.createdAt).toLocaleDateString('en-US',{day: '2-digit', month: 'short', year: 'numeric'})}</div>
                  </td>
                  <td>
                    <Button
                      className='m-0 py-0 px-2'
                      size='sm'
                      variant='outline-primary'
                      onClick={()=>navigate(`/staff/cutoff/${c._id}`)}
                    >
                      More<MdOutlineArrowOutward />
                    </Button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        }
      </div>
    </div>
  )
}

export default CutOffListScreen