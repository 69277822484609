import axios from 'axios'

const API_URL = '/api/gallery'

//Add New gallery url
const addUrlToGallery = async (data, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.post(API_URL, data, config)
    return response.data
}

//Get gallery url list
const getUrlsGallery = async () => {
    const response = await axios.get(API_URL)
    return response.data
}

//delete gallery url
const deleteUrlGallery = async (urlId, token) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    }
    const response = await axios.delete(API_URL+'/'+ urlId, config)
    return response.data
}


const galleryServices = {
    addUrlToGallery,
    getUrlsGallery,
    deleteUrlGallery,
}

export default galleryServices