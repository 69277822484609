const nyTeam = [
    {
        name: 'Zaw Than Htaik',
        office1: '(718) 899-8389',
        mobile1: '(718) 310-9163',
        email: 'Zaw.nyss@gmail.com'
    },
    {
        name:'Tracy Htwe',
        office1: '(718) 899-8389',
        mobile1: '(929) 320-9957',
        email: 'Tracyhtwe.nyss@gmail.com',
    },
    {
        name:'Nandar Aung Win',
        office1: '(718) 899-8389',
        mobile1: '(718) 314-5268',
        email: 'Nandar.nyss@gmail.com',
    },
    {
        name:'Receiving & Handling (Warehouse)',
        office1: '(718) 899-8389',
        mobile1: '(347) 653-5546',
        email: 'nyssoperation@gmail.com',
    }
]

const ygTeam = [
    {
        name:'May Thu Min Swe',
        office1: '09 973 966 398',
        mobile1: '09 788 699 411',
        email: 'Maythuminswe.nyss@gmail.com',
    },
    {
        name:'Hay Mar',
        office1: '09 973 966 398',
        mobile1: '09 770 453 863',
        email: 'Thinsabal4753@gmail.com',
    },
    {
        name:'Thin Tharaphy Soe',
        office1: '-',
        mobile1: '09 754 881 336',
        email: 'Tharaphysoe.nyss@gmail.com ',
    },
    {
        name:'Soe Moe Kyaw',
        office1: '-',
        mobile1: '09 950 899 098',
        email: 'Soemoekyawnyss@gmail.com',
    }

]

export {
    nyTeam,
    ygTeam
}