import {isExpired} from 'react-jwt'

const checkToken = (token) => {
    return isExpired(token)
    // if(isExpired(token)){
    //     console.log('token expires')
    //     localStorage.removeItem('staff')
    //     window.location.replace(window.location.origin);
    // }
    // console.log('token ok')
    // return
}

// if(checkToken(token)){
//     toast.error('Session expired. Please login again.')
//     localStorage.removeItem('staff')
//     window.location.replace(window.location.origin)            
//     return
// }

export default checkToken