import { useEffect } from 'react'
import AboutUs from './AboutUs/AboutUs'
import Contact from './Contact/Contact'
import Services from './Services/Services'
import Tracking from './TrackingAndCostCheck/Tracking'
import CostCheck from './TrackingAndCostCheck/CostCheck'
import Welcome from './Welcome/Welcome'
import Footer from './Footer/Footer'
import LandingHeader from './LandingHeader/LandingHeader'
import { Row,Col } from 'react-bootstrap'
// import BoxSizes from './BoxSizes/BoxSizes'
import { useDispatch, useSelector } from 'react-redux'
import { getBoxsizes } from '../../features/boxsizes/boxsizeSlice'

const Landing = () => {
  const dispatch = useDispatch()

  const { boxsizes } = useSelector(state=>state.boxsize.list)
  useEffect(()=>{
    dispatch(getBoxsizes())
  },[dispatch])
  return (
    <div className='lading-main'>
        <LandingHeader />
        <Welcome />
        <Row style={{padding: '50px 5% 50px 5%'}}>
          <Col md={6}>
            <Tracking />      
          </Col>
          <Col md={6}>
            <CostCheck boxSizes={boxsizes} />
          </Col>
        </Row>
          
        <Services />
        {/* <BoxSizes /> */}
        <AboutUs />
        <Contact />
        <Footer />
    </div>
  )
}

export default Landing