import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, Table, Button, Form, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { cutOffShipmentFilter, getCutOffDetail, deleteReset, deleteCutOff, updateCutOff } from '../../features/cutoff/cutoffSlice'
import { AlertMessage, MySpinner, useWindowSize } from '../../Components'
import { MdSearch, MdOutlineWifiProtectedSetup, MdClose } from 'react-icons/md'
import { DeleteConfirm } from '../../Components'
import { AddTrackingModal } from '../../Modals'
import CutOffAddShipmentScreen from './CutOffAddShipmentScreen'

const CutOffDetailScreen = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const size = useWindowSize()
  const {id} = useParams()
  const [smId, setSmId] = useState('')

  const { cutoff, detailLoading, detailSuccess, detailError, detailMessage, detailFilter } = useSelector(state=>state.cutoff.detail)
  const { updateLoading, updateError, updateMessage } = useSelector(state=>state.cutoff.update)

  useEffect(()=>{
    dispatch(getCutOffDetail(id))
  }, [dispatch, id])

  return (
    <div>
      {detailLoading && <MySpinner />}
      {updateLoading && <MySpinner />}
      {detailError && <AlertMessage msg={detailMessage} />}
      {updateError && <AlertMessage msg={updateMessage} />}
      {detailSuccess &&
        <div>
          <Row className='mb-3'>          
          
            <Col md={8} className='fs-3 mb-3'>
              ID - {cutoff.cutoffId}
            </Col>
              
            <Col md={4} className='d-flex'>
              <div>
                <CutOffAddShipmentScreen cutoff={cutoff} />
              </div>     
              <div>
                <AddTrackingModal 
                  className='mx-3' 
                  text='Add Tracking' 
                  forCutoff={true} 
                  cutoffId={id}
                  />  
              </div>     

              <div className='ms-auto'>
                <DeleteConfirm 
                  type='Cut Off'
                  name={cutoff && cutoff.cutoffId}
                  id={id}
                  deleteFunction={deleteCutOff}
                  stateName='cutoff'
                  reset={deleteReset}
                  doReset={true}
                  location='/staff/cutoff'
                  size='md'
                />
              </div>
            </Col>
          </Row>

          <div className='mb-3'>
            <Row>
              <Col md={4} className='mb-2'>
                <Card body className='fs-5'> Total Shipment - {cutoff.shipmentIds.length}</Card>
              </Col>
              <Col md={4} className='mb-2'>
                <Card body className='fs-5'> Total Weight - {cutoff.totalWeight} lbs</Card>
              </Col>
              <Col md={4} className='mb-2'>
                <Card body className='fs-5'> Total Box - {cutoff.totalBoxQty}</Card>
              </Col>
            </Row>
          
          </div>

          <Col md={4} className='mb-3 pe-3' >
            <InputGroup >
              <Form.Control               
                placeholder='Search ID'
                value={smId}
                onChange={({target})=>setSmId(target.value.toUpperCase())}
              />
              {detailFilter ? 
                <Button 
                  variant="outline-secondary"
                  onClick={()=>{
                    setSmId('')
                    dispatch(getCutOffDetail(id))
                  }}
                >
                  <MdOutlineWifiProtectedSetup size='25' />
                </Button>
                :
                <Button 
                  variant="secondary"
                  disabled={smId === ''}
                  onClick={()=>dispatch(cutOffShipmentFilter(smId))}
                >
                  <MdSearch size='25' />
                </Button>
              }
                
            </InputGroup>
          </Col>
          <div>
            {size.width > 760 ? 
            <Table striped bordered hover size="sm" className='text-center' style={{fontSize: '15px'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Shipment ID</th>
                  <th>Cargo Type</th>
                  <th>Boxes</th>
                  <th>Total Weight</th>
                  <th>Created</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {cutoff.shipmentIds.map((s,i)=>(
                  <tr key={s._id}>
                    <td>{i+1}</td>
                    <td style={{color: 'blue', cursor: 'pointer', textDecoration: 'underline'}}
                      onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                    >{s.shipmentId}</td>
                    <td>{s.cargoType.toUpperCase()}</td>
                    <td style={{fontSize: '13px'}}>
                      <Table striped bordered size="sm" className='text-center mb-0'>
                        <thead>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </thead>
                        <tbody>
                          {s.boxes.map((b,i)=>(
                            <tr key={b.boxId}>
                              <td>{i+1}.</td>
                              <td>{b.boxId}</td>
                              <td>{b.length}x{b.width}x{b.height}</td>
                              <td>{b.actualWeight.toFixed(2)}</td>
                            </tr>
                          ))}
                        </tbody>
                    </Table>
                    </td>
                    <td>{s.totalWeight.toFixed(2)}</td>
                    <td>{new Date(s.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'})}</td>
                    <td>
                      <Button
                        className='my-0 py-0 px-3'
                        variant='danger'
                        onClick={()=>dispatch(updateCutOff({cutoffId: id, shipmentIds: [s._id], remove: true}))}
                      ><MdClose size={23} /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            :
            <Table striped bordered hover size="sm" style={{fontSize: '14px'}}>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Summary</th>
                  <th>Remove</th>
                </tr>
              </thead>
              <tbody>
                {cutoff.shipmentIds.map((s,i)=>(
                  <tr key={s._id}>
                    <td>{i+1}</td>
                    <td>
                      <div 
                        style={{color: 'blue', cursor: 'pointer', textDecoration: 'underline'}}
                        onClick={()=>navigate(`/staff/shipments/${s._id}`)}
                      >Shipment ID - {s.shipmentId}</div>
                      <div>Cargo Type - {s.cargoType.toUpperCase()}</div>
                      <div>Total Weight - {s.totalWeight.toFixed(2)}</div>
                      <div>Created - {new Date(s.createdAt).toLocaleDateString('en-US', {day: '2-digit', month: '2-digit', year: 'numeric'})}</div>
                      <div>
                        <div>Boxes</div>
                        <Table striped bordered size="sm" className='text-center mb-0'>
                          <thead>
                            <tr>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </thead>
                          <tbody>
                            {s.boxes.map((b,i)=>(
                              <tr key={b.boxId}>
                                <td>{i+1}.</td>
                                <td>{b.boxId}</td>
                                <td>{b.length}x{b.width}x{b.height}</td>
                                <td>{b.actualWeight.toFixed(2)}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </td>
                    <td>
                      <Button
                        className='my-0 py-0 px-3'
                        variant='danger'
                        onClick={()=>dispatch(updateCutOff({cutoffId: id, shipmentIds: [s._id], remove: true}))}
                      ><MdClose size={23} /></Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            }
          </div>
        </div>      
      }

    </div>
  )
}

export default CutOffDetailScreen