import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import shipmentServices from './shipmentService'
import { toast } from 'react-toastify'
//import { toast } from 'react-toastify';

const initialState = {
    list: {
        shipments: [],  
        page: null,
        pages: null,
        listLoading : false,
        listError : false,
        listSuccess : false,
        listMessage : '',
    },
    filter: {
        filterShipments: [],  
        filterPage: null,
        filterPages: null,
        filterLoading : false,
        filterError : false,
        filterSuccess : false,
        filterMessage : '',
    },
    pickup: {
        shipments: [],  
        pickupLoading : false,
        pickupError : false,
        pickupSuccess : false,
        pickupMessage : '',
    },
    cutoff: {
        shipments: [],  
        cutoffLoading : false,
        cutoffError : false,
        cutoffSuccess : false,
        cutoffMessage : '',
    },
    add: {
        shipment: null,  
        addLoading : false,
        addError : false,
        addSuccess : false,
        addMessage : '',
    },
    detail: {
        shipment: null,  
        detailLoading : false,
        detailError : false,
        detailSuccess : false,
        detailMessage : '',
    },
    update: {
        shipment: null,  
        updateLoading : false,
        updateError : false,
        updateSuccess : false,
        updateMessage : '',
    },
    delete: {
        deleteLoading : false,
        deleteError : false,
        deleteSuccess : false,
        deleteMessage : '',
    },
    payment: {
        payment: null,
        paymentLoading : false,
        paymentError : false,
        paymentSuccess : false,
        paymentMessage : '',
    },
    paymentdelete: {
        paymentdeleteLoading : false,
        paymentdeleteError : false,
        paymentdeleteSuccess : false,
        paymentdeleteMessage : '',
    },
    tracking: {
        detail: {
            tracking: null,
            trackingLoading : false,
            trackingError : false,
            trackingSuccess : false,
            trackingMessage : '',
        },
        add: {
            addTracking: null,
            addTrackingLoading : false,
            addTrackingError : false,
            addTrackingSuccess : false,
            addTrackingMessage : '',
        },
        delete: {
            deleteTracking: null,
            deleteTrackingLoading : false,
            deleteTrackingError : false,
            deleteTrackingSuccess : false,
            deleteTrackingMessage : '',
        }
    },
    boxes: {
        boxes: null,
        boxesLoading : false,
        boxesError : false,
        boxesSuccess : false,
        boxesMessage : '',
    },
    picked: {
        pickedList: [],
        pickedLoading : false,
        pickedError : false,
        pickedSuccess : false,
        pickedMessage : '',
    },
    shipped: {
        shippedList: [],
        shippedLoading : false,
        shippedError : false,
        shippedSuccess : false,
        shippedMessage : '',
    },
    received: {
        receivedList: [],
        receivedLoading : false,
        receivedError : false,
        receivedSuccess : false,
        receivedMessage : '',
    },
    delivered: {
        deliveredList: [],
        deliveredLoading : false,
        deliveredError : false,
        deliveredSuccess : false,
        deliveredMessage : '',
    },

}

//get shipments
export const getShipments = createAsyncThunk('Shipment_List', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getShipments(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get pickup list
export const getPickupList = createAsyncThunk('Shipment_Pickup_List', async (_,thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getPickupList(token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get my shipments
export const getMyShipments = createAsyncThunk('Shipment_List_My', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().customer.auth.customer.token        
        return await shipmentServices.getMyShipments(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get my shipment detail
export const getMyShipmentDetail = createAsyncThunk('Shipment_Detail_My', async (id, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().customer.auth.customer.token        
        return await shipmentServices.getMyShipmentDetail(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get shipment detail
export const getShipmentDetail = createAsyncThunk('Shipment_Detail', async (id, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getShipmentDetail(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Add new shipment
export const addShipment = createAsyncThunk('Shipment_Add', async (data, thunkAPI) => {
    try {      
        let token
        if (data.staff && data.staff === true){
            token = thunkAPI.getState().staff.auth.staff.token
        } else {
            token = thunkAPI.getState().customer.auth.customer.token
        }
            
        return await shipmentServices.addShipment(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//Update shipment
export const updateShipment = createAsyncThunk('Shipment_Update', async (data, thunkAPI) => {
    try {      
        let token
        if (data.staff && data.staff === true){
            token = thunkAPI.getState().staff.auth.staff.token
        } else {
            token = thunkAPI.getState().customer.auth.customer.token
        }
            
        return await shipmentServices.updateShipment(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete shipment detail
export const deleteShipment = createAsyncThunk('Shipment_Delete', async (id, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.deleteShipment(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//tracking shipment 
export const trackShipment = createAsyncThunk('Shipment_Tracking', async (id, thunkAPI) => {
    try {          

        return await shipmentServices.trackShipment(id)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//tracking shipment 
export const addTrackingStatus = createAsyncThunk('Shipment_Add_Tracking', async (data, thunkAPI) => {
    try {     
        const token = thunkAPI.getState().staff.auth.staff.token         
        return await shipmentServices.addTrackingStatus(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete tracking shipment 
export const deleteTrackingStatus = createAsyncThunk('Shipment_Delete_Tracking', async (data, thunkAPI) => {
    try {     
        const token = thunkAPI.getState().staff.auth.staff.token         
        return await shipmentServices.deleteTrackingStatus(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//add payment in shipment
export const payShipment = createAsyncThunk('Shipment_Payment_Add', async (data, thunkAPI) => {
    try {     
        const token = thunkAPI.getState().staff.auth.staff.token         
        return await shipmentServices.payShipment(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete payment in shipment
export const deletePaymentShipment = createAsyncThunk('Shipment_Payment_Delete', async (data, thunkAPI) => {
    try {     
        const token = thunkAPI.getState().staff.auth.staff.token         
        return await shipmentServices.deletePaymentShipment(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})



//delete shipment detail
export const getShipmentsForCutOff = createAsyncThunk('Shipment_Get_CutOff', async (data, thunkAPI) => {
    try {      
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getShipmentsForCutOff(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//remove from cut off list
export const removeFromCutOffList = createAsyncThunk('Shipment_Remove_From_CutOff', async (id, thunkAPI) => {
    try {            
        return id
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


//get boxes by shipment Id
export const getBoxesByShipmentId = createAsyncThunk('Shipment_Get_Boxes_ShipmentID', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getBoxesByShipmentId(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})



//mark as picked
export const markAsPicked = createAsyncThunk('Shipment_Mark_Picked', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.markAsPicked(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//undo picked
export const undoPickedUp = createAsyncThunk('Shipment_Undo_PickedUp', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.undoPickedUp(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//mark as shipped
export const markAsShipped = createAsyncThunk('Shipment_Mark_Shipped', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.markAsShipped(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//undo shipped
export const undoShipped = createAsyncThunk('Shipment_Undo_Shipped', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.undoShipped(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//mark as received
export const markAsReceived = createAsyncThunk('Shipment_Mark_Received', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.markAsReceived(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//undo receive
export const undoReceived = createAsyncThunk('Shipment_Undo_Received', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.undoReceived(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//mark as delivered
export const markAsDelivered = createAsyncThunk('Shipment_Mark_Delivered', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.markAsDelivered(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//undo picked
export const undoDelivered = createAsyncThunk('Shipment_Undo_Delivered', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.undoDelivered(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//mark as delivered
export const markAsDeliveredWithPod = createAsyncThunk('Shipment_Mark_Delivered_POD', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.markAsDeliveredWithPod(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get filter shipments
export const getFilteredShipments = createAsyncThunk('Shipment_Filter_List', async (data, thunkAPI) => {
    try {            
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await shipmentServices.getFilteredShipments(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})


export const shipmentSlice = createSlice({
    name: 'Shipments',
    initialState,
    reducers: {
        reset: () => initialState,
        addReset: (state) => {
            state.add.addLoading = false
            state.add.addError = false
            state.add.addSuccess = false
            state.add.addMessage = ''
        },
        updateReset: (state) => {
            state.update.updateLoading = false
            state.update.updateError = false
            state.update.updateSuccess = false
            state.update.updateMessage = ''
        },
        listReset: (state) => {
            state.list.listLoading = false
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        },
        cutoffReset: (state) => {
            state.cutoff.cutoffLoading = false
            state.cutoff.cutoffError = false
            state.cutoff.cutoffSuccess = false
            state.cutoff.cutoffMessage = ''
            state.cutoff.shipments = [] 
        },
        pickupReset: (state) => {
            state.pickup.pickupLoading = false
            state.pickup.pickupError = false
            state.pickup.pickupSuccess = false
            state.pickup.pickupMessage = ''
        },
        deleteReset: (state) => {
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        },
        trackingReset: (state) => {
            state.tracking.detail.trackingLoading = false
            state.tracking.detail.trackingError = false
            state.tracking.detail.trackingSuccess = false
            state.tracking.detail.trackingMessage = ''
            state.tracking.detail.tracking = null
        },
        boxesReset: (state) => {
            state.boxes.boxesLoading = false
            state.boxes.boxesError = false
            state.boxes.boxesSuccess = false
            state.boxes.boxesMessage = ''
            state.boxes.boxes = null
        },
        filterReset: (state) => {
            state.filter.filterShipments = []
            state.filter.filterPage = null
            state.filter.filterPages = null
            state.filter.filterLoading = false
            state.filter.filterSuccess = false
            state.filter.filterError = false
            state.filter.filterMessage = ''
        }
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(addShipment.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
        }) 
        .addCase(addShipment.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            state.add.shipment = action.payload
        })  
        .addCase(addShipment.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addError = true
            state.add.addSuccess = false
            state.add.addMessage = action.payload
            state.add.shipment = null
        })
        //update shipment
        .addCase(updateShipment.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateSuccess = false
            state.update.updateError = false
        }) 
        .addCase(updateShipment.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateSuccess = true
            state.update.updateError = false
            state.update.shipment = action.payload
        })  
        .addCase(updateShipment.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateSuccess = false
            state.update.updateMessage = action.payload
            state.update.shipment = null
        })
        //get my shipments
        .addCase(getMyShipments.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
        }) 
        .addCase(getMyShipments.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = false
            state.list.listSuccess = true
            state.list.shipments = action.payload.shipments
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getMyShipments.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.shipments = []
        })
       //get my shipment detail
        .addCase(getMyShipmentDetail.pending, (state)=>{
            state.detail.detailLoading = true
            state.detail.detailError = false
            state.detail.detailSuccess = false
        }) 
        .addCase(getMyShipmentDetail.fulfilled, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = false
            state.detail.detailSuccess = true
            state.detail.shipment = action.payload
        })  
        .addCase(getMyShipmentDetail.rejected, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = true
            state.detail.detailSuccess = false
            state.detail.detailMessage = action.payload
            state.detail.shipment = null
        })
        //get shipment detail
        .addCase(getShipmentDetail.pending, (state)=>{
            state.detail.detailLoading = true
            state.detail.detailError = false
            state.detail.detailSuccess = false
        }) 
        .addCase(getShipmentDetail.fulfilled, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = false
            state.detail.detailSuccess = true
            state.detail.shipment = action.payload
        })  
        .addCase(getShipmentDetail.rejected, (state, action)=>{
            state.detail.detailLoading = false
            state.detail.detailError = true
            state.detail.detailSuccess = false
            state.detail.detailMessage = action.payload
            state.detail.shipment = null
        })
        //get shipments
        .addCase(getShipments.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
        }) 
        .addCase(getShipments.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = false
            state.list.listSuccess = true
            state.list.shipments = action.payload.shipments
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getShipments.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
            state.list.shipments = []
        })
        //get pickup list
        .addCase(getPickupList.pending, (state)=>{
            state.pickup.pickupLoading = true
            state.pickup.pickupError = false
            state.pickup.pickupSuccess = false
        }) 
        .addCase(getPickupList.fulfilled, (state, action)=>{
            state.pickup.pickupLoading = false
            state.pickup.pickupError = false
            state.pickup.pickupSuccess = true
            state.pickup.shipments = action.payload
        })  
        .addCase(getPickupList.rejected, (state, action)=>{
            state.pickup.pickupLoading = false
            state.pickup.pickupError = true
            state.pickup.pickupSuccess = false
            state.pickup.pickupMessage = action.payload
            state.pickup.shipments = []
        })
        //delete shipment
        .addCase(deleteShipment.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
        }) 
        .addCase(deleteShipment.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = true
            toast.success('Shipment deleted.')
        })  
        .addCase(deleteShipment.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })
        //track shipment
        .addCase(trackShipment.pending, (state)=>{
            state.tracking.detail.trackingLoading = true
            state.tracking.detail.trackingError = false
            state.tracking.detail.trackingSuccess = false
        }) 
        .addCase(trackShipment.fulfilled, (state, action)=>{
            state.tracking.detail.trackingLoading = false
            state.tracking.detail.trackingError = false
            state.tracking.detail.trackingSuccess = true
            state.tracking.detail.trackingMessage = ''
            state.tracking.detail.tracking = action.payload
        })  
        .addCase(trackShipment.rejected, (state, action)=>{
            state.tracking.detail.trackingLoading = false
            state.tracking.detail.trackingError = true
            state.tracking.detail.trackingSuccess = false
            state.tracking.detail.trackingMessage = action.payload
        })
         //add tracking shipment
        .addCase(addTrackingStatus.pending, (state)=>{
            state.tracking.add.addTrackingLoading = true
            state.tracking.add.addTrackingError = false
            state.tracking.add.addTrackingSuccess = false
        }) 
        .addCase(addTrackingStatus.fulfilled, (state, action)=>{
            state.tracking.add.addTrackingLoading = false
            state.tracking.add.addTrackingError = false
            state.tracking.add.addTrackingSuccess = true
            state.tracking.add.addTrackingMessage = ''
            state.detail.shipment.tracking = [action.payload, ...state.detail.shipment.tracking]
            toast.success('Shipment tracking status added.')
        })  
        .addCase(addTrackingStatus.rejected, (state, action)=>{
            state.tracking.delete.deleteTrackingLoading = false
            state.tracking.delete.deleteTrackingError = true
            state.tracking.delete.deleteTrackingSuccess = false
            state.tracking.delete.deleteTrackingMessage = action.payload
        })
        //delete tracking shipment
        .addCase(deleteTrackingStatus.pending, (state)=>{
            state.tracking.delete.deleteTrackingLoading = true
            state.tracking.delete.deleteTrackingError = false
            state.tracking.delete.deleteTrackingSuccess = false
        }) 
        .addCase(deleteTrackingStatus.fulfilled, (state, action)=>{
            state.tracking.delete.deleteTrackingLoading = false
            state.tracking.delete.deleteTrackingError = false
            state.tracking.delete.deleteTrackingSuccess = true
            state.tracking.delete.deleteTrackingMessage = ''
            state.detail.shipment.tracking.splice(action.payload, 1)
            toast.success('Shipment tracking status deleted.')
        })  
        .addCase(deleteTrackingStatus.rejected, (state, action)=>{
            state.tracking.delete.deleteTrackingLoading = false
            state.tracking.delete.deleteTrackingError = true
            state.tracking.delete.deleteTrackingSuccess = false
            state.tracking.delete.deleteTrackingMessage = action.payload
        })
        //add payment to shipment
        .addCase(payShipment.pending, (state)=>{
            state.payment.paymentLoading = true
            state.payment.paymentError = false
            state.payment.paymentSuccess = false
        }) 
        .addCase(payShipment.fulfilled, (state, action)=>{
            state.payment.paymentLoading = false
            state.payment.paymentError = false
            state.payment.paymentSuccess = true
            state.payment.paymentMessage = ''
            state.detail.shipment.paymentDetail.push(action.payload.payment)
            state.detail.shipment.isPaid = action.payload.isPaid
            toast.success('Payment updated')
        })  
        .addCase(payShipment.rejected, (state, action)=>{
            state.payment.paymentLoading = false
            state.payment.paymentError = true
            state.payment.paymentSuccess = false
            state.payment.paymentMessage = action.payload
        })

        //delete payment from shipment
        .addCase(deletePaymentShipment.pending, (state)=>{
            state.paymentdelete.paymentdeleteLoading = true
            state.paymentdelete.paymentdeleteError = false
            state.paymentdelete.paymentdeleteSuccess = false
        }) 
        .addCase(deletePaymentShipment.fulfilled, (state, action)=>{
            state.paymentdelete.paymentdeleteLoading = false
            state.paymentdelete.paymentdeleteError = false
            state.paymentdelete.paymentdeleteSuccess = true
            state.paymentdelete.paymentdeleteMessage = ''
            const paymentIndex = state.detail.shipment.paymentDetail.findIndex(e => e._id === action.payload.paymentId)
            if(state.detail.shipment.paymentDetail.length === 1){
                state.detail.shipment.paymentDetail = []
            } else {
                state.detail.shipment.paymentDetail.splice(paymentIndex,1)
            }
            state.detail.shipment.isPaid = action.payload.isPaid
            toast.success('Payment deleted')
        })  
        .addCase(deletePaymentShipment.rejected, (state, action)=>{
            state.paymentdelete.paymentdeleteLoading = false
            state.paymentdelete.paymentdeleteError = true
            state.paymentdelete.paymentdeleteSuccess = false
            state.paymentdelete.paymentdeleteMessage = action.payload
        })
        //get shipment for cutoff
        .addCase(getShipmentsForCutOff.pending, (state)=>{
            state.cutoff.cutoffLoading = true
            state.cutoff.cutoffError = false
            state.cutoff.cutoffSuccess = false
        }) 
        .addCase(getShipmentsForCutOff.fulfilled, (state, action)=>{
            state.cutoff.cutoffLoading = false
            state.cutoff.cutoffError = false
            state.cutoff.cutoffSuccess = true
            state.cutoff.cutoffMessage = ''
            state.cutoff.shipments = action.payload
        })  
        .addCase(getShipmentsForCutOff.rejected, (state, action)=>{
            state.cutoff.cutoffLoading = false
            state.cutoff.cutoffError = true
            state.cutoff.cutoffSuccess = false
            state.cutoff.cutoffMessage = action.payload
            state.cutoff.shipments = []
        })
        //remove shipment from cut off create list
        .addCase(removeFromCutOffList.fulfilled, (state, action)=>{
            state.cutoff.shipments = state.cutoff.shipments.filter(s=>s._id !== action.payload) 
        })
         //get boxes by shipment Id
        .addCase(getBoxesByShipmentId.pending, (state)=>{
            state.boxes.boxesLoading = true
            state.boxes.boxesError = false
            state.boxes.boxesSuccess = false
        }) 
        .addCase(getBoxesByShipmentId.fulfilled, (state, action)=>{
            state.boxes.boxesLoading = false
            state.boxes.boxesError = false
            state.boxes.boxesSuccess = true
            state.boxes.boxesMessage = ''
            state.boxes.boxes = action.payload
        })  
        .addCase(getBoxesByShipmentId.rejected, (state, action)=>{
            state.boxes.boxesLoading = false
            state.boxes.boxesError = true
            state.boxes.boxesSuccess = false
            state.boxes.boxesMessage = action.payload
            state.boxes.boxes = null
        })

        //mark as picked
        .addCase(markAsPicked.pending, (state)=>{
            state.picked.pickedLoading = true
            state.picked.pickedError = false
            state.picked.pickedSuccess = false
            state.picked.pickedMessage = ''
        }) 
        .addCase(markAsPicked.fulfilled, (state, action)=>{
            state.picked.pickedLoading = false
            state.picked.pickedError = false
            state.picked.pickedSuccess = true
            state.picked.pickedMessage = ''
            if(action.payload !== 'success'){
                state.picked.pickedList = [action.payload, ...state.picked.pickedList]
            }
            toast.success('Success')
        })  
        .addCase(markAsPicked.rejected, (state, action)=>{
            state.picked.pickedLoading = false
            state.picked.pickedError = true
            state.picked.pickedSuccess = false
            state.picked.pickedMessage = action.payload
        })

        //undo picked
        .addCase(undoPickedUp.pending, (state)=>{
            state.picked.pickedLoading = true
            state.picked.pickedError = false
            state.picked.pickedSuccess = false
            state.picked.pickedMessage = ''
        }) 
        .addCase(undoPickedUp.fulfilled, (state, action)=>{
            state.picked.pickedLoading = false
            state.picked.pickedError = false
            state.picked.pickedMessage = ''
            state.detail.shipment.pickupData = action.payload
            state.detail.shipment.isPickedUp = false
            toast.success('Success')
        })  
        .addCase(undoPickedUp.rejected, (state, action)=>{
            state.picked.pickedLoading = false
            state.picked.pickedError = true
            state.picked.pickedSuccess = false
            state.picked.pickedMessage = action.payload
        })

        //mark as shipped
        .addCase(markAsShipped.pending, (state)=>{
            state.shipped.shippedLoading = true
            state.shipped.shippedError = false
            state.shipped.shippedSuccess = false
            state.shipped.shippedMessage = ''
        }) 
        .addCase(markAsShipped.fulfilled, (state, action)=>{
            state.shipped.shippedLoading = false
            state.shipped.shippedError = false
            state.shipped.shippedSuccess = true
            state.shipped.shippedMessage = ''
            if(action.payload !== 'success'){
                state.shipped.shippedList = [action.payload, ...state.shipped.shippedList]
            }
            toast.success('Success')
        })  
        .addCase(markAsShipped.rejected, (state, action)=>{
            state.shipped.shippedLoading = false
            state.shipped.shippedError = true
            state.shipped.shippedSuccess = false
            state.shipped.shippedMessage = action.payload
        })

        //undo shipped
        .addCase(undoShipped.pending, (state)=>{
            state.shipped.shippedLoading = true
            state.shipped.shippedError = false
            state.shipped.shippedSuccess = false
            state.shipped.shippedMessage = ''
        }) 
        .addCase(undoShipped.fulfilled, (state, action)=>{
            state.shipped.shippedLoading = false
            state.shipped.shippedError = false
            state.shipped.shippedMessage = ''
            state.detail.shipment.isShipped = false
            toast.success('Success')
        })  
        .addCase(undoShipped.rejected, (state, action)=>{
            state.shipped.shippedLoading = false
            state.shipped.shippedError = true
            state.shipped.shippedSuccess = false
            state.shipped.shippedMessage = action.payload
        })

        //mark as received
        .addCase(markAsReceived.pending, (state)=>{
            state.received.receivedLoading = true
            state.received.receivedError = false
            state.received.receivedSuccess = false
            state.received.receivedMessage = ''
        }) 
        .addCase(markAsReceived.fulfilled, (state, action)=>{
            state.received.receivedLoading = false
            state.received.receivedError = false
            state.received.receivedSuccess = true
            state.received.receivedMessage = ''
            if(action.payload !== 'success'){
                state.received.receivedList = [action.payload, ...state.received.receivedList]
            }            
            toast.success('Success')
        })  
        .addCase(markAsReceived.rejected, (state, action)=>{
            state.received.receivedLoading = false
            state.received.receivedError = true
            state.received.receivedSuccess = false
            state.received.receivedMessage = action.payload
        })

        //undo receive
        .addCase(undoReceived.pending, (state)=>{
            state.received.receivedLoading = true
            state.received.receivedError = false
            state.received.receivedSuccess = false
            state.received.receivedMessage = ''
        }) 
        .addCase(undoReceived.fulfilled, (state, action)=>{
            state.received.receivedLoading = false
            state.received.receivedError = false
            state.received.receivedMessage = ''
            state.detail.shipment.isReceived = false
            toast.success('Success')
        })  
        .addCase(undoReceived.rejected, (state, action)=>{
            state.received.receivedLoading = false
            state.received.receivedError = true
            state.received.receivedSuccess = false
            state.received.receivedMessage = action.payload
        })

        //mark as delivered
        .addCase(markAsDelivered.pending, (state)=>{
            state.delivered.deliveredLoading = true
            state.delivered.deliveredError = false
            state.delivered.deliveredSuccess = false
            state.delivered.deliveredMessage = ''
        }) 
        .addCase(markAsDelivered.fulfilled, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = false
            state.delivered.deliveredSuccess = true
            state.delivered.deliveredMessage = ''
            if(action.payload !== 'success'){
                state.delivered.deliveredList = [action.payload, ...state.delivered.deliveredList]
            }
            toast.success('Success')
        })  
        .addCase(markAsDelivered.rejected, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = true
            state.delivered.deliveredSuccess = false
            state.delivered.deliveredMessage = action.payload
        })

        //undo delivered
        .addCase(undoDelivered.pending, (state)=>{
            state.delivered.deliveredLoading = true
            state.delivered.deliveredError = false
            state.delivered.deliveredSuccess = false
            state.delivered.deliveredMessage = ''
        }) 
        .addCase(undoDelivered.fulfilled, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = false
            state.delivered.deliveredMessage = ''
            state.detail.shipment.isDelivered = false
            toast.success('Success')
        })  
        .addCase(undoDelivered.rejected, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = true
            state.delivered.deliveredSuccess = false
            state.delivered.deliveredMessage = action.payload
        })

        //mark as delivered with POD
        .addCase(markAsDeliveredWithPod.pending, (state)=>{
            state.delivered.deliveredLoading = true
            state.delivered.deliveredError = false
            state.delivered.deliveredSuccess = false
        }) 
        .addCase(markAsDeliveredWithPod.fulfilled, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = false
            state.delivered.deliveredSuccess = true
            state.delivered.deliveredMessage = ''
            toast.success('Success')
        })  
        .addCase(markAsDeliveredWithPod.rejected, (state, action)=>{
            state.delivered.deliveredLoading = false
            state.delivered.deliveredError = true
            state.delivered.deliveredSuccess = false
            state.delivered.deliveredMessage = action.payload
        })
        //get filter shipments
        .addCase(getFilteredShipments.pending, (state)=>{
            state.filter.filterLoading = true
            state.filter.filterError = false
            state.filter.filterSuccess = false
        }) 
        .addCase(getFilteredShipments.fulfilled, (state, action)=>{
            state.filter.filterLoading = false
            state.filter.filterError = false
            state.filter.filterSuccess = true
            state.filter.filterMessage = ''
            state.filter.filterShipments = action.payload.shipments
            state.filter.filterPage = action.payload.page
            state.filter.filterPages = action.payload.pages
        })  
        .addCase(getFilteredShipments.rejected, (state, action)=>{
            state.filter.filterLoading = false
            state.filter.filterError = true
            state.filter.filterSuccess = false
            state.filter.filterMessage = action.payload
            state.filter.filterShipments = []
            state.filter.filterPage = null
            state.filter.filterPages = null
        })
    },
})


export const { reset, addReset, updateReset, cutoffReset, listReset, pickupReset, deleteReset, trackingReset, boxesReset, filterReset } = shipmentSlice.actions
export default shipmentSlice.reducer