import {useEffect, useState} from 'react'
import { Table, Button } from 'react-bootstrap'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { MdReply, MdKeyboardDoubleArrowUp, MdLocationOn,MdOutlineLibraryAddCheck, MdOutlineAccessTime } from 'react-icons/md'
import { trackingReset } from '../features/shipment/shipmentSlice'

const TrackingDetailScreen = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [hover, setHover] = useState(false)
    const { tracking, trackingSuccess } = useSelector(state=>state.shipment.tracking.detail)

    useEffect(()=>{
        if(!tracking){
            navigate('/')
        }
    },[tracking, navigate])
    return (
        <div style={{padding: '150px 5% 100px 5%', display: 'flex', justifyContent: 'center'}} >
            
            {trackingSuccess && <div style={{width: '600px'}}>
                <div>
                    <Button className='m-0 p-0 fs-5' variant='link' onClick={()=>{
                        dispatch(trackingReset())
                        navigate('/')
                    }}><MdReply /> Back</Button>
                    <h3 className='mb-4 mt-2'>Tracking Detail - {params.shipmentId}</h3>
                </div>
                

                <div className='mb-3 fs-5'>Last destination - {tracking.destination.state}, {tracking.destination.country}</div>

                <div className='border rounded p-2 mb-4'>
                     <Table  size="sm" className='text-center'>
                        <thead>
                            <tr>
                            <th>#</th>
                            <th>Box ID</th>
                            <th>Weight</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tracking.boxes.map((b,i)=>(
                                <tr key={i}>
                                    <td>{i+1}</td>
                                    <td>{b.boxId}</td>
                                    <td>{b.weight} lb</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div
                    className='bg-info-subtle text-center mb-4 p-2 rounded'
                >Please <span
                    style={{
                        cursor: 'pointer',
                        color: hover ? 'black' : 'var(--color-gold)',
                        fontWeight: '700',
                        background: hover ? 'white' : '#000000',
                        padding: '2px 5px',
                        borderRadius: '5px',
                        letterSpacing: '2px'
                        
                    }}
                    onMouseEnter={()=>setHover(true)}
                    onMouseLeave={()=>setHover(false)}
                    onClick={()=>navigate('/login')}
                >Login</span> to see Proof of Delivery and Receiver Signature</div>
                <div>
                    {tracking && tracking.tracking.map((t,i)=>(
                        <div key={i}>
                            <div className='text-center'><MdOutlineLibraryAddCheck /> {t.status}, <MdLocationOn /> {t.location}</div>
                            <div className='text-center'><MdOutlineAccessTime /> {new Date(t.date).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                            <div className='text-center'>< MdKeyboardDoubleArrowUp /></div>
                        </div>
                    ))}
                    {tracking && <div>
                        <div className='text-center'><MdOutlineLibraryAddCheck /> Shipment Created, <MdLocationOn />  NYSS</div>
                        <div className='text-center'><MdOutlineAccessTime /> {new Date(tracking.createdAt).toLocaleString('en-US', {day: '2-digit', month: '2-digit', year: '2-digit', 'hour': '2-digit', minute: '2-digit'})}</div>
                    </div>}
                </div>
            </div>}

        </div>
    )
}

export default TrackingDetailScreen