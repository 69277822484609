import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Button, Form, InputGroup, Spinner  } from 'react-bootstrap'
import { MdVisibility, MdVisibilityOff } from 'react-icons/md'
import { toast } from 'react-toastify'
import { changePasswordAccount, logout, passwordReset } from '../features/staff/staffSlice'
import { AlertMessage } from '../Components'

const ChangePasswordModal = ({accountId}) => {
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [viewOld, setViewOld] = useState(false)
    const [viewNew, setViewNew] = useState(false)
    
    const handleShow = () => setShow(true)
    const { passwordLoading, passwordSuccess, passwordError, passwordMessage } = useSelector(state=>state.staff.password)

    useEffect(()=>{
        if(passwordSuccess){
            setOldPassword('')
            setNewPassword('')
            setViewOld(false)
            setViewNew(false)
            toast.success('Please login with new password')
            dispatch(logout())
        }
    },[passwordSuccess, dispatch])


    const handleSubmit = (e) => {
        e.preventDefault()
        if(oldPassword === '' || newPassword === ''){
            toast.warning('Old password and New password can not be blank or empty')
            return
        }
        dispatch(changePasswordAccount({accountId, oldPassword, newPassword}))
    }

    const handleClose = () => {
        setOldPassword('')
        setNewPassword('')
        setViewOld(false)
        setViewNew(false)
        setShow(false)
        dispatch(passwordReset())
    }
    return (
        <>
            <Button variant="info" onClick={handleShow}>
                Change Password
            </Button>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Change Password {passwordLoading && <Spinner />} </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {passwordError && <AlertMessage msg={passwordMessage} />}
                    <Form.Group className='mb-4'>
                        <Form.Label className='mb-1'>Old Password</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                type={viewOld ? 'text' : 'password'}
                                value={oldPassword}
                                onChange={({target})=>setOldPassword(target.value)}
                            />
                            <Button variant="outline-secondary" onClick={()=>setViewOld(prev=>!prev)}>
                                {viewOld ? <MdVisibilityOff /> : <MdVisibility />}
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className='mb-4'>
                        <Form.Label className='mb-1'>New Password</Form.Label>
                        <InputGroup>
                            <Form.Control 
                                type={viewNew ? 'text' : 'password'}
                                value={newPassword}
                                onChange={({target})=>setNewPassword(target.value)}
                            />
                            <Button variant="outline-secondary" onClick={()=>setViewNew(prev=>!prev)}>
                                {viewNew ? <MdVisibilityOff /> : <MdVisibility />}
                            </Button>
                        </InputGroup>
                    </Form.Group>


                </Modal.Body>

                <Modal.Footer>
                    <Button variant="success" style={{width: '100px'}} onClick={handleSubmit}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
  )
}

export default ChangePasswordModal