
import { Col } from 'react-bootstrap'
import { countries } from '../../../data/countries'

const ReceiverDetails = ({addReceiverNow, receiverDetail, setReceiverDetail}) => {
    return (
        <>
            <h4 className='mb-5'>Receiver Address</h4>
            <Col sm={12} md={12} className='mb-4'>
                <p>Full Name <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.name}
                    onChange={e=>{setReceiverDetail({...receiverDetail, name: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>
            <Col sm={12} md={6} className='mb-4'>
                <p>Company <span className='text-muted'>(Optional)</span></p>
                <input 
                    type='text'
                    value={receiverDetail.company}
                    onChange={e=>{setReceiverDetail({...receiverDetail, company: e.target.value})}}
                />
            </Col>
            <Col sm={12} md={6} className='mb-4'>
                <p>Email <span className='text-muted'>(Optional)</span></p>
                <input 
                    type='email'
                    value={receiverDetail.email}
                    onChange={e=>{setReceiverDetail({...receiverDetail, email: e.target.value})}}
                />
            </Col>
            <Col sm={12} md={8} className='mb-4'>
                <p>Street <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.street}
                    onChange={e=>{setReceiverDetail({...receiverDetail, street: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>
            <Col sm={12} md={4} className='mb-4'>
                <p>City <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.city}
                    onChange={e=>{setReceiverDetail({...receiverDetail, city: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>
            <Col sm={12} md={4} className='mb-4'>
                <p>State / Province <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.state}
                    onChange={e=>{setReceiverDetail({...receiverDetail, state: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>
            <Col sm={12} md={4} className='mb-4'>
                <p>Postal code <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.postalcode}
                    onChange={e=>{setReceiverDetail({...receiverDetail, postalcode: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>
            <Col sm={12} md={4} className='mb-4'>
                <p>Country <span>*</span></p>
                {/* <input 
                    type='text'
                    value={receiverDetail.country}
                    onChange={e=>{setReceiverDetail({...receiverDetail, country: e.target.value})}}
                    required = {addReceiverNow && true}
                /> */}
                <select value={receiverDetail.country} onChange={e=>{setReceiverDetail({...receiverDetail, country: e.target.value})}} required = {addReceiverNow && true}>
                    {countries.map(c=>(
                        <option key={c.name} value={c.value}>{c.name}</option>
                    ))}
                </select>
            </Col>      

             <Col sm={12} md={6} className='mb-4'>
                <p>Phone Number 1 <span>*</span></p>
                <input 
                    type='text'
                    value={receiverDetail.phonenumber1}
                    onChange={e=>{setReceiverDetail({...receiverDetail, phonenumber1: e.target.value})}}
                    required = {addReceiverNow && true}
                />
            </Col>     
            <Col sm={12} md={6} className='mb-4'>
                <p>Phone Number 2</p>
                <input 
                    type='text'
                    value={receiverDetail.phonenumber2}
                    onChange={e=>{setReceiverDetail({...receiverDetail, phonenumber2: e.target.value})}}
                />
            </Col>      
        </>
    )
}

export default ReceiverDetails