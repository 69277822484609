import styles from './customercreateshipmentscreen.module.css'
import ProgressBar from 'react-bootstrap/ProgressBar';

const MyProgressBar = ({progress}) => {
  return (
    <div className={styles.pg_bar_main}> 

      <div className={styles.pg}>
        <ProgressBar now={100} variant={progress >= 20 ? 'success' : 'secondary'} className={styles.pg_bar} />
      </div>

      <div className={`${styles.pg_bar_number} ${progress >= 20 ? 'bg-success' : 'bg-secondary'}`}>1</div>

      <div className={styles.pg}>
        <ProgressBar now={100} variant={progress >= 40 ? 'success' : 'secondary'} className={styles.pg_bar} />
      </div>

      <div className={`${styles.pg_bar_number} ${progress >= 40 ? 'bg-success' : 'bg-secondary'}`}>2</div>

      <div className={styles.pg}>
        <ProgressBar now={100} variant={progress >= 60 ? 'success' : 'secondary'} className={styles.pg_bar} />
      </div>

      <div className={`${styles.pg_bar_number} ${progress >= 60 ? 'bg-success' : 'bg-secondary'}`}>3</div>

      <div className={styles.pg}>
        <ProgressBar now={100} variant={progress >= 80 ? 'success' : 'secondary'} className={styles.pg_bar} />
      </div>

      <div className={`${styles.pg_bar_number} ${progress >= 80 ? 'bg-success' : 'bg-secondary'}`}>4</div>

      <div className={styles.pg}>
        <ProgressBar now={100} variant={progress >= 100 ? 'success' : 'secondary'} className={styles.pg_bar} />
      </div>

      <div className={`${styles.pg_bar_number} ${progress >= 100 ? 'bg-success' : 'bg-secondary'}`}>5</div>
    </div>
  )
}

export default MyProgressBar