import { useState } from 'react';
import styles from './modals.module.css'
import { Modal, Button, ListGroup } from 'react-bootstrap'
import { MdHouse } from 'react-icons/md'

const ReceiverSelectModal = ({addressList, customerId, setReceiver, update}) => {
   
    const [show, setShow] = useState(false)

    const handleShow = () => setShow(true)
    const handleClose = () => {setShow(false)}
  

    return (
        <div>
            {update ?             
            <Button variant='warning' onClick={handleShow}>
                <MdHouse size={20} className='pb-1' /> Choose Receiver
            </Button>
            :
            <Button variant='warning' onClick={handleShow} disabled={customerId === ""}>
                <MdHouse size={20} className='pb-1' /> Choose Receiver
            </Button>
            }

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size='lg'
            >
                <Modal.Header closeButton>               
                    <Modal.Title>Select Receiver</Modal.Title>              
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        {addressList.map((ad,i)=>(
                            <ListGroup.Item 
                                key={i}
                                className={`${styles.customer_list}`}
                                onClick={()=>{
                                    setReceiver({
                                        street: ad.street,
                                        city: ad.city,
                                        state: ad.state,
                                        country: ad.country,
                                        postalcode: ad.postalcode,
                                        name: ad.name,
                                        company: ad.company ? ad.company : "",
                                        email: ad.email ? ad.email : "",
                                        phonenumber1: ad.phonenumber1,
                                        phonenumber2: ad.phonenumber2 ? ad.phonenumber2 : ""
                                    })
                                    setShow(false)
                                }}
                            >
                                <div className='bg-warning-subtle ps-2 rounded fw-semibold'>{ad.name}</div>
                                <div style={{fontSize:'14px'}} className='ps-1'>
                                    <div className='d-flex'>
                                        {ad.company && <div>{ad.company + ','}&nbsp;</div>}
                                        <div>{ad.email}</div>
                                    </div>
                                    <div>
                                        {ad.street + ', ' + ad.city + ', ' + ad.state + ', ' + ad.country + ', ' + ad.postalcode}
                                    </div>
                                    <div>{ad.phonenumber1} {ad.phonenumber2 && ', '+ad.phonenumber2}</div>
                                </div>
                            </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ReceiverSelectModal