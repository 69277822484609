import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import osItemServices from './osItemService'
import { toast } from 'react-toastify';

const initialState = {
    list: {
        ositemlist: [],
        customerlist: [],
        listLoading: false,
        listError: false,
        listSuccess: false,
        listMessage: '',
        page: null,
        pages: null
    },
    add :{
        addLoading: false,
        addError: false,
        addSuccess: false,
        addMessage: '',
    },
    update :{
        updateLoading: false,
        updateError: false,
        updateSuccess: false,
        updateMessage: '',
    },
    delete :{
        deleteLoading: false,
        deleteError: false,
        deleteSuccess: false,
        deleteMessage: '',
    }
}


//Add new ositem
export const addNewOSItem = createAsyncThunk('OSItem_Add', async (newItem, thunkAPI) => {
    try {    
        const token = thunkAPI.getState().staff.auth.staff.token        
        return await osItemServices.addNewOSItem(newItem, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get os item list
export const getOSItemList = createAsyncThunk('OSItem_List', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token
        return await osItemServices.getOSItemList(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get os item list by customer id
export const getOSItemListByCusId = createAsyncThunk('OSItem_List_ByCusId', async ({customerId, customer}, thunkAPI) => {
    try {
        let token
        if(customer){
            token = thunkAPI.getState().customer.auth.customer.token          
        } else {
            token = thunkAPI.getState().staff.auth.staff.token
        }        
        return await osItemServices.getOSItemListByCusId(customerId, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get shipped os item list by customer id
export const getOSItemShippedListByCusId = createAsyncThunk('OSItem_Shipped_List_ByCusId', async (id, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token
        return await osItemServices.getOSItemShippedListByCusId(id, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//get my os item list
export const getMyOSItemList = createAsyncThunk('OSItem_My_List', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().customer.auth.customer.token
        return await osItemServices.getMyOSItemList(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//update a os item
export const updateOSItem = createAsyncThunk('OSItem_Update', async (data, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await osItemServices.updateOSItem(data, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

//delete os item
export const deleteOSItem = createAsyncThunk('OSItem_Delete', async (itemId, thunkAPI) => {
    try {
        const token = thunkAPI.getState().staff.auth.staff.token  
        return await osItemServices.deleteOSItem(itemId, token)
    } catch (error) {
        const message = error.response && error.response.data.message ? error.response.data.message : error.message
        return thunkAPI.rejectWithValue(message)
    }
})

export const ositemSlice = createSlice({
    name: 'OSItem',
    initialState,
    reducers: {
        reset: (state) => {
            // state.loading = false
            // state.error = false
            // state.success = false
            // state.message = ''
        },
    },
    extraReducers: (builder) => {
        builder
        .addCase(addNewOSItem.pending, (state)=>{
            state.add.addLoading = true
            state.add.addSuccess = false
            state.add.addError = false
            state.add.addMessage = ''
        }) 
        .addCase(addNewOSItem.fulfilled, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = true
            state.add.addError = false
            state.add.addMessage = ''
            toast.success('new os item added successfully.')
            state.list.ositemlist = [action.payload, ...state.list.ositemlist]
        })  
        .addCase(addNewOSItem.rejected, (state, action)=>{
            state.add.addLoading = false
            state.add.addSuccess = false
            state.add.addError = true
            state.add.addMessage = action.payload
        })

        //get os item list
        .addCase(getOSItemList.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getOSItemList.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ''
            state.list.ositemlist = action.payload.ositemlist
            state.list.customerlist = action.payload.customerlist
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getOSItemList.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
        })

        //get os item list by customer id
        .addCase(getOSItemListByCusId.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getOSItemListByCusId.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ''
            state.list.ositemlist = action.payload.ositemlist
        })  
        .addCase(getOSItemListByCusId.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
        })

        //get shipped os item list by customer id
        .addCase(getOSItemShippedListByCusId.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getOSItemShippedListByCusId.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ''
            state.list.ositemlist = action.payload.ositemlist
        })  
        .addCase(getOSItemShippedListByCusId.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
        })

        //get My os item list
        .addCase(getMyOSItemList.pending, (state)=>{
            state.list.listLoading = true
            state.list.listError = false
            state.list.listSuccess = false
            state.list.listMessage = ''
        }) 
        .addCase(getMyOSItemList.fulfilled, (state, action)=>{
            state.list.listLoading = false
            state.list.listSuccess = true
            state.list.listError = false
            state.list.listMessage = ''
            state.list.ositemlist = action.payload.ositemlist
            state.list.customerlist = action.payload.customerlist
            state.list.page = action.payload.page
            state.list.pages = action.payload.pages
        })  
        .addCase(getMyOSItemList.rejected, (state, action)=>{
            state.list.listLoading = false
            state.list.listError = true
            state.list.listSuccess = false
            state.list.listMessage = action.payload
        })

        //update a ositem  
        .addCase(updateOSItem.pending, (state)=>{
            state.update.updateLoading = true
            state.update.updateError = false
            state.update.updateSuccess = false
            state.update.updateMessage = ''
        }) 
        .addCase(updateOSItem.fulfilled, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = false
            state.update.updateSuccess = true
            state.update.updateMessage = ''
            toast.success('OS Item is updated successfully.')
            const itemIndex = state.list.ositemlist.findIndex(e => e._id === action.payload._id)
            if(action.payload.isShipped){
                state.list.ositemlist.splice(itemIndex,1)
            } else {
                state.list.ositemlist = [...state.list.ositemlist.slice(0, itemIndex), action.payload, ...state.list.ositemlist.slice(itemIndex + 1)]
            }            
        })  
        .addCase(updateOSItem.rejected, (state, action)=>{
            state.update.updateLoading = false
            state.update.updateError = true
            state.update.updateSuccess = false
            state.update.updateMessage = action.payload
        })

        //delete a os item 
        .addCase(deleteOSItem.pending, (state)=>{
            state.delete.deleteLoading = true
            state.delete.deleteError = false
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = ''
        }) 
        .addCase(deleteOSItem.fulfilled, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = false
            state.delete.deleteSuccess = true
            state.delete.deleteMessage = ''
            toast.success('OS Item is deleted successfully.')
            const itemIndex = state.list.ositemlist.findIndex(e => e._id === action.payload)
            state.list.ositemlist.splice(itemIndex,1)
        })  
        .addCase(deleteOSItem.rejected, (state, action)=>{
            state.delete.deleteLoading = false
            state.delete.deleteError = true
            state.delete.deleteSuccess = false
            state.delete.deleteMessage = action.payload
        })


    },
})


export const { reset } = ositemSlice.actions
export default ositemSlice.reducer