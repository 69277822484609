import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div style={{padding: '100px 5%'}}>
        <div className='m-0 p-0' style={{width: '100%', height: '100%'}}>
        Terms
        </div>
    </div>
  )
}

export default PrivacyPolicy